import classNames from 'classnames/bind';
import React, { Suspense, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ListSkeleton from '../../../components/molecules/ListSkeleton';
import Header from '../../../components/organisms/Header';
import InputSearch from '../../../components/organisms/InputSearch';
import DefaultTemplate from '../../../components/templates/Default';
import { useLocationSearchQueryParam } from '../../../hooks/useLocationSearchQueryParam';
import { Type } from '../../../utils/decorators';
import styles from './index.module.scss';
import qs from 'qs';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  LinkfarmProductsRes,
  useLinkfarmProducts
} from '../../../hooks/http/linkfarm/product/fetch-linkfarm-product';
import Table from '../../../components/molecules/Table';
import Text from '../../../components/atoms/Text';
import List from '../../../components/molecules/List';
import Flex from '../../../components/molecules/Flex';
import ReadMore from '../../../components/molecules/ReadMore';
import Button from '../../../components/atoms/Button';
import {
  LinkfarmProducInspectiontStats,
  LinkfarmProductTaxType
} from '../../../types/linkfarm/product';
import DropdownMenu from '../../../components/molecules/DropdownMenu';
import { useModifyLinkfarmProductMutate } from '../../../hooks/http/linkfarm/product/modify-linkfarm-product';
import Modal from '../../../components/molecules/Modal';
import dayjs from 'dayjs';
import { LinkfarmProductResellSalePeriodType } from '../../../types/linkfarm';
import { CreateLinkfarmProductProductResellApi } from '../../../hooks/http/linkfarm/product/resell/create-linkfarm-product-resell';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LinkfarmProductResellForm from '../../../components/organisms/LinkfarmProductResellForm';
import { LinkfarmProductType } from '@aimpact-korea/arrange-front-types';
import TextInput from '../../../components/molecules/TextInput';
import Selection from '../../../components/molecules/Selection';
import { FetchPickuponSite } from '../../../hooks/http/pickupon-site/fetch-pickupon-site';
import { LinkProductPickuponSiteRound } from '../../../hooks/http/pickupon-site/link-product-pickupon-site-round';
import { UnlinkProductPickuponSiteRound } from '../../../hooks/http/pickupon-site/unlink-product-pickupon-site-round';

const cx = classNames.bind(styles);

export class LocationQuery {
  @Type(() => String)
  keyword?: string;
}

const LinkfarmProducts: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationQuery = useLocationSearchQueryParam(LocationQuery);

  const currentRedirectFromParam = ({ keyword }: { keyword?: string }) => {
    const queryStringObject = {
      ...locationQuery,
      keyword: keyword || undefined
    };

    navigate(`${location.pathname}?${qs.stringify(queryStringObject)}`);
  };

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'쇼핑몰 상품'}>
          <div className={cx('header-container')}>
            <InputSearch
              placeholder="상품검색"
              onClickSearch={(text) =>
                currentRedirectFromParam({ keyword: text })
              }
              defaultValue={locationQuery.keyword}
            />
          </div>
        </Header>

        <LinkfarmProductData keyword={locationQuery.keyword} />
      </Suspense>
    </DefaultTemplate>
  );
};

export default LinkfarmProducts;

const NOT_PICKUP_ON_SITE_VAL = '현장수령X';

function LinkfarmProductData({ keyword }: { keyword?: string }) {
  const query = useLinkfarmProducts({
    keyword,
    linkfarmProducInspectiontStatses: [LinkfarmProducInspectiontStats.SUCCESS],
    take: 20,
    cursor: {
      standardColumn: 'createdAt',
      orderBy: 'DESC'
    }
  });

  return (
    <InfiniteScroll
      style={{ overflow: 'hidden', minWidth: '2400px' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={query
        .data!.pages.map((page) => page.data.linkfarmProducts.length)
        .reduce((sum, current) => sum + current, 0)}
      next={query.fetchNextPage}
      hasMore={query.hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              No.
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체명 / 대표자
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              상품명 / 상품문구
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              상품옵션
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              수정일 / 등록일
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              상품보기
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              반응 스코어
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              링크팜 메인
              <br />
              노출
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              현장수령 상품
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              과세 / 면세
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              리셀러 상품 등록
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {query.data?.pages.map((group) =>
            group.data.linkfarmProducts.map((linkfarmProduct) => (
              <LinkfarmProductDataRow
                key={linkfarmProduct.id}
                linkfarmProduct={linkfarmProduct}
              />
            ))
          )}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
}

function LinkfarmProductDataRow({
  linkfarmProduct
}: {
  linkfarmProduct: LinkfarmProductsRes['linkfarmProducts'][number];
}) {
  const [
    isActiveLinkfarmProductInspectionState,
    setActiveLinkfarmProductInspectionState
  ] = useState(false);

  const linkProductPickuponSiteMutate =
    LinkProductPickuponSiteRound.useMutate();
  const unlinkProductPickuponSiteMutate =
    UnlinkProductPickuponSiteRound.useMutate();

  const form = useFormik<
    Pick<
      React.ComponentProps<typeof LinkfarmProductResellForm>,
      'form'
    >['form']['values']
  >({
    initialValues: {
      salePeriodEndAt: null,
      salePeriodStartAt: null,
      salePeriodType: LinkfarmProductResellSalePeriodType.ALL,
      resellerCommission: '0',
      saleCommission: '0',
      maxPickCount: 1
    },
    onSubmit: ({
      salePeriodEndAt,
      salePeriodStartAt,
      salePeriodType,
      resellerCommission,
      saleCommission,
      maxPickCount
    }) => {
      createLinkfarmProductProductResellApi.mutate({
        linkfarmProductId: linkfarmProduct.id,
        resellerCommission: Number(resellerCommission),
        saleCommission: Number(saleCommission),
        salePeriodType,
        salePeriodEndAt:
          salePeriodType === LinkfarmProductResellSalePeriodType.PERIOD
            ? dayjs(salePeriodEndAt).format('YYYY-MM-DD')
            : null,
        salePeriodStartAt:
          salePeriodType === LinkfarmProductResellSalePeriodType.PERIOD
            ? dayjs(salePeriodStartAt).format('YYYY-MM-DD')
            : null,
        maxPickCount: maxPickCount as number
      });
    }
  });

  const [taxType, setTaxType] = useState(linkfarmProduct.taxType);
  const [isShowCreateResellerModal, setShowCreateResellerModal] =
    useState(false);

  const pickuponSite = FetchPickuponSite.useFetchQuery();

  const createLinkfarmProductProductResellApi =
    CreateLinkfarmProductProductResellApi.useMutate({
      onSuccess: () => {
        alert('리셀러 상품으로 등록되었습니다.');
        setShowCreateResellerModal(false);
      },
      onError: (error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.exceptionCode
        ) {
          switch (error.response.data.exceptionCode) {
            case '':
              return;
          }
        }

        if (error instanceof Yup.ValidationError) {
          alert(error.message);
          return;
        }

        if (error.response?.data) {
          alert(error.response?.data);
          return;
        }
      }
    });

  const modifyLinkfarmMutate = useModifyLinkfarmProductMutate({
    onSuccess: (_, params) => {
      if (params.taxType) {
        setTaxType(params.taxType);
      }
    }
  });

  function onClickLinkfarmProduct() {
    window.open(linkfarmProduct.linkfarmFullUrl, '_blank');
  }

  function onChangeTaxType(value: string) {
    const v = value as LinkfarmProductTaxType;

    if (taxType === v) {
      return;
    }

    modifyLinkfarmMutate.mutate({
      taxType: v,
      linkfarmProductId: linkfarmProduct.id
    });
  }

  function onCreateLinkfarmProduct() {
    setShowCreateResellerModal(true);
  }

  function onChangeReactionScore(
    linkfarmProductId: number,
    reactionScore: number
  ) {
    modifyLinkfarmMutate.mutate({
      linkfarmProductId: linkfarmProductId,
      reactionScore
    });
  }

  function handleVisibleLinkfarmProductMain(
    linkfarmProductId: number,
    visible: boolean
  ) {
    modifyLinkfarmMutate.mutate({
      linkfarmProductId: linkfarmProductId,
      isVisibleLinkfarmMain: visible
    });
  }

  function onChangePickuponSiteRound(value: string) {
    if (value === NOT_PICKUP_ON_SITE_VAL) {
      unlinkProductPickuponSiteMutate.mutate({
        barofarmProductId: linkfarmProduct.id
      });
      return;
    }

    linkProductPickuponSiteMutate.mutate({
      barofarmProductId: linkfarmProduct.id,
      pickuponSiteRoundId: parseInt(value)
    });
  }

  return (
    <Table.Body.Row>
      <Table.Body.Row.Cell alignType="center">
        <Text size="small" isBold={true}>
          No. {linkfarmProduct.id}
        </Text>
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="left">
        <Flex gap="large" direction="column" alignItems="center">
          <Text isBold={true}>{linkfarmProduct.store.company}</Text>
          <Text>{linkfarmProduct.store.ceoName}</Text>
        </Flex>
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="center">
        <Flex gap="large" direction="column" alignItems="center">
          <Text>{linkfarmProduct.product.name}</Text>
          <Text>{linkfarmProduct.storeTitle}</Text>
        </Flex>
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="center">
        <ProductOption options={linkfarmProduct.product.options} />
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="center">
        <Flex gap="medium" direction="column" alignItems="center">
          <Text>{linkfarmProduct.updatedAt}</Text>
          <Text>{linkfarmProduct.createdAt}</Text>
        </Flex>
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="center">
        <Button text="보기" onClick={() => onClickLinkfarmProduct()} />
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="center">
        {linkfarmProduct.linkfarmProductType ===
          LinkfarmProductType.PAYMENT_GATEWAY && (
          <TextInput isContour={true}>
            <TextInput.Body>
              <TextInput.Body.Input
                value={linkfarmProduct.reactionScore}
                onChange={(event) => {
                  if (event.target.value === '') {
                    onChangeReactionScore(linkfarmProduct.id, 0);
                    return;
                  }

                  if (
                    event.target.value &&
                    !isNaN(parseInt(event.target.value)) &&
                    typeof parseInt(event.target.value) === 'number'
                  ) {
                    onChangeReactionScore(
                      linkfarmProduct.id,
                      parseInt(event.target.value)
                    );
                  }
                }}
              />
            </TextInput.Body>
          </TextInput>
        )}
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="center">
        <Selection>
          <Selection.Input
            type="toggle"
            id="aa_1"
            isChecked={linkfarmProduct.isVisibleLinkfarmMain}
            onChangeChecked={() =>
              handleVisibleLinkfarmProductMain(
                linkfarmProduct.id,
                !linkfarmProduct.isVisibleLinkfarmMain
              )
            }
          />
          <Selection.Label htmlFor="aa_1">노출</Selection.Label>
        </Selection>
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="center">
        {linkfarmProduct.linkfarmProductType ===
          LinkfarmProductType.PAYMENT_GATEWAY && (
          <Flex>
            <DropdownMenu
              setValue={(value) => onChangePickuponSiteRound(value)}
              value={`${
                linkfarmProduct.pickuponSiteRound?.id ?? NOT_PICKUP_ON_SITE_VAL
              }`}
            >
              <DropdownMenu.Title>
                <DropdownMenu.Title.UpDownIcon />
                <DropdownMenu.Title.Text />
              </DropdownMenu.Title>
              <DropdownMenu.ItemWrapper alignType="center" size="small">
                <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                    value={NOT_PICKUP_ON_SITE_VAL}
                  >
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                      text={NOT_PICKUP_ON_SITE_VAL}
                    />
                  </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                  {pickuponSite.data?.data.items.map((item) =>
                    item.pickupOnSiteRounds.map((pickupOnSiteRound) => (
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                        key={pickupOnSiteRound.id}
                        value={`${pickupOnSiteRound.id}`}
                      >
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                          text={`${item.name}_${pickupOnSiteRound.name}`}
                        />
                      </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                    ))
                  )}
                </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
              </DropdownMenu.ItemWrapper>
            </DropdownMenu>
          </Flex>
        )}
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="center">
        {linkfarmProduct.linkfarmProductType ===
          LinkfarmProductType.PAYMENT_GATEWAY && (
          <DropdownMenu
            isActive={isActiveLinkfarmProductInspectionState}
            setActive={setActiveLinkfarmProductInspectionState}
            setValue={onChangeTaxType}
            value={taxType}
          >
            <DropdownMenu.Title>
              <DropdownMenu.Title.UpDownIcon />
              <DropdownMenu.Title.Text />
            </DropdownMenu.Title>

            <DropdownMenu.ItemWrapper alignType="center" size="small">
              <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                  value={LinkfarmProductTaxType.TAX}
                >
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text text="과세" />
                </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>

                <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                  value={LinkfarmProductTaxType.TAX_FREE}
                >
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text text="면세" />
                </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
              </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
            </DropdownMenu.ItemWrapper>
          </DropdownMenu>
        )}
      </Table.Body.Row.Cell>
      <Table.Body.Row.Cell alignType="center">
        {!linkfarmProduct.linkfarmProductResell && (
          <React.Fragment>
            <Button text="등록" onClick={() => onCreateLinkfarmProduct()} />
            {isShowCreateResellerModal && (
              // TODO: 데이터 전송 Form으로 만들자.
              <Modal
                isOpen={isShowCreateResellerModal}
                sizeType="large"
                handleClose={() => setShowCreateResellerModal(false)}
              >
                <Modal.Header>
                  <Modal.Header.Title text="리셀러 상품 등록" />
                </Modal.Header>
                <Modal.Body>
                  <Flex style={{ width: '100%' }}>
                    <LinkfarmProductResellForm
                      form={form}
                      productImages={linkfarmProduct.images}
                      product={{ options: linkfarmProduct.product.options }}
                      storeTitle={linkfarmProduct.storeTitle}
                    />
                  </Flex>
                </Modal.Body>
                <Modal.Footer>
                  <Flex direction="row" gap="small" style={{ width: '100%' }}>
                    <Button
                      text="취소"
                      fullwidth={true}
                      type="secondary-color"
                      isLoading={
                        createLinkfarmProductProductResellApi.isLoading
                      }
                      htmlType="button"
                      onClick={() => setShowCreateResellerModal(false)}
                    />
                    <Button
                      text="완료"
                      fullwidth={true}
                      isLoading={
                        createLinkfarmProductProductResellApi.isLoading
                      }
                      htmlType="submit"
                      onClick={() => form.submitForm()}
                    />
                  </Flex>
                </Modal.Footer>
              </Modal>
            )}
          </React.Fragment>
        )}
      </Table.Body.Row.Cell>
    </Table.Body.Row>
  );
}

interface ProductOptionProps {
  options: LinkfarmProductsRes['linkfarmProducts'][number]['product']['options'];
}

const cutLine = 5;

const ProductOption: React.FC<ProductOptionProps> = ({ options }) => {
  const [readmoreTotalItem, setReadmoreTotalItem] = useState(0);
  const [readmore, setReadmore] = useState(false);

  return (
    <ReadMore setReadMore={setReadmore} readMore={readmore}>
      <List gap="small">
        <ReadMore.ItemWrapper
          cutline={cutLine}
          onChangeItem={(count) => setReadmoreTotalItem(count)}
        >
          {options.map((option) => (
            <div key={option.name} className="flex">
              <Text>{option.name}</Text>
            </div>
          ))}
        </ReadMore.ItemWrapper>
        {!readmore && readmoreTotalItem > cutLine && (
          <Text
            onClick={() => setReadmore(true)}
            colorType="gray-color"
            style={{ cursor: 'pointer' }}
          >
            + {readmoreTotalItem - cutLine}개의 상품 옵션
          </Text>
        )}
        {readmore && (
          <Text
            colorType="gray-color"
            style={{ cursor: 'pointer' }}
            onClick={() => setReadmore(false)}
          >
            숨기기
          </Text>
        )}
      </List>
    </ReadMore>
  );
};
