import { Suspense } from 'react';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import { ContractTermsType } from '../../types/contract-terms';
import { ContractTermsDetail } from '../../components/features/contract-terms/ContreactTermsDetail';

export default function LinkfarmAgreement() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'링크팜 이용약관'}></Header>
        <ContractTermsDetail type={ContractTermsType.LINKFARM_AGREEMENT} />
      </Suspense>
    </DefaultTemplate>
  );
}
