import { WeatherThunderIcon } from '@aimpact-korea/arrange-front-icons/build/icons';
import Text from '../../atoms/Text';
import Flex from '../../molecules/Flex';

export interface Props {
  text?: string;
  iconType?: 'weather-thunder';
  children?: React.ReactNode;
}

const ErrorDisplay: React.FC<Props> = ({
  iconType = 'weather-thunder',
  text,
  children
}) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap="xxlarge"
    >
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="xsmall"
      >
        {iconType === 'weather-thunder' && (
          <WeatherThunderIcon
            width="126"
            height="126"
            colorType="text-gray-500"
          />
        )}
        {text && (
          <Text colorType="gray-color" size="xlarge">
            {text}
          </Text>
        )}
      </Flex>
      {children}
    </Flex>
  );
};

export default ErrorDisplay;
