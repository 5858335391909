import { Span } from '@aimpact-korea/arrange-front-atomic';
import { LinkfarmProductResellSalePeriodType } from '../../../types/linkfarm';
import Flex from '../../molecules/Flex';
import Selection from '../../molecules/Selection';
import Table from '../../molecules/Table';
import TextInput from '../../molecules/TextInput';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Calendar, OnChangeDateRangeCallback } from 'react-calendar';
import dayjs from 'dayjs';
import { Image } from '../../molecules/Image';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type Props = {
  storeTitle: string;
  productImages: {
    attachmentId: number;
    url: string;
  }[];
  product: {
    options: {
      code: string;
      name: string;
      price: number | null;
      normalPrice: number;
      stock: number | null;
    }[];
  };
  form: Pick<
    FormikProps<{
      salePeriodType: LinkfarmProductResellSalePeriodType;
      saleCommission?: string;
      resellerCommission?: string;
      salePeriodEndAt: string | null;
      salePeriodStartAt: string | null;
      maxPickCount?: number;
    }>,
    'values' | 'setValues' | 'setFieldValue'
  >;
};

export default function LinkfarmProductResellForm({
  form,
  storeTitle,
  product,
  productImages
}: Props) {
  const [isShowResellDateRange, setShowResellDateRange] = useState(false);

  const { setValues } = form;

  useEffect(() => {
    if (
      form.values.salePeriodType === LinkfarmProductResellSalePeriodType.ALL
    ) {
      setValues((values) => ({
        ...values,
        salePeriodStartAt: null,
        salePeriodEndAt: null
      }));
    }
  }, [form.values.salePeriodType, setValues]);

  function handleChangeResellDateRange([startDate, endDate]: [Date, Date]) {
    startDate = startDate ?? null;
    endDate = endDate ?? null;

    form.setFieldValue(
      'salePeriodStartAt',
      startDate && dayjs(startDate).format('YYYY-MM-DD')
    );
    form.setFieldValue(
      'salePeriodEndAt',
      endDate && dayjs(endDate).format('YYYY-MM-DD')
    );

    if (
      endDate &&
      form.values.salePeriodEndAt !== dayjs(endDate).format('YYYY-MM-DD')
    ) {
      setShowResellDateRange(false);
    }
  }

  function resellDateSpan() {
    if (form.values.salePeriodStartAt && form.values.salePeriodEndAt) {
      return `${dayjs(form.values.salePeriodStartAt).format(
        'YYYY-MM-DD'
      )} ~ ${dayjs(form.values.salePeriodEndAt).format('YYYY-MM-DD')}`;
    }

    return '판매기간을 선택해주세요.';
  }

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <Table type="simple-table" className={`${cx('simple-table')}`}>
        <Table.Body>
          <Table.Body.Row>
            <Table.Body.Row.Cell tag="th" width={'120px'}>
              상품명
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              {storeTitle}
            </Table.Body.Row.Cell>
          </Table.Body.Row>
          <Table.Body.Row>
            <Table.Body.Row.Cell tag="th">상품이미지</Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <Flex direction="row" gap="xsmall" style={{ overflowX: 'auto' }}>
                {productImages.map((productImage) => (
                  <Image
                    width={74}
                    height={74}
                    src={productImage.url}
                    key={productImage.attachmentId}
                  />
                ))}
              </Flex>
            </Table.Body.Row.Cell>
          </Table.Body.Row>
          <Table.Body.Row>
            <Table.Body.Row.Cell tag="th">상품 가격</Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <Flex>
                {product.options.map((option) => (
                  <Span key={option.code} textAlign="text-left">
                    {option.name} :{' '}
                    {(option.price ?? option.normalPrice).toLocaleString()}원
                  </Span>
                ))}
              </Flex>
            </Table.Body.Row.Cell>
          </Table.Body.Row>
          <Table.Body.Row>
            <Table.Body.Row.Cell tag="th">재고</Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <Flex>
                {product.options.map((option) => (
                  <Span key={option.code} textAlign="text-left">
                    {option.name} :{' '}
                    {option.stock === null
                      ? '재고 무한'
                      : option.stock === 0
                      ? '재고 없음'
                      : `${option.stock}개`}
                  </Span>
                ))}
              </Flex>
            </Table.Body.Row.Cell>
          </Table.Body.Row>
          <Table.Body.Row>
            <Table.Body.Row.Cell tag="th">판매기간</Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <Flex
                gap="small"
                style={{ position: 'relative' }}
                flexType="inline-flex"
                justifyContent="start"
                alignItems="start"
              >
                <Selection>
                  <Selection.Input
                    type="radio"
                    id="aa_1"
                    value={LinkfarmProductResellSalePeriodType.ALL}
                    isChecked={
                      form.values.salePeriodType ===
                      LinkfarmProductResellSalePeriodType.ALL
                    }
                    onChangeChecked={() =>
                      form.setFieldValue(
                        'salePeriodType',
                        LinkfarmProductResellSalePeriodType.ALL
                      )
                    }
                  />
                  <Selection.Label htmlFor="aa_1">연중</Selection.Label>
                </Selection>
                <Selection>
                  <Selection.Input
                    type="radio"
                    id="aa_2"
                    value={LinkfarmProductResellSalePeriodType.PERIOD}
                    isChecked={
                      form.values.salePeriodType ===
                      LinkfarmProductResellSalePeriodType.PERIOD
                    }
                    onChangeChecked={() =>
                      form.setFieldValue(
                        'salePeriodType',
                        LinkfarmProductResellSalePeriodType.PERIOD
                      )
                    }
                  />
                  <Selection.Label htmlFor="aa_2">
                    판매기간 선택
                  </Selection.Label>
                </Selection>
                {form.values.salePeriodType ===
                  LinkfarmProductResellSalePeriodType.PERIOD && (
                  <React.Fragment>
                    <Span
                      style={{ cursor: 'pointer' }}
                      textAlign="text-left"
                      onClick={() => setShowResellDateRange(true)}
                    >
                      {resellDateSpan()}
                    </Span>

                    {isShowResellDateRange && (
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setShowResellDateRange(false);
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            zIndex: '100'
                          }}
                        >
                          <Calendar
                            allowPartialRange={true}
                            returnValue={'range'}
                            selectRange={true}
                            minDate={new Date()}
                            value={[
                              form.values.salePeriodStartAt
                                ? dayjs(form.values.salePeriodStartAt).toDate()
                                : null,
                              form.values.salePeriodEndAt
                                ? dayjs(form.values.salePeriodEndAt).toDate()
                                : null
                            ]}
                            onChange={
                              ((date) => {
                                handleChangeResellDateRange(
                                  date as [Date, Date]
                                );
                              }) as OnChangeDateRangeCallback
                            }
                            locale="ko"
                          />
                        </div>
                      </OutsideClickHandler>
                    )}
                  </React.Fragment>
                )}
              </Flex>
            </Table.Body.Row.Cell>
          </Table.Body.Row>
          <Table.Body.Row>
            <Table.Body.Row.Cell tag="th">판매수수료</Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <TextInput isContour={true} widthType="auto">
                <TextInput.Body>
                  <TextInput.Body.Input
                    value={form.values.saleCommission}
                    onChange={(event) => {
                      form.setFieldValue('saleCommission', event.target.value);
                    }}
                  />
                  %
                </TextInput.Body>
              </TextInput>
            </Table.Body.Row.Cell>
          </Table.Body.Row>
          <Table.Body.Row>
            <Table.Body.Row.Cell tag="th">리셀러 수수료</Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <TextInput isContour={true} widthType="auto">
                <TextInput.Body>
                  <TextInput.Body.Input
                    value={form.values.resellerCommission}
                    onChange={(event) => {
                      form.setFieldValue(
                        'resellerCommission',
                        event.target.value
                      );
                    }}
                  />
                  %
                </TextInput.Body>
              </TextInput>
            </Table.Body.Row.Cell>
          </Table.Body.Row>
          <Table.Body.Row>
            <Table.Body.Row.Cell tag="th">
              에이임팩트 수수료
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              {form.values.saleCommission && form.values.resellerCommission && (
                <Span>
                  {(
                    Number(form.values.saleCommission) -
                    Number(form.values.resellerCommission)
                  ).toFixed(2)}
                  %
                </Span>
              )}
            </Table.Body.Row.Cell>
          </Table.Body.Row>
          <Table.Body.Row>
            <Table.Body.Row.Cell tag="th">리셀러 픽 카운트</Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <TextInput isContour={true} widthType="auto">
                <TextInput.Body>
                  <TextInput.Body.Input
                    value={form.values.maxPickCount}
                    type={'number'}
                    onChange={(event) => {
                      form.setFieldValue(
                        'maxPickCount',
                        Number(event.target.value)
                      );
                    }}
                  />
                  명
                </TextInput.Body>
              </TextInput>
            </Table.Body.Row.Cell>
          </Table.Body.Row>
        </Table.Body>
      </Table>
    </div>
  );
}
