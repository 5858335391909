import {
  InfiniteData,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useQueryClient
} from '@tanstack/react-query';
import axios from '../../../utils/axios';
import qs from 'qs';
import { isNextCursor, QueryInfiniteCallback } from '..';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { Optional } from 'utility-types';
import produce from 'immer';
import { Bank } from '@aimpact-korea/arrange-front-types';

export namespace FetchResellerOrderSettles {
  export type ResponseType = {
    items: {
      id: number;
      resellerId: number;
      resellerName: string;
      resellerPhone: string;
      originalTotalAmount: number;
      totalAmount: number;
      totalAmountVat: number;
      vat: string;
      commission: number;
      vatCommission: number;
      settleDate: string;
      isSettle: true;
      bankName: Bank;
      bankAccount: string;
      bankHolder: string;
      createdAt: string;
    }[];
    cursor: CursorType | null;
  };

  export type CursorType = {
    id?: number;
    standardColumn?: 'createdAt' | 'settleDate' | undefined;
    orderBy?: 'DESC' | 'ASC' | null | undefined;
    value?: string | number;
  };

  export type RequestType = {
    limit?: number;
    cursor?: CursorType;
  };

  export const KEY_STRING = 'fetch-reseller-order-settles' as const;

  export type QueryKeyType = [typeof KEY_STRING, RequestType];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    pageParam,
    queryKey
  }: QueryFunctionContext<QueryKeyType2, CursorType>) {
    const _queryKey = queryKey as QueryKeyType;

    let cursor: CursorType | undefined = undefined;
    if (pageParam) {
      cursor = pageParam;
    } else if (_queryKey[1].cursor) {
      cursor = {};
      if (_queryKey[1].cursor.id) {
        cursor.id = _queryKey[1].cursor.id;
      }
      if (_queryKey[1].cursor.orderBy) {
        cursor.orderBy = _queryKey[1].cursor.orderBy;
      }
      if (_queryKey[1].cursor.standardColumn) {
        cursor.standardColumn = _queryKey[1].cursor.standardColumn;
      }
      if (_queryKey[1].cursor.value) {
        cursor.value = _queryKey[1].cursor.value;
      }
    }

    return await axios.get<ResponseType>(
      '/reseller-order-settles?' + qs.stringify({ cursor }),
      {
        params: {
          limit: _queryKey[1].limit
        }
      }
    );
  }

  export const useModify = () => {
    const queryClient = useQueryClient();

    const modify = (
      resellerOrderSettle: Optional<ResponseType['items'][number]> & {
        id: number;
      }
    ) => {
      queryClient.setQueriesData(
        [KEY_STRING],
        (previous: InfiniteData<AxiosResponse<ResponseType>> | undefined) => {
          const _previous = previous as InfiniteData<
            AxiosResponse<ResponseType>
          >;
          return produce(_previous, (draft) => {
            draft.pages = draft.pages.map((page) => {
              page.data.items = page.data.items.map((item) => {
                if (item.id === resellerOrderSettle.id) {
                  return {
                    ...item,
                    ...resellerOrderSettle
                  };
                }
                return item;
              });

              return page;
            });
          });
        }
      );
    };

    return { modify };
  };

  export const useFetch = (
    {
      cursor: { id, orderBy, standardColumn, value } = {},
      limit = 15
    }: RequestType = { limit: 15 },
    {
      ...props
    }: QueryInfiniteCallback<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useInfiniteQuery(
      [
        KEY_STRING,
        {
          cursor: {
            id,
            orderBy,
            standardColumn,
            value
          },
          limit
        }
      ],
      fetchData,
      {
        getNextPageParam: (data, datas) => {
          return isNextCursor(data.data.cursor, datas);
        },
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}
