import { BANK_NAME } from '@aimpact-korea/arrange-front-types';
import classNames from 'classnames/bind';
import React, { Suspense, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from '../../../components/atoms/Button';
import Text from '../../../components/atoms/Text';
import Textarea from '../../../components/atoms/Textarea';
import Flex from '../../../components/molecules/Flex';
import ListSkeleton from '../../../components/molecules/ListSkeleton';
import Modal from '../../../components/molecules/Modal';
import Selection from '../../../components/molecules/Selection';
import Table from '../../../components/molecules/Table';
import Header from '../../../components/organisms/Header';
import DefaultTemplate from '../../../components/templates/Default';
import { ApproveResellerRequest } from '../../../hooks/http/reseller-request/approve-reseller-request';
import { FetchResellerRequest } from '../../../hooks/http/reseller-request/fetch-reseller-request';
import { RejectResellerRequest } from '../../../hooks/http/reseller-request/reject-reseller-request';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export default function ResellerRequests() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'리셀러 신청 리스트'}>
          <div className={cx('header-container')}>
            {/* <InputSearch
              placeholder="상품검색"
              onClickSearch={(text) =>
                currentRedirectFromParam({ keyword: text })
              }
              defaultValue={locationQuery.keyword}
            /> */}
          </div>
        </Header>

        {/* <LinkfarmProductData keyword={locationQuery.keyword} /> */}
        <ResellerRequestData />
      </Suspense>
    </DefaultTemplate>
  );
}

function ResellerRequestData() {
  const query = FetchResellerRequest.useFetch({ limit: 20 });

  return (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={query
        .data!.pages.map((page) => page.data.items.length)
        .reduce((sum, current) => sum + current, 0)}
      next={query.fetchNextPage}
      hasMore={query.hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              No.
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              채널명
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              성명
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              연락처
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              주소
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              인스타 / 유튜브
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              정산계좌
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              요청 상태
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              승인
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {query.data?.pages.map((group) =>
            group.data.items.map((item) => (
              <ResellerRequestRow key={item.id} resellerRequest={item} />
            ))
          )}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
}

function ResellerRequestRow({
  resellerRequest
}: {
  resellerRequest: FetchResellerRequest.ResponseType['items'][number];
}) {
  const approveResellerRequest = ApproveResellerRequest.useMutate({
    onSuccess: () => {
      alert('리셀러 신청이 승인되었습니다.');
    }
  });
  const rejectResellerRequest = RejectResellerRequest.useMutate({
    onSuccess: () => {
      alert('리셀러 신청이 거절 되었습니다.');
    }
  });
  const [isShowApproveOrRejectModal, setShowApproveOrRejectModal] =
    useState(false);
  const [isShowRejectModal, setShowRejectModal] = useState(false);
  const [approveOrReject, setApproveOrReject] = useState<
    'APPROVE' | 'REJECT' | undefined
  >();
  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false);
  const [failedCauseInput, setFailedCauseInput] = useState('');

  function onClickLinkfarmProductInspectionFail() {
    if (failedCauseInput.length < 10) {
      alert('거절 사유는 10글자 이상 작성해야 합니다.');
      return;
    }

    if (confirm('정말 리셀러 신청을 거절하시겠습니까?')) {
      rejectResellerRequest.mutate({
        rejectReason: failedCauseInput,
        resellerRequestId: resellerRequest.id
      });
      setShowRejectModal(false);
      setFailedCauseInput('');
    }
  }

  function handleShowRejectReasonModal(isShow: boolean) {
    setShowRejectReasonModal(isShow);

    setFailedCauseInput(isShow ? resellerRequest.rejectReason ?? '' : '');
  }

  return (
    <React.Fragment>
      {showRejectReasonModal && (
        <Modal
          sizeType="large"
          isOpen={showRejectReasonModal}
          handleClose={() => setShowRejectReasonModal(false)}
        >
          <Modal.Header>
            <Modal.Header.Title text="거절사유" />
          </Modal.Header>
          <Modal.Body>
            <Textarea
              style={{ width: '100%', height: '400px' }}
              // placeholder="거절사유를 입력해주세요."
              value={failedCauseInput}
              readOnly={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <Flex direction="row" style={{ width: '100%' }} gap="xsmall">
              <Button
                fullwidth
                text="닫기"
                onClick={() => setShowRejectReasonModal(false)}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
      {isShowRejectModal && (
        <Modal
          sizeType="large"
          isOpen={isShowRejectModal}
          handleClose={() => setShowRejectModal(false)}
        >
          <Modal.Header>
            <Modal.Header.Title text="거절사유" />
          </Modal.Header>
          <Modal.Body>
            <Textarea
              style={{ width: '100%', height: '400px' }}
              placeholder="거절사유를 입력해주세요."
              value={failedCauseInput}
              onChange={(event) => setFailedCauseInput(event.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Flex direction="row" style={{ width: '100%' }} gap="xsmall">
              <Button
                fullwidth
                text="닫기"
                onClick={() => setShowRejectModal(false)}
              />
              <Button
                fullwidth
                text="승인거절"
                onClick={() => onClickLinkfarmProductInspectionFail()}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
      {isShowApproveOrRejectModal && (
        <Modal sizeType="small" isOpen={isShowApproveOrRejectModal}>
          <Modal.Header>
            <Modal.Header.Title text="승인이나 거절을 선택해주세요" />
          </Modal.Header>
          <Modal.Body>
            <Flex direction="column" gap="xsmall">
              <Selection>
                <Selection.Input
                  type="radio"
                  id="aa_1"
                  value={'APPROVE'}
                  isChecked={approveOrReject === 'APPROVE'}
                  onChangeChecked={() => setApproveOrReject('APPROVE')}
                />
                <Selection.Label htmlFor="aa_1">승인</Selection.Label>
              </Selection>
              <Selection>
                <Selection.Input
                  type="radio"
                  id="aa_2"
                  value={'REJECT'}
                  isChecked={approveOrReject === 'REJECT'}
                  onChangeChecked={() => setApproveOrReject('REJECT')}
                />
                <Selection.Label htmlFor="aa_2">거절</Selection.Label>
              </Selection>
            </Flex>
          </Modal.Body>
          <Modal.Footer>
            <Flex direction="row" style={{ width: '100%' }} gap="xsmall">
              <Button
                fullwidth
                text="닫기"
                onClick={() => {
                  setShowApproveOrRejectModal(false);
                  setApproveOrReject(undefined);
                }}
              />
              <Button
                fullwidth
                text="확인"
                onClick={() => {
                  if (approveOrReject === 'APPROVE') {
                    approveResellerRequest.mutate({
                      resellerRequestId: resellerRequest.id
                    });
                  }
                  if (approveOrReject === 'REJECT') {
                    setShowRejectModal(true);
                  }
                  setApproveOrReject(undefined);
                  setShowApproveOrRejectModal(false);
                }}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
      <Table.Body.Row>
        <Table.Body.Row.Cell alignType="center">
          <Text size="small" isBold={true}>
            No. {resellerRequest.id}
          </Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="left">
          <Flex gap="large" direction="column" alignItems="center">
            <Text isBold={true}>{resellerRequest.channelName}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="large" direction="column" alignItems="center">
            <Text>{resellerRequest.account.name}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Text>{resellerRequest.account.phone}</Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="medium" direction="column" alignItems="center">
            <Text>
              [{resellerRequest.postCode}] {resellerRequest.address}
            </Text>
            <Text>{resellerRequest.detailAddress}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="medium" direction="column" alignItems="center">
            {resellerRequest.instagramId && (
              <Text>인스타 : {resellerRequest.instagramId}</Text>
            )}
            {resellerRequest.youtubeId && (
              <Text>유튜브 : {resellerRequest.youtubeId}</Text>
            )}
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="medium" direction="column" alignItems="center">
            <Text>은행 : {BANK_NAME[resellerRequest.bankInfo.bank]}</Text>
            <Text>계좌번호 : {resellerRequest.bankInfo.bankAccount}</Text>
            <Text>예금주명 : {resellerRequest.bankInfo.bankHolder}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="medium" alignItems="center">
            {resellerRequest.state === 'WAIT' && (
              <Text isBold={true}>대기</Text>
            )}
            {resellerRequest.state === 'REJECT' && (
              <Text isBold={true} colorType="secondary-color">
                거절
              </Text>
            )}
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          {resellerRequest.state === 'WAIT' && (
            <Button
              text="승인/거절"
              isLoading={approveResellerRequest.isLoading}
              onClick={() => setShowApproveOrRejectModal(true)}
            />
          )}
          {resellerRequest.state === 'REJECT' && (
            <Button
              text="거절사유"
              type="secondary-color"
              isLoading={approveResellerRequest.isLoading}
              onClick={() => handleShowRejectReasonModal(true)}
            />
          )}
        </Table.Body.Row.Cell>
      </Table.Body.Row>
    </React.Fragment>
  );
}
