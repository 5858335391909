import styles from './body.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export interface Props {
  children: React.ReactNode;
}

const Body: React.FC<Props> = ({ children }) => {
  return <div className={cx('container')}>{children}</div>;
};

export default Body;
