import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { arrangeAppApi } from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';

export namespace FetchArrangeManualGroupDetail {
  export type RequestType = {
    arrangeManualGroupId: number;
  };

  export type ResponseType = {
    id: number;
    title: string;
    order: number;
    code: string;
    itemList: {
      id: number;
      title: string;
      movieId: string;
      order: number;
      isImportant: boolean;
      code: string;
      thumbnailImage: {
        attachmentId: number;
        url: string;
      } | null;
    }[];
  };

  export const KEY_STRING = 'fetch-arrange-manual-group-detail' as const;

  export type QueryKeyType = [typeof KEY_STRING, RequestType];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    queryKey
  }: QueryFunctionContext<QueryKeyType2>) {
    const _queryKey = queryKey as QueryKeyType;

    return await arrangeAppApi.get<ResponseType>(
      `/v1/arrange-manual-group/${_queryKey[1].arrangeManualGroupId}`
    );
  }

  export const useRefetchData = () => {
    const queryClient = useQueryClient();

    const refetch = ({
      arrangeManualGroupId
    }: {
      arrangeManualGroupId: number;
    }) => {
      queryClient.refetchQueries([KEY_STRING, { arrangeManualGroupId }]);
    };

    return { refetch };
  };

  export const useFetch = (
    { arrangeManualGroupId }: RequestType,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING, { arrangeManualGroupId }], fetchData, {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
