import { useEffect, useRef, useState } from 'react';
import { SizeType } from '../../../types/style';
import Text from '../../atoms/Text';
import Flex from '../Flex';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Selection from '../Selection';
import { getRandomHash } from '../../../utils/crypt';
import { ItemCheckedType } from '../../../utils/dropdown-item';
import OutsideClickHandler from 'react-outside-click-handler';
import { IconProps } from '@aimpact-korea/arrange-front-icons/build/icons';

const cx = classNames.bind(styles);

export interface Props {
  sizeType?: Extract<SizeType, 'medium'>;
  IconComponent?: React.FC<IconProps>;
  placeholder?: string;
  isActive?: boolean;
  setActive?: (state: boolean) => void;
  items: ItemCheckedType[];
  setChangeCheckedIndex?: (idx: number, state: boolean) => void;
  handleClose?: () => void;
}

const MultiDropdownMenu: React.FC<Props> = ({
  sizeType = 'medium',
  IconComponent,
  placeholder,
  isActive,
  setActive,
  items,
  setChangeCheckedIndex,
  handleClose
}) => {
  const [isActiveState, setActiveState] = useState(isActive ?? false);
  const hashId = useRef(items.map(() => getRandomHash()));

  const handleActiveState = (state: boolean) => {
    setActive?.(state);
    isActive ?? setActiveState(state);

    if (state === false) {
      handleClose?.();
    }
  };

  useEffect(() => {
    if (typeof isActive === 'boolean') {
      setActiveState(isActive);
    }
  }, [isActive]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (isActiveState) {
          handleActiveState(false);
        }
      }}
    >
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <Flex
          flexType="inline-flex"
          style={{ cursor: 'pointer', marginBottom: '4px' }}
          direction="row"
          alignItems="center"
          onClick={(e) => {
            e.stopPropagation();

            handleActiveState(!isActiveState);
          }}
        >
          <Text isBold={true}>{placeholder}</Text>
          {IconComponent && <IconComponent colorType="text-gray-800" />}
        </Flex>
        <Flex
          className={cx(
            'item-wrapper',
            { show: isActiveState },
            `size-type--${sizeType}`,
            'bg-color--gray-light-color'
          )}
          gap="large"
        >
          {items.map((item, index) => (
            <Selection key={item.value} className={cx('flex-left')}>
              <Selection.Input
                id={hashId.current[index]}
                isChecked={item.checked}
                onChangeChecked={() => {
                  setChangeCheckedIndex?.(index, !item.checked);
                }}
                type="checkbox"
                value={item.value}
              />
              <Selection.Label
                htmlFor={hashId.current[index]}
                colorType={
                  item.checked === true ? item.colorType : 'gray-dark-color'
                }
                style={{ userSelect: 'none' }}
              >
                {item.text}
              </Selection.Label>
            </Selection>
          ))}
        </Flex>
      </div>
    </OutsideClickHandler>
  );
};

export default MultiDropdownMenu;
