import classNames from 'classnames/bind';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { SizeType } from '../../../types/style';
import DropdownItemWrapper from '../DropdownItem';
import { ActionKind, DropdownMenuContext, initState, reducer } from './context';
import styles from './index.module.scss';
import Label from './subcomponents/Label';
import Title from './subcomponents/Title';

const cx = classNames.bind(styles);

export interface Props {
  children: React.ReactNode;
  size?: Extract<SizeType, 'medium' | 'large' | 'xxlarge'>;
  isActive?: boolean;
  value?: string;
  disabled?: boolean;
  setActive?: (state: boolean) => void;
  setValue?: (value: string) => void;
}

type DropdownMenuComponet = React.FC<Props> & {
  ItemWrapper: typeof DropdownItemWrapper;
  Label: typeof Label;
  Title: typeof Title;
};

const DropdownMenu: DropdownMenuComponet = ({
  children,
  size = 'medium',
  isActive,
  value,
  setActive,
  setValue,
  disabled = false
}: Props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [isActiveState, setActiveState] = useState(isActive);

  const handleSetActive = useCallback(
    (state: boolean) => {
      const func = setActive ?? setActiveState;
      func(state);
    },
    [setActive]
  );

  useEffect(() => {
    dispatch({
      type: ActionKind.SET_STATE,
      payload: {
        size: size,
        isActive: isActive ?? isActiveState,
        value: value ? state.items[value] : undefined,
        setActive: handleSetActive,
        setValue: setValue,
        disabled
      }
    });
  }, [
    disabled,
    handleSetActive,
    isActive,
    isActiveState,
    setActive,
    setValue,
    size,
    state.items,
    value
  ]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (state.isActive) {
          handleSetActive?.(false);
        }
      }}
    >
      <DropdownMenuContext.Provider value={{ state, dispatch }}>
        <div className={cx('container')}>{children}</div>
      </DropdownMenuContext.Provider>
    </OutsideClickHandler>
  );
};

DropdownMenu.ItemWrapper = DropdownItemWrapper;
DropdownMenu.Label = Label;
DropdownMenu.Title = Title;
export default DropdownMenu;
