import { Quill } from 'react-quill';
import ReactQuill from 'react-quill';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ImageResize from 'quill-image-resize';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import VideoResize from 'quill-video-resize-module';
import Quill2 from 'quill';
window.Quill = Quill2;

const DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);

const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

const BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);

const ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);

const DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);

const FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);

const SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);

const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);

const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);

const FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);

const SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);

const Parchment = Quill.import('parchment');

class IndentAttributor extends Parchment.Attributor.Style {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(node: any, value: any) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const IndentStyle = new IndentAttributor('indent', 'text-indent', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em']
});

Quill.register(IndentStyle, true);

const ImageBlot = ReactQuill.Quill.import('formats/image');
export class CustomImageBlot extends ImageBlot {
  static blotName = 'customImage';
  static tagName = 'img';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create(value: any) {
    const node = super.create();
    console.log('value', value);

    node.setAttribute('src', value.src);
    if (
      value['attachment-id'] &&
      value['attachment-id'] !== 'null' &&
      value['attachment-id'] !== 'undefined'
    ) {
      node.setAttribute('attachment-id', value['attachment-id']);
      if (value['style']) {
        node.setAttribute('style', value['style']);
      }
      if (value['align']) {
        node.setAttribute('align', value['align']);
      }
    }

    return node;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static value(node: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const blot: any = {};

    blot.src = node.getAttribute('src');

    if (
      node.getAttribute('attachment-id') &&
      node.getAttribute('attachment-id') !== 'null' &&
      node.getAttribute('attachment-id') !== 'undefined'
    ) {
      blot['attachment-id'] = node.getAttribute('attachment-id');

      if (node.getAttribute('style')) {
        blot['style'] = node.getAttribute('style');
      }

      if (node.getAttribute('align')) {
        blot['align'] = node.getAttribute('align');
      }
    }

    return blot;
  }
}
ReactQuill.Quill.register(CustomImageBlot, true);

ReactQuill.Quill.register('modules/ImageResize', ImageResize);

const VideoBlot = ReactQuill.Quill.import('formats/video');

export class CustomVideoBlot extends VideoBlot {
  static blotName = 'customVideo';
  static tagName = 'IFRAME';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create(value: any) {
    const node = super.create();

    if (value['style']) {
      node.setAttribute('style', value['style']);
    }
    if (value['align']) {
      node.setAttribute('align', value['align']);
    }

    node.setAttribute('src', value['src']);

    return node;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static value(node: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const blot: any = {};

    if (node.getAttribute('style')) {
      blot['style'] = node.getAttribute('style');
    }

    if (node.getAttribute('align')) {
      blot['align'] = node.getAttribute('align');
    }

    blot['src'] = node.getAttribute('src');

    return blot;
  }
}

ReactQuill.Quill.register(CustomVideoBlot, true);

// eslint-disable-next-line @typescript-eslint/no-var-requires
const VideoResize = require('quill-video-resize-module').default;

ReactQuill.Quill.register('modules/VideoResize', VideoResize);
