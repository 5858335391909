import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Text from '../../atoms/Text';

function Link({
  children,
  ...props
}: React.ComponentProps<typeof ReactRouterLink>) {
  return (
    <ReactRouterLink {...props}>
      <Text isBold={true} textDecoration="underline" colorType="primary-color">
        {children}
      </Text>
    </ReactRouterLink>
  );
}

export default Link;
