import { ColorType } from './style';

export enum OrderType {
  MESSAGE = 'MESSAGE',
  STORE = 'STORE'
}

export enum OrderState {
  CANCELLED = 'CANCELLED', // 취소
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT', // 결제대기
  PAID = 'PAID', // 결제완료
  DELIVERY_READY = 'DELIVERY_READY', // 발송준비
  SHIPPED = 'SHIPPED', // 발송완료
  EXCHANGED = 'EXCHANGED', // 교환완료
  REFUND_REQUEST = 'REFUND_REQUEST', // 환불/반품 요청
  REFUND = 'REFUND', // 환불/반품 완료
  EXCHANGE_REQUESTED = 'EXCHANGE_REQUESTED', // 교환요청
  RE_DELIVERY = 'RE_DELIVERY', // 재주문 (파생된 주문)
  PICK_UP_ON_SITE_RECEIVED = 'PICK_UP_ON_SITE_RECEIVED', // 현장수령 수령 완료
  PICK_UP_ON_SITE_NOT_RECEIVED = 'PICK_UP_ON_SITE_NOT_RECEIVED' // 현장수령 미수령
}

export const OrderStateInfo: {
  [key in keyof typeof OrderState]: {
    color: ColorType;
    text: string;
  };
} = {
  [OrderState.PAID]: {
    color: 'primary-color',
    text: '결제완료'
  },
  [OrderState.EXCHANGE_REQUESTED]: {
    color: 'blue-color',
    text: '교환요청'
  },
  [OrderState.EXCHANGED]: {
    color: 'black',
    text: '교환'
  },
  [OrderState.CANCELLED]: {
    color: 'secondary-color',
    text: '주문취소'
  },
  [OrderState.WAITING_FOR_PAYMENT]: {
    color: 'black',
    text: '결제대기'
  },
  [OrderState.SHIPPED]: {
    color: 'gray-color',
    text: '발송완료'
  },
  [OrderState.REFUND_REQUEST]: {
    color: 'primary-color',
    text: '환불/반품 요청'
  },
  [OrderState.REFUND]: {
    color: 'primary-color',
    text: '환불/반품 완료'
  },
  [OrderState.DELIVERY_READY]: {
    color: 'primary-color',
    text: '발송준비'
  },
  [OrderState.RE_DELIVERY]: {
    color: 'primary-color',
    text: '재발송'
  },
  [OrderState.PICK_UP_ON_SITE_RECEIVED]: {
    color: 'blue-color',
    text: '수령완료'
  },
  [OrderState.PICK_UP_ON_SITE_NOT_RECEIVED]: {
    color: 'gray-light-color',
    text: '미수령'
  }
} as const;
