import classNames from 'classnames/bind';
import React from 'react';
import { ColorType } from '../../../types/style';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props extends React.ComponentPropsWithoutRef<'hr'> {
  color?: ColorType;
  size?: string;
}

const Hr = React.forwardRef<HTMLHRElement, Props>(
  ({ size = '1px', color = 'gray-color', ...props }, ref) => {
    return (
      <hr
        ref={ref}
        style={{ height: size }}
        className={`${cx('hr')} bg-color--${color}`}
        {...props}
      />
    );
  }
);

Hr.displayName = 'Hr';

export default Hr;
