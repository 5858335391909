import { AxiosResponse, AxiosError } from 'axios';
import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import axios from '../../../utils/axios';

export namespace FetchStatsByYear {
  export type StatsByYearRes = {
    type: 'monthly' | 'year';
    count: number;
    amount: number;
    date: string;
  }[];

  export type StatsByYearParam = {
    year: string;
    output?: 'monthly' | 'year';
  };

  export type QueryResult = UseQueryResult<
    AxiosResponse<StatsByYearRes>,
    AxiosError
  >;

  export const KEY_STRING = 'stats-by-year' as const;

  type StatsByYearKeyType = [typeof KEY_STRING, StatsByYearParam];
  type StatsByYearKey = StatsByYearKeyType & QueryKey;

  export async function fetchStatsByYear({
    queryKey
  }: QueryFunctionContext<StatsByYearKey>) {
    const _queryKey = queryKey as StatsByYearKeyType;

    return await axios.get<StatsByYearRes>(
      '/databoard/transaction/stats/year',
      {
        params: {
          year: _queryKey[1].year,
          output: _queryKey[1].output
        }
      }
    );
  }

  export const useStatsByYear = (
    { year, output = 'monthly' }: StatsByYearParam,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<StatsByYearRes>,
      AxiosError,
      AxiosResponse<StatsByYearRes>,
      StatsByYearKeyType
    > = {}
  ) => {
    const query = useQuery([KEY_STRING, { year, output }], fetchStatsByYear, {
      staleTime: 300000,
      ...props
    });

    return query;
  };
}

export namespace FetchRegisterStoreCount {
  export type RegisterStoreCountRes = {
    count: number;
  };

  export type RegisterStoreCountParam = {
    startAt?: string;
    endAt?: string;
  };

  export type QueryResult = UseQueryResult<
    AxiosResponse<RegisterStoreCountRes>,
    AxiosError
  >;

  export const KEY_STRING = 'register-store-count' as const;

  type RegisterStoreCountKeyType = [typeof KEY_STRING, RegisterStoreCountParam];
  type RegisterStoreCountKey = RegisterStoreCountKeyType & QueryKey;

  /**
   * @deprecated
   *
   * useQueries 고쳐질 때 까지 사용..
   */
  export function useFetchs(param: RegisterStoreCountParam[], prefix = '') {
    const query = useQuery(
      ['many_' + prefix + KEY_STRING],
      async () => {
        const functions = param.map((item) => {
          return fetchRegisterStoreCount({
            queryKey: [
              KEY_STRING,
              {
                startAt: item.startAt,
                endAt: item.endAt
              }
            ]
          } as QueryFunctionContext<RegisterStoreCountKeyType>);
        });

        return Promise.all(functions);
      },
      {
        staleTime: 300000
      }
    );

    return query;
  }

  export async function fetchRegisterStoreCount({
    queryKey
  }: QueryFunctionContext<RegisterStoreCountKey>) {
    const _queryKey = queryKey as RegisterStoreCountKeyType;

    return await axios.get<RegisterStoreCountRes>('/databoard/store/count', {
      params: {
        startAt: _queryKey[1] ? _queryKey[1].startAt : undefined,
        endAt: _queryKey[1] ? _queryKey[1].endAt : undefined
      }
    });
  }

  export const useRegisterStoreCount = (
    { startAt, endAt }: RegisterStoreCountParam = {},
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<RegisterStoreCountRes>,
      AxiosError,
      AxiosResponse<RegisterStoreCountRes>,
      RegisterStoreCountKeyType
    > = {}
  ) => {
    const query = useQuery(
      [KEY_STRING, { startAt, endAt }],
      fetchRegisterStoreCount,
      {
        staleTime: 300000,
        ...props
      }
    );

    return query;
  };
}

export namespace FetchOrderAmount {
  export type OrderAmountRes = {
    count: number;
  };

  export type OrderAmountParam = {
    startAt?: string;
    endAt?: string;
  };

  export type QueryResult = UseQueryResult<
    AxiosResponse<OrderAmountRes>,
    AxiosError
  >;

  export const KEY_STRING = 'order-amount' as const;

  type OrderAmountKeyType = [typeof KEY_STRING, OrderAmountParam];
  type OrderAmountKey = OrderAmountKeyType & QueryKey;

  /**
   * @deprecated
   *
   * useQueries 고쳐질 때 까지 사용..
   */
  export function useFetchs(param: OrderAmountParam[], prefix = '') {
    const query = useQuery(
      ['many_' + prefix + KEY_STRING],
      async () => {
        const functions = param.map((item) => {
          return fetchOrderAmountCount({
            queryKey: [
              KEY_STRING,
              {
                startAt: item.startAt,
                endAt: item.endAt
              }
            ]
          } as QueryFunctionContext<OrderAmountKeyType>);
        });

        return Promise.all(functions);
      },
      {
        staleTime: 300000
      }
    );

    return query;
  }

  export async function fetchOrderAmountCount({
    queryKey
  }: QueryFunctionContext<OrderAmountKey>) {
    const _queryKey = queryKey as OrderAmountKeyType;

    return await axios.get<OrderAmountRes>(
      '/databoard/transaction/order-count',
      {
        params: {
          startAt: _queryKey[1] ? _queryKey[1].startAt : undefined,
          endAt: _queryKey[1] ? _queryKey[1].endAt : undefined
        }
      }
    );
  }

  export const useOrderAmountCount = (
    { startAt, endAt }: OrderAmountParam = {},
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<OrderAmountRes>,
      AxiosError,
      AxiosResponse<OrderAmountRes>,
      OrderAmountKeyType
    > = {}
  ) => {
    const query = useQuery(
      [KEY_STRING, { startAt, endAt }],
      fetchOrderAmountCount,
      {
        staleTime: 300000,
        ...props
      }
    );

    return query;
  };
}
