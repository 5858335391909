import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { arrangeAppApi } from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';

export namespace FetchArrangeManualGroup {
  export type ResponseType = {
    groupList: {
      id: number;
      title: string;
      code: string;
      order: number;
      thumbnailImage: {
        attachmentId: number;
        url: string;
      } | null;
      // itemList: {
      //   id: number;
      //   title: string;
      //   movieId: string;
      //   order: number;
      //   isImportant: true;
      // }[];
    }[];
  };

  export const KEY_STRING = 'fetch-arrange-manual-group' as const;

  export type QueryKeyType = [typeof KEY_STRING];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    queryKey
  }: QueryFunctionContext<QueryKeyType2>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _queryKey = queryKey as QueryKeyType;

    return await arrangeAppApi.get<ResponseType>(`/arrange-manual-group`);
  }

  export const useRefetchData = () => {
    const queryClient = useQueryClient();

    const refetch = () => {
      queryClient.refetchQueries([KEY_STRING]);
    };

    return { refetch };
  };

  export const useFetch = ({
    ...props
  }: UseQueryOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    AxiosResponse<ResponseType>,
    QueryKeyType
  > = {}) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING], fetchData, {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
