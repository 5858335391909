import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router';
import { IsTypeKey } from '../utils/decorators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T> = new (...args: any[]) => T;

export function useLocationSearchQueryParam<T>(clz: Constructor<T>): T {
  const location = useLocation();

  const metadata = Reflect.getMetadata(IsTypeKey, clz);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const getLocationQuery = useCallback(() => {
    const locationQuery = new URLSearchParams(location.search);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const object: any = {};

    for (const [key, value] of locationQuery.entries()) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let val: string | number = value;

      if (key.lastIndexOf('[]') !== -1) {
        const _key = key.substring(0, key.lastIndexOf('[]'));

        if (!object[_key]) {
          object[_key] = [];
        }

        (object[_key] as string[]).push(value);
      } else {
        if (metadata) {
          const type = metadata[key];

          if (type) {
            val = convertType(value, type);
          }

          object[key] = val;
        } else {
          object[key] = val;
        }
      }
    }

    return object;
  }, [location.search, metadata]);

  return getLocationQuery() as T;
}

export function useLocationStateParam<T>(clz: Constructor<T>): T {
  const params = useParams<Record<string, string>>();

  const metadata = Reflect.getMetadata(IsTypeKey, clz);

  const getLocationQuery = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const object: any = {};

    for (const [key, value] of Object.entries(params)) {
      let val: string | number | undefined = value;

      if (metadata) {
        const type = metadata[key];

        if (type) {
          val = convertType(value, type);
        }

        object[key] = val;
      } else {
        object[key] = val;
      }
    }

    return object;
  }, [metadata, params]);

  return getLocationQuery() as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertType(value: any, type: NumberConstructor | StringConstructor) {
  switch (type) {
    case Number:
      return parseInt(value);
    case String:
      return `${value}`;
    default:
      throw new Error('정의되지 않은 타입입니다.');
  }
}
