import { ColorType } from './style';

export enum DeliveryFormCreatedType {
  SELF = 'SELF',
  REQUEST = 'REQUEST'
}

export const DeliveryFormCreatedTypeColor: {
  [key in keyof typeof DeliveryFormCreatedType]: ColorType;
} = {
  [DeliveryFormCreatedType.SELF]: 'secondary-color',
  [DeliveryFormCreatedType.REQUEST]: 'blue-color'
} as const;

export const deliveryFormSelectedColor = (selected: boolean): ColorType =>
  selected ? 'primary-color' : 'gray-color';
