// 컴포넌트 이름이 맘에 안 드네

import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import Hr from '../../components/atoms/Hr';
import Text from '../../components/atoms/Text';
import Flex from '../../components/molecules/Flex';
import { splitIntoChunk } from '../../utils/array';
import styles from './days-stats.module.scss';

const cx = classNames.bind(styles);

export interface DateStats {
  title: string;
  count: number;
}

export interface Props {
  suffix?: string;
  dates: DateStats[];
}

const DaysStats: React.FC<Props> = ({ suffix = '', dates }) => {
  const [dataOfPart, setDataOfPart] = useState<DateStats[][]>();

  useEffect(() => {
    setDataOfPart(splitIntoChunk(dates.slice(1), 2) as DateStats[][]);
  }, [dates]);

  return (
    <Flex alignItems="center" gap="large">
      <Flex gap="xsmall" alignItems="center">
        <Text colorType="gray-color">{dates[0].title}</Text>
        <Flex direction="row" alignItems="baseline" gap="small">
          <Text colorType="gray-color" size="small">
            총
          </Text>
          <Text size="xlarge" isBold={true}>
            {dates[0].count.toLocaleString()}
          </Text>
          <Text colorType="gray-color" size="small">
            {suffix}
          </Text>
        </Flex>
      </Flex>
      <Hr />
      {dataOfPart?.map((item, idx) => (
        <React.Fragment key={idx}>
          <Flex gap="xlarge">
            <Flex direction="row" gap="xxlarge">
              {item.map((dateInfo) => (
                <Flex key={dateInfo.title} gap="xsmall" className={cx('item')}>
                  <Text colorType="gray-color">{dateInfo.title}</Text>
                  <Flex direction="row" gap="xsmall" alignItems="baseline">
                    <Text size="large" isBold={true}>
                      {dateInfo.count.toLocaleString()}
                    </Text>
                    <Text size="small" colorType="gray-color">
                      {suffix}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
          {!(idx == dataOfPart.length - 1) && <Hr />}
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default DaysStats;
