export enum LinkfarmProductResellSalePeriodType {
  PERIOD = 'PERIOD',
  ALL = 'ALL'
}

export enum LinkfarmProductResellDisplayedState {
  // 판매종료
  END_OF_SALE = 'END_OF_SALE',
  // 판매중
  SALE = 'SALE',
  // 판매예정
  TO_BE_SOLD = 'TO_BE_SOLD',
  // PAUSE
  PAUSE = 'PAUSE'
}
