import ContentLoader from 'react-content-loader';

export interface Props {
  width?: string;
  height?: string;
  viewBox: string;
  children: React.ReactNode;
}

const Skeleton: React.FC<Props> = ({
  width = '100%',
  height = '100%',
  viewBox,
  children
}) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      backgroundColor="#C4C4C4"
      foregroundColor="#ecebeb"
      viewBox={viewBox}
    >
      {children}
    </ContentLoader>
  );
};

export default Skeleton;
