import { isNextCursor, QueryInfiniteCallback } from '..';
import { AxiosResponse, AxiosError } from 'axios';
import {
  useInfiniteQuery,
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from '@tanstack/react-query';
import axios, { arrangeAppApi } from '../../../utils/axios';
import { DeliveryFormCreatedType } from '../../../types/delivery-form';
import { StatusCodes } from 'http-status-codes';
import { getRandomHash } from '../../../utils/crypt';
import { DeliveryFormListSortStandard, SortState } from '../../../types/sort';
import { useErrorHandler } from 'react-error-boundary';
import { CourierType } from '@aimpact-korea/arrange-front-types';

export namespace FetchDeliveryForms {
  export type DeliveryFormsCurser = {
    id?: number;
    value?: string;
  } & SortState<DeliveryFormListSortStandard>;

  export type DeliveryFormsRes = {
    orderExcels: {
      id: number;
      courierType: CourierType;
      courierTypeKo: string;
      isSelected: boolean;
      userId: number;
      createdAt: string;
      createdType: DeliveryFormCreatedType;
      updatedAt: string;
      store: {
        ceoName: string;
        storeName: string;
      };
    }[];
    cursor: {
      id: number;
      date: string;
    };
  };

  export type DeliveryFormsParam = {
    limit?: number;
    cursor?: DeliveryFormsCurser;
  };

  export const KEY_STRING = 'delivery-forms' as const;

  type DeliveryFormsQueryKeyType = [typeof KEY_STRING, DeliveryFormsParam];
  type DeliveryFormsQueryKey = DeliveryFormsQueryKeyType & QueryKey;

  export async function fetchDeliveryForms({
    pageParam,
    queryKey
  }: QueryFunctionContext<DeliveryFormsQueryKey, DeliveryFormsCurser>) {
    const _queryKey = queryKey as DeliveryFormsQueryKeyType;

    let queryParam = '';
    if (pageParam) {
      queryParam = decodeURIComponent(
        `?cursor[id]=${pageParam?.id}&cursor[value]=${pageParam?.value}&cursor[standardColumn]=${pageParam?.standardColumn}&cursor[orderBy]=${pageParam?.orderBy}`
      );
    } else if (_queryKey[1].cursor?.standardColumn) {
      const urlSearchParams = new URLSearchParams();

      if (_queryKey[1].cursor.id) {
        urlSearchParams.append('cursor[id]', `${_queryKey[1].cursor.id}`);
      }
      if (_queryKey[1].cursor.orderBy) {
        urlSearchParams.append(
          'cursor[orderBy]',
          `${_queryKey[1].cursor.orderBy}`
        );
      }
      if (_queryKey[1].cursor.standardColumn) {
        urlSearchParams.append(
          'cursor[standardColumn]',
          `${_queryKey[1].cursor.standardColumn}`
        );
      }
      if (_queryKey[1].cursor.value) {
        urlSearchParams.append('cursor[value]', `${_queryKey[1].cursor.value}`);
      }

      queryParam = `?${urlSearchParams.toString()}`;
    }

    return await axios.get<DeliveryFormsRes>('/order-excel' + queryParam, {
      params: {
        limit: _queryKey[1].limit
      }
    });
  }

  export const useDeliveryForms = (
    {
      cursor: { id, orderBy, standardColumn, value } = {},
      limit = 15
    }: DeliveryFormsParam = {},
    {
      ...props
    }: QueryInfiniteCallback<
      AxiosResponse<DeliveryFormsRes>,
      AxiosError,
      AxiosResponse<DeliveryFormsRes>,
      DeliveryFormsQueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useInfiniteQuery(
      [
        'delivery-forms',
        { cursor: { id, value, standardColumn, orderBy }, limit }
      ],
      fetchDeliveryForms,
      {
        getNextPageParam: (data, allData) =>
          isNextCursor(data.data.cursor, allData),
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}

export namespace FetchDeliveryForm {
  export type DeliveryFormRes = {
    id: number;
    courierType: CourierType;
    courierTypeKo: string;
    isSelected: boolean;
    userId: number;
    createdAt: string;
    createdType: DeliveryFormCreatedType;
    updatedAt: string;
    store: {
      ceoName: string;
      storeName: string;
    };
    columns: {
      _unique?: string;
      type: number;
      name: string;
    }[];
  };

  export type DeliveryFormParam = {
    excelId: number;
  };

  export const KEY_STRING = 'delivery-form' as const;

  type DeliveryFormQueryKeyType = [typeof KEY_STRING, DeliveryFormParam];
  type DeliveryFormQueryKey = DeliveryFormQueryKeyType & QueryKey;

  export async function fetchDeliveryForm({
    queryKey
  }: QueryFunctionContext<DeliveryFormQueryKey>) {
    const _queryKey = queryKey as DeliveryFormQueryKeyType;

    const response = await axios.get<DeliveryFormRes>(
      `/order-excel/${_queryKey[1].excelId}`
    );

    response.data.columns = response.data.columns.map?.((column) => {
      return {
        ...column,
        _unique: getRandomHash()
      };
    });

    return response;

    // DeliveryFormValues
  }

  export const useFetchDeliveryForm = (
    { excelId }: DeliveryFormParam,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<DeliveryFormRes>,
      AxiosError,
      AxiosResponse<DeliveryFormRes>,
      DeliveryFormQueryKeyType
    > = {}
  ) => {
    const query = useQuery(['delivery-form', { excelId }], fetchDeliveryForm, {
      staleTime: 300000,
      ...props,
      enabled: !!excelId
    });

    return query;
  };
}

export namespace FetchExcelFields {
  export type ExcelFieldsRes = {
    type: number;
    name: string;
    priority: number;
  }[];

  const KEY_STRING = 'excels-fields' as const;

  type ExcelFieldsQueryKeyType = [typeof KEY_STRING];

  export async function fetchExcelFields() {
    return await arrangeAppApi.get<ExcelFieldsRes>('/orders/excels/fields');
  }

  export const useFetchExcelFields = (
    props: UseQueryOptions<
      AxiosResponse<ExcelFieldsRes>,
      AxiosError,
      AxiosResponse<ExcelFieldsRes>,
      ExcelFieldsQueryKeyType
    > = {}
  ) => {
    const query = useQuery(['excels-fields'], fetchExcelFields, {
      staleTime: Infinity,
      ...props
    });

    return query;
  };
}

export namespace MutationDeliveryFormDetail {
  export type Param = {
    excelId: number;
    columns?: {
      _unique?: string;
      type: number;
      name: string;
    }[];
    courierType?: CourierType;
  };

  export const useMutate = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, Param> = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ columns, courierType, excelId }) => {
        return axios.patch(`/order-excel/${excelId}`, {
          columns,
          courierType
        });
      },
      {
        onSuccess: (...rest) => {
          queryClient.invalidateQueries([
            FetchDeliveryForm.KEY_STRING,
            { excelId: rest[1].excelId }
          ]);
          onSuccess?.(...rest);
        },
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('존재하지 않는 excelId입니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
