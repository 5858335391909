import Text from '../../../atoms/Text';

export interface Props {
  text: string;
}

const LabelText: React.FC<Props> = ({ text }) => {
  return (
    <Text size={'small'} colorType={'gray-color'}>
      {text}
    </Text>
  );
};

export default LabelText;
