import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';

export namespace PickupRequestPickupOnSiteAlarm2 {
  export type RequestType = {
    pickupOnSiteRoundId: number;
  };

  export type ResponseType = {
    sendedCount: number;
  };

  export const useMutate = ({
    ...options
  }: UseMutationOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    RequestType
  > = {}) => {
    const mutation = useMutation(
      ({ pickupOnSiteRoundId }) => {
        return axios.post(
          `pickupon-site/round/${pickupOnSiteRoundId}/pickup-reqeust-2`
        );
      },
      {
        ...options
      }
    );

    return mutation;
  };
}
