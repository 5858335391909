import classNames from 'classnames/bind';
import React, { Suspense, useState } from 'react';
import Button from '../../components/atoms/Button';
import DropdownMenu from '../../components/molecules/DropdownMenu';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import styles from './index.module.scss';
import TransactionData from './TransactionData';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import InputSearch from '../../components/organisms/InputSearch';
import { useLocationSearchQueryParam } from '../../hooks/useLocationSearchQueryParam';
import { Type } from '../../utils/decorators';
import { FileIcon } from '@aimpact-korea/arrange-front-icons/build/icons';

const cx = classNames.bind(styles);

export class LocationQuery {
  @Type(() => Number)
  storeId?: number;

  @Type(() => String)
  orderSettleAt?: string;

  @Type(() => String)
  resellerOrderSettleAt?: string;

  @Type(() => Number)
  resellerId?: number;
}

const TransactionPage: React.FC = () => {
  const [orderDropdownActive, setOrderDropdownActive] = useState(false);
  const [orderDropdownValue, setOrderDropdownValue] = useState('주문수');
  const [keyword, setKeyword] = useState('');
  const locationQuery = useLocationSearchQueryParam(LocationQuery);

  const searchKeyword = (keyword: string) => {
    setKeyword(keyword);
  };

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName="거래내역">
          <div className={cx('container')}>
            <DropdownMenu
              isActive={orderDropdownActive}
              size={'large'}
              setActive={(state) => setOrderDropdownActive(state)}
              setValue={(value) => setOrderDropdownValue(value)}
              value={orderDropdownValue}
            >
              <DropdownMenu.Title isBottomLine={true}>
                <DropdownMenu.Title.UpDownIcon />
                <DropdownMenu.Title.Text placeholder=""></DropdownMenu.Title.Text>
              </DropdownMenu.Title>
              <DropdownMenu.ItemWrapper size="medium">
                <DropdownMenu.ItemWrapper.ListVerticalItemGroup
                  isContour={true}
                >
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item value="주문수">
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text text="주문수" />
                  </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item value="날짜">
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text text="날짜" />
                  </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
              </DropdownMenu.ItemWrapper>
            </DropdownMenu>

            <Button
              text="엑셀 내보내기"
              size="large"
              startIcon={<FileIcon colorType="text-white" />}
            />

            <InputSearch
              placeholder="주문자명 / 연락처"
              onClickSearch={(text) => searchKeyword(text)}
            />
          </div>
        </Header>
        <TransactionData keyword={keyword} locationQuery={locationQuery} />
      </Suspense>
    </DefaultTemplate>
  );
};

export default TransactionPage;
