import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import CopyToClipboard, {
  Props as CopyToClipboardProps
} from 'react-copy-to-clipboard';
import Text from '../../atoms/Text';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props extends CopyToClipboardProps {
  toastLabel?: string;
  toastMs?: number; // ms
  toastPosition?: 'top' | 'right';
}

const ClipboardCopy: React.FC<Props> = ({
  toastLabel = 'Copied!',
  toastMs = 1000,
  toastPosition = 'top',
  children,
  onCopy,
  ...props
}) => {
  const [toastActive, setToastActive] = useState(false);
  const toastTimer = useRef<NodeJS.Timeout | null>(null);

  const handleCopy: (text: string, result: boolean) => void = (...args) => {
    setToastActive(true);

    onCopy?.(...args);
  };

  useEffect(() => {
    if (toastActive) {
      toastTimer.current = setTimeout(() => {
        setToastActive(false);
      }, toastMs);
    }

    return () => {
      if (toastActive) {
        toastTimer.current && clearTimeout(toastTimer.current);
        toastTimer.current = null;
      }
    };
  }, [toastActive, toastMs]);

  return (
    <CopyToClipboard onCopy={handleCopy} {...props}>
      <div className={cx('container')}>
        {children}
        <div
          className={`${cx('toast', `position--${toastPosition}`, {
            active: toastActive
          })}`}
        >
          <Text colorType="gray-color" isBold={true}>
            {toastLabel}
          </Text>
        </div>
      </div>
    </CopyToClipboard>
  );
};

export default ClipboardCopy;
