import { CheckRoundIcon } from '@aimpact-korea/arrange-front-icons/build/icons';
import classNames from 'classnames/bind';
import { useContextApi } from '../context';
import BodyIcon from './BodyIcon';
import styles from './complate-icon.module.scss';

const cx = classNames.bind(styles);

const ComplateIcon: React.FC = () => {
  const { state } = useContextApi();

  return (
    <BodyIcon
      IconComponent={CheckRoundIcon}
      onClick={() => state.onChangeValue?.('')}
      fill="text-primary-500"
      className={cx('container', !state.isFocus && state.active && 'show')}
    />
  );
};

export default ComplateIcon;
