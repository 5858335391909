import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { arrangeAppApi } from '../../../utils/axios';

export namespace FetchAndroidAppVersionList {
  export type ResponseType = {
    id: number;
    version: string;
    isCompulsionVersion: boolean;
    androidAppType: 'ARRANGE' | 'ARRANGE_PLUS';
  }[];

  export type RequestType = {
    androidAppType?: 'ARRANGE' | 'ARRANGE_PLUS';
  };

  export const KEY_STRING = 'fetch-anroid-app-version-list' as const;

  export type QueryKeyType = [typeof KEY_STRING, RequestType];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    queryKey
  }: QueryFunctionContext<QueryKeyType2>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _queryKey = queryKey as QueryKeyType;

    return await arrangeAppApi.get<ResponseType>(`/android/versions`, {
      params: {
        androidAppType: _queryKey[1].androidAppType
      }
    });
  }

  export const useRefetchData = () => {
    const queryClient = useQueryClient();

    const refetch = () => {
      queryClient.refetchQueries([KEY_STRING]);
    };

    return { refetch };
  };

  export const useFetch = (
    { androidAppType }: RequestType = {},
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery(
      [
        KEY_STRING,
        {
          androidAppType: androidAppType
        }
      ],
      fetchData,
      {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}
