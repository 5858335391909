import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { arrangeAppApi } from '../../../utils/axios';
import { useErrorHandler } from 'react-error-boundary';
import { ArrangeProductSubscribeKind } from '@aimpact-korea/arrange-front-types';

export namespace FetchArrangeProductSubscribes {
  export type ResponseType = {
    arrangeProductSubscribeKinds: ArrangeProductSubscribeKind[];
  };

  export const KEY_STRING = 'fetch-arrange-product-subscribes' as const;

  export type QueryKeyType = [typeof KEY_STRING];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    queryKey
  }: QueryFunctionContext<QueryKeyType2>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _queryKey = queryKey as QueryKeyType;

    return await arrangeAppApi.get<ResponseType>(
      `/arrange-product-subscribe/kind`
    );
  }

  export const useRefetchData = () => {
    const queryClient = useQueryClient();

    const refetch = () => {
      queryClient.refetchQueries([KEY_STRING]);
    };

    return { refetch };
  };

  export const useFetch = ({
    ...props
  }: UseQueryOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    AxiosResponse<ResponseType>,
    QueryKeyType
  > = {}) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING], fetchData, {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
