import classNames from 'classnames/bind';
import React from 'react';
import styles from './head.module.scss';
import Row from './Row';

const cx = classNames.bind(styles);

export type Props = React.ComponentPropsWithoutRef<'div'>;

export interface HeadComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
  > {
  Row: typeof Row;
}

// eslint-disable-next-line react/display-name
const Head = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} className={cx('container')} {...props}>
        {children}
      </div>
    );
  }
) as HeadComponent;

Head.displayName = 'Head';
Head.Row = Row;

export default Head;
