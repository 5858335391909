import { useFileUploader } from '@aimpact-korea/arrange-front-hooks';
import { Suspense, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router';
import Button from '../../../../components/atoms/Button';
import Input from '../../../../components/atoms/Input';
import Flex from '../../../../components/molecules/Flex';
import ListSkeleton from '../../../../components/molecules/ListSkeleton';
import Header from '../../../../components/organisms/Header';
import DefaultTemplate from '../../../../components/templates/Default';
import { AttachmentFileUploadApi } from '../../../../hooks/http/attachment/upload-attachments';
import { FetchNoticeDetail } from '../../../../hooks/http/notice/fetch-notice-detail';
import { ModifyNoticeApi } from '../../../../hooks/http/notice/modify-notice';
import { useLocationStateParam } from '../../../../hooks/useLocationSearchQueryParam';
import { Type } from '../../../../utils/decorators';

class PathParams {
  @Type(() => Number)
  noticeId!: number;
}

export default function ArrangeNoticeDetailPage() {
  const stateParam = useLocationStateParam(PathParams);

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'어레인지 공지사항'}></Header>
        <ArrangeNoticeDetailWrapper noticeId={stateParam.noticeId} />
      </Suspense>
    </DefaultTemplate>
  );
}
function ArrangeNoticeDetailWrapper({ noticeId }: { noticeId: number }) {
  const navigate = useNavigate();

  const fetchNoticeDetail = FetchNoticeDetail.useFetch({ noticeId: noticeId });
  const modifyNoticeApi = ModifyNoticeApi.useMutate({
    onSuccess: () => {
      alert('공지사항이 수정되었습니다.');
      navigate('/notice/arrange');
    }
  });

  const [contentHtml, setContentHtml] = useState(
    fetchNoticeDetail.data?.data.contentHtml ?? ''
  );
  const [title, setTitle] = useState(fetchNoticeDetail.data?.data.title ?? '');

  const { fileUploader } = useFileUploader();
  const attachmentFileUploadApi = AttachmentFileUploadApi.useMutate({
    onSuccess: (response) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const quillEditor = quillRef.current!;

      const range = quillEditor.getEditor().getSelection(true);

      quillEditor.getEditor().insertEmbed(range.index, 'customImage', {
        src: response.data.url,
        ['attachment-id']: response.data.attachmentId
      });

      quillEditor.getEditor().setSelection({
        index: range.index + 1,
        length: range.length
      });
    }
  });

  const quillRef = useRef<ReactQuill>(null);

  const addAttachmentNoticeImage = () => {
    fileUploader(
      {
        accept: 'image/*',
        multiple: false
      },
      {
        onUpload: (files) => {
          if (!files.length) {
            return;
          }
          if (!quillRef.current) {
            return;
          }
          attachmentFileUploadApi.mutate({
            file: files[0]
          });
        }
      }
    );
  };

  const quillModules = useMemo(
    () => ({
      VideoResize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar']
      },
      ImageResize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar']
      },
      toolbar: {
        container: [
          [
            {
              indent: '-1'
            },
            {
              indent: '+1'
            }
          ],
          [
            'bold',
            'italic',
            {
              color: []
            },
            {
              background: []
            }
          ],
          [{ align: '' }, { align: 'center' }, { align: 'right' }],
          ['image', 'video']
        ],
        handlers: {
          image: () => {
            addAttachmentNoticeImage();
          }
        }
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Flex
      direction="column"
      gap="xsmall"
      justifyContent="center"
      alignItems="center"
      className="w-full"
    >
      <Input value={title} onChange={(event) => setTitle(event.target.value)} />
      <Flex
        direction="column"
        gap="xsmall"
        alignItems="center"
        className="w-full"
      >
        <Flex style={{ width: '100%', height: '550px' }}>
          <ReactQuill
            style={{ width: '100%', height: '500px' }}
            ref={quillRef}
            modules={quillModules}
            theme="snow"
            value={contentHtml}
            onChange={setContentHtml}
          ></ReactQuill>
        </Flex>
      </Flex>
      <Button
        text="수정"
        onClick={() =>
          modifyNoticeApi.mutate({
            contentHtml: contentHtml,
            noticeId: noticeId,
            title: title
          })
        }
      />
    </Flex>
  );
}
