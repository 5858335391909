import { SearchFailedIcon } from '@aimpact-korea/arrange-front-icons/build/icons';
import classNames from 'classnames/bind';
import Text from '../components/atoms/Text';
import DefaultTemplate from '../components/templates/Default';
import styles from './not-found.module.scss';

const cx = classNames.bind(styles);

const NotFound: React.FC = () => {
  return (
    <DefaultTemplate>
      <div className={cx('container')}>
        <div className={cx('flex-container')}>
          <SearchFailedIcon
            colorType="text-gray-500"
            width={126}
            height={126}
          />
          <Text size="xxlarge" colorType="gray-color">
            페이지를 찾을 수 없습니다.
          </Text>
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default NotFound;
