import React, { Suspense, useEffect, useState } from 'react';
import DefaultTemplate from '../../components/templates/Default';
import {
  FourBox,
  OneBox,
  TwoBox
} from '../../components/molecules/MainSkeleton';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  LabelList,
  CartesianGrid
} from 'recharts';
import {
  FetchRegisterStoreCount,
  FetchOrderAmount,
  FetchStatsByYear
} from '../../hooks/http/dash-board';
import dayjs from 'dayjs';
import Flex from '../../components/molecules/Flex';
import Text from '../../components/atoms/Text';
import Hr from '../../components/atoms/Hr';
import DaysStats, { DateStats } from './DaysStats';
import Vr from '../../components/atoms/Vr';
import {
  ArrowRefreshIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';

const cx = classNames.bind(styles);

const DashBoard: React.FC = () => {
  const [year, setYear] = useState(dayjs().year());

  const prevYear = () => {
    setYear(year - 1);
  };

  const nextYear = () => {
    setYear(year + 1);
  };

  return (
    <DefaultTemplate>
      <div className={cx('container')}>
        <div className={cx('total-order-count-wrapper')}>
          <Suspense fallback={<FourBox />}>
            <Flex gap="xlarge">
              <div className={cx('header')}>
                <Flex gap="large" direction="row" alignItems="center">
                  <Text isBold={true} size="large">
                    월 거래건수 (월 거래액)
                  </Text>
                  <Flex gap="small" direction="row" alignItems="center">
                    <Text size="small" colorType="gray-dark-color">
                      마지막 업데이트: 3분전
                    </Text>
                    <ArrowRefreshIcon colorType="text-primary-500" />
                  </Flex>
                </Flex>
                <Flex gap="large" direction="row" alignItems="center">
                  <ChevronLeftIcon
                    onClick={() => prevYear()}
                    colorType="text-primary-500"
                  />
                  <Text colorType="primary-color" isBold={true}>
                    {year}년
                  </Text>
                  <ChevronRightIcon
                    onClick={() => nextYear()}
                    colorType={
                      dayjs().year() === year
                        ? 'text-gray-300'
                        : 'text-primary-500'
                    }
                  ></ChevronRightIcon>
                </Flex>
              </div>
              <Hr color="gray-dark-color" />
              <TotalOrderCountByYear year={year} />
            </Flex>
          </Suspense>
        </div>
        <div className={cx('one-item')}>
          <Suspense fallback={<OneBox />}>
            <Flex gap="xlarge">
              <Flex gap="large" direction="row" alignItems="center">
                <Text isBold={true} size="large">
                  가입업체수
                </Text>
                <Text colorType="gray-dark-color" size="small">
                  마지막 업데이트: 3분전
                </Text>
              </Flex>
              <Hr color="gray-dark-color" />
              <RegisterStoreCount />
            </Flex>
          </Suspense>
        </div>
        <div className={cx('one-item')}>
          <Suspense fallback={<OneBox />}>
            <Flex gap="xlarge">
              <Flex gap="large" direction="row" alignItems="center">
                <Text isBold={true} size="large">
                  거래건수
                </Text>
                <Text colorType="gray-dark-color" size="small">
                  마지막 업데이트: 3분전
                </Text>
              </Flex>
              <Hr color="gray-dark-color" />
              <OrderCount />
            </Flex>
          </Suspense>
        </div>
        <div className={cx('two-item')}>
          <TwoBox />
        </div>
      </div>
    </DefaultTemplate>
  );
};

export default DashBoard;

type TotalOrderCountByYearGraphType = {
  name: string;
  count: number;
  amount: number;
};

interface TotalOrderCountByYearProps {
  year: number;
}

const TotalOrderCountByYear: React.FC<TotalOrderCountByYearProps> = ({
  year
}) => {
  const [stats, setStats] = useState<TotalOrderCountByYearGraphType[]>();

  const query = FetchStatsByYear.useStatsByYear({ year: `${year}` });

  const queryData = query.data?.data;

  useEffect(() => {
    const temp = queryData?.reduce(
      (acc: Record<string, TotalOrderCountByYearGraphType>, curr) => {
        acc[dayjs(curr.date).month()] = {
          amount: curr.amount,
          name: `${dayjs(curr.date).month() + 1}월`,
          count: curr.count
        };

        return acc;
      },
      {}
    );

    if (temp) {
      setStats(
        Array.from(Array(12).keys()).map<TotalOrderCountByYearGraphType>(
          (idx) =>
            temp[idx] ?? {
              amount: 0,
              name: `${idx + 1} 월`,
              count: 0
            }
        )
      );
    }
  }, [queryData]);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={stats} margin={{ top: 20, left: 20, right: 20 }}>
        <CartesianGrid strokeDasharray="1" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis
          // yAxisId="1"
          padding={{ top: 40 }}
          // domain={[0, (dataMax: number) => dataMax * 2]}
          label={() => (
            <text
              x={30}
              y={290}
              fill="#727272"
              style={{ fontSize: '12px', fontWeight: 'bold' }}
            >
              거래건수
            </text>
          )}
          orientation="left"
          tickFormatter={(value) => Number(value).toLocaleString()}
        />
        <Tooltip
          content={({ label, payload }) => {
            if (payload && payload.length && payload[0].payload.amount) {
              return (
                <div className={cx('chart-tooltip')}>
                  <Flex direction="row" gap="large">
                    <Flex gap="small">
                      <Text colorType="gray-dark-color" isBold={true}>
                        {label} 최고 거래건수 (액)
                      </Text>
                      <Hr />
                      <Flex gap="small">
                        <Text colorType="primary-color">
                          13,209(x) 건<br />
                          (W)123,456,789
                        </Text>
                        <Text
                          tag="p"
                          alignType="right"
                          size="xxsmall"
                          colorType="gray-color"
                        >
                          {label} 16일
                        </Text>
                      </Flex>
                    </Flex>
                    <Vr />
                    <Flex gap="small">
                      <Text colorType="gray-dark-color" isBold={true}>
                        {label} 최저 거래건수 (액)
                      </Text>
                      <Hr />
                      <Flex gap="small">
                        <Text colorType="primary-color">
                          13,209(x) 건<br />
                          (W)123,456,789
                        </Text>
                        <Text
                          tag="p"
                          alignType="right"
                          size="xxsmall"
                          colorType="gray-color"
                        >
                          {label} 16일
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </div>
              );
            }

            return <></>;
          }}
        />
        <Bar dataKey="count" fill="#498C74">
          <LabelList
            dataKey="count"
            position="top"
            content={({ x, y, width, height, value }) => {
              return (
                <text
                  x={Number(x) + Number(width ?? '0') / 2}
                  y={Number(y) - 30}
                  width={width}
                  height={height}
                  textAnchor="middle"
                  fill="#727272"
                  style={{ fontSize: '12px', fontWeight: 'bold' }}
                >
                  {value ? `${Number(value).toLocaleString()} 건` : ''}
                </text>
              );
            }}
          />
          <LabelList
            dataKey="amount"
            position="top"
            content={({ x, y, width, height, value }) => {
              return (
                <text
                  x={Number(x) + Number(width ?? '0') / 2}
                  y={Number(y) - 10}
                  width={width}
                  height={height}
                  textAnchor="middle"
                  fill="#727272"
                  style={{ fontSize: '12px', fontWeight: 'bold' }}
                >
                  {value ? `(￦${Number(value).toLocaleString()})` : ''}
                </text>
              );
            }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

type Parent = FetchRegisterStoreCount.RegisterStoreCountParam &
  FetchOrderAmount.OrderAmountParam;
interface RSCParams extends Parent {
  _title: string;
}

const DatesOfWhere: RSCParams[] = [
  {
    _title: '전체'
  },
  {
    _title: '오늘',
    startAt: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss')
  },
  {
    _title: '어제',
    startAt: dayjs()
      .add(-1, 'day')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    endAt: dayjs().add(-1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')
  },
  {
    _title: '이번주',
    startAt: dayjs().day(1).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    endAt: dayjs().day(7).endOf('day').format('YYYY-MM-DD HH:mm:ss')
  },
  {
    _title: '지난주',
    startAt: dayjs()
      .add(-7, 'day')
      .day(1)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    endAt: dayjs()
      .add(-7, 'day')
      .day(7)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
  },
  {
    _title: '이번달',
    startAt: dayjs().date(1).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    endAt: dayjs()
      .date(1)
      .add(1, 'month')
      .add(-1, 'day')
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
  },
  {
    _title: '지난달',
    startAt: dayjs()
      .date(1)
      .startOf('day')
      .add(-1, 'month')
      .format('YYYY-MM-DD HH:mm:ss'),
    endAt: dayjs()
      .date(1)
      .add(1, 'month')
      .add(-1, 'month')
      .add(-1, 'day')
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
  }
];

const RegisterStoreCount: React.FC = () => {
  // @BUG useQueries suspense 동작 안 함
  // 깃헙 이슈 https://github.com/tannerlinsley/react-query/issues/2395
  // https://github.com/tannerlinsley/react-query/pull/2109
  // const queries = useQueries(
  //   registerStoreCountParams.map(({ endAt, startAt }) => ({
  //     queryKey: [FetchRegisterStoreCount.KEY_STRING, { endAt, startAt }],
  //     queryFn: FetchRegisterStoreCount.fetchRegisterStoreCount
  //   }))
  // ) as FetchRegisterStoreCount.QueryResult[];

  const query = FetchRegisterStoreCount.useFetchs(DatesOfWhere);
  const [dates, setDates] = useState<DateStats[]>();

  useEffect(() => {
    setDates(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      query.data!.map((item, idx) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        count: item.data!.count,
        title: DatesOfWhere[idx]._title
      }))
    );
  }, [query.data]);

  return (
    <React.Fragment>
      {dates && <DaysStats dates={dates} suffix="업체" />}
    </React.Fragment>
  );
};

const OrderCount: React.FC = () => {
  const query = FetchOrderAmount.useFetchs(DatesOfWhere);

  const [dates, setDates] = useState<DateStats[]>();

  useEffect(() => {
    setDates(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      query.data!.map((item, idx) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        count: item.data!.count,
        title: DatesOfWhere[idx]._title
      }))
    );
  }, [query.data]);

  return (
    <React.Fragment>
      {dates && <DaysStats dates={dates} suffix="건" />}
    </React.Fragment>
  );
};
