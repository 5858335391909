import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import { useEffect, useState } from 'react';
import Button from '../../atoms/Button';
import Modal from '../../molecules/Modal';
import Table from '../../molecules/Table';
import TextInput from '../../molecules/TextInput';

type ArrangeManualGroupType = {
  packageName: string;
  title: string;
  subtitle: string;
  sms: string;
  mms: string;
};

function CreateAndroidMessageAppModal({
  isOpen,
  androidMessageApp: arrangeManualGroupProps,
  handleSave,
  handleClose,
  isLoading
}: {
  isOpen: boolean;
  androidMessageApp: ArrangeManualGroupType;
  handleSave: (arrangeManualGroup: ArrangeManualGroupType) => void;
  handleClose: () => void;
  isLoading?: boolean;
}) {
  const [androidMessageApp, setAndroidMessageApp] = useState(
    arrangeManualGroupProps
  );

  useEffect(() => {
    if (arrangeManualGroupProps) {
      setAndroidMessageApp(arrangeManualGroupProps);
    }
  }, [arrangeManualGroupProps]);

  return (
    <Modal sizeType="large" isOpen={isOpen} handleClose={() => handleClose()}>
      <Modal.Header>
        <Modal.Header.Title text="어레인지 매뉴얼 그룹 수정" />
      </Modal.Header>
      <Modal.Body>
        <Table type="simple-table">
          <Table.Body>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">packageName</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={androidMessageApp.packageName}
                      onChange={(e) =>
                        setAndroidMessageApp({
                          ...androidMessageApp,
                          packageName: e.target.value
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">title</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={androidMessageApp.title}
                      onChange={(e) =>
                        setAndroidMessageApp({
                          ...androidMessageApp,
                          title: e.target.value
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">subtitle</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={androidMessageApp.subtitle}
                      onChange={(e) =>
                        setAndroidMessageApp({
                          ...androidMessageApp,
                          subtitle: e.target.value
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">sms</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={androidMessageApp.sms}
                      onChange={(e) =>
                        setAndroidMessageApp({
                          ...androidMessageApp,
                          sms: e.target.value
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">mms</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={androidMessageApp.mms}
                      onChange={(e) =>
                        setAndroidMessageApp({
                          ...androidMessageApp,
                          mms: e.target.value
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          flexDirection="flex-row"
          width="w-full"
          gapSize={StyleBuilderInstane.toGapSpaceType('gap-xxs')}
        >
          <Button
            text="취소"
            type="secondary-color"
            fullwidth={true}
            onClick={() => handleClose()}
            isLoading={isLoading}
          />
          <Button
            text="저장"
            fullwidth={true}
            onClick={() => handleSave(androidMessageApp)}
            isLoading={isLoading}
          />
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateAndroidMessageAppModal;
