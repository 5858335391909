import {
  EditPencilIcon,
  MailIcon,
  SearchFailedIcon,
  TrashIcon,
  FruitForbiddenIcon,
  ParcelIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';

export const BlockItem = {
  IconComponent: SearchFailedIcon,
  text: '차단',
  value: 'block'
} as const;

export const BlockCancelItem = {
  IconComponent: SearchFailedIcon,
  text: '차단해제',
  value: 'block-cancel'
} as const;

export const MessageItem = {
  IconComponent: MailIcon,
  text: '메시지',
  value: 'message'
} as const;

export const ModifyItem = {
  IconComponent: EditPencilIcon,
  text: '수정',
  value: 'modify'
} as const;

export const DeleteItem = {
  IconComponent: TrashIcon,
  text: '삭제',
  value: 'delete'
} as const;

export const UnExposedItem = {
  IconComponent: FruitForbiddenIcon,
  text: '비노출',
  value: 'unexposed'
} as const;

export const ArrangeProductSubscribeItem = {
  IconComponent: ParcelIcon,
  text: '구독상품 설정',
  value: 'arrange-product-sbuscribe'
} as const;

export const StoreBulkCountInitItem = {
  IconComponent: ParcelIcon,
  text: '주문등록 카운트 초기화',
  value: 'store-bulk-count-init'
} as const;
