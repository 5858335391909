import {
  QueryFunctionContext,
  QueryKey,
  useQueries,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { arrangeAppApi } from '../../../utils/axios';
import { FetchRootCategory } from './fetch-root-category';

export namespace FetchCategoryByParentId {
  export type ResponseType = FetchRootCategory.ResponseType;
  export type ReqeustType = {
    parentId: number;
  };

  type TUseQueryOptions = UseQueryOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    AxiosResponse<ResponseType>,
    TQueryKey
  >;

  export const KEY_STRING = 'featch-category-by-parent-id' as const;

  type TQueryKey = [typeof KEY_STRING, ReqeustType];
  type TQueryKey2 = TQueryKey & QueryKey;

  export async function fetchData({
    queryKey
  }: QueryFunctionContext<TQueryKey2>) {
    return await arrangeAppApi.get<ResponseType>(
      `/product-category/parent-id/${queryKey[1].parentId}`
    );
  }

  export const useFetchQuery = (
    { parentId }: ReqeustType,
    { ...props }: TUseQueryOptions = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery(
      [
        KEY_STRING,
        {
          parentId
        }
      ],
      fetchData,
      {
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };

  export const useFetchQueries = (
    reqeustTypes: ReqeustType[],
    { ...props }: TUseQueryOptions = {}
  ) => {
    const errorHandler = useErrorHandler();

    const queries = useQueries<TUseQueryOptions[]>({
      queries: reqeustTypes.map((reqeustType) => ({
        queryKey: [KEY_STRING, reqeustType],
        queryFn: fetchData,
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        enabled: !!reqeustType.parentId,
        ...props
      }))
    });

    return queries;
  };
}
