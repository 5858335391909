import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import {
  InfiniteData,
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import {
  LinkfarmProducInspectiontStats,
  LinkfarmProductTaxType
} from '../../../../types/linkfarm/product';
import axios from '../../../../utils/axios';
import {
  LinkfarmProductsRes,
  LINK_FARM_FETCH_PRODUCT_KEY_STRING
} from './fetch-linkfarm-product';
import produce from 'immer';

export type ModifyLinkfarmProductParam = {
  linkfarmProductId: number;
  storeTitle?: string;
  inspectiontStatus?: LinkfarmProducInspectiontStats;
  inspectiontRequestFailReason?: string;
  taxType?: LinkfarmProductTaxType;
  reactionScore?: number;
  isVisibleLinkfarmMain?: boolean;
};

export const useModifyLinkfarmProductMutate = ({
  onSuccess,
  ...options
}: UseMutationOptions<
  AxiosResponse,
  AxiosError,
  ModifyLinkfarmProductParam
> = {}) => {
  const errorHandler = useErrorHandler();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({
      linkfarmProductId,
      inspectiontRequestFailReason,
      inspectiontStatus,
      storeTitle,
      taxType,
      reactionScore,
      isVisibleLinkfarmMain
    }) => {
      return axios.put(`store/linkfarm/product/${linkfarmProductId}`, {
        inspectiontRequestFailReason,
        inspectiontStatus,
        storeTitle,
        taxType,
        reactionScore,
        isVisibleLinkfarmMain
      });
    },
    {
      onError: errorHandler,
      onSuccess: (...args) => {
        const [
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          _,
          {
            linkfarmProductId,
            inspectiontRequestFailReason,
            inspectiontStatus,
            storeTitle,
            reactionScore,
            isVisibleLinkfarmMain
          }
        ] = args;

        queryClient.setQueriesData<
          InfiniteData<AxiosResponse<LinkfarmProductsRes>> | undefined
        >([LINK_FARM_FETCH_PRODUCT_KEY_STRING], (previous) => {
          if (previous) {
            return produce(previous, (draft) => {
              draft = {
                ...draft,
                pages: draft.pages.map((page) => ({
                  ...page,
                  data: {
                    ...page.data,
                    linkfarmProducts: page.data.linkfarmProducts.map((lp) => {
                      if (lp.id === linkfarmProductId) {
                        return {
                          ...lp,
                          inspectiontRequestFailReason:
                            inspectiontRequestFailReason === undefined
                              ? lp.inspectiontRequestFailReason
                              : inspectiontRequestFailReason,
                          storeTitle:
                            storeTitle === undefined
                              ? lp.storeTitle
                              : storeTitle,
                          inspectiontStatus:
                            inspectiontStatus === undefined
                              ? lp.inspectiontStatus
                              : inspectiontStatus,
                          reactionScore: reactionScore ?? lp.reactionScore ?? 0,
                          isVisibleLinkfarmMain:
                            isVisibleLinkfarmMain ?? lp.isVisibleLinkfarmMain
                        };
                      }

                      return lp;
                    })
                  }
                }))
              };
              return draft;
            });
          }

          return previous;
        });

        onSuccess?.(...args);
      },
      ...options
    }
  );

  return mutation;
};
