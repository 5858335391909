import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import classNames from 'classnames/bind';
import { Suspense } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/atoms/Button';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import { FetchPickuponSite } from '../../hooks/http/pickupon-site/fetch-pickupon-site';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export default function PickonSitePage() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'현장수령 지점 리스트'}></Header>
        <PickonSiteData />
      </Suspense>
    </DefaultTemplate>
  );
}

function PickonSiteData() {
  const pickuponSite = FetchPickuponSite.useFetchQuery();

  return (
    <Flex
      flexDirection="flex-col"
      width="w-full"
      alignItems="items-start"
      gapSize={StyleBuilderInstane.toGapSpaceType('gap-s')}
    >
      <Button text="추가" />
      <table className={`${cx('table')}`}>
        <thead>
          <tr>
            <th>No</th>
            <th>지점명</th>
            <th>주소</th>
            <th>상세주소</th>
            <th>우편번호</th>
            <th>회차정보</th>
            {/* <th>픽업 시작일</th> */}
            {/* <th>픽업 종료일</th>
            <th>수정</th> */}
          </tr>
        </thead>
        <tbody>
          {pickuponSite.data?.data.items.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.address}</td>
              <td>{item.detailAddress}</td>
              <td>{item.postCode}</td>
              <td>
                <Link to={`/pickon-site/${item.id}`}>
                  <Button text="회차 리스트" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
}
