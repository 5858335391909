import { AxiosError, AxiosResponse } from 'axios';
import { isNextCursor, QueryInfiniteCallback } from '..';
import axios from '../../../utils/axios';
import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  InfiniteData,
  UseQueryOptions,
  UseMutationOptions
} from '@tanstack/react-query';
import { StatusCodes } from 'http-status-codes';
import { USED_ITEMDS } from '../../../utils/dropdown-item';
import { useErrorHandler } from 'react-error-boundary';
import produce from 'immer';
import { ArrangeProductSubscribeKind } from '@aimpact-korea/arrange-front-types';

export namespace FetchStores {
  export type StoresRes = {
    stores: {
      id: number;
      lastAutoOrderSyncBackup: string | null;
      createLinkfarmRequest: boolean;
      barofarmExist: boolean;
      ceoAccount: {
        id: number;
        isChannelTalk: boolean;
        name: string;
        phone: string;
        email: string;
        alimtalkEnabled: boolean;
        lastLoginedAt: string | null;
        kakaoProfileImg: string | null;
        androidVersion: string | null;
        firstStartArrangeMini: boolean;
        androidAppReviewType: 'NO_ACTION' | 'PENDING' | 'SUCCESS';
      };
      isActiveCustomerBackup: boolean;
      shareCount: number;
      orderGroupCount: number;
      orderGroupCountOfBeforeMonth: number;
      company: string;
      productsCount: number;
      updatedAt: string;
      createdAt: string;
      deliveryFormCount: number;
      address: string;
      detailedAddress: string | null;
      postCode: string | null;
      barofarmEnabled: boolean;
      sharedStore: {
        id: number;
        name: string;
        phone: string;
        kakaoProfileImg: string | null;
      }[];
      arrangeProductSubscribe: {
        id: number;
        storeId: number;
        arrangeProductSubscribeKind: ArrangeProductSubscribeKind;
        endDate: string;
        createdAt: string;
        orderPossibleCount: number;
        remainingPossibleCount: number;
      } | null;
    }[];
    cursor: StoresCursor;
  };

  export type StoresCursor = {
    id?: number;
    standardColumn?: string;
    orderBy?: string | null;
    value?: string | number;
  };

  export type StoresParam = {
    cursor?: StoresCursor;
    keyword?: string;
    isBlockStores?: boolean;
    limit?: number;
    alimtalkEnableds?: typeof USED_ITEMDS[number]['value'][];
  };

  export const KEY_STRING = 'stores' as const;

  export type StoresQueryKeyType = [typeof KEY_STRING, StoresParam];
  export type StoresQueryKey = StoresQueryKeyType & QueryKey;

  export async function fetchStores({
    pageParam,
    queryKey
  }: QueryFunctionContext<StoresQueryKey, StoresCursor>) {
    const _queryKey = queryKey as StoresQueryKeyType;

    let queryParam = '';

    if (pageParam) {
      if (pageParam.id) {
        queryParam = decodeURIComponent(
          `?cursor[id]=${pageParam.id}&cursor[orderBy]=${pageParam.orderBy}&cursor[value]=${pageParam.value}&cursor[standardColumn]=${pageParam.standardColumn}`
        );
      }
    } else if (_queryKey[1].cursor?.standardColumn) {
      const urlSearchParam = new URLSearchParams();

      if (_queryKey[1].cursor.id) {
        urlSearchParam.append('cursor[id]', `${_queryKey[1].cursor.id}`);
      }
      if (_queryKey[1].cursor.orderBy) {
        urlSearchParam.append(
          'cursor[orderBy]',
          `${_queryKey[1].cursor.orderBy}`
        );
      }
      if (_queryKey[1].cursor.value) {
        urlSearchParam.append('cursor[value]', `${_queryKey[1].cursor.value}`);
      }
      if (_queryKey[1].cursor.standardColumn) {
        urlSearchParam.append(
          'cursor[standardColumn]',
          `${_queryKey[1].cursor.standardColumn}`
        );
      }

      queryParam = `?${urlSearchParam.toString()}`;
    }

    return await axios.get<StoresRes>('/store' + queryParam, {
      params: {
        keyword: _queryKey[1].keyword || undefined,
        isBlockStores: _queryKey[1].isBlockStores,
        limit: _queryKey[1].limit,
        alimtalkEnableds: _queryKey[1].alimtalkEnableds
      }
    });
  }

  export const useModify = () => {
    const queryClient = useQueryClient();
    const fetchStore = FetchStore.useMutateStore({
      onSuccess: (data) => {
        modify(data.data.id, data.data);
      }
    });

    const modify = (storeId: number, data: StoresRes['stores'][number]) => {
      queryClient.setQueriesData(
        [KEY_STRING],
        (previous: InfiniteData<AxiosResponse<StoresRes>> | undefined) => {
          const _previous = previous as InfiniteData<AxiosResponse<StoresRes>>;
          return produce(_previous, (draft) => {
            draft.pages = draft.pages.map((page) => {
              page.data.stores = page.data.stores.map((item) => {
                if (item.id === storeId) {
                  return {
                    ...item,
                    ...data
                  };
                }
                return item;
              });

              return page;
            });
          });
        }
      );
    };

    const requestAndroidAppReview = (ceoAccountId: number) => {
      queryClient.setQueriesData(
        [KEY_STRING],
        (previous: InfiniteData<AxiosResponse<StoresRes>> | undefined) => {
          const _previous = previous as InfiniteData<AxiosResponse<StoresRes>>;
          return produce(_previous, (draft) => {
            draft.pages = draft.pages.map((page) => {
              page.data.stores = page.data.stores.map((item) => {
                if (item.ceoAccount.id === ceoAccountId) {
                  item.ceoAccount.androidAppReviewType = 'PENDING';
                }
                return item;
              });

              return page;
            });
          });
        }
      );
    };

    const refetchByStore = (storeId: number) => {
      fetchStore.mutate({
        storeId
      });
    };

    return {
      modify,
      requestAndroidAppReview,
      refetchByStore
    };
  };

  export const useStores = (
    {
      cursor: { id, orderBy, standardColumn, value } = {},
      keyword = '',
      isBlockStores = false,
      limit = 15,
      alimtalkEnableds: alimtalkEnabled
    }: StoresParam = {},
    {
      ...props
    }: QueryInfiniteCallback<
      AxiosResponse<StoresRes>,
      AxiosError,
      AxiosResponse<StoresRes>,
      StoresQueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useInfiniteQuery(
      [
        'stores',
        {
          cursor: {
            id,
            orderBy,
            standardColumn,
            value
          },
          keyword,
          isBlockStores,
          limit,
          alimtalkEnableds: alimtalkEnabled
        }
      ],
      fetchStores,
      {
        getNextPageParam: (data, datas) => {
          return isNextCursor(data.data.cursor, datas);
        },
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}

export namespace FetchStore {
  export type StoreRes = {
    id: number;
    lastAutoOrderSyncBackup: string | null;
    createLinkfarmRequest: boolean;
    barofarmExist: boolean;
    ceoAccount: {
      id: number;
      name: string;
      phone: string;
      email: string;
      alimtalkEnabled: boolean;
      lastLoginedAt: string | null;
      kakaoProfileImg: string | null;
      isChannelTalk: boolean;
      androidVersion: string | null;
      firstStartArrangeMini: boolean;
      androidAppReviewType: 'NO_ACTION' | 'PENDING' | 'SUCCESS';
    };
    isActiveCustomerBackup: boolean;
    shareCount: number;
    orderGroupCount: number;
    orderGroupCountOfBeforeMonth: number;
    company: string;
    productsCount: number;
    updatedAt: string;
    createdAt: string;
    deliveryFormCount: number;
    address: string;
    detailedAddress: string | null;
    postCode: string | null;
    barofarmEnabled: boolean;
    sharedStore: {
      id: number;
      name: string;
      phone: string;
      kakaoProfileImg: string | null;
    }[];
    isBlock: boolean;
    arrangeProductSubscribe: {
      id: number;
      storeId: number;
      arrangeProductSubscribeKind: ArrangeProductSubscribeKind;
      endDate: string;
      createdAt: string;
      orderPossibleCount: number;
      remainingPossibleCount: number;
    } | null;
  };

  export type StoreParam = {
    storeId: number;
  };

  export const KEY_STRING = 'store' as const;

  type StoreQueryKeyType = [typeof KEY_STRING, StoreParam];
  type StoreQueryKey = StoreQueryKeyType & QueryKey;

  export async function fetchStore({
    queryKey
  }: QueryFunctionContext<StoreQueryKey>) {
    const _queryKey = queryKey as StoreQueryKeyType;

    return await axios.get<StoreRes>(`/store/${_queryKey[1].storeId}`);
  }

  export const useStore = (
    { storeId }: StoreParam,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<StoreRes>,
      AxiosError,
      AxiosResponse<StoreRes>,
      StoreQueryKeyType
    > = {}
  ) => {
    const query = useQuery(
      [
        KEY_STRING,
        {
          storeId
        }
      ],
      fetchStore,
      {
        staleTime: 60000,
        ...props
      }
    );

    return query;
  };

  export const useMutateStore = ({
    onSuccess,
    ...options
  }: UseMutationOptions<
    AxiosResponse<StoreRes>,
    AxiosError,
    StoreParam
  > = {}) => {
    const mutation = useMutation(
      ({ storeId }) => {
        return axios.get<StoreRes>(`/store/${storeId}`);
      },
      {
        onSuccess: (...rest) => {
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace MutationStoreAddBlock {
  export type StoreAddBlockParam = {
    storeId: number;
  };

  export const useMutationStoreAddBlock = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<
    AxiosResponse,
    AxiosError,
    StoreAddBlockParam
  > = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ storeId }) => {
        return axios.post(`/store-block/store/${storeId}`);
      },
      {
        onSuccess: (...rest) => {
          queryClient.setQueriesData(
            [FetchStores.KEY_STRING, { isBlockStores: false }],
            (
              previous:
                | InfiniteData<AxiosResponse<FetchStores.StoresRes>>
                | undefined
            ) => {
              const _previous = previous as InfiniteData<
                AxiosResponse<FetchStores.StoresRes>
              >;

              _previous.pages = _previous.pages.map((page) => {
                page.data.stores = page.data.stores.filter(
                  (store) => store.id !== rest[1].storeId
                );

                return page;
              });

              return _previous;
            }
          );

          queryClient.invalidateQueries([
            FetchStores.KEY_STRING,
            { isBlockStores: true }
          ]);

          onSuccess?.(...rest);
        },
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.CONFLICT:
              alert('이미 차단 된 업체입니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace MutationStoreCancelBlock {
  export type Param = {
    storeId: number;
  };

  export const useMutate = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, Param> = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ storeId }) => {
        return axios.delete(`/store-block/store/${storeId}`);
      },
      {
        onSuccess: (...rest) => {
          queryClient.setQueriesData(
            [FetchStores.KEY_STRING, { isBlockStores: true }],
            (
              previous:
                | InfiniteData<AxiosResponse<FetchStores.StoresRes>>
                | undefined
            ) => {
              const _previous = previous as InfiniteData<
                AxiosResponse<FetchStores.StoresRes>
              >;

              _previous.pages = _previous.pages.map((page) => {
                page.data.stores = page.data.stores.filter(
                  (store) => store.id !== rest[1].storeId
                );

                return page;
              });

              return _previous;
            }
          );

          queryClient.invalidateQueries([
            FetchStores.KEY_STRING,
            { isBlockStores: false }
          ]);

          onSuccess?.(...rest);
        },
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('존재하지 않는 업체입니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace MutationStoreDetail {
  export type Param = {
    storeId: number;
    company?: string;
    address?: string;
    detailAddress?: string | null;
    postCode?: string;
    barofarmEnabled?: boolean;
    sendMessage?: string | null;
    createLinkfarmRequest?: boolean;
  };

  export const useMutate = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, Param> = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ storeId, ...param }) => {
        return axios.put(`/store/${storeId}`, param);
      },
      {
        onSuccess: (...rest) => {
          queryClient.setQueriesData(
            [FetchStores.KEY_STRING],
            (
              previous:
                | InfiniteData<AxiosResponse<FetchStores.StoresRes>>
                | undefined
            ) => {
              const _previous = previous as InfiniteData<
                AxiosResponse<FetchStores.StoresRes>
              >;

              return produce(_previous, (draft) => {
                draft.pages = draft.pages.map((page) => {
                  page.data.stores = page.data.stores.map((store) => {
                    if (store.id === rest[1].storeId) {
                      return {
                        ...store,
                        ...rest[1]
                      };
                    }
                    return {
                      ...store
                    };
                  });

                  return page;
                });
              });
            }
          );

          queryClient.invalidateQueries([
            FetchStore.KEY_STRING,
            { storeId: rest[1].storeId }
          ]);

          onSuccess?.(...rest);
        },
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('존재하지 않는 업체입니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace MutateArrangeAppForceSignin {
  export type Param = {
    storeId: number;
  };

  export type ResData = {
    accessToken: string;
    refreshToken: string;
  };

  export const useMutate = ({
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse<ResData>, AxiosError, Param> = {}) => {
    const mutation = useMutation(
      ({ storeId }) => {
        return axios.post(`/store/arrange/force-signin/${storeId}`);
      },
      {
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('존재하지 않는 업체입니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace FetchStoreSendMessage {
  export type ResponseType = {
    sendAdminMessage: string | null;
  };

  export type ParameterType = {
    storeId: number;
  };

  export const KEY_STRING = 'fetch-send-store-message' as const;

  type SendStoreMessageQueryKeyType = [typeof KEY_STRING, ParameterType];
  type SendStoreMessageQueryKey = SendStoreMessageQueryKeyType & QueryKey;

  export async function fetchSendStoreMessage({
    queryKey
  }: QueryFunctionContext<SendStoreMessageQueryKey>) {
    const _queryKey = queryKey as SendStoreMessageQueryKeyType;

    return await axios.get<ResponseType>(
      `/store/send-admin-message/${_queryKey[1].storeId}`
    );
  }

  export const useFetchSendStoreMessage = (
    { storeId }: ParameterType,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      SendStoreMessageQueryKeyType
    > = {}
  ) => {
    const query = useQuery(
      [
        KEY_STRING,
        {
          storeId
        }
      ],
      fetchSendStoreMessage,
      {
        staleTime: 60000,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        ...props
      }
    );

    return query;
  };
}

export namespace InitBulkOrderCountApi {
  export type Param = {
    storeId: number;
  };

  export const useMutate = ({
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, Param> = {}) => {
    const mutation = useMutation(
      ({ storeId }) => {
        return axios.patch(`/store/${storeId}/init-bulk-order-count`);
      },
      {
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('존재하지 않는 업체입니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace MutationStoreActiveCustomerBackup {
  export type Param = {
    storeId: number;
    isActiveCustomerBackup: boolean;
  };

  export const useMuuuuuutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, Param> = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ storeId, isActiveCustomerBackup }) => {
        return axios.patch(`/store/${storeId}/is-active-customer-backup`, {
          isActiveCustomerBackup
        });
      },
      {
        onSuccess: (...rest) => {
          queryClient.setQueriesData(
            [FetchStores.KEY_STRING],
            (
              previous:
                | InfiniteData<AxiosResponse<FetchStores.StoresRes>>
                | undefined
            ) => {
              const _previous = previous as InfiniteData<
                AxiosResponse<FetchStores.StoresRes>
              >;

              // _previous.pages = _previous.pages.map((page) => {
              //   page.data.stores = page.data.stores.map((store) => {
              //     if (store.id === rest[1].storeId) {
              //       return {
              //         ...store,
              //         isActiveCustomerBackup: rest[1].isActiveCustomerBackup
              //       };
              //     }
              //     return {
              //       ...store
              //     };
              //   });

              //   return page;
              // });
              return produce(_previous, (draft) => {
                draft.pages = draft.pages.map((page) => {
                  page.data.stores = page.data.stores.map((store) => {
                    if (store.id === rest[1].storeId) {
                      return {
                        ...store,
                        isActiveCustomerBackup: rest[1].isActiveCustomerBackup
                      };
                    }
                    return {
                      ...store
                    };
                  });

                  return page;
                });
              });
            }
          );

          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
