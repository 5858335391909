import classNames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import Flex from '../../molecules/Flex';
import SortingIcon, {
  Props as SortingIconProps
} from '../../molecules/SortingIcon';
import { SortState } from '../../../types/sort';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props extends SortingIconProps {
  justifyContent?: 'center' | 'space-between';
  onClick: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}

const SortingWithText: React.FC<Props> = ({
  children,
  justifyContent = 'center',
  onClick,
  ...props
}) => {
  return (
    <Flex direction="row" alignItems="center" justifyContent={justifyContent}>
      {children}
      <div className={cx('icon')} onClick={onClick}>
        <SortingIcon {...props} />
      </div>
    </Flex>
  );
};

export default SortingWithText;

export function useSort<T extends string = string>({
  initState
}: {
  initState?: SortState<T>;
} = {}) {
  const initStateRef = useRef(initState);
  const [sorts, setSorts] = useState<Map<T, SortState<T>>>(
    new Map<T, SortState<T>>()
  );
  const [sort, setSort] = useState<SortState<T> | undefined>(
    initStateRef.current
  );

  const props = (type: T): Props => {
    if (!sorts.has(type)) {
      setSorts((prev) => {
        if (initStateRef.current?.standardColumn === type) {
          return new Map(prev).set(type, {
            standardColumn: type,
            orderBy: initStateRef.current.orderBy
          });
        }

        return new Map(prev).set(type, {
          standardColumn: type,
          orderBy: null
        });
      });
    }

    return {
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();

        const map = sorts.get(type);
        if (map) {
          const orderBy = map.orderBy === 'DESC' ? 'ASC' : 'DESC';

          setSorts((prev) => {
            const map = new Map(prev).set(type, {
              standardColumn: type,
              orderBy: orderBy
            });

            for (const [, value] of map) {
              if (value.standardColumn !== type) {
                value.orderBy = undefined;
              }
            }

            return map;
          });

          setSort({
            standardColumn: type,
            orderBy: orderBy
          });
        }
      },
      orderBy: sorts.get(type)?.orderBy
    };
  };

  return {
    props,
    sort
  };
}
