export function parsePhone(phone: string) {
  if (!phone) {
    return phone;
  }
  return phone
    .replace(/[^0-9]/g, '')
    .replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      '$1-$2-$3'
    )
    .replace('--', '-');

  // try {
  //   return phoneUtil.format(
  //     phoneUtil.parseAndKeepRawInput(phone, 'KR'),
  //     PhoneNumberFormat.NATIONAL
  //   );
  // } catch {
  //   return phone;
  // }
}
