import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DashBoard from './pages/DashBoard';
import Signin from './pages/Signin';
import NotFound from './pages/NotFound';
import { adminAuthInfo } from './recoil/atoms';
import { Helmet } from 'react-helmet-async';
import React, { useCallback, useEffect } from 'react';
import TransactionPage from './pages/Transaction';
import useSignout from './hooks/useSignout';
import Products from './pages/Product';
import Stores from './pages/Store';
import DeliveryForm from './pages/DeliveryForm';
import StoreDetail from './pages/Store/Detail';
import StoreProductDetail from './pages/Product/Detail';
import DeliveryFormDetail from './pages/DeliveryForm/Detail';
import { MenuType } from './types/menu-type';
import { menuType as menuTypeRecoilAtom } from './recoil/atoms';
import StoreBlock from './pages/StoreBlock';
import ProductInterest from './pages/ProductInterest';
import LinkfarmProducts from './pages/Linkfarm/Product';
import InspectionLinkfarmProducts from './pages/Linkfarm/Product/Inspection';
import MessageAnalysisPage from './pages/MessageAnalysisLog';
import VisionOcrLogsPage from './pages/VisionOcr';
import AddressStopWordsPage from './pages/MessageAnalysisLog/StopWord/Address';
import StopWordPrePage from './pages/MessageAnalysisLog/StopWord/NamePre';
import LinkfarmProductResellerPage from './pages/Linkfarm/Product/Reseller';
import ResellerRequests from './pages/Reseller/Request';
import ResellerListPage from './pages/Reseller';
import OrderSettlePage from './pages/OrderSettle';
import ResellerOrderSettlePage from './pages/ResellerOrderSettle';
import ContractTerms from './pages/ContractTerms';
import ArrangeAgreement from './pages/ContractTerms/ArrangeAgreement';
import ArrangePrivacy from './pages/ContractTerms/ArrangePrivacy';
import LinkfarmAgreement from './pages/ContractTerms/LinkfarmAgreement';
import LinkfarmPrivacy from './pages/ContractTerms/LinkfarmPrivacy';
import ArrangeCallPrivacy from './pages/ContractTerms/ArrangeCallPrivacy';
import ArrangeManualVideo from './pages/ArrangeManualVideo';
import ArrangeManualGroupDetailVideo from './pages/ArrangeManualVideo/Detail';
import ArrangeNoticePage from './pages/Notice/Arrange';
import ArrangeNoticeDetailPage from './pages/Notice/Arrange/Detail';
import ArrangeNoticeCreatePage from './pages/Notice/Arrange/Create';
import AndroidMessageAppPage from './pages/AndroidMessageApp';
import ArrangeProductPaymentPage from './pages/ArrangeProductPayment';
import PickonSitePage from './pages/PickonSite';
import PickonSiteDetailPage from './pages/PickonSite/PickupOnSiteDetail';
import AndroidAppVersionPage from './pages/AndroidAppVersion';
import CouponPresetPage from './pages/CouponPreset';
import Account from './pages/Account';

const Routers: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route element={<DefaultRoute title="로그인" />}>
          <Route path="/signin" element={<Signin />} />
        </Route>
        <Route element={<AuthRoute title="데이터보드" menuType="dash-board" />}>
          <Route path="/" element={<DashBoard />} />
        </Route>
        <Route
          element={
            <AuthRoute title="데이터보드" menuType="transaction-history" />
          }
        >
          <Route path="/transaction" element={<TransactionPage />} />
        </Route>
        <Route element={<AuthRoute title="업체상품 목록" menuType="product" />}>
          <Route path="/product" element={<Products />} />
        </Route>
        <Route
          element={
            <AuthRoute title="관심상품 목록" menuType="product-interest" />
          }
        >
          <Route path="/product-interest" element={<ProductInterest />} />
        </Route>
        <Route element={<AuthRoute title="상품정보" menuType="product" />}>
          <Route path="/product/:productId" element={<StoreProductDetail />} />
        </Route>
        <Route element={<AuthRoute title="업체 리스트" menuType="store" />}>
          <Route path="/store" element={<Stores />} />
        </Route>
        <Route
          element={<AuthRoute title="유저 리스트" menuType="account-list" />}
        >
          <Route path="/accounts" element={<Account />} />
        </Route>
        <Route element={<AuthRoute title="차단업체" menuType="store-block" />}>
          <Route path="/store-block" element={<StoreBlock />} />
        </Route>
        <Route
          element={<AuthRoute title="택배양식" menuType="delivery-form" />}
        >
          <Route path="/delivery-form" element={<DeliveryForm />} />
        </Route>
        <Route
          element={<AuthRoute title="택배양식" menuType="delivery-form" />}
        >
          <Route
            path="/delivery-form/:deliveryFormId"
            element={<DeliveryFormDetail />}
          />
        </Route>
        <Route element={<AuthRoute title="유저 상세" menuType="store" />}>
          <Route path="/store/:storeId" element={<StoreDetail />} />
        </Route>
        <Route
          element={
            <AuthRoute title="쇼핑몰 상품" menuType="linkfarm-product" />
          }
        >
          <Route path="/linkfarm/product" element={<LinkfarmProducts />} />
        </Route>
        <Route
          element={<AuthRoute title="리셀러 목록" menuType="reseller-list" />}
        >
          <Route path="/reseller" element={<ResellerListPage />} />
        </Route>
        <Route
          element={
            <AuthRoute title="리셀러 신청" menuType="reseller-reqeust" />
          }
        >
          <Route path="/reseller/request" element={<ResellerRequests />} />
        </Route>
        <Route
          element={
            <AuthRoute
              title="검수중인 쇼핑몰 상품"
              menuType="linkfarm-product-inspections"
            />
          }
        >
          <Route
            path="/linkfarm/product/inspection"
            element={<InspectionLinkfarmProducts />}
          />
        </Route>
        <Route
          element={
            <AuthRoute title="리셀러 상품" menuType="linkfarm-product-resll" />
          }
        >
          <Route
            path="/linkfarm/product/resell"
            element={<LinkfarmProductResellerPage />}
          />
        </Route>
        <Route
          element={
            <AuthRoute title="메세지 분석 로그" menuType="message-analysis" />
          }
        >
          <Route path="/message-analysis" element={<MessageAnalysisPage />} />
        </Route>
        <Route
          element={
            <AuthRoute
              title="비전 OCR 로그 리스트"
              menuType="vision-ocr-logs"
            />
          }
        >
          <Route path="/vision-ocr-logs" element={<VisionOcrLogsPage />} />
        </Route>
        <Route
          element={
            <AuthRoute
              title="리셀러 주문 정산현황"
              menuType="reseller-order-settle"
            />
          }
        >
          <Route
            path="/reseller-order-settle"
            element={<ResellerOrderSettlePage />}
          />
        </Route>
        <Route
          element={
            <AuthRoute title="주소분석 불용어" menuType="stop-word-address" />
          }
        >
          <Route path="/stop-word/address" element={<AddressStopWordsPage />} />
        </Route>
        <Route
          element={
            <AuthRoute
              title="주문자 예측 불용어"
              menuType="stop-word-name-pre"
            />
          }
        >
          <Route path="/stop-word/name-pre" element={<StopWordPrePage />} />
        </Route>
        <Route
          element={<AuthRoute title="주문정산 현황" menuType="order-settle" />}
        >
          <Route path="/order-settle" element={<OrderSettlePage />} />
        </Route>
        <Route
          element={<AuthRoute title="서비스 약관" menuType="contract-terms" />}
        >
          <Route path="/contract-terms" element={<ContractTerms />} />
        </Route>
        <Route
          element={
            <AuthRoute
              title="어레인지 이용약관"
              menuType="contract-terms-arrange-agreement"
            />
          }
        >
          <Route
            path="/contract-terms/arrange-agreement"
            element={<ArrangeAgreement />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="어레인지 개인정보 처리방침"
              menuType="contract-terms-arrange-privacy"
            />
          }
        >
          <Route
            path="/contract-terms/arrange-privacy"
            element={<ArrangePrivacy />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="어레인지 콜 개인정보 처리방침"
              menuType="contract-terms-arrange-call-privacy"
            />
          }
        >
          <Route
            path="/contract-terms/arrange-call-privacy"
            element={<ArrangeCallPrivacy />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="링크팜 이용약관"
              menuType="contract-terms-linkfarm-agreement"
            />
          }
        >
          <Route
            path="/contract-terms/linkfarm-agreement"
            element={<LinkfarmAgreement />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="링크팜 개인정보 처리방침"
              menuType="contract-terms-linkfarm-privacy"
            />
          }
        >
          <Route
            path="/contract-terms/linkfarm-privacy"
            element={<LinkfarmPrivacy />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="어레인지 사용법 동영상"
              menuType="arrange-manual-video"
            />
          }
        >
          <Route
            path="/arrange-manual-video"
            element={<ArrangeManualVideo />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="어레인지 사용법 동영상"
              menuType="arrange-manual-video"
            />
          }
        >
          <Route
            path="/arrange-manual-video/:arrangeManualGroupId"
            element={<ArrangeManualGroupDetailVideo />}
          />
        </Route>
        <Route
          element={
            <AuthRoute title="어레인지 공지사항" menuType="arrange-notice" />
          }
        >
          <Route path="/notice/arrange" element={<ArrangeNoticePage />} />
        </Route>
        <Route
          element={
            <AuthRoute
              title="어레인지 공지사항 상세"
              menuType="arrange-notice-detail"
            />
          }
        >
          <Route
            path="/notice/arrange/:noticeId"
            element={<ArrangeNoticeDetailPage />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="어레인지 공지사항 추가"
              menuType="arrange-notice-create"
            />
          }
        >
          <Route
            path="/notice/arrange/create"
            element={<ArrangeNoticeCreatePage />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="안드로이드 메시지 앱"
              menuType="android-message-app"
            />
          }
        >
          <Route
            path="/android-message-app"
            element={<AndroidMessageAppPage />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="어레인지 상품 구매내역"
              menuType="arrange-product-payment"
            />
          }
        >
          <Route
            path="/arrange-product-payment"
            element={<ArrangeProductPaymentPage />}
          />
        </Route>
        <Route
          element={
            <AuthRoute title="현장수령 지점 리스트" menuType="pickon-site" />
          }
        >
          <Route path="/pickon-site" element={<PickonSitePage />} />
        </Route>
        <Route
          element={
            <AuthRoute
              title="현장수령 회차 리스트"
              menuType="pickon-site-round-list"
            />
          }
        >
          <Route
            path="/pickon-site/:pickupOnSiteId"
            element={<PickonSiteDetailPage />}
          />
        </Route>
        <Route
          element={
            <AuthRoute
              title="안드로이드 앱 버전"
              menuType="android-app-version"
            />
          }
        >
          <Route
            path="/android-app-version"
            element={<AndroidAppVersionPage />}
          />
        </Route>
        <Route
          element={<AuthRoute title="쿠폰 프리셋" menuType="coupon-preset" />}
        >
          <Route path="/coupon-preset" element={<CouponPresetPage />} />
        </Route>
        <Route
          path="/*"
          element={<DefaultRoute title="NOT FOUND PAGE" menuType="store" />}
        >
          <Route path="/*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

type DefaultRoute = {
  title: string;
  menuType?: MenuType;
};

function DefaultRoute({ title, menuType }: DefaultRoute) {
  const setGMenuType = useSetRecoilState(menuTypeRecoilAtom);

  useEffect(() => {
    setGMenuType(menuType);
  }, [menuType, setGMenuType]);

  const signout = useSignout();

  // axios refreshToken 요청시 401 response 되면 처리될거
  const onLogout = useCallback(() => {
    signout();
  }, [signout]);

  /**
   * @TODO: 추후 Recoil Atom UseEffect로 값 변화 구독하여 자동 로그아웃 처리
   */
  useEffect(() => {
    window.addEventListener('on-logout', onLogout as EventListener);

    return () => {
      window.removeEventListener('on-logout', onLogout as EventListener);
    };
  }, [onLogout]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Outlet />
    </React.Fragment>
  );
}

type AuthRoute = DefaultRoute;
function AuthRoute({ title, menuType, ...props }: AuthRoute) {
  const { accessToken } = useRecoilValue(adminAuthInfo);

  return accessToken ? (
    <DefaultRoute title={title} menuType={menuType} {...props} />
  ) : (
    <Navigate to="/signin" />
  );
}

export default Routers;
