import {
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { StopWordType } from '../../../types/stop-word';
import { FetchStopWordName } from './fetch-stop-word-name';
import axios from '../../../utils/axios';

export namespace RemoveStopWordName {
  export type RequestParam = {
    type: StopWordType;
    word: string;
  };

  export const useMutateQuery = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestParam> = {}) => {
    const errorHandler = useErrorHandler();
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ type, word }) => {
        return axios.delete(`stop-word/name`, {
          params: {
            type,
            word
          }
        });
      },
      {
        onError: errorHandler,
        onSuccess: (data, { type, word }, context) => {
          queryClient.setQueryData<
            AxiosResponse<FetchStopWordName.ResponseType> | undefined
          >([FetchStopWordName.KEY_STRING, { type: type }], (data) => {
            if (!data) {
              return data;
            }
            return {
              ...data,
              data: {
                ...data.data,
                words: data.data.words.filter((sw) => sw !== word) ?? []
              }
            };
          });

          onSuccess?.(data, { type, word }, context);
        },
        ...options
      }
    );

    return mutation;
  };
}
