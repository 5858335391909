import { useEffect, useState } from 'react';
import { ColorType, SizeType } from '../../../../types/style';
import TextAtom from '../../../atoms/Text';
import { useContextApi } from '../context';
import {
  ActionKind,
  useContextApi as useContextApiMenu
} from '../../DropdownMenu/context';
import {
  ActionKind as ActionKindItem,
  useContextApi as useContextApiItem
} from './item-context';

type ComponentSizeType = Extract<SizeType, 'small' | 'medium' | 'large'>;

export interface Props {
  size?: ComponentSizeType;
  text: string;
  colorType?: ColorType;
}

const Text: React.FC<Props> = ({
  size: propSizeType,
  colorType: color,
  text
}) => {
  const { state } = useContextApi();
  const { dispatch: itemDispatch, state: itemState } = useContextApiItem();
  const { dispatch: menuDispatch } = useContextApiMenu();

  const [size, setSize] = useState<ComponentSizeType | undefined>(propSizeType);

  useEffect(() => {
    if (!propSizeType) {
      setSize(state.size);
    }
  }, [propSizeType, state.size]);

  useEffect(() => {
    if (itemState.value) {
      menuDispatch({
        type: ActionKind.ADD_ITEM,
        payload: {
          value: itemState.value,
          text: text,
          colorType: color ?? itemState.colorType
        }
      });
    }
  }, [color, itemState.colorType, itemState.value, menuDispatch, text]);

  useEffect(() => {
    itemDispatch({
      type: ActionKindItem.SET_STATE,
      payload: {
        text
      }
    });
  }, [itemDispatch, text]);

  return (
    <TextAtom
      size={size}
      colorType={color ?? itemState.colorType}
      isBold={true}
    >
      {text}
    </TextAtom>
  );
};

export default Text;
