import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchStores } from '../store';

export namespace DeleteArrangeProductSubscribeByStore {
  export type RequestType = {
    id: number;
    storeId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { refetchByStore } = FetchStores.useModify();

    const mutation = useMutation(
      ({ id }) => {
        return axios.delete(`/arrange-product-subscribe/${id}`);
      },
      {
        onSuccess: (...rest) => {
          refetchByStore(rest[1].storeId);
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
