import classNames from 'classnames/bind';
import { Suspense, useEffect, useState } from 'react';
import Button from '../../../components/atoms/Button';
import Hr from '../../../components/atoms/Hr';
import Text from '../../../components/atoms/Text';
import DropdownMenu from '../../../components/molecules/DropdownMenu';
import Flex from '../../../components/molecules/Flex';
import Selection from '../../../components/molecules/Selection';
import TextInput from '../../../components/molecules/TextInput';
import DetailSkeletion from '../../../components/organisms/DetailSkeleton';
import Header from '../../../components/organisms/Header';
import DefaultTemplate from '../../../components/templates/Default';
import {
  DeleteProductApi,
  FetchProduct,
  MutationProductDetail
} from '../../../hooks/http/product';
import styles from './index.module.scss';
import {
  SortableContainer,
  SortableItem,
  SortableItems
} from '../../../components/molecules/Sortable';
import DetailDataTemplate from '../../../components/templates/DetailDataTemplate';
import ClipboardCopy from '../../../components/molecules/ClipboardCopy';
import { Form, FormikContextType, FormikProvider, useFormik } from 'formik';
import { Optional } from 'utility-types';
import { objectDiffOmit } from '../../../utils/object/object-diff';
import { DropResult } from 'react-beautiful-dnd';
import DivTable from '../../../components/molecules/DivTable';
import { arrayMove } from '../../../utils/array/swap';
import { Type } from '../../../utils/decorators';
import { useLocationStateParam } from '../../../hooks/useLocationSearchQueryParam';
import { useNavigate } from 'react-router';
import {
  ArrowReturnIcon,
  EditPencilIcon,
  MessageIcon,
  TrashIcon,
  OrderIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import { FetchLastCategoryIdToWhole } from '../../../hooks/http/product-category/fetch-last-category-id-to-whole';
import Modal from '../../../components/molecules/Modal';
import { FetchRootCategory } from '../../../hooks/http/product-category/fetch-root-category';
import { FetchCategoryByParentId } from '../../../hooks/http/product-category/fetch-category-by-parent-id';
import produce from 'immer';
import { FetchProductCategoryKindByCateId } from '../../../hooks/http/product-category-kind/fetch-product-category-kind-by-cate-id';

const cx = classNames.bind(styles);

class PathParams {
  @Type(() => Number)
  productId!: number;
}

type IDetailForm = Optional<Omit<MutationProductDetail.Param, 'productId'>>;

const StoreProductDetail: React.FC = () => {
  const [editMode, setEditMode] = useState(false);

  const stateParam = useLocationStateParam(PathParams);

  const productModifyMutation = MutationProductDetail.useMutate({
    onSuccess: () => {
      setEditMode(false);
    },
    onError: (error) => {
      if (error.response && error.response.data) {
        switch (error.response.data.exceptionCode) {
          case 'P_1009':
            alert('품종을 선택해주세요.');
            return;
          case 'P_1010':
            alert('카테고리를 선택해주세요.');
            return;
        }
        throw error;
      }
    }
  });

  const onSubmit = (values: IDetailForm) => {
    const diff = objectDiffOmit<IDetailForm>(form.initialValues, values);

    diff.options = diff.options?.map((option) => {
      if (option.stock === null || option.stock === undefined) {
        return option;
      }

      option.stock = Number(option.stock);

      return option;
    });

    productModifyMutation.mutate({
      productId: stateParam.productId,
      ...diff
    });
  };

  const form = useFormik<IDetailForm>({
    initialValues: {},
    onSubmit: onSubmit
  });

  return (
    <FormikProvider value={form}>
      <Form onSubmit={form.handleSubmit}>
        <DefaultTemplate>
          <Suspense fallback={<DetailSkeletion />}>
            <Header
              prevPages={[
                {
                  name: '업체상품',
                  path: '/product'
                }
              ]}
              currentPageName={'상품정보'}
            >
              <Flex gap="xlarge" direction="row">
                {editMode && (
                  <Flex
                    direction="row"
                    alignItems="center"
                    gap="xsmall"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();

                      form.resetForm();

                      setEditMode(false);
                    }}
                  >
                    <ArrowReturnIcon colorType="text-primary-500" />
                    <Text colorType="primary-color" isBold={true} size="large">
                      되돌리기
                    </Text>
                  </Flex>
                )}
                <Button
                  text="완료"
                  size="large"
                  disabled={!editMode}
                  isLoading={productModifyMutation.isLoading}
                  htmlType="submit"
                ></Button>
              </Flex>
            </Header>
            <ProductDetail
              productId={stateParam.productId}
              editMode={editMode}
              setEditMode={setEditMode}
              form={form}
            />
          </Suspense>
        </DefaultTemplate>
      </Form>
    </FormikProvider>
  );
};

export default StoreProductDetail;

interface ProductDetailProps {
  editMode: boolean;
  setEditMode: (state: boolean) => void;
  form: FormikContextType<IDetailForm>;
  productId: number;
}

const ProductDetail: React.FC<ProductDetailProps> = ({
  editMode,
  setEditMode,
  form: { resetForm, ...form },
  productId
}) => {
  const product = FetchProduct.useFetchProduct(
    { productId },
    {
      enabled: !editMode
    }
  );

  const fetchLastCategoryIdToWhole = FetchLastCategoryIdToWhole.useFetchQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    lastCategoryId: form.values.lastProductCategoryId!
  });

  const fetchProductCategoryKindByCateId =
    FetchProductCategoryKindByCateId.useFetchQuery({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      productCategoryId: form.values.lastProductCategoryId!
    });

  const navigate = useNavigate();

  const [isShowCategoryModal, setShowCategoryModal] = useState(false);

  const deleteProduct = DeleteProductApi.useMutate({
    onSuccess: () => {
      alert('상품이 삭제 되었습니다.');
      navigate('/product', { replace: true });
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const productData = product.data!.data;

  useEffect(() => {
    resetForm({
      values: {
        name: productData.name,
        options: productData.options,
        lastProductCategoryId: productData.productCategory
          ? productData.productCategory.productCategoryLevels[
              productData.productCategory.productCategoryLevels.length - 1
            ].id
          : undefined,
        productCategoryKindId: productData.productCategoryKind
          ? productData.productCategoryKind.id
          : undefined
      }
    });
  }, [
    productData.name,
    productData.options,
    productData.productCategory,
    productData.productCategoryKind,
    resetForm
  ]);

  const setFormFieldValue = form.setFieldValue;
  useEffect(() => {
    if (fetchProductCategoryKindByCateId.data?.data) {
      if (
        fetchProductCategoryKindByCateId.data.data.items.every(
          (item) => item.id !== form.values.productCategoryKindId
        )
      ) {
        setFormFieldValue('productCategoryKindId', undefined);
      }
    }
  }, [
    fetchProductCategoryKindByCateId.data,
    form.values.productCategoryKindId,
    setFormFieldValue
  ]);

  const onSortEnd = (dropResult: DropResult) => {
    if (form.values.options && dropResult.destination) {
      const swapArray = arrayMove(
        form.values.options,
        dropResult.source.index,
        dropResult.destination.index
      );

      form.setValues({
        ...form.values,
        options: swapArray
      });
    }
  };

  function onClickDeleteProduct() {
    if (confirm('상품을 정말 삭제 하시겠습니까?')) {
      deleteProduct.mutate({
        productId: productId
      });
    }
  }

  function onClickApplyCategory(lastCategoryId: number) {
    form.setFieldValue('lastProductCategoryId', lastCategoryId);
  }

  return (
    <Flex gap="xxlarge">
      {isShowCategoryModal && (
        <ModifyProductCategory
          isOpen={isShowCategoryModal}
          onClickApplyCategory={onClickApplyCategory}
          handleClose={() => setShowCategoryModal(false)}
          initCategoryWhole={
            fetchLastCategoryIdToWhole.data?.data.productCategoryLevels ?? []
          }
        />
      )}
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap="medium"
        >
          <Text size="large" isBold={true} colorType="gray-color">
            상품코드:{' '}
            <Text colorType="gray-dark-color" size="large" isBold={true}>
              aimpact
            </Text>
          </Text>
          <ClipboardCopy text={productData.code} toastPosition="right">
            <Button text="복사" size="small" fit={true} />
          </ClipboardCopy>
        </Flex>
        <Flex direction="row" gap="xlarge">
          <Selection>
            <Selection.Label>
              <Text colorType="gray-color" isBold={true} size="small">
                상품 비노출
              </Text>
            </Selection.Label>
            <Selection.Input
              disabled={!editMode}
              type="toggle"
              value=""
              isChecked={false}
            ></Selection.Input>
          </Selection>
          <Button
            startIcon={<MessageIcon colorType="text-white" />}
            text="1:1 메시지"
            type="primary-color"
            size="large"
            disabled={editMode}
          />
          <Button
            startIcon={<EditPencilIcon colorType="text-white" />}
            text="수정"
            type="primary-color"
            size="large"
            onClick={(e) => {
              e.stopPropagation();

              setEditMode(true);
            }}
            disabled={editMode}
          />
          <Button
            onClick={() => onClickDeleteProduct()}
            startIcon={<TrashIcon colorType="text-white" />}
            text="삭제"
            type="secondary-color"
            size="large"
          />
        </Flex>
      </Flex>
      <Flex direction="row" gap="xlarge">
        <TextInput
          sizeType="xxlarge"
          disabled={!editMode}
          isContour={true}
          widthType="auto"
        >
          <TextInput.Head>
            <TextInput.Head.Icon />
            <TextInput.Head.Text text="상품분류명" />
          </TextInput.Head>
          <TextInput.Body>
            <TextInput.Body.Input
              value={form.values.name}
              handleChangeValue={(value) => {
                form.setValues({
                  ...form.values,
                  name: value
                });
              }}
            />
          </TextInput.Body>
        </TextInput>
        <TextInput
          sizeType="xxlarge"
          disabled={!editMode}
          isContour={true}
          widthType="auto"
          className="cursor-pointer"
        >
          <TextInput.Head>
            <TextInput.Head.Icon />
            <TextInput.Head.Text text="카테고리 선택" />
          </TextInput.Head>
          <TextInput.Body>
            <TextInput.Body.Input
              onClick={() => setShowCategoryModal(true)}
              readOnly={true}
              placeholder="카테고리를 선택해주세요."
              className="cursor-pointer"
              value={
                fetchLastCategoryIdToWhole.data?.data.productCategoryLevels
                  .map((productCategoryLevel) => productCategoryLevel.name)
                  .join(' > ') ?? ''
              }
            />
          </TextInput.Body>
        </TextInput>
        {fetchProductCategoryKindByCateId.data?.data.items.length ? (
          <DropdownMenu
            size="xxlarge"
            disabled={!editMode}
            setValue={(value) =>
              form.setFieldValue('productCategoryKindId', parseInt(value))
            }
            value={
              form.values.productCategoryKindId
                ? `${form.values.productCategoryKindId}`
                : undefined
            }
          >
            <DropdownMenu.Label label="품종" iconComponent={EditPencilIcon} />
            <DropdownMenu.Title isBottomLine={true}>
              <DropdownMenu.Title.Text placeholder="품종을 선택해주세요." />
              <DropdownMenu.Title.UpDownIcon />
            </DropdownMenu.Title>
            <DropdownMenu.ItemWrapper>
              <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                {fetchProductCategoryKindByCateId.data?.data.items.map(
                  (kindItem) => (
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                      sizeType="large"
                      value={`${kindItem.id}`}
                      key={kindItem.id}
                    >
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                        size="large"
                        text={`${kindItem.name}`}
                      />
                    </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                  )
                )}
              </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
            </DropdownMenu.ItemWrapper>
          </DropdownMenu>
        ) : undefined}
      </Flex>
      <Hr color="gray-dark-color" />
      <DetailDataTemplate>
        <DetailDataTemplate.Box title="상품 요약">
          <Flex
            className={cx('product-summary-wrapper')}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="medium"
          >
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                총 주문 건수
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {productData.orderCount} 건
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                상품후기
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {productData.reviewCount} 건
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                상품 만족도
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {/* @TODO 실제 데이터로 변경되어야 함 */}
                15%(x)
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                상품 등록일
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {productData.createdAt}
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                마지막 수정일
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {productData.updatedAt}
              </Text>
            </Flex>
          </Flex>
        </DetailDataTemplate.Box>
      </DetailDataTemplate>
      <Hr color="gray-dark-color" />
      <DetailDataTemplate>
        <DetailDataTemplate.Box title="상품 옵션">
          <DivTable size={[1, 1, 4, 1, 2, 1, 1, 1]}>
            <DivTable.Head>
              <DivTable.Head.Row>
                <DivTable.Head.Row.Cell>
                  <Text isBold={true} colorType="gray-color">
                    순서
                  </Text>
                </DivTable.Head.Row.Cell>
                <DivTable.Head.Row.Cell>
                  <Text isBold={true} colorType="gray-color">
                    옵션코드
                  </Text>
                </DivTable.Head.Row.Cell>
                <DivTable.Head.Row.Cell>
                  <Text isBold={true} colorType="gray-color">
                    옵션명
                  </Text>
                </DivTable.Head.Row.Cell>
                <DivTable.Head.Row.Cell textAlign="right">
                  <Text isBold={true} colorType="gray-color">
                    재고
                  </Text>
                </DivTable.Head.Row.Cell>
                <DivTable.Head.Row.Cell>
                  <Text isBold={true} colorType="gray-color">
                    옵션가격
                  </Text>
                </DivTable.Head.Row.Cell>
                <DivTable.Head.Row.Cell>
                  <Text isBold={true} colorType="gray-color">
                    순서변경
                  </Text>
                </DivTable.Head.Row.Cell>
                <DivTable.Head.Row.Cell>
                  <Text isBold={true} colorType="gray-color">
                    옵션삭제
                  </Text>
                </DivTable.Head.Row.Cell>
              </DivTable.Head.Row>
            </DivTable.Head>
            <SortableContainer onDragEnd={onSortEnd}>
              <SortableItems droppableId="table" isDropDisabled={!editMode}>
                {(provided) => (
                  <DivTable.Body
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {form.values.options?.map((option, idx) => (
                      <SortableItem
                        key={option.name}
                        index={idx}
                        id={option.name}
                      >
                        {(provided) => (
                          <DivTable.Body.Row
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <DivTable.Body.Row.Cell>
                              <Text colorType="gray-color" size="large">
                                {idx}.
                              </Text>
                            </DivTable.Body.Row.Cell>
                            <DivTable.Body.Row.Cell>
                              <Flex gap="xsmall">
                                <Text colorType="gray-color" size="small">
                                  {option.code}
                                </Text>
                                <ClipboardCopy text={option.code}>
                                  <Button text="복사" size="small" fit={true} />
                                </ClipboardCopy>
                              </Flex>
                            </DivTable.Body.Row.Cell>
                            <DivTable.Body.Row.Cell textAlign="left">
                              <TextInput
                                disabled={!editMode}
                                isContour={true}
                                sizeType="large"
                                widthType="auto"
                              >
                                <TextInput.Body>
                                  <TextInput.Body.Input
                                    name={`options.${idx}.name`}
                                    defaultValue={option.name}
                                    onBlur={form.handleChange}
                                  />
                                </TextInput.Body>
                              </TextInput>
                            </DivTable.Body.Row.Cell>
                            <DivTable.Body.Row.Cell textAlign="right">
                              <TextInput
                                disabled={!editMode}
                                isContour={true}
                                widthType="auto"
                                sizeType="large"
                              >
                                <TextInput.Body>
                                  <TextInput.Body.Input
                                    defaultValue={option.stock ?? ''}
                                    name={`options.${idx}.stock`}
                                    placeholder="재고입력"
                                    onChange={(event) => {
                                      if (event.target.value === '') {
                                        form.setFieldValue(
                                          `options.${idx}.stock`,
                                          null
                                        );
                                        return;
                                      }

                                      form.handleChange(event);
                                    }}
                                  />
                                  <Text
                                    colorType="gray-dark-color"
                                    isBold={true}
                                  >
                                    개
                                  </Text>
                                </TextInput.Body>
                              </TextInput>
                            </DivTable.Body.Row.Cell>
                            <DivTable.Body.Row.Cell>
                              <TextInput
                                disabled={!editMode}
                                isContour={true}
                                widthType="auto"
                                sizeType="large"
                              >
                                <TextInput.Body>
                                  <Text
                                    colorType="gray-dark-color"
                                    isBold={true}
                                  >
                                    ₩
                                  </Text>
                                  <TextInput.Body.Input
                                    value={
                                      form.values.options
                                        ? form.values.options[
                                            idx
                                          ].price.toLocaleString()
                                        : '0'
                                    }
                                    handleChangeValue={(value) => {
                                      form.setValues({
                                        ...form.values,
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        options: form.values.options!.map(
                                          (option, _idx) => {
                                            const _option = Object.assign(
                                              {},
                                              option
                                            );

                                            if (idx === _idx) {
                                              _option.price = Number(
                                                value.split(',').join('')
                                              );
                                            }

                                            return _option;
                                          }
                                        )
                                      });
                                    }}
                                  />
                                </TextInput.Body>
                              </TextInput>
                            </DivTable.Body.Row.Cell>
                            <DivTable.Body.Row.Cell>
                              <div
                                {...provided.dragHandleProps}
                                style={{ display: 'inline-block' }}
                              >
                                <OrderIcon colorType="text-gray-500" />
                              </div>
                            </DivTable.Body.Row.Cell>
                            <DivTable.Body.Row.Cell>
                              <TrashIcon colorType="text-gray-500" />
                            </DivTable.Body.Row.Cell>
                          </DivTable.Body.Row>
                        )}
                      </SortableItem>
                    ))}
                    {provided.placeholder}
                  </DivTable.Body>
                )}
              </SortableItems>
            </SortableContainer>
          </DivTable>
        </DetailDataTemplate.Box>
      </DetailDataTemplate>
    </Flex>
  );
};

function ModifyProductCategory({
  isOpen,
  handleClose,
  initCategoryWhole,
  onClickApplyCategory
}: {
  isOpen: boolean;
  handleClose: () => void;
  initCategoryWhole: FetchLastCategoryIdToWhole.ResponseType['productCategoryLevels'];
  onClickApplyCategory: (lastCategoryId: number) => void;
}) {
  const fetchRootCategory = FetchRootCategory.useFetchQuery();

  const [categoryWhole, setCategoryWhole] = useState(initCategoryWhole ?? []);
  const [categorys, setCategorys] = useState<
    FetchRootCategory.ResponseType['items'][number][][]
  >([]);
  const [reactQueriesIssuseCache, setReactQueriesIssuseCache] = useState('');

  const fetchCategoryByParentIds = FetchCategoryByParentId.useFetchQueries(
    categoryWhole
      .filter((categoryWhole) => !!categoryWhole.id)
      .map((categoryWhole) => ({
        parentId: categoryWhole.id as NonNullable<number>
      }))
  );

  useEffect(() => {
    if (
      fetchCategoryByParentIds.length &&
      fetchCategoryByParentIds.every(
        (fetchCategoryByParentId) => fetchCategoryByParentId.isSuccess
      )
    ) {
      const js = JSON.stringify([
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fetchRootCategory.data!.data.items.map((item) => item),
        ...fetchCategoryByParentIds.map((fetchCategoryByParentId) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchCategoryByParentId.data!.data.items.map((item) => item)
        )
      ]);

      if (reactQueriesIssuseCache === js) {
        return;
      }
      setReactQueriesIssuseCache(js);
      setCategorys([
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fetchRootCategory.data!.data.items.map((item) => item),
        ...fetchCategoryByParentIds.map((fetchCategoryByParentId) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchCategoryByParentId.data!.data.items.map((item) => item)
        )
      ]);
    }

    if (!categoryWhole.length) {
      const js = JSON.stringify([
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fetchRootCategory.data!.data.items.map((item) => item)
      ]);
      if (reactQueriesIssuseCache === js) {
        return;
      }
      setReactQueriesIssuseCache(js);
      setCategorys([
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fetchRootCategory.data!.data.items.map((item) => item)
      ]);
    }
  }, [
    reactQueriesIssuseCache,
    fetchCategoryByParentIds,
    fetchRootCategory.data,
    categoryWhole.length
  ]);

  function onChangeCategoryWhole(index: number, categoryId: string) {
    const findCategory = categorys[index].find(
      (cate) => cate.id === parseInt(categoryId)
    );

    if (findCategory) {
      setCategoryWhole(
        produce(categoryWhole, (draft) => {
          draft[index] = findCategory;
          draft.splice(index + 1);
          return draft;
        })
      );
    }
  }

  function onClickCategoryOkay() {
    if (categorys[categorys.length - 1].length) {
      alert('카테고리를 모두 선택 해주세요');
      return;
    }
    onClickApplyCategory(categoryWhole[categoryWhole.length - 1].id);
    handleClose();
  }

  return (
    <Modal
      sizeType="large"
      isOpen={isOpen}
      isHandleEscClose={true}
      handleClose={() => handleClose()}
    >
      <Modal.Header>카테고리 수정</Modal.Header>
      <Modal.Body>
        <Flex className="w-full" alignItems="center" justifyContent="center">
          {categorys.map((categoryItems, idx) =>
            categoryItems.length ? (
              <DropdownMenu
                key={idx}
                value={
                  categoryWhole[idx] ? `${categoryWhole[idx].id}` : undefined
                }
                setValue={(value) => onChangeCategoryWhole(idx, value)}
              >
                <DropdownMenu.Title>
                  <DropdownMenu.Title.Text placeholder="카테고리를 선택해주세요" />
                  <DropdownMenu.Title.UpDownIcon />
                </DropdownMenu.Title>
                <DropdownMenu.ItemWrapper>
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                    {categoryItems.map((category) => (
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                        value={`${category.id}`}
                        key={category.id}
                      >
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                          text={`${category.name}`}
                        />
                      </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                    ))}
                  </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                </DropdownMenu.ItemWrapper>
              </DropdownMenu>
            ) : undefined
          )}
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Flex direction="row" className="w-full" gap="xsmall">
          <Button
            text="취소"
            fullwidth={true}
            onClick={() => handleClose()}
            type="secondary-color"
          />
          <Button
            text="확인"
            fullwidth={true}
            onClick={() => onClickCategoryOkay()}
          />
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
