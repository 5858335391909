import styles from './index.module.scss';
import Navigation from '../../organisms/Navigation';
import { useRecoilValue } from 'recoil';
import { bgFadeoutState } from '../../../recoil/atoms/global-state';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import ErrorDisplay from '../../organisms/ErrorDisplay';
import Flex from '../../molecules/Flex';
import Button from '../../atoms/Button';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { ArrowRefreshIcon } from '@aimpact-korea/arrange-front-icons/build/icons';

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  // children,
  resetErrorBoundary
}) => {
  const queryClient = useQueryClient();

  Sentry.captureException(error);

  if (error.message === 'Network Error') {
    alert('인터넷 연결이 원할하지 않습니다.');
  }

  const onClickRefresh = (e: React.MouseEvent) => {
    e.stopPropagation();

    queryClient.removeQueries({
      type: 'inactive'
    });

    resetErrorBoundary();
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <ErrorDisplay text="연결이 실패됐어요..." iconType="weather-thunder">
        <Button
          text="새로고침"
          startIcon={<ArrowRefreshIcon colorType="text-white" />}
          fit={true}
          onClick={onClickRefresh}
        />
      </ErrorDisplay>
    </Flex>
  );
};

const Default: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const bgFadeout = useRecoilValue(bgFadeoutState);

  return (
    <div className={styles.root}>
      <div className={styles.grid}>
        <Navigation />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <section className={styles.content}>{children}</section>
        </ErrorBoundary>
      </div>
      {bgFadeout && <div id="background" className={styles.background}></div>}
    </div>
  );
};

export default Default;
