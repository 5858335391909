import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { FetchResellerRequest } from './fetch-reseller-request';

export namespace RejectResellerRequest {
  export type RequestType = {
    resellerRequestId: number;
    rejectReason: string;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { modify } = FetchResellerRequest.useModifyResellerRequest();

    const mutation = useMutation(
      ({ resellerRequestId, rejectReason }) => {
        return axios.patch(`/reseller/request/reject/${resellerRequestId}`, {
          rejectReason
        });
      },
      {
        onSuccess: (...rest) => {
          modify({
            id: rest[1].resellerRequestId,
            state: 'REJECT',
            rejectReason: rest[1].rejectReason
          });
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
