import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchAndroidMessageApp } from './fetch-android-message-app';

export namespace CreateAndroidMessageApp {
  export type RequestType = {
    packageName: string;
    title: string;
    subtitle: string;
    sms: string;
    mms: string;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { refetch } = FetchAndroidMessageApp.useRefetchData();

    const mutation = useMutation(
      ({ mms, packageName, sms, subtitle, title }) => {
        return axios.post(`/android/message-app`, {
          mms,
          packageName,
          sms,
          subtitle,
          title
        });
      },
      {
        onSuccess: (...rest) => {
          refetch();
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
