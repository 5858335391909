import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { arrangeAppApi } from '../../../utils/axios';

export namespace FetchNoticeList {
  export type ResponseType = {
    id: number;
    title: string;
    content: string;
    contentHtml: string;
    createdAt: number;
    updatedAt: number;
  }[];

  export const KEY_STRING = 'fetch-notice-list' as const;

  export type QueryKeyType = [typeof KEY_STRING];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData() {
    return await arrangeAppApi.get<ResponseType>('/notices');
  }

  // export const useModify = () => {
  //   const queryClient = useQueryClient();

  //   const modify = (
  //     orderSettle: Optional<ResponseType['items'][number]> & { id: number }
  //   ) => {
  //     queryClient.setQueriesData(
  //       [KEY_STRING],
  //       (previous: InfiniteData<AxiosResponse<ResponseType>> | undefined) => {
  //         const _previous = previous as InfiniteData<
  //           AxiosResponse<ResponseType>
  //         >;
  //         return produce(_previous, (draft) => {
  //           draft.pages = draft.pages.map((page) => {
  //             page.data.items = page.data.items.map((item) => {
  //               if (item.id === orderSettle.id) {
  //                 return {
  //                   ...item,
  //                   ...orderSettle
  //                 };
  //               }
  //               return item;
  //             });

  //             return page;
  //           });
  //         });
  //       }
  //     );
  //   };

  //   return { modify };
  // };

  export const useRefetchData = () => {
    const queryClient = useQueryClient();

    const refetch = () => {
      queryClient.refetchQueries([KEY_STRING]);
    };

    return { refetch };
  };

  export const useFetch = ({
    ...props
  }: UseQueryOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    AxiosResponse<ResponseType>,
    QueryKeyType
  > = {}) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING], fetchData, {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
