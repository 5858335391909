import styles from './index.module.scss';
import { Button } from '@material-ui/core';
import { SizeType } from '../../../types/style';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export interface Props {
  startIcon?: React.ReactNode;
  label: string;
  active: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  sizeType?: Extract<SizeType, 'medium' | 'small'>;
}

const ActiveButton: React.FC<Props> = ({
  startIcon: StartIcon,
  onClick,
  label,
  active,
  sizeType = 'medium',
  className
}) => {
  return (
    <Button
      onClick={(e) => onClick && onClick(e)}
      className={`${className && className} ${styles.btn} ${
        active && styles.active
      }`}
      startIcon={StartIcon}
      fullWidth
    >
      <span className={`${styles.content} ${cx(`size-type--${sizeType}`)}`}>
        {label}
      </span>
    </Button>
  );
};

export default ActiveButton;
