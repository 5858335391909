import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { SizeType } from '../../../types/style';
import { useResizeDetector } from 'react-resize-detector';

const cx = classNames.bind(styles);

export interface Props extends React.ComponentPropsWithoutRef<'input'> {
  type?: 'text' | 'email' | 'password' | 'number';
  name?: string;
  disabled?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  autoWidth?: boolean;
  fontSize?: SizeType;
  /**
   * @deprecated
   */
  handleChangeValue?: (value: string) => void;
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      type = 'text',
      name = '',
      handleChangeValue,
      autoWidth = false,
      fontSize = 'medium',
      className,
      ...props
    },
    ref
  ) => {
    const [originalValue, setOriginalValue] = useState<
      string | ReadonlyArray<string> | number
    >();

    const onHtmlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOriginalValue(event.target.value);
      handleChangeValue?.(event.target.value);
    };

    useEffect(() => {
      setOriginalValue(props.value ?? props.defaultValue);
    }, [props.defaultValue, props.value]);

    if (autoWidth) {
      return (
        <AutoResizeInput
          ref={ref}
          name={name}
          type={type}
          onChange={(e) => {
            props.onChange?.(e);
            onHtmlChange(e);
          }}
          originalValue={originalValue}
          className={`${cx('input', {
            active: !!originalValue
          })} font-size--${fontSize} ${className ?? ''}`}
          {...props}
        />
      );
    }

    return (
      <input
        ref={ref}
        name={name}
        type={type}
        onChange={(e) => {
          props.onChange?.(e);
          onHtmlChange(e);
        }}
        className={`${cx('input', {
          active: !!originalValue
        })} font-size--${fontSize} ${className ?? ''}`}
        {...props}
      />
    );
  }
);

Input.displayName = 'Input';

export default Input;

interface AutoResizeInputProps extends React.ComponentPropsWithoutRef<'input'> {
  originalValue?: string | ReadonlyArray<string> | number;
}

const AutoResizeInput = React.forwardRef<
  HTMLInputElement,
  AutoResizeInputProps
>(({ style, originalValue, ...props }, ref) => {
  const { width, ref: divRef } = useResizeDetector();

  return (
    <div>
      <input ref={ref} {...props} style={{ width, minWidth: '30px' }} />
      <div
        ref={divRef}
        style={{
          height: 0,
          position: 'absolute',
          visibility: 'hidden',
          width: 'max-content',
          display: 'inline-block',
          ...style
        }}
        {...props}
      >
        {originalValue || props.placeholder}
      </div>
    </div>
  );
});

AutoResizeInput.displayName = 'AutoResizeInput';
