import Item from './Item';
import styles from './list-vertical-item-group.module.scss';
import classNames from 'classnames/bind';
import React from 'react';

const cx = classNames.bind(styles);

export interface Props {
  isContour?: boolean;
  children: React.ReactNode;
}

type ListVerticalItemGroupComponent = React.FC<Props> & {
  Item: typeof Item;
};

const ListVerticalItemGroup: ListVerticalItemGroupComponent = ({
  isContour,
  children
}: Props) => {
  const Children = () => {
    return React.Children.map(children, (item) => {
      return (
        <React.Fragment>
          {item}
          <div className={cx('margin', isContour && 'line')}></div>
        </React.Fragment>
      );
    });
  };

  return <div className={cx('list-item-group')}>{Children()}</div>;
};

ListVerticalItemGroup.Item = Item;

export default ListVerticalItemGroup;
