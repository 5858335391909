import classNames from 'classnames/bind';
import React from 'react';
import { useContextApi } from '../context';
import Cell from './Cell';
import styles from './row.module.scss';

const cx = classNames.bind(styles);

export type Props = React.ComponentPropsWithoutRef<'div'>;

export interface RowComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
  > {
  Cell: typeof Cell;
}

// eslint-disable-next-line react/display-name
const Row = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    const { state } = useContextApi();

    return (
      <div ref={ref} className={cx('container')} {...props}>
        {/* {children} */}
        {React.Children.map(children, (ch, idx) => (
          <div className={`col-${state.size[idx]}`}>{ch}</div>
        ))}
      </div>
    );
  }
) as RowComponent;

Row.displayName = 'Row';
Row.Cell = Cell;

export default Row;
