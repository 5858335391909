import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchAndroidAppVersionList } from './fetch-android-app-version-list';

export namespace CreateAndroidAppVersion {
  export type RequestType = {
    androidAppType: 'ARRANGE' | 'ARRANGE_PLUS';
    version: string;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { refetch } = FetchAndroidAppVersionList.useRefetchData();

    const mutation = useMutation(
      ({ androidAppType, version }) => {
        return axios.post(`/android/versions`, {
          androidAppType,
          version
        });
      },
      {
        onSuccess: (...rest) => {
          refetch();
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
