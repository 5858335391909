import {
  InfiniteData,
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import produce from 'immer';
import axios from '../../../utils/axios';
import {
  LinkfarmProductsRes,
  LINK_FARM_FETCH_PRODUCT_KEY_STRING
} from '../linkfarm/product/fetch-linkfarm-product';
import { FetchPickuponSite } from './fetch-pickupon-site';

export namespace LinkProductPickuponSiteRound {
  export type RequestType = {
    pickuponSiteRoundId: number;
    barofarmProductId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const queryClient = useQueryClient();
    const pickuponSites = FetchPickuponSite.useFetchQuery();

    const mutation = useMutation(
      ({ barofarmProductId, pickuponSiteRoundId: pickuponSiteRoundId }) => {
        return axios.patch(
          `/pickupon-site/round/${pickuponSiteRoundId}/link-product/${barofarmProductId}`
        );
      },
      {
        onSuccess: (...rest) => {
          const barofarmProductId = rest[1].barofarmProductId;
          const pickuponSiteRoundId = rest[1].pickuponSiteRoundId;

          if (pickuponSites.data?.data.items.length) {
            const pickuponSiteIndex = pickuponSites.data?.data.items.findIndex(
              (item) =>
                item.pickupOnSiteRounds.some(
                  (pickupOnSiteRound) =>
                    pickupOnSiteRound.id === pickuponSiteRoundId
                )
            );

            if (pickuponSiteIndex === -1) {
              return;
            }

            const pickuponSiteRoundIndex = pickuponSites.data?.data.items[
              pickuponSiteIndex
            ].pickupOnSiteRounds.findIndex(
              (pickupOnSiteRound) =>
                pickupOnSiteRound.id === pickuponSiteRoundId
            );

            if (pickuponSiteRoundIndex === -1) {
              return;
            }

            queryClient.setQueriesData<
              InfiniteData<AxiosResponse<LinkfarmProductsRes>> | undefined
            >([LINK_FARM_FETCH_PRODUCT_KEY_STRING], (previous) => {
              if (previous) {
                return produce(previous, (draft) => {
                  draft = {
                    ...draft,
                    pages: draft.pages.map((page) => ({
                      ...page,
                      data: {
                        ...page.data,
                        linkfarmProducts: page.data.linkfarmProducts.map(
                          (lp) => {
                            if (lp.id === barofarmProductId) {
                              return {
                                ...lp,
                                pickuponSiteRound: {
                                  id: pickuponSites.data.data.items[
                                    pickuponSiteIndex
                                  ].pickupOnSiteRounds[pickuponSiteRoundIndex]
                                    .id,
                                  pickupEndAt:
                                    pickuponSites.data.data.items[
                                      pickuponSiteIndex
                                    ].pickupOnSiteRounds[pickuponSiteRoundIndex]
                                      .pickupEndAt,
                                  pickupStartAt:
                                    pickuponSites.data.data.items[
                                      pickuponSiteIndex
                                    ].pickupOnSiteRounds[pickuponSiteRoundIndex]
                                      .pickupStartAt,
                                  pickuponSite:
                                    pickuponSites.data.data.items[
                                      pickuponSiteIndex
                                    ],
                                  purchaseEndDate:
                                    pickuponSites.data.data.items[
                                      pickuponSiteIndex
                                    ].pickupOnSiteRounds[pickuponSiteRoundIndex]
                                      .purchaseEndDate,
                                  purchaseStartDate:
                                    pickuponSites.data.data.items[
                                      pickuponSiteIndex
                                    ].pickupOnSiteRounds[pickuponSiteRoundIndex]
                                      .purchaseStartDate
                                }
                              };
                            }

                            return lp;
                          }
                        )
                      }
                    }))
                  };
                  return draft;
                });
              }

              return previous;
            });
          }

          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
