import { Span } from '@aimpact-korea/arrange-front-atomic';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import { BANK_NAME } from '@aimpact-korea/arrange-front-types';
import React, { Suspense, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import Button from '../../components/atoms/Button';
import Flex from '../../components/molecules/Flex';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Modal from '../../components/molecules/Modal';
import Table from '../../components/molecules/Table';
import TextInput from '../../components/molecules/TextInput';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import {
  FetchResellerOrderSettles,
  SuccessResellerOrderSettle
} from '../../hooks/http/reseller-order-settle';

function ResellerOrderSettlePage() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'리셀러 주문정산 현황'}></Header>
        <ResellerOrderSettleDataWrapper />
      </Suspense>
    </DefaultTemplate>
  );
}

export default ResellerOrderSettlePage;

function ResellerOrderSettleDataWrapper() {
  const orderSettles = FetchResellerOrderSettles.useFetch({
    cursor: {
      orderBy: 'DESC',
      standardColumn: 'settleDate'
    }
  });

  return (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={orderSettles
        .data!.pages.map((page) => page.data.items.length)
        .reduce((sum, current) => sum + current, 0)}
      next={orderSettles.fetchNextPage}
      hasMore={orderSettles.hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              No.
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              이름
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              휴대폰 번호
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              원금
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              정산금 (부가세 포함)
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              수수료 (부가세 제외)
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              정산계좌 정보
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              정산일
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              주문보기
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              실제 정산여부
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {orderSettles.data?.pages.map((page) =>
            page.data.items.map((item) => (
              <OrderSettleData key={item.id} item={item}></OrderSettleData>
            ))
          )}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
}

function OrderSettleData({
  item
}: {
  item: FetchResellerOrderSettles.ResponseType['items'][number];
}) {
  const [showSuccessOrderSettleModal, setShowSuccessOrderSettleModal] =
    useState(false);
  const [orderSettleInput, setOrderSettleInput] = useState('');
  const navigate = useNavigate();

  const successOrderSettle = SuccessResellerOrderSettle.useMutate({
    onSuccess: () => {
      alert('정산체크가 완료 되었습니다.');
    }
  });

  function onClickSuccessOrderSettle() {
    if (orderSettleInput !== '정산체크') {
      return;
    }

    successOrderSettle.mutate({
      orderSettleId: item.id
    });
    handleShowSuccessOrderSettleModal(false);
  }

  function handleShowSuccessOrderSettleModal(status: boolean) {
    setOrderSettleInput('');
    setShowSuccessOrderSettleModal(status);
  }

  function onClickResellerOrder(resellerId: number, orderSettleAt: string) {
    navigate(
      `/transaction?resellerId=${resellerId}&resellerOrderSettleAt=${orderSettleAt}`
    );
  }

  return (
    <React.Fragment>
      {showSuccessOrderSettleModal && (
        <Modal
          isOpen={showSuccessOrderSettleModal}
          sizeType="small"
          isHandleEscClose={true}
        >
          <Modal.Header>
            <Modal.Header.Title text="정산체크" />
          </Modal.Header>
          <Modal.Body>
            <Flex direction="column" gap="medium">
              <Span>
                정산체크를 하려면{' '}
                <Span fontWeightType="font-bold">&apos;정산체크&apos;</Span>를
                입력해주세요.
              </Span>
              <TextInput isContour={true}>
                <TextInput.Body>
                  <TextInput.Body.Input
                    handleChangeValue={(str) => setOrderSettleInput(str)}
                    placeholder="입력해주세요."
                  ></TextInput.Body.Input>
                </TextInput.Body>
              </TextInput>
            </Flex>
          </Modal.Body>
          <Modal.Footer>
            <Flex direction="row" style={{ width: '100%' }} gap="small">
              <Button
                text="취소"
                fullwidth={true}
                type="secondary-color"
                onClick={() => handleShowSuccessOrderSettleModal(false)}
              />
              <Button
                text="확인"
                fullwidth={true}
                onClick={() => onClickSuccessOrderSettle()}
                disabled={orderSettleInput !== '정산체크'}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
      <Table.Row>
        <Table.Row.Cell alignType="center">{item.id}</Table.Row.Cell>
        <Table.Row.Cell alignType="center">{item.resellerName}</Table.Row.Cell>
        <Table.Row.Cell alignType="center">{item.resellerPhone}</Table.Row.Cell>
        <Table.Row.Cell alignType="center">
          {item.originalTotalAmount.toLocaleString()}원
        </Table.Row.Cell>
        <Table.Row.Cell alignType="center">
          {item.totalAmountVat.toLocaleString()}원
        </Table.Row.Cell>
        <Table.Row.Cell alignType="center">{item.commission}%</Table.Row.Cell>
        <Table.Row.Cell alignType="center">
          <Flex direction="column">
            <Span>은행: {BANK_NAME[item.bankName]}</Span>
            <Span>계좌번호: {item.bankAccount}</Span>
            <Span>예금주명: {item.bankHolder}</Span>
          </Flex>
        </Table.Row.Cell>
        <Table.Row.Cell alignType="center">{item.settleDate}</Table.Row.Cell>
        <Table.Row.Cell alignType="center">
          <Button
            text="주문보기"
            onClick={() =>
              onClickResellerOrder(item.resellerId, item.settleDate)
            }
          />
        </Table.Row.Cell>
        <Table.Row.Cell alignType="center">
          {item.isSettle ? (
            <Span
              fontColorType={StyleBuilderInstane.toFontColorType(
                'text-primary-500'
              )}
              fontWeightType="font-bold"
            >
              정산완료
            </Span>
          ) : (
            <Button
              text="정산체크"
              onClick={() => handleShowSuccessOrderSettleModal(true)}
            />
          )}
        </Table.Row.Cell>
      </Table.Row>
    </React.Fragment>
  );
}
