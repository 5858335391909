import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { arrangeAppApi } from '../../../utils/axios';

export namespace FetchProductCategoryKindByCateId {
  export type ResponseType = {
    items: {
      id: number;
      isEtc: boolean;
      name: string;
      order: number;
      productCategoryId: number;
    }[];
  };

  export type RequestType = {
    productCategoryId: number;
  };

  export const KEY_STRING = 'fetch-product-category-kind-by-cate-id' as const;

  type TQueryKey = [typeof KEY_STRING, RequestType];
  type TQueryKey2 = TQueryKey & QueryKey;

  export async function fetchData({
    queryKey
  }: QueryFunctionContext<TQueryKey2>) {
    return await arrangeAppApi.get<ResponseType>(
      `/product-category-kind/by-category-id/${queryKey[1].productCategoryId}`
    );
  }

  export const useFetchQuery = (
    { productCategoryId }: RequestType,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      TQueryKey
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery(
      [
        KEY_STRING,
        {
          productCategoryId
        }
      ],
      fetchData,
      {
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        enabled: !!productCategoryId,
        ...props
      }
    );

    return query;
  };
}
