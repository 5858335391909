import {
  ArrowHorizontalIcon,
  CardBoardIcon,
  FruitHalaIcon,
  HatFarmerIcon,
  MailIcon,
  MainIcon,
  ParcelIcon,
  CardCreditIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import { selector } from 'recoil';
import { Menu } from '../../types/menu-type';
import { menuType } from '../atoms';

export const menuList: Menu[] = [
  {
    type: 'dash-board',
    name: '대시보드',
    link: '/',
    iconComponent: MainIcon
  },
  {
    type: 'account-list',
    name: '유저 리스트',
    link: '/accounts',
    iconComponent: HatFarmerIcon
  },
  {
    type: 'store',
    name: '업체목록',
    link: '/store',
    iconComponent: HatFarmerIcon,
    childrenMenus: [
      {
        type: 'store-block',
        name: '차단업체목록',
        link: '/store-block',
        parentMenu: 'store'
      }
    ]
  },
  {
    type: 'product',
    name: '업체상품',
    link: '/product',
    iconComponent: FruitHalaIcon,
    childrenMenus: [
      {
        type: 'product-interest',
        name: '관심상품목록',
        link: '/product-interest',
        parentMenu: 'product'
      }
    ]
  },
  {
    type: 'linkfarm-product',
    name: '쇼핑몰 상품',
    link: '/linkfarm/product',
    iconComponent: FruitHalaIcon,
    childrenMenus: [
      {
        type: 'linkfarm-product-inspections',
        name: '검수 상품',
        link: '/linkfarm/product/inspection',
        parentMenu: 'linkfarm-product'
      },
      {
        type: 'linkfarm-product-resll',
        name: '리셀러 상품',
        link: '/linkfarm/product/resell',
        parentMenu: 'linkfarm-product'
      }
    ]
  },
  {
    type: 'reseller-list',
    name: '리셀러 목록',
    link: '/reseller',
    iconComponent: FruitHalaIcon,
    childrenMenus: [
      {
        type: 'reseller-reqeust',
        name: '리셀러 신청',
        link: '/reseller/request',
        parentMenu: 'reseller-list'
      }
    ]
  },
  {
    type: 'transaction-history',
    name: '거래내역',
    link: '/transaction',
    iconComponent: ArrowHorizontalIcon
  },
  {
    type: 'delivery-form',
    name: '택배양식',
    link: '/delivery-form',
    iconComponent: ParcelIcon
  },
  {
    type: 'message-analysis',
    name: '메세지 분석',
    link: '/message-analysis',
    iconComponent: MailIcon,
    childrenMenus: [
      {
        type: 'stop-word-address',
        name: '주소분석 불용어',
        link: '/stop-word/address',
        parentMenu: 'message-analysis'
      },
      {
        type: 'stop-word-name-pre',
        name: '주문자 예측 불용어',
        link: '/stop-word/name-pre',
        parentMenu: 'message-analysis'
      }
    ]
  },
  {
    type: 'vision-ocr-logs',
    name: 'Ocr 분석',
    link: '/vision-ocr-logs',
    iconComponent: CardBoardIcon
  },
  {
    type: 'order-settle',
    name: '주문 정산현황',
    link: '/order-settle',
    iconComponent: CardCreditIcon,
    childrenMenus: [
      {
        type: 'reseller-order-settle',
        name: '리셀러 주문 정산현황',
        link: '/reseller-order-settle',
        parentMenu: 'order-settle'
      }
    ]
  },
  {
    type: 'contract-terms',
    name: '서비스 약관',
    link: '/contract-terms',
    iconComponent: CardCreditIcon,
    childrenMenus: [
      {
        type: 'contract-terms-arrange-agreement',
        name: '어레인지 이용약관',
        link: '/contract-terms/arrange-agreement',
        parentMenu: 'contract-terms'
      },
      {
        type: 'contract-terms-arrange-privacy',
        name: '어레인지 개인정보처리방침',
        link: '/contract-terms/arrange-privacy',
        parentMenu: 'contract-terms'
      },
      {
        type: 'contract-terms-linkfarm-agreement',
        name: '링크팜 이용약관',
        link: '/contract-terms/linkfarm-agreement',
        parentMenu: 'contract-terms'
      },
      {
        type: 'contract-terms-linkfarm-privacy',
        name: '링크팜 개인정보처리방침',
        link: '/contract-terms/linkfarm-privacy',
        parentMenu: 'contract-terms'
      },
      {
        type: 'contract-terms-arrange-call-privacy',
        name: '어레인지 콜 개인정보처리방침',
        link: '/contract-terms/arrange-call-privacy',
        parentMenu: 'contract-terms'
      }
    ]
  },
  {
    type: 'arrange-manual-video',
    name: '어레인지 사용법 동영상',
    link: '/arrange-manual-video',
    iconComponent: CardCreditIcon
  },
  {
    type: 'arrange-notice',
    name: '어레인지 공지사항',
    link: '/notice/arrange',
    iconComponent: CardCreditIcon
  },
  {
    type: 'android-message-app',
    name: '안드로이드 메시지 앱',
    link: '/android-message-app',
    iconComponent: CardCreditIcon
  },
  {
    type: 'arrange-product-payment',
    name: '안드로이드 상품 구매내역',
    link: '/arrange-product-payment',
    iconComponent: CardCreditIcon
  },
  {
    type: 'pickon-site',
    name: '현장수령 지점',
    link: '/pickon-site',
    iconComponent: CardCreditIcon
  },
  {
    type: 'android-app-version',
    name: '안드로이드 앱 버전',
    link: '/android-app-version',
    iconComponent: CardCreditIcon
  },
  {
    type: 'coupon-preset',
    name: '쿠폰 프리셋',
    link: '/coupon-preset',
    iconComponent: CardCreditIcon
  }
];

export const menuTypeInfo = selector({
  key: 'menuTypeInfo',
  get: ({ get }) => {
    const type = get(menuType);

    // const menuInfo = menuList.find((menu) => {
    //   if (menu.type === type) {
    //     return menu;
    //   }

    //   if (menu.childrenMenus && menu.childrenMenus.length) {
    //     return menu.childrenMenus.some((menu) => menu.type === type);
    //   }

    //   return null;
    // });

    let menuInfo = null;

    for (const menu of menuList) {
      if (menu.type === type) {
        menuInfo = menu;
        break;
      }

      if (menu.childrenMenus && menu.childrenMenus.length) {
        for (const childrenMenu of menu.childrenMenus) {
          if (childrenMenu.type === type) {
            menuInfo = childrenMenu;
            break;
          }
        }
      }
    }

    return menuInfo;
  }
});
