import Skeleton from '../Skeleton';

interface Props {
  width?: string;
  height?: string;
}

export const FourBox: React.FC<Props> = ({ width, height }) => {
  return (
    <Skeleton viewBox="0 0 1560 303" width={width} height={height}>
      <path
        d="M114 108C114 103.582 117.582 100 122 100H184C188.418 100 192 103.582 192 108V271C192 275.418 188.418 279 184 279H122C117.582 279 114 275.418 114 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M798 108C798 103.582 801.582 100 806 100H868C872.418 100 876 103.582 876 108V271C876 275.418 872.418 279 868 279H806C801.582 279 798 275.418 798 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M228 108C228 103.582 231.582 100 236 100H298C302.418 100 306 103.582 306 108V271C306 275.418 302.418 279 298 279H236C231.582 279 228 275.418 228 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M912 108C912 103.582 915.582 100 920 100H982C986.418 100 990 103.582 990 108V271C990 275.418 986.418 279 982 279H920C915.582 279 912 275.418 912 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M342 108C342 103.582 345.582 100 350 100H412C416.418 100 420 103.582 420 108V271C420 275.418 416.418 279 412 279H350C345.582 279 342 275.418 342 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M1026 108C1026 103.582 1029.58 100 1034 100H1096C1100.42 100 1104 103.582 1104 108V271C1104 275.418 1100.42 279 1096 279H1034C1029.58 279 1026 275.418 1026 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M456 108C456 103.582 459.582 100 464 100H526C530.418 100 534 103.582 534 108V271C534 275.418 530.418 279 526 279H464C459.582 279 456 275.418 456 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M1140 108C1140 103.582 1143.58 100 1148 100H1210C1214.42 100 1218 103.582 1218 108V271C1218 275.418 1214.42 279 1210 279H1148C1143.58 279 1140 275.418 1140 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M570 108C570 103.582 573.582 100 578 100H640C644.418 100 648 103.582 648 108V271C648 275.418 644.418 279 640 279H578C573.582 279 570 275.418 570 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M1254 108C1254 103.582 1257.58 100 1262 100H1324C1328.42 100 1332 103.582 1332 108V271C1332 275.418 1328.42 279 1324 279H1262C1257.58 279 1254 275.418 1254 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M684 108C684 103.582 687.582 100 692 100H754C758.418 100 762 103.582 762 108V271C762 275.418 758.418 279 754 279H692C687.582 279 684 275.418 684 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M1368 108C1368 103.582 1371.58 100 1376 100H1438C1442.42 100 1446 103.582 1446 108V271C1446 275.418 1442.42 279 1438 279H1376C1371.58 279 1368 275.418 1368 271V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 100C0 95.5817 3.58172 92 8 92H38C42.4183 92 46 95.5817 46 100C46 104.418 42.4183 108 38 108H8C3.58172 108 0 104.418 0 100Z"
        fill="#C4C4C4"
      />
      <path
        d="M1514 100C1514 95.5817 1517.58 92 1522 92H1552C1556.42 92 1560 95.5817 1560 100C1560 104.418 1556.42 108 1552 108H1522C1517.58 108 1514 104.418 1514 100Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 136C0 131.582 3.58172 128 8 128H38C42.4183 128 46 131.582 46 136C46 140.418 42.4183 144 38 144H8C3.58172 144 0 140.418 0 136Z"
        fill="#C4C4C4"
      />
      <path
        d="M1514 136C1514 131.582 1517.58 128 1522 128H1552C1556.42 128 1560 131.582 1560 136C1560 140.418 1556.42 144 1552 144H1522C1517.58 144 1514 140.418 1514 136Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 172C0 167.582 3.58172 164 8 164H38C42.4183 164 46 167.582 46 172C46 176.418 42.4183 180 38 180H8C3.58172 180 0 176.418 0 172Z"
        fill="#C4C4C4"
      />
      <path
        d="M1514 172C1514 167.582 1517.58 164 1522 164H1552C1556.42 164 1560 167.582 1560 172C1560 176.418 1556.42 180 1552 180H1522C1517.58 180 1514 176.418 1514 172Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 208C0 203.582 3.58172 200 8 200H38C42.4183 200 46 203.582 46 208C46 212.418 42.4183 216 38 216H8C3.58172 216 0 212.418 0 208Z"
        fill="#C4C4C4"
      />
      <path
        d="M1514 208C1514 203.582 1517.58 200 1522 200H1552C1556.42 200 1560 203.582 1560 208C1560 212.418 1556.42 216 1552 216H1522C1517.58 216 1514 212.418 1514 208Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 244C0 239.582 3.58172 236 8 236H38C42.4183 236 46 239.582 46 244C46 248.418 42.4183 252 38 252H8C3.58172 252 0 248.418 0 244Z"
        fill="#C4C4C4"
      />
      <path
        d="M1514 244C1514 239.582 1517.58 236 1522 236H1552C1556.42 236 1560 239.582 1560 244C1560 248.418 1556.42 252 1552 252H1522C1517.58 252 1514 248.418 1514 244Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 295C0 290.582 3.58172 287 8 287H38C42.4183 287 46 290.582 46 295C46 299.418 42.4183 303 38 303H8C3.58172 303 0 299.418 0 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M140 295C140 290.582 143.582 287 148 287H158C162.418 287 166 290.582 166 295C166 299.418 162.418 303 158 303H148C143.582 303 140 299.418 140 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M254 295C254 290.582 257.582 287 262 287H272C276.418 287 280 290.582 280 295C280 299.418 276.418 303 272 303H262C257.582 303 254 299.418 254 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M368 295C368 290.582 371.582 287 376 287H386C390.418 287 394 290.582 394 295C394 299.418 390.418 303 386 303H376C371.582 303 368 299.418 368 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M482 295C482 290.582 485.582 287 490 287H500C504.418 287 508 290.582 508 295C508 299.418 504.418 303 500 303H490C485.582 303 482 299.418 482 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M595 295C595 290.582 598.582 287 603 287H613C617.418 287 621 290.582 621 295C621 299.418 617.418 303 613 303H603C598.582 303 595 299.418 595 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M710 295C710 290.582 713.582 287 718 287H728C732.418 287 736 290.582 736 295C736 299.418 732.418 303 728 303H718C713.582 303 710 299.418 710 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M824 295C824 290.582 827.582 287 832 287H842C846.418 287 850 290.582 850 295C850 299.418 846.418 303 842 303H832C827.582 303 824 299.418 824 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M938 295C938 290.582 941.582 287 946 287H956C960.418 287 964 290.582 964 295C964 299.418 960.418 303 956 303H946C941.582 303 938 299.418 938 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M1514 295C1514 290.582 1517.58 287 1522 287H1552C1556.42 287 1560 290.582 1560 295C1560 299.418 1556.42 303 1552 303H1522C1517.58 303 1514 299.418 1514 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M1052 295C1052 290.582 1055.58 287 1060 287H1070C1074.42 287 1078 290.582 1078 295C1078 299.418 1074.42 303 1070 303H1060C1055.58 303 1052 299.418 1052 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M1166 295C1166 290.582 1169.58 287 1174 287H1184C1188.42 287 1192 290.582 1192 295C1192 299.418 1188.42 303 1184 303H1174C1169.58 303 1166 299.418 1166 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M1280 295C1280 290.582 1283.58 287 1288 287H1298C1302.42 287 1306 290.582 1306 295C1306 299.418 1302.42 303 1298 303H1288C1283.58 303 1280 299.418 1280 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M1394 295C1394 290.582 1397.58 287 1402 287H1412C1416.42 287 1420 290.582 1420 295C1420 299.418 1416.42 303 1412 303H1402C1397.58 303 1394 299.418 1394 295Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H223C227.418 0 231 3.58172 231 8V23C231 27.4183 227.418 31 223 31H8C3.58172 31 0 27.4183 0 23V8Z"
        fill="#C4C4C4"
      />
    </Skeleton>
  );
};

export const OneBox: React.FC = () => {
  return (
    <Skeleton viewBox="0 0 326 548">
      <path
        d="M223 116C223 111.582 219.418 108 215 108H116C111.582 108 108 111.582 108 116V141C108 145.418 111.582 149 116 149H215C219.418 149 223 145.418 223 141V116Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H223C227.418 0 231 3.58172 231 8V23C231 27.4183 227.418 31 223 31H8C3.58172 31 0 27.4183 0 23V8Z"
        fill="#C4C4C4"
      />
      <path
        d="M139 238C139 233.582 135.418 230 131 230H32C27.5817 230 24 233.582 24 238V273C24 277.418 27.5817 281 32 281H131C135.418 281 139 277.418 139 273V238Z"
        fill="#C4C4C4"
      />
      <path
        d="M139 372C139 367.582 135.418 364 131 364H32C27.5817 364 24 367.582 24 372V407C24 411.418 27.5817 415 32 415H131C135.418 415 139 411.418 139 407V372Z"
        fill="#C4C4C4"
      />
      <path
        d="M139 505C139 500.582 135.418 497 131 497H32C27.5817 497 24 500.582 24 505V540C24 544.418 27.5817 548 32 548H131C135.418 548 139 544.418 139 540V505Z"
        fill="#C4C4C4"
      />
      <path
        d="M326 238C326 233.582 322.418 230 318 230H219C214.582 230 211 233.582 211 238V273C211 277.418 214.582 281 219 281H318C322.418 281 326 277.418 326 273V238Z"
        fill="#C4C4C4"
      />
      <path
        d="M326 372C326 367.582 322.418 364 318 364H219C214.582 364 211 367.582 211 372V407C211 411.418 214.582 415 219 415H318C322.418 415 326 411.418 326 407V372Z"
        fill="#C4C4C4"
      />
      <path
        d="M326 505C326 500.582 322.418 497 318 497H219C214.582 497 211 500.582 211 505V540C211 544.418 214.582 548 219 548H318C322.418 548 326 544.418 326 540V505Z"
        fill="#C4C4C4"
      />
    </Skeleton>
  );
};

export const TwoBox: React.FC = () => {
  return (
    <Skeleton viewBox="0 0 744 505">
      <path
        d="M39 87C39 82.5817 42.5817 79 47 79H76C80.4183 79 84 82.5817 84 87V92C84 96.4183 80.4183 100 76 100H47C42.5817 100 39 96.4183 39 92V87Z"
        fill="#C4C4C4"
      />
      <path
        d="M39 132C39 127.582 42.5817 124 47 124H76C80.4183 124 84 127.582 84 132V137C84 141.418 80.4183 145 76 145H47C42.5817 145 39 141.418 39 137V132Z"
        fill="#C4C4C4"
      />
      <path
        d="M39 177C39 172.582 42.5817 169 47 169H76C80.4183 169 84 172.582 84 177V182C84 186.418 80.4183 190 76 190H47C42.5817 190 39 186.418 39 182V177Z"
        fill="#C4C4C4"
      />
      <path
        d="M39 222C39 217.582 42.5817 214 47 214H76C80.4183 214 84 217.582 84 222V227C84 231.418 80.4183 235 76 235H47C42.5817 235 39 231.418 39 227V222Z"
        fill="#C4C4C4"
      />
      <path
        d="M39 267C39 262.582 42.5817 259 47 259H76C80.4183 259 84 262.582 84 267V272C84 276.418 80.4183 280 76 280H47C42.5817 280 39 276.418 39 272V267Z"
        fill="#C4C4C4"
      />
      <path
        d="M39 312C39 307.582 42.5817 304 47 304H76C80.4183 304 84 307.582 84 312V317C84 321.418 80.4183 325 76 325H47C42.5817 325 39 321.418 39 317V312Z"
        fill="#C4C4C4"
      />
      <path
        d="M39 357C39 352.582 42.5817 349 47 349H76C80.4183 349 84 352.582 84 357V362C84 366.418 80.4183 370 76 370H47C42.5817 370 39 366.418 39 362V357Z"
        fill="#C4C4C4"
      />
      <path
        d="M39 402C39 397.582 42.5817 394 47 394H76C80.4183 394 84 397.582 84 402V407C84 411.418 80.4183 415 76 415H47C42.5817 415 39 411.418 39 407V402Z"
        fill="#C4C4C4"
      />
      <path
        d="M39 447C39 442.582 42.5817 439 47 439H76C80.4183 439 84 442.582 84 447V452C84 456.418 80.4183 460 76 460H47C42.5817 460 39 456.418 39 452V447Z"
        fill="#C4C4C4"
      />
      <path
        d="M39 492C39 487.582 42.5817 484 47 484H76C80.4183 484 84 487.582 84 492V497C84 501.418 80.4183 505 76 505H47C42.5817 505 39 501.418 39 497V492Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 87C427 82.5817 430.582 79 435 79H464C468.418 79 472 82.5817 472 87V92C472 96.4183 468.418 100 464 100H435C430.582 100 427 96.4183 427 92V87Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 132C427 127.582 430.582 124 435 124H464C468.418 124 472 127.582 472 132V137C472 141.418 468.418 145 464 145H435C430.582 145 427 141.418 427 137V132Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 177C427 172.582 430.582 169 435 169H464C468.418 169 472 172.582 472 177V182C472 186.418 468.418 190 464 190H435C430.582 190 427 186.418 427 182V177Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 222C427 217.582 430.582 214 435 214H464C468.418 214 472 217.582 472 222V227C472 231.418 468.418 235 464 235H435C430.582 235 427 231.418 427 227V222Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 267C427 262.582 430.582 259 435 259H464C468.418 259 472 262.582 472 267V272C472 276.418 468.418 280 464 280H435C430.582 280 427 276.418 427 272V267Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 312C427 307.582 430.582 304 435 304H464C468.418 304 472 307.582 472 312V317C472 321.418 468.418 325 464 325H435C430.582 325 427 321.418 427 317V312Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 357C427 352.582 430.582 349 435 349H464C468.418 349 472 352.582 472 357V362C472 366.418 468.418 370 464 370H435C430.582 370 427 366.418 427 362V357Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 402C427 397.582 430.582 394 435 394H464C468.418 394 472 397.582 472 402V407C472 411.418 468.418 415 464 415H435C430.582 415 427 411.418 427 407V402Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 447C427 442.582 430.582 439 435 439H464C468.418 439 472 442.582 472 447V452C472 456.418 468.418 460 464 460H435C430.582 460 427 456.418 427 452V447Z"
        fill="#C4C4C4"
      />
      <path
        d="M427 492C427 487.582 430.582 484 435 484H464C468.418 484 472 487.582 472 492V497C472 501.418 468.418 505 464 505H435C430.582 505 427 501.418 427 497V492Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 87C0 82.5817 3.58172 79 8 79H13C17.4183 79 21 82.5817 21 87V92C21 96.4183 17.4183 100 13 100H8C3.58172 100 0 96.4183 0 92V87Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 132C0 127.582 3.58172 124 8 124H13C17.4183 124 21 127.582 21 132V137C21 141.418 17.4183 145 13 145H8C3.58172 145 0 141.418 0 137V132Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 177C0 172.582 3.58172 169 8 169H13C17.4183 169 21 172.582 21 177V182C21 186.418 17.4183 190 13 190H8C3.58172 190 0 186.418 0 182V177Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 222C0 217.582 3.58172 214 8 214H13C17.4183 214 21 217.582 21 222V227C21 231.418 17.4183 235 13 235H8C3.58172 235 0 231.418 0 227V222Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 267C0 262.582 3.58172 259 8 259H13C17.4183 259 21 262.582 21 267V272C21 276.418 17.4183 280 13 280H8C3.58172 280 0 276.418 0 272V267Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 312C0 307.582 3.58172 304 8 304H13C17.4183 304 21 307.582 21 312V317C21 321.418 17.4183 325 13 325H8C3.58172 325 0 321.418 0 317V312Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 357C0 352.582 3.58172 349 8 349H13C17.4183 349 21 352.582 21 357V362C21 366.418 17.4183 370 13 370H8C3.58172 370 0 366.418 0 362V357Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 402C0 397.582 3.58172 394 8 394H13C17.4183 394 21 397.582 21 402V407C21 411.418 17.4183 415 13 415H8C3.58172 415 0 411.418 0 407V402Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 447C0 442.582 3.58172 439 8 439H13C17.4183 439 21 442.582 21 447V452C21 456.418 17.4183 460 13 460H8C3.58172 460 0 456.418 0 452V447Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 492C0 487.582 3.58172 484 8 484H13C17.4183 484 21 487.582 21 492V497C21 501.418 17.4183 505 13 505H8C3.58172 505 0 501.418 0 497V492Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 87C388 82.5817 391.582 79 396 79H401C405.418 79 409 82.5817 409 87V92C409 96.4183 405.418 100 401 100H396C391.582 100 388 96.4183 388 92V87Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 132C388 127.582 391.582 124 396 124H401C405.418 124 409 127.582 409 132V137C409 141.418 405.418 145 401 145H396C391.582 145 388 141.418 388 137V132Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 177C388 172.582 391.582 169 396 169H401C405.418 169 409 172.582 409 177V182C409 186.418 405.418 190 401 190H396C391.582 190 388 186.418 388 182V177Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 222C388 217.582 391.582 214 396 214H401C405.418 214 409 217.582 409 222V227C409 231.418 405.418 235 401 235H396C391.582 235 388 231.418 388 227V222Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 267C388 262.582 391.582 259 396 259H401C405.418 259 409 262.582 409 267V272C409 276.418 405.418 280 401 280H396C391.582 280 388 276.418 388 272V267Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 312C388 307.582 391.582 304 396 304H401C405.418 304 409 307.582 409 312V317C409 321.418 405.418 325 401 325H396C391.582 325 388 321.418 388 317V312Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 357C388 352.582 391.582 349 396 349H401C405.418 349 409 352.582 409 357V362C409 366.418 405.418 370 401 370H396C391.582 370 388 366.418 388 362V357Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 402C388 397.582 391.582 394 396 394H401C405.418 394 409 397.582 409 402V407C409 411.418 405.418 415 401 415H396C391.582 415 388 411.418 388 407V402Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 447C388 442.582 391.582 439 396 439H401C405.418 439 409 442.582 409 447V452C409 456.418 405.418 460 401 460H396C391.582 460 388 456.418 388 452V447Z"
        fill="#C4C4C4"
      />
      <path
        d="M388 492C388 487.582 391.582 484 396 484H401C405.418 484 409 487.582 409 492V497C409 501.418 405.418 505 401 505H396C391.582 505 388 501.418 388 497V492Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 87C281 82.5817 284.582 79 289 79H348C352.418 79 356 82.5817 356 87V92C356 96.4183 352.418 100 348 100H289C284.582 100 281 96.4183 281 92V87Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 132C281 127.582 284.582 124 289 124H348C352.418 124 356 127.582 356 132V137C356 141.418 352.418 145 348 145H289C284.582 145 281 141.418 281 137V132Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 177C281 172.582 284.582 169 289 169H348C352.418 169 356 172.582 356 177V182C356 186.418 352.418 190 348 190H289C284.582 190 281 186.418 281 182V177Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 222C281 217.582 284.582 214 289 214H348C352.418 214 356 217.582 356 222V227C356 231.418 352.418 235 348 235H289C284.582 235 281 231.418 281 227V222Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 267C281 262.582 284.582 259 289 259H348C352.418 259 356 262.582 356 267V272C356 276.418 352.418 280 348 280H289C284.582 280 281 276.418 281 272V267Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 312C281 307.582 284.582 304 289 304H348C352.418 304 356 307.582 356 312V317C356 321.418 352.418 325 348 325H289C284.582 325 281 321.418 281 317V312Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 357C281 352.582 284.582 349 289 349H348C352.418 349 356 352.582 356 357V362C356 366.418 352.418 370 348 370H289C284.582 370 281 366.418 281 362V357Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 402C281 397.582 284.582 394 289 394H348C352.418 394 356 397.582 356 402V407C356 411.418 352.418 415 348 415H289C284.582 415 281 411.418 281 407V402Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 447C281 442.582 284.582 439 289 439H348C352.418 439 356 442.582 356 447V452C356 456.418 352.418 460 348 460H289C284.582 460 281 456.418 281 452V447Z"
        fill="#C4C4C4"
      />
      <path
        d="M281 492C281 487.582 284.582 484 289 484H348C352.418 484 356 487.582 356 492V497C356 501.418 352.418 505 348 505H289C284.582 505 281 501.418 281 497V492Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 87C669 82.5817 672.582 79 677 79H736C740.418 79 744 82.5817 744 87V92C744 96.4183 740.418 100 736 100H677C672.582 100 669 96.4183 669 92V87Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 132C669 127.582 672.582 124 677 124H736C740.418 124 744 127.582 744 132V137C744 141.418 740.418 145 736 145H677C672.582 145 669 141.418 669 137V132Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 177C669 172.582 672.582 169 677 169H736C740.418 169 744 172.582 744 177V182C744 186.418 740.418 190 736 190H677C672.582 190 669 186.418 669 182V177Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 222C669 217.582 672.582 214 677 214H736C740.418 214 744 217.582 744 222V227C744 231.418 740.418 235 736 235H677C672.582 235 669 231.418 669 227V222Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 267C669 262.582 672.582 259 677 259H736C740.418 259 744 262.582 744 267V272C744 276.418 740.418 280 736 280H677C672.582 280 669 276.418 669 272V267Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 312C669 307.582 672.582 304 677 304H736C740.418 304 744 307.582 744 312V317C744 321.418 740.418 325 736 325H677C672.582 325 669 321.418 669 317V312Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 357C669 352.582 672.582 349 677 349H736C740.418 349 744 352.582 744 357V362C744 366.418 740.418 370 736 370H677C672.582 370 669 366.418 669 362V357Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 402C669 397.582 672.582 394 677 394H736C740.418 394 744 397.582 744 402V407C744 411.418 740.418 415 736 415H677C672.582 415 669 411.418 669 407V402Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 447C669 442.582 672.582 439 677 439H736C740.418 439 744 442.582 744 447V452C744 456.418 740.418 460 736 460H677C672.582 460 669 456.418 669 452V447Z"
        fill="#C4C4C4"
      />
      <path
        d="M669 492C669 487.582 672.582 484 677 484H736C740.418 484 744 487.582 744 492V497C744 501.418 740.418 505 736 505H677C672.582 505 669 501.418 669 497V492Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H223C227.418 0 231 3.58172 231 8V23C231 27.4183 227.418 31 223 31H8C3.58172 31 0 27.4183 0 23V8Z"
        fill="#C4C4C4"
      />
    </Skeleton>
  );
};
