import {
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchResellerListApi } from './fetch-reseller-list';

export namespace ModifyReseller {
  export type RequestType = {
    resellerId: number;
    youtubeId?: string;
    instagramId?: string;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ resellerId, instagramId, youtubeId }) => {
        return axios.put(`/reseller/${resellerId}`, {
          instagramId,
          youtubeId
        });
      },
      {
        onSuccess: (...rest) => {
          queryClient.refetchQueries([FetchResellerListApi.KEY_STRING]);
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
