import {
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchResellerListApi } from './fetch-reseller-list';

export namespace DisabledReseller {
  export type RequestType = {
    resellerId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    RequestType
  > = {}) => {
    const qc = useQueryClient();

    const mutation = useMutation(
      ({ resellerId }) => {
        return axios.patch(`reseller/${resellerId}/disabled`);
      },
      {
        onSuccess(...args) {
          qc.refetchQueries([FetchResellerListApi.KEY_STRING]);

          onSuccess?.(...args);
        },
        ...options
      }
    );

    return mutation;
  };
}
