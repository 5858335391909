import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { ColorType, SizeType } from '../../../../types/style';
import Button from '../../../atoms/Button';
import Text from '../../../atoms/Text';
import {
  ActionKind,
  useContextApi as useContextApiMenu,
  State
} from '../../DropdownMenu/context';
import Flex from '../../Flex';
import styles from './list-box-item-group.module.scss';

const cx = classNames.bind(styles);

export interface Props {
  label: string;
  items: {
    value: string;
    text: string;
    colorType?: ColorType;
  }[];
  sizeType?: Extract<SizeType, 'medium'>;
}

const ListBoxItemGroup: React.FC<Props> = ({ items, label }) => {
  const { state: menuState, dispatch: menuDispatch } = useContextApiMenu();

  const onClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();

    menuState.setActive?.(false);
  };

  useEffect(() => {
    const _items: State['items'] = {};

    items.forEach((item) => {
      _items[item.value] = { ...item, colorType: 'primary-color' };
    });

    menuDispatch({
      type: ActionKind.SET_STATE,
      payload: {
        items: _items
      }
    });
  }, [items, menuDispatch]);

  const onClickValue = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    e.stopPropagation();

    menuState.setValue?.(value);
    menuState.setActive?.(false);
  };

  return (
    <Flex
      gap="large"
      className={cx('container', { active: menuState.isActive })}
    >
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Text size="large" colorType="black" isBold={true}>
          {label}
        </Text>
        <Text
          textDecoration="underline"
          colorType="primary-color"
          style={{ cursor: 'pointer' }}
          onClick={onClickClose}
        >
          닫기
        </Text>
      </Flex>
      <Flex gap="large" direction="row" flexWrap="wrap">
        {items.map((item) =>
          menuState.value?.value === item.value ? (
            <Button
              key={item.value}
              text={item.text}
              fit={true}
              disabledBoxShadow={true}
              type="primary-color"
              onClick={(e) => onClickValue(e, item.value)}
            />
          ) : (
            <Button
              key={item.value}
              text={item.text}
              fit={true}
              isBorder={true}
              borderColor="primary-color"
              disabledBoxShadow={true}
              fontColor="primary-color"
              type="transparent"
              onClick={(e) => onClickValue(e, item.value)}
            />
          )
        )}
      </Flex>
    </Flex>
  );
};

export default ListBoxItemGroup;
