import {
  ArrowRightIcon,
  ChevronRightIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import classNames from 'classnames/bind';
import React from 'react';
import { Link } from 'react-router-dom';
import Text from '../../atoms/Text';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface NextStep {
  onClick: () => void;
  name: string;
}

interface PageRoute {
  path: string;
  name: string;
}

export interface Props {
  currentPageName: string;
  prevPages?: PageRoute[];
  nextStep?: NextStep;
  children?: React.ReactNode;
}

const Header: React.FC<Props> = ({
  currentPageName,
  prevPages,
  children,
  nextStep
}) => {
  return (
    <div className={cx('container')}>
      <div className={cx('title-container')}>
        {prevPages?.map((prevPage) => (
          <Link
            to={prevPage.path}
            key={prevPage.name}
            style={{ cursor: 'pointer' }}
          >
            <Text size="xlarge" colorType="gray-color" isBold={true}>
              {prevPage.name}
            </Text>
            <ChevronRightIcon
              colorType="text-primary-500"
              className={cx('right-icon')}
            />
          </Link>
        ))}
        <Text size="xlarge" colorType="black" isBold={true}>
          {currentPageName}
        </Text>
        {nextStep && (
          <div className={cx('next-page-container')}>
            <div className={cx('contour')}></div>
            <Text
              onClick={() => nextStep.onClick()}
              colorType="primary-color"
              size="large"
              isBold={true}
              style={{ cursor: 'pointer' }}
            >
              {nextStep.name}
            </Text>
            <ArrowRightIcon
              colorType="text-gray-500"
              className={cx('right-arrow-icon')}
            />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default Header;
