import { Suspense, useEffect, useState } from 'react';
import DetailSkeletion from '../../../components/organisms/DetailSkeleton';
import Header from '../../../components/organisms/Header';
import DefaultTemplate from '../../../components/templates/Default';
import Flex from '../../../components/molecules/Flex';
import Text from '../../../components/atoms/Text';
import Button from '../../../components/atoms/Button';
import TextInput from '../../../components/molecules/TextInput';
import Hr from '../../../components/atoms/Hr';
import {
  FetchDeliveryForm,
  FetchExcelFields,
  MutationDeliveryFormDetail
} from '../../../hooks/http/delivery-form';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { deliveryFormCreatedTypeToString } from '../../../utils/format/delivery-form';
import { deliveryFormSelectedColor } from '../../../types/delivery-form';
import { DeliveryFormCreatedTypeColor } from '../../../types/delivery-form';
import DivTable from '../../../components/molecules/DivTable';
import DropdownMenu from '../../../components/molecules/DropdownMenu';
import DetailDataTemplate from '../../../components/templates/DetailDataTemplate';
import { Form, FormikContextType, FormikProvider, useFormik } from 'formik';
import { Optional } from 'utility-types';
import { objectDiffOmit } from '../../../utils/object/object-diff';
import {
  SortableContainer,
  SortableItem,
  SortableItems
} from '../../../components/molecules/Sortable';
import { DropResult } from 'react-beautiful-dnd';
import { arrayMove } from '../../../utils/array/swap';
import { Type } from '../../../utils/decorators';
import { useLocationStateParam } from '../../../hooks/useLocationSearchQueryParam';
import {
  ArrowReturnIcon,
  EditPencilIcon,
  OrderIcon,
  TrashIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import { CourierTypeKo } from '@aimpact-korea/arrange-front-types';

const cx = classNames.bind(styles);

class QueryStringParams {
  @Type(() => Number)
  deliveryFormId!: number;
}

type IDetailForm = Optional<Omit<MutationDeliveryFormDetail.Param, 'excelId'>>;

const DeliveryFormDetail: React.FC = () => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const locationQueryString = useLocationStateParam(QueryStringParams);

  const mutation = MutationDeliveryFormDetail.useMutate();

  const onSubmit = (values: IDetailForm) => {
    const diff = objectDiffOmit<IDetailForm>(form.initialValues, values);

    const apis = [
      mutation.mutateAsync({
        excelId: locationQueryString.deliveryFormId,
        ...diff
      })
    ];

    Promise.all(apis).then(() => {
      setLoading(false);
      setEditMode(false);
    });
  };

  const form = useFormik<IDetailForm>({
    initialValues: {},
    onSubmit: (values) => {
      setLoading(true);
      onSubmit(values);
    }
  });

  return (
    <FormikProvider value={form}>
      <Form onSubmit={form.handleSubmit}>
        <DefaultTemplate>
          <Suspense fallback={<DetailSkeletion />}>
            <Header
              prevPages={[
                {
                  name: '택배양식',
                  path: '/delivery-form'
                }
              ]}
              currentPageName={'양식정보'}
            >
              <Flex gap="xlarge" direction="row">
                {editMode && (
                  <Flex
                    direction="row"
                    alignItems="center"
                    gap="xsmall"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();

                      setEditMode(false);
                      form.resetForm();
                    }}
                  >
                    <ArrowReturnIcon colorType="text-primary-500" />
                    <Text colorType="primary-color" isBold={true} size="large">
                      되돌리기
                    </Text>
                  </Flex>
                )}
                <Button
                  isLoading={loading}
                  text="완료"
                  size="large"
                  disabled={!editMode}
                  htmlType="submit"
                ></Button>
              </Flex>
            </Header>
            <DeliveryFormDetailData
              deliveryFormId={locationQueryString.deliveryFormId}
              editMode={editMode}
              setEditMode={setEditMode}
              form={form}
            />
          </Suspense>
        </DefaultTemplate>
      </Form>
    </FormikProvider>
  );
};

export default DeliveryFormDetail;

interface DeliveryFormDetailDataProps {
  editMode: boolean;
  setEditMode: (state: boolean) => void;
  form: FormikContextType<IDetailForm>;
  deliveryFormId: number;
}

const DeliveryFormDetailData: React.FC<DeliveryFormDetailDataProps> = ({
  editMode,
  setEditMode,
  form: { resetForm, ...form },
  deliveryFormId
}) => {
  const { data } = FetchExcelFields.useFetchExcelFields();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const excelFields = data!.data;

  const deliveryForm = FetchDeliveryForm.useFetchDeliveryForm({
    excelId: deliveryFormId
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const deliveryFormData = deliveryForm.data!.data;

  useEffect(() => {
    resetForm({
      values: {
        columns: deliveryFormData.columns,
        courierType: deliveryFormData.courierType
      }
    });
  }, [deliveryFormData.columns, deliveryFormData.courierType, resetForm]);

  const onSortEnd = (dropResult: DropResult) => {
    if (form.values.columns && dropResult.destination) {
      const swapArray = arrayMove(
        form.values.columns,
        dropResult.source.index,
        dropResult.destination.index
      );

      form.setValues({
        ...form.values,
        columns: swapArray
      });
    }
  };

  return (
    <Flex gap="xxlarge">
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Text size="large" isBold={true} colorType="gray-color">
          NO. {deliveryFormId}
        </Text>
        <Flex direction="row" gap="xlarge">
          <Button
            startIcon={<EditPencilIcon colorType="text-white" />}
            text="수정"
            type="primary-color"
            size="large"
            disabled={editMode}
            onClick={(e) => {
              e.stopPropagation();

              setEditMode(true);
            }}
          />
          <Button
            startIcon={<TrashIcon colorType="text-white" />}
            text="삭제"
            type="secondary-color"
            size="large"
            disabled={editMode}
          />
        </Flex>
      </Flex>
      <Flex direction="row" gap="xlarge">
        <TextInput
          sizeType="xxlarge"
          disabled={!editMode}
          isContour={true}
          widthType="auto"
        >
          <TextInput.Head>
            <TextInput.Head.Icon />
            <TextInput.Head.Text text="택배사" />
          </TextInput.Head>
          <TextInput.Body>
            <TextInput.Body.Input
              name={'courierType'}
              defaultValue={
                form.values.courierType
                  ? CourierTypeKo[form.values.courierType]
                  : ''
              }
              disabled
            />
          </TextInput.Body>
        </TextInput>

        <TextInput
          sizeType="xxlarge"
          disabled={!editMode}
          isContour={true}
          widthType="auto"
        >
          <TextInput.Head>
            <TextInput.Head.Icon />
            <TextInput.Head.Text text="사용자" />
          </TextInput.Head>
          <TextInput.Body>
            <TextInput.Body.Input value={deliveryFormData.store.ceoName} />
          </TextInput.Body>
        </TextInput>
      </Flex>
      <Hr color="gray-dark-color" />
      <DetailDataTemplate>
        <DetailDataTemplate.Box title="설정 타입">
          <Flex
            className={cx('delivery-form-summary-wrapper')}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="medium"
          >
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                설정 타입
              </Text>
              <Text
                size="xlarge"
                isBold={true}
                colorType={
                  DeliveryFormCreatedTypeColor[deliveryFormData.createdType]
                }
              >
                {deliveryFormCreatedTypeToString(deliveryFormData.createdType)}
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                사용 여부
              </Text>
              <Text
                size="xlarge"
                colorType={deliveryFormSelectedColor(
                  deliveryFormData.isSelected
                )}
                isBold={true}
              >
                {deliveryFormData.isSelected ? '사용중' : '미사용'}
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                수정 날짜
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {deliveryFormData.updatedAt}
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                등록 날짜
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {deliveryFormData.createdAt}
              </Text>
            </Flex>
          </Flex>
        </DetailDataTemplate.Box>
      </DetailDataTemplate>
      <Hr color="gray-dark-color" />
      <DetailDataTemplate>
        <DetailDataTemplate.Box title="택배 양식 항목">
          <DivTable size={[1, 9, 1, 1]}>
            <DivTable.Head>
              <DivTable.Head.Row>
                <DivTable.Head.Row.Cell textAlign="center">
                  <Text isBold={true} colorType="gray-color">
                    순서
                  </Text>
                </DivTable.Head.Row.Cell>
                <DivTable.Head.Row.Cell textAlign="left">
                  <Text isBold={true} colorType="gray-color">
                    양식 항목
                  </Text>
                </DivTable.Head.Row.Cell>
                <DivTable.Head.Row.Cell textAlign="center">
                  <Text isBold={true} colorType="gray-color">
                    순서변경
                  </Text>
                </DivTable.Head.Row.Cell>
                <DivTable.Head.Row.Cell textAlign="center">
                  <Text isBold={true} colorType="gray-color">
                    옵션삭제
                  </Text>
                </DivTable.Head.Row.Cell>
              </DivTable.Head.Row>
            </DivTable.Head>
            <SortableContainer onDragEnd={onSortEnd}>
              <SortableItems droppableId="table" isDropDisabled={!editMode}>
                {(provided) => (
                  <DivTable.Body
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {form.values.columns?.map((column, idx) => (
                      <SortableItem
                        key={column._unique}
                        index={idx}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        id={column._unique!}
                      >
                        {(provided) => (
                          <DivTable.Body.Row
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <DivTable.Body.Row.Cell textAlign="center">
                              <Text colorType="gray-color" size="large">
                                {idx}.
                              </Text>
                            </DivTable.Body.Row.Cell>
                            <DivTable.Body.Row.Cell textAlign="left">
                              <DropdownMenu
                                value={`${column.type}`}
                                disabled={!editMode}
                                size="large"
                                setValue={(value) => {
                                  form.setValues({
                                    ...form.values,
                                    columns: form.values.columns?.map(
                                      (column, _idx) => {
                                        let _column = column;

                                        if (idx === _idx) {
                                          _column = Object.assign({}, column);

                                          _column.type = Number(value);
                                        }

                                        return _column;
                                      }
                                    )
                                  });
                                }}
                              >
                                <DropdownMenu.Title isBottomLine={true}>
                                  <DropdownMenu.Title.Text
                                    placeholder=""
                                    colorType="gray-dark-color"
                                  />
                                  <DropdownMenu.Title.UpDownIcon />
                                </DropdownMenu.Title>
                                <DropdownMenu.ItemWrapper>
                                  <DropdownMenu.ItemWrapper.ListBoxItemGroup
                                    label="택배접수양식 항목 선택"
                                    items={excelFields.map((field) => ({
                                      value: `${field.type}`,
                                      text: field.name
                                    }))}
                                  />
                                </DropdownMenu.ItemWrapper>
                              </DropdownMenu>
                            </DivTable.Body.Row.Cell>
                            <DivTable.Body.Row.Cell textAlign="center">
                              <div
                                {...provided.dragHandleProps}
                                style={{ display: 'inline-block' }}
                              >
                                <OrderIcon colorType="text-gray-500" />
                              </div>
                            </DivTable.Body.Row.Cell>
                            <DivTable.Body.Row.Cell textAlign="center">
                              <TrashIcon colorType="text-gray-500" />
                            </DivTable.Body.Row.Cell>
                          </DivTable.Body.Row>
                        )}
                      </SortableItem>
                    ))}
                    {provided.placeholder}
                  </DivTable.Body>
                )}
              </SortableItems>
            </SortableContainer>
          </DivTable>
        </DetailDataTemplate.Box>
      </DetailDataTemplate>
    </Flex>
  );
};
