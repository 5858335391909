import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SigninForm from '../components/organisms/SigninForm';
import ContentCenter from '../components/templates/ContentCenter';
import { adminAuthInfo } from '../recoil/atoms';

const Signin: React.FC = () => {
  const { accessToken } = useRecoilValue(adminAuthInfo);

  if (accessToken) {
    return <Navigate to={{ pathname: '/' }} />;
  }

  return (
    <ContentCenter>
      <SigninForm />
    </ContentCenter>
  );
};

export default Signin;
