import classNames from 'classnames/bind';
import React from 'react';
import { SizeType } from '../../../types/style';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
  gap?: Extract<
    SizeType,
    'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
  >;
  direction?: 'column' | 'row';
  justifyContent?: 'center' | 'space-between' | 'start';
  alignItems?: 'center' | 'baseline' | 'start' | 'end';
  children?: React.ReactNode;
  flexWrap?: 'wrap';
  flexType?: 'inline-flex' | 'flex';
}

export type FlexComponent = React.ForwardRefExoticComponent<
  Props & React.RefAttributes<HTMLDivElement>
>;

const Flex = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className = '',
      direction = 'column',
      justifyContent,
      alignItems,
      gap,
      flexWrap,
      flexType = 'flex',
      ...props
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={`${cx(
          'flex',
          `flex-type--${flexType}`,
          `${gap ? `gap--${gap}` : ''}`,
          `${direction ? `direction--${direction}` : ''}`,
          `${justifyContent ? `justify-content--${justifyContent}` : ''}`,
          `${alignItems ? `align-items--${alignItems}` : ''}`
        )} ${className}`}
        style={{ flexWrap: flexWrap }}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Flex.displayName = 'Flex';

export default Flex;
