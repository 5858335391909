export enum LinkfarmProducInspectiontStats {
  SUCCESS = 'SUCCESS',
  REQUEST = 'REQUEST',
  FAILED = 'FAILED'
}

export enum LinkfarmProductTaxType {
  TAX_FREE = 'TAX_FREE', // 면세
  TAX = 'TAX' // 과세
}
