import { Span } from '@aimpact-korea/arrange-front-atomic';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { Suspense, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from '../../../../components/atoms/Button';
import Flex from '../../../../components/molecules/Flex';
import ListSkeleton from '../../../../components/molecules/ListSkeleton';
import Modal from '../../../../components/molecules/Modal';
import Table from '../../../../components/molecules/Table';
import Header from '../../../../components/organisms/Header';
import LinkfarmProductResellForm from '../../../../components/organisms/LinkfarmProductResellForm';
import DefaultTemplate from '../../../../components/templates/Default';
import { linkfarmProductResellDisplayedStateKo } from '../../../../constants/linkfarm-product-resell';
import { DeleteLinkfarmProductProductResellApi } from '../../../../hooks/http/linkfarm/product/resell/delete-linkfarm-product-resell';
import { FetchLinkfarmProductResellsApi } from '../../../../hooks/http/linkfarm/product/resell/fetch-linkfarm-product-resells';
import { ModifyLinkfarmProductProductResellApi } from '../../../../hooks/http/linkfarm/product/resell/modify-linkfarm-product-resell';
import {
  LinkfarmProductResellDisplayedState,
  LinkfarmProductResellSalePeriodType
} from '../../../../types/linkfarm';
import styles from './index.module.scss';
import * as Yup from 'yup';
import Link from '../../../../components/molecules/Link';

const cx = classNames.bind(styles);

function LinkfarmProductResellerPage() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'리셀러 상품'}>
          <div className={cx('header-container')}></div>
        </Header>
        <LinkfarmProductResellData />
      </Suspense>
    </DefaultTemplate>
  );
}

function LinkfarmProductResellData() {
  const query = FetchLinkfarmProductResellsApi.useFetchQuery();

  return (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={query
        .data!.pages.map((page) => page.data.linkfarmProductResells.length)
        .reduce((sum, current) => sum + current, 0)}
      next={query.fetchNextPage}
      hasMore={query.hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              No.
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              쇼핑몰 상품명
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              상품가격
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              상품재고
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              판매기간
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              판매수수료
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              리셀러 수수료
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              에이임팩트 수수료
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              판매상태
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              PICK 리스트
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              수정
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              삭제
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {query.data?.pages.map((group) =>
            group.data.linkfarmProductResells.map((linkfarmProductResell) => (
              <LinkfarmProductResellDataRow
                key={linkfarmProductResell.id}
                linkfarmProductResell={linkfarmProductResell}
              />
            ))
          )}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
}

function LinkfarmProductResellDataRow({
  linkfarmProductResell
}: {
  linkfarmProductResell: FetchLinkfarmProductResellsApi.ResponseType['linkfarmProductResells'][number];
}) {
  const deleteLinkfarmProductProductResellApi =
    DeleteLinkfarmProductProductResellApi.useMutate({
      onSuccess: () => {
        alert('상품 삭제가 완료되었습니다.');
      }
    });

  const [isShowModifyResellerModal, setShowModifyResellerModal] =
    useState(false);

  function deleteLinkfarmProductResellConfirm(pickCount: number) {
    if (confirm(`${pickCount}개의 픽이 있습니다. 그래도 삭제하시겠습니까?`)) {
      deleteLinkfarmProductProductResellApi.mutate({
        linkfarmProductResellId: linkfarmProductResell.id
      });
    }
  }

  return (
    <Table.Row>
      <Table.Row.Cell alignType="center">
        {linkfarmProductResell.id}
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        {linkfarmProductResell.linkfarmProduct.storeTitle}
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        <Flex>
          {linkfarmProductResell.linkfarmProduct.product.options.map(
            (option) => (
              <Span key={option.code}>
                {option.name} :{' '}
                {(option.price ?? option.normalPrice).toLocaleString()}원
              </Span>
            )
          )}
        </Flex>
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        <Flex>
          {linkfarmProductResell.linkfarmProduct.product.options.map(
            (option) => (
              <Span key={option.code}>
                {option.name} :{' '}
                {option.stock === null
                  ? '재고 무한'
                  : option.stock === 0
                  ? '재고 없음'
                  : `${option.stock}개`}
              </Span>
            )
          )}
        </Flex>
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        {linkfarmProductResell.salePeriodType ===
        LinkfarmProductResellSalePeriodType.ALL
          ? '연중'
          : `${linkfarmProductResell.salePeriodStartAt} ~ ${linkfarmProductResell.salePeriodEndAt}`}
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        {linkfarmProductResell.saleCommission}%
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        {linkfarmProductResell.resellerCommission}%
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        {(
          linkfarmProductResell.saleCommission -
          linkfarmProductResell.resellerCommission
        ).toFixed(2)}
        %
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        <Span
          fontWeightType={'font-bold'}
          fontColorType={StyleBuilderInstane.toFontColorType(
            `${
              linkfarmProductResell.displayedState ===
              LinkfarmProductResellDisplayedState.SALE
                ? 'text-primary-500'
                : linkfarmProductResell.displayedState ===
                  LinkfarmProductResellDisplayedState.END_OF_SALE
                ? 'text-error'
                : 'text-gray-300'
            }`
          )}
        >
          {
            linkfarmProductResellDisplayedStateKo[
              linkfarmProductResell.displayedState
            ]
          }
        </Span>
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        <Flex gap="xsmall">
          {linkfarmProductResell.resellers.map((reseller) => (
            <Link key={reseller.id} to={`/reseller?id=${reseller.id}`}>
              <Span>{reseller.channelName}</Span>
            </Link>
          ))}
        </Flex>
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        <Button text="수정" onClick={() => setShowModifyResellerModal(true)} />
        {isShowModifyResellerModal && (
          <LinkfarmProductResellDataModifyModal
            linkfarmProductResell={linkfarmProductResell}
            handleClose={() => setShowModifyResellerModal(false)}
          />
        )}
      </Table.Row.Cell>
      <Table.Row.Cell alignType="center">
        <Button
          text="삭제"
          type="secondary-color"
          onClick={() =>
            deleteLinkfarmProductResellConfirm(linkfarmProductResell.pickCount)
          }
        />
      </Table.Row.Cell>
    </Table.Row>
  );
}

export default LinkfarmProductResellerPage;

function LinkfarmProductResellDataModifyModal({
  linkfarmProductResell,
  handleClose
}: {
  linkfarmProductResell: FetchLinkfarmProductResellsApi.ResponseType['linkfarmProductResells'][number];
  handleClose: () => void;
}) {
  const form = useFormik<
    Pick<
      React.ComponentProps<typeof LinkfarmProductResellForm>,
      'form'
    >['form']['values']
  >({
    initialValues: {
      salePeriodEndAt: linkfarmProductResell.salePeriodEndAt,
      salePeriodStartAt: linkfarmProductResell.salePeriodStartAt,
      salePeriodType: linkfarmProductResell.salePeriodType,
      resellerCommission: `${linkfarmProductResell.resellerCommission}`,
      saleCommission: `${linkfarmProductResell.saleCommission}`,
      maxPickCount: linkfarmProductResell.maxPickCount
    },
    onSubmit: ({
      salePeriodEndAt,
      salePeriodStartAt,
      salePeriodType,
      resellerCommission,
      saleCommission,
      maxPickCount
    }) => {
      modifyLinkfarmProductProductResellApi.mutate({
        linkfarmProductResellId: linkfarmProductResell.id,
        resellerCommission: Number(resellerCommission),
        saleCommission: Number(saleCommission),
        salePeriodEndAt:
          salePeriodType === LinkfarmProductResellSalePeriodType.PERIOD
            ? dayjs(salePeriodEndAt).format('YYYY-MM-DD')
            : null,
        salePeriodStartAt:
          salePeriodType === LinkfarmProductResellSalePeriodType.PERIOD
            ? dayjs(salePeriodStartAt).format('YYYY-MM-DD')
            : null,
        salePeriodType,
        maxPickCount: maxPickCount as number
      });
    }
  });

  const modifyLinkfarmProductProductResellApi =
    ModifyLinkfarmProductProductResellApi.useMutate({
      onSuccess: () => {
        handleClose();
        alert('리셀러 상품이 수정되었습니다.');
      },
      onError: (error) => {
        if (error instanceof Yup.ValidationError) {
          alert(error.message);
          return;
        }

        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.exceptionCode
        ) {
          switch (error.response.data.exceptionCode) {
            case 'L_P_R_1002':
              alert('리셀러 수수료는 판매 수수료보다 높을 수 없습니다.');
              return;
            case 'L_P_R_1003':
              alert(
                '리셀러 총 픽 카운트는 현재 픽 하고 있는 리셀러 수 보다 많거나 같아야 합니다.'
              );
              return;
          }
        }

        if (error.response?.data) {
          alert(error.response?.data);
        }
      }
    });

  return (
    <Modal isOpen={true} sizeType="large" handleClose={() => handleClose()}>
      <Modal.Header>
        <Modal.Header.Title text="리셀러 상품 수정" />
      </Modal.Header>
      <Modal.Body>
        <Flex style={{ width: '100%' }}>
          <LinkfarmProductResellForm
            form={form}
            productImages={linkfarmProductResell.linkfarmProduct.images}
            product={{
              options: linkfarmProductResell.linkfarmProduct.product.options
            }}
            storeTitle={linkfarmProductResell.linkfarmProduct.storeTitle}
          />
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Flex direction="row" gap="small" style={{ width: '100%' }}>
          <Button
            text="취소"
            fullwidth={true}
            type="secondary-color"
            isLoading={modifyLinkfarmProductProductResellApi.isLoading}
            htmlType="button"
            onClick={() => handleClose()}
          />
          <Button
            text="수정"
            fullwidth={true}
            isLoading={modifyLinkfarmProductProductResellApi.isLoading}
            htmlType="submit"
            onClick={() => form.submitForm()}
          />
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
