import { IconProps } from '@aimpact-korea/arrange-front-icons/build/icons';
import classNames from 'classnames/bind';
import React from 'react';
import { SizeType } from '../../../types/style';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props {
  src?: string;
  sizeType?: Extract<SizeType, 'medium' | 'small'>;
  outline?: boolean;
  IconComponent?: React.FC<IconProps>;
}

const backupUrl =
  "data:image/svg+xml,%3Csvg viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M30 32.9167C36.4434 32.9167 41.6667 27.6933 41.6667 21.25C41.6667 14.8067 36.4434 9.58333 30 9.58333C23.5567 9.58333 18.3334 14.8067 18.3334 21.25C18.3334 27.6933 23.5567 32.9167 30 32.9167ZM30 27.0833C33.2217 27.0833 35.8334 24.4717 35.8334 21.25C35.8334 18.0283 33.2217 15.4167 30 15.4167C26.7784 15.4167 24.1667 18.0283 24.1667 21.25C24.1667 24.4717 26.7784 27.0833 30 27.0833Z' fill='%23C4C4C4'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M30 59.1667C46.1083 59.1667 59.1667 46.1083 59.1667 30C59.1667 13.8917 46.1083 0.833328 30 0.833328C13.8917 0.833328 0.833374 13.8917 0.833374 30C0.833374 46.1083 13.8917 59.1667 30 59.1667ZM48.4675 44.2636C51.5181 40.3197 53.3334 35.3719 53.3334 30C53.3334 17.1134 42.8867 6.66666 30 6.66666C17.1134 6.66666 6.66671 17.1134 6.66671 30C6.66671 35.3719 8.482 40.3197 11.5326 44.2636C12.6063 42.6387 14.0617 41.1977 15.7399 40.013C19.3794 37.4439 24.3557 35.8333 30 35.8333C35.6444 35.8333 40.6207 37.4439 44.2602 40.013C45.9384 41.1977 47.3937 42.6387 48.4675 44.2636ZM44.1719 48.5381C43.6468 47.3015 42.597 45.9792 40.8962 44.7787C38.3378 42.9727 34.564 41.6667 30 41.6667C25.436 41.6667 21.6623 42.9727 19.1039 44.7787C17.4031 45.9792 16.3533 47.3015 15.8282 48.5381C19.757 51.546 24.6698 53.3333 30 53.3333C35.3303 53.3333 40.2431 51.546 44.1719 48.5381Z' fill='%23C4C4C4'/%3E%3C/svg%3E%0A";
const ProfileImage: React.FC<Props> = ({
  src,
  sizeType = 'medium',
  outline = false,
  IconComponent
}) => {
  return (
    <div
      style={{ backgroundImage: `url("${src}"), url("${backupUrl}")` }}
      className={cx('img', `size-type--${sizeType}`, {
        outline
      })}
    >
      {IconComponent && (
        <div className={cx('icon-wrapper')}>
          <IconComponent colorType="text-primary-500" />
        </div>
      )}
    </div>
  );
};

export default ProfileImage;
