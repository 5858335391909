import React, { useEffect, useState } from 'react';
import Input from '../../../atoms/Input';
import { ActionKind, useContextApi } from '../context';

export interface Props extends React.ComponentPropsWithoutRef<'input'> {
  placeholder?: string;
  handleChangeValue?: (value: string) => void;
  type?: 'text' | 'password' | 'number';
}

export type BodyInputComponent = React.ForwardRefExoticComponent<
  Props & React.RefAttributes<HTMLInputElement>
>;

// eslint-disable-next-line react/display-name
const BodyInput = React.forwardRef<HTMLInputElement, Props>(
  (
    { placeholder = '', handleChangeValue, type = 'text', onBlur, ...props },
    ref
  ) => {
    const { state, dispatch } = useContextApi();
    const [originalValue, setOriginalValue] = useState<string>();

    const [focus, setFocus] = useState(false);

    const onChangeValue = (value: string) => {
      handleChangeValue?.(value);
    };

    useEffect(() => {
      dispatch({
        type: ActionKind.SET_STATE,
        payload: {
          active: !!(originalValue || focus),
          isFocus: focus
        }
      });
    }, [dispatch, focus, originalValue]);

    useEffect(() => {
      dispatch({
        type: ActionKind.SET_STATE,
        payload: {
          onChangeValue: handleChangeValue
        }
      });
    }, [dispatch, handleChangeValue]);

    return (
      <Input
        ref={ref}
        type={type}
        fontSize={state.size}
        placeholder={placeholder}
        handleChangeValue={(value) => {
          setOriginalValue(value);
          onChangeValue?.(value);
        }}
        onBlur={(e) => {
          onBlur?.(e);
          setFocus(false);
        }}
        onFocus={() => setFocus(true)}
        disabled={state.disabled}
        autoWidth={state.widthType === 'auto'}
        {...props}
      />
    );
  }
) as BodyInputComponent;

BodyInput.displayName = 'BodyInput';

export default BodyInput;
