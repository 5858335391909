import React, { useEffect } from 'react';
import { useContextApi } from '../context';

export interface Props {
  cutline?: number;
  children: React.ReactNode;
  onChangeItem?: (count: number) => void;
}

const ItemWrapper: React.FC<Props> = ({
  children,
  cutline = 5,
  onChangeItem
}) => {
  const { state } = useContextApi();

  const childrenCnt = React.Children.count(children);

  useEffect(() => {
    onChangeItem?.(childrenCnt);
  }, [childrenCnt, onChangeItem]);

  const childrenRender = () => {
    const childrens: React.ReactNode[] | undefined | null = React.Children.map(
      children,
      (children) => children
    );

    if (!state.readmore) {
      return childrens?.splice(0, cutline).map((children) => children);
    }
    return childrens;
  };

  return <React.Fragment>{childrenRender()}</React.Fragment>;
};

export default ItemWrapper;
