import axios, { AxiosError } from 'axios';
import pDebounce from 'p-debounce';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
  withCredentials: true
});

const getAccessTokenFromRt = pDebounce.promise(async () => {
  const accessToken = await axios
    .post(
      `${process.env.REACT_APP_API_HOST}/auth/token`,
      {
        grantType: 'refresh_token'
      },
      {
        withCredentials: true
      }
    )
    .catch((error: AxiosError) => {
      window.dispatchEvent(new CustomEvent('on-logout'));

      return Promise.reject(error);
    });

  return accessToken.data.accessToken;
});

instance.interceptors.request.use(async (config) => {
  const accessToken = window.localStorage.getItem('access_token');

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error: AxiosError) => {
    const originalReq = error.config;

    if (error.response?.status === 401) {
      const accessToken = await getAccessTokenFromRt();

      window.localStorage.setItem('access_token', accessToken);

      originalReq.headers = {
        ...originalReq.headers,
        Authorization: `Bearer ${accessToken}`
      };

      return axios(originalReq);
    }

    return Promise.reject(error);
  }
);

export default instance;

export const arrangeAppApi = axios.create({
  baseURL: `${process.env.REACT_APP_ARRANGE_API_HOST}`
});

export function isAxiosError(error: Error): error is AxiosError {
  return (error as AxiosError).isAxiosError;
}
