import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import {
  CouponFeautreType,
  CouponSaleType
} from '@aimpact-korea/arrange-front-types';
import { useState } from 'react';
import { CreateCouponPreset } from '../../../hooks/http/coupon-preset/create-coupon-preset';
import Button from '../../atoms/Button';
import DropdownMenu from '../../molecules/DropdownMenu';
import Modal from '../../molecules/Modal';
import Selection from '../../molecules/Selection';
import Table from '../../molecules/Table';
import TextInput from '../../molecules/TextInput';

function CreateCouponPresetModal({
  isOpen,
  handleSave,
  handleClose,
  isLoading
}: {
  isOpen: boolean;
  handleSave: (couponPreset: CreateCouponPreset.RequestType) => void;
  handleClose: () => void;
  isLoading?: boolean;
}) {
  const [couponPreset, setCouponPreset] =
    useState<CreateCouponPreset.RequestType>({
      amount: 0,
      couponFeautreType: CouponFeautreType.DOWNLOAD,
      couponSaleType: CouponSaleType.PRICE,
      isDuplicationUse: false,
      maxAmountPrice: 0,
      minAmountPrice: 0
    });

  return (
    <Modal sizeType="large" isOpen={isOpen} handleClose={() => handleClose()}>
      <Modal.Header>
        <Modal.Header.Title text="쿠폰 프리셋 추가" />
      </Modal.Header>
      <Modal.Body>
        <Table type="simple-table">
          <Table.Body>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">쿠폰 타입</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <DropdownMenu
                  setValue={(value) =>
                    setCouponPreset((old) => ({
                      ...old,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      couponSaleType: value as CouponSaleType
                    }))
                  }
                  value={couponPreset.couponSaleType}
                >
                  <DropdownMenu.Title isBottomLine={true}>
                    <DropdownMenu.Title.Text placeholder="쿠폰 타입을 선택해주세요."></DropdownMenu.Title.Text>
                  </DropdownMenu.Title>
                  <DropdownMenu.ItemWrapper alignType="center" size="small">
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                        value={CouponSaleType.PRICE}
                      >
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                          text={CouponSaleType.PRICE}
                        />
                      </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                        value={CouponSaleType.PERCENT}
                      >
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                          text={CouponSaleType.PERCENT}
                        />
                      </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                    </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                  </DropdownMenu.ItemWrapper>
                </DropdownMenu>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">쿠폰 기능</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <DropdownMenu
                  setValue={(value) =>
                    setCouponPreset((old) => ({
                      ...old,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      couponFeautreType: value as CouponFeautreType
                    }))
                  }
                  value={couponPreset.couponFeautreType}
                >
                  <DropdownMenu.Title isBottomLine={true}>
                    <DropdownMenu.Title.Text placeholder="쿠폰 타입을 선택해주세요."></DropdownMenu.Title.Text>
                  </DropdownMenu.Title>
                  <DropdownMenu.ItemWrapper alignType="center" size="small">
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                        value={CouponFeautreType.DOWNLOAD}
                      >
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                          text={CouponFeautreType.DOWNLOAD}
                        />
                      </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                        value={CouponFeautreType.REFERRAL_CODE_BUY}
                      >
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                          text={CouponFeautreType.REFERRAL_CODE_BUY}
                        />
                      </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                        value={CouponFeautreType.PERSONAL}
                      >
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                          text={CouponFeautreType.PERSONAL}
                        />
                      </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                    </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                  </DropdownMenu.ItemWrapper>
                </DropdownMenu>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">할인가</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={couponPreset.amount}
                      onChange={(e) =>
                        setCouponPreset({
                          ...couponPreset,
                          amount: isNaN(parseInt(e.target.value))
                            ? 0
                            : parseInt(e.target.value)
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">최대 할인가</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={couponPreset.maxAmountPrice ?? ''}
                      onChange={(e) =>
                        setCouponPreset({
                          ...couponPreset,
                          maxAmountPrice: isNaN(parseInt(e.target.value))
                            ? 0
                            : parseInt(e.target.value)
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">최소 주문금액</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={couponPreset.minAmountPrice}
                      onChange={(e) =>
                        setCouponPreset({
                          ...couponPreset,
                          minAmountPrice: isNaN(parseInt(e.target.value))
                            ? 0
                            : parseInt(e.target.value)
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">
                쿠폰 중복사용 여부
              </Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <Selection>
                  <Selection.Input
                    type="toggle"
                    isChecked={couponPreset.isDuplicationUse}
                    onChangeChecked={() =>
                      setCouponPreset({
                        ...couponPreset,
                        isDuplicationUse: !couponPreset.isDuplicationUse
                      })
                    }
                  ></Selection.Input>
                </Selection>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          flexDirection="flex-row"
          width="w-full"
          gapSize={StyleBuilderInstane.toGapSpaceType('gap-xxs')}
        >
          <Button
            text="취소"
            type="secondary-color"
            fullwidth={true}
            onClick={() => handleClose()}
            isLoading={isLoading}
          />
          <Button
            text="저장"
            fullwidth={true}
            onClick={() => handleSave(couponPreset)}
            isLoading={isLoading}
          />
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateCouponPresetModal;
