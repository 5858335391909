import classNames from 'classnames/bind';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { LoginMutation } from '../../../hooks/http/admin';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import TextInput from '../../molecules/TextInput';
import styles from './index.module.scss';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';

const cx = classNames.bind(styles);

interface Form {
  id: string;
  pwd: string;
}

const SigninForm: React.FC = () => {
  const [btnActive, setBtnActive] = useState(false);
  const queryClient = useQueryClient();

  const { handleSubmit, values, handleChange, setValues } = useFormik<Form>({
    initialValues: {
      id: '',
      pwd: ''
    },
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  const navigate = useNavigate();

  const { mutate, isLoading } = LoginMutation.useLogin({
    onSuccess() {
      // history.replace('/');
      navigate('/', {
        replace: true
      });
      queryClient.invalidateQueries();
    },
    onError() {
      setValues({
        ...values,
        pwd: ''
      });
    }
  });

  useEffect(() => {
    if (values.id && values.pwd) {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }
  }, [values.id, values.pwd]);

  const onSubmit = (data: Form) => {
    if (data.id && data.pwd) {
      mutate({ id: data.id, pwd: data.pwd });
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('logo-container')}>
        <Logo />
      </div>
      <form className={cx('signin-form-container')} onSubmit={handleSubmit}>
        <div className={cx('group')}>
          <Text size={'small'} colorType={'gray-color'} isBold={true}>
            아이디
          </Text>
          <TextInput isContour={true} widthType={'full'}>
            <TextInput.Body>
              <TextInput.Body.Input
                placeholder="아이디를 입력하세요."
                value={values.id}
                name="id"
                onChange={handleChange}
              />
              <TextInput.Body.ClearIcon />
            </TextInput.Body>
          </TextInput>
        </div>
        <div className={cx('group')}>
          <Text size={'small'} colorType={'gray-color'} isBold={true}>
            비밀번호
          </Text>
          <TextInput isContour={true} widthType="full">
            <TextInput.Body>
              <TextInput.Body.Input
                type="password"
                value={values.pwd}
                placeholder="비밀번호를 입력하세요."
                name="pwd"
                onChange={handleChange}
              />
              <TextInput.Body.ClearIcon />
            </TextInput.Body>
          </TextInput>
        </div>
        <div className={cx('group')}>
          <Button
            disabledBoxShadow={true}
            type={btnActive ? 'primary-color' : 'gray-color'}
            text="로그인"
            fullwidth={true}
            htmlType="submit"
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default SigninForm;
