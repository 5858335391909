import axios from '../../../../../utils/axios';
import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery
} from '@tanstack/react-query';
import {
  LinkfarmProductResllsCursorStandard,
  SortState
} from '../../../../../types/sort';
import { AxiosError, AxiosResponse } from 'axios';
import { isNextCursor, QueryInfiniteCallback } from '../../..';
import { useErrorHandler } from 'react-error-boundary';
import {
  LinkfarmProductResellDisplayedState,
  LinkfarmProductResellSalePeriodType
} from '../../../../../types/linkfarm';
import qs from 'qs';

export namespace FetchLinkfarmProductResellsApi {
  export type CursorType = {
    id?: number;
    value?: string;
  } & SortState<LinkfarmProductResllsCursorStandard>;

  export type ResponseType = {
    linkfarmProductResells: {
      id: number;
      linkfarmProductId: number;
      isEnabled: boolean;
      salePeriodType: LinkfarmProductResellSalePeriodType;
      salePeriodStartAt: string | null;
      salePeriodEndAt: string | null;
      resellerCommission: number;
      saleCommission: number;
      createdAt: string;
      updatedAt: string;
      displayedState: LinkfarmProductResellDisplayedState;
      maxPickCount: number;
      pickCount: number;
      resellers: {
        id: number;
        channelName: string;
      }[];
      linkfarmProduct: {
        id: number;
        storeTitle: string;
        images: {
          attachmentId: number;
          url: string;
        }[];
        product: {
          id: number;
          name: string;
          options: {
            name: string;
            code: string;
            price: number | null;
            normalPrice: number;
            stock: number | null;
          }[];
        };
      };
    }[];
    cursor: CursorType;
  };

  export type ParameterType = {
    cursor?: CursorType;
    take?: number;
  };

  export const QUERY_KEY_STRING = 'fetch-linkfarm-product-resells' as const;

  type TQueryKeyType = [typeof QUERY_KEY_STRING, ParameterType];
  type QueryKeyType = TQueryKeyType & QueryKey;

  export async function fetchData({
    pageParam,
    queryKey
  }: QueryFunctionContext<QueryKeyType, CursorType>) {
    const _queryKey = queryKey as TQueryKeyType;

    let cursor: CursorType | undefined = undefined;
    if (pageParam) {
      cursor = pageParam;
    } else if (_queryKey[1].cursor) {
      cursor = {};
      if (_queryKey[1].cursor.id) {
        cursor.id = _queryKey[1].cursor.id;
      }
      if (_queryKey[1].cursor.orderBy) {
        cursor.orderBy = _queryKey[1].cursor.orderBy;
      }
      if (_queryKey[1].cursor.standardColumn) {
        cursor.standardColumn = _queryKey[1].cursor.standardColumn;
      }
      if (_queryKey[1].cursor.value) {
        cursor.value = _queryKey[1].cursor.value;
      }
    }

    return await axios.get<ResponseType>(
      '/linkfarm/product/resell?' + qs.stringify({ cursor }),
      {
        params: {
          take: _queryKey[1].take
        }
      }
    );
  }

  export const useFetchQuery = (
    {
      cursor: { id, orderBy, standardColumn, value } = {},
      take = 20
    }: ParameterType = {},
    {
      ...props
    }: QueryInfiniteCallback<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      TQueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useInfiniteQuery(
      [
        QUERY_KEY_STRING,
        {
          cursor: { id, value, standardColumn, orderBy },
          take
        }
      ],
      fetchData,
      {
        getNextPageParam: (data, datas) => {
          return isNextCursor(data.data.cursor, datas);
        },
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}
