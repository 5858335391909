import { Suspense } from 'react';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import { ContractTermsType } from '../../types/contract-terms';
import { ContractTermsDetail } from '../../components/features/contract-terms/ContreactTermsDetail';

export default function ArrangeCallPrivacy() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'어레인지 콜 개인정보 처리방침'}></Header>
        <ContractTermsDetail type={ContractTermsType.ARRANGE_CALL_PRIVACY} />
      </Suspense>
    </DefaultTemplate>
  );
}
