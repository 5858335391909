import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { ArrangeProductSubscribeKind } from '@aimpact-korea/arrange-front-types';
import { FetchArrangeProductSubscribeByStore } from './fetch-arrange-product-subscribe-by-store';
import { FetchStores } from '../store';

export namespace CreateArrangeProductSubscribeByStore {
  export type RequestType = {
    storeId: number;
    arrangeProductSubscribeKind: ArrangeProductSubscribeKind;
    endDate: string;
    orderPossibleCount: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const arrangeProductSubscribeByStore =
      FetchArrangeProductSubscribeByStore.useRefetchData();

    const { refetchByStore } = FetchStores.useModify();

    const mutation = useMutation(
      ({
        arrangeProductSubscribeKind,
        endDate,
        storeId,
        orderPossibleCount
      }) => {
        return axios.post(`/arrange-product-subscribe/store/${storeId}`, {
          arrangeProductSubscribeKind,
          endDate,
          orderPossibleCount
        });
      },
      {
        onSuccess: (...rest) => {
          arrangeProductSubscribeByStore.refetchByStore(rest[1].storeId);
          refetchByStore(rest[1].storeId);
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
