import { ArrangeProductSubscribeKind } from '@aimpact-korea/arrange-front-types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchStores } from '../store';
import { FetchArrangeProductSubscribeByStore } from './fetch-arrange-product-subscribe-by-store';

export namespace PatchArrangeProductSubscribeByStore {
  export type RequestType = {
    id: number;
    endDate: string;
    storeId: number;
    arrangeProductSubscribeKind: ArrangeProductSubscribeKind;
    orderPossibleCount: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const arrangeProductSubscribeByStore =
      FetchArrangeProductSubscribeByStore.useRefetchData();
    const { refetchByStore } = FetchStores.useModify();

    const mutation = useMutation(
      ({ endDate, id, arrangeProductSubscribeKind, orderPossibleCount }) => {
        return axios.patch(`/arrange-product-subscribe/${id}`, {
          endDate,
          arrangeProductSubscribeKind,
          orderPossibleCount
        });
      },
      {
        onSuccess: (...rest) => {
          arrangeProductSubscribeByStore.refetchByStore(rest[1].storeId);
          refetchByStore(rest[1].storeId);
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
