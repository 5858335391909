import { State } from '.';

export enum ActionKind {
  SET_TYPE = 'SET_TYPE'
}

export interface SetState {
  type: ActionKind.SET_TYPE;
  payload: State['type'];
}

export type Actions = SetState;
