import { State } from '.';
import { ActionKind, Actions } from './actions';

export function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case ActionKind.SET_SIZE: {
      return {
        ...state,
        size: action.payload
      };
    }
    default:
      state;
  }
  return state;
}
