import { Suspense } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/atoms/Button';
import ListSkeleton from '../../../components/molecules/ListSkeleton';
import Table from '../../../components/molecules/Table';
import Header from '../../../components/organisms/Header';
import DefaultTemplate from '../../../components/templates/Default';
import { FetchNoticeList } from '../../../hooks/http/notice/fetch-notice-list';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export default function ArrangeNoticePage() {
  const navigate = useNavigate();

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'어레인지 공지사항'}>
          <Button
            text="등록"
            onClick={() => navigate('/notice/arrange/create')}
          />
        </Header>
        <ArrangeNoticePageWrapper />
      </Suspense>
    </DefaultTemplate>
  );
}

function ArrangeNoticePageWrapper() {
  const fetchNoticeList = FetchNoticeList.useFetch();

  const fetchNoticeListData = fetchNoticeList.data?.data ?? [];

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Table.Head.Row.Cell className="col-1" tag="th">
            No.
          </Table.Head.Row.Cell>
          <Table.Head.Row.Cell className="col-9" tag="th">
            제목
          </Table.Head.Row.Cell>
          <Table.Head.Row.Cell className="col-2" tag="th">
            생성날짜
            <br />
            수정날짜
          </Table.Head.Row.Cell>
        </Table.Head.Row>
      </Table.Head>
      <Table.Body>
        {fetchNoticeListData.map((notice) => (
          <Table.Body.Row key={notice.id}>
            <Table.Body.Row.Cell alignType="center">
              {notice.id}
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell>
              <Link to={`/notice/arrange/${notice.id}`}>{notice.title}</Link>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              {dayjs(notice.createdAt * 1000).format('YYYY-MM-DD HH:mm:ss')}
              <br />
              {dayjs(notice.updatedAt * 1000).format('YYYY-MM-DD HH:mm:ss')}
            </Table.Body.Row.Cell>
          </Table.Body.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
