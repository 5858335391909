import React, { Dispatch } from 'react';
import { Actions } from './actions';

export interface State {
  type: 'table' | 'simple-table';
}

type ContextType = {
  state: State;
  dispatch: Dispatch<Actions>;
};

export const initState: State = {
  type: 'table'
};

export const TableContext = React.createContext<ContextType | undefined>(
  undefined
);

export const useContextApi = (): ContextType => {
  const context = React.useContext(TableContext);

  if (!context) {
    throw new Error('useContextApi error');
  }

  return context;
};

export * from './actions';
export * from './reducer';
