import React, { Dispatch } from 'react';
import { Actions } from './actions';

export interface State {
  size: number[];
}

export const initState: State = {
  size: []
};

type ContextType = {
  state: State;
  dispatch: Dispatch<Actions>;
};

export const DivTableContext = React.createContext<ContextType | undefined>(
  undefined
);

export const useContextApi = (): ContextType => {
  const context = React.useContext(DivTableContext);

  if (!context) {
    throw new Error('useContextApi error');
  }

  return context;
};

export * from './actions';
export * from './reducer';
