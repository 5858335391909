import classNames from 'classnames/bind';
import React, { Suspense, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ListSkeleton from '../../../../components/molecules/ListSkeleton';
import Header from '../../../../components/organisms/Header';
import InputSearch from '../../../../components/organisms/InputSearch';
import DefaultTemplate from '../../../../components/templates/Default';
import { useLocationSearchQueryParam } from '../../../../hooks/useLocationSearchQueryParam';
import { Type } from '../../../../utils/decorators';
import styles from './index.module.scss';
import qs from 'qs';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  LinkfarmProductsRes,
  useLinkfarmProducts
} from '../../../../hooks/http/linkfarm/product/fetch-linkfarm-product';
import Table from '../../../../components/molecules/Table';
import Text from '../../../../components/atoms/Text';
import List from '../../../../components/molecules/List';
import Flex from '../../../../components/molecules/Flex';
import ReadMore from '../../../../components/molecules/ReadMore';
import Button from '../../../../components/atoms/Button';
import DropdownMenu from '../../../../components/molecules/DropdownMenu';
import {
  LinkfarmProducInspectiontStats,
  LinkfarmProductTaxType
} from '../../../../types/linkfarm/product';
import { useModifyLinkfarmProductMutate } from '../../../../hooks/http/linkfarm/product/modify-linkfarm-product';
import Modal from '../../../../components/molecules/Modal';
import { MutateArrangeAppForceSignin } from '../../../../hooks/http/store';
import { ColorType } from '../../../../types/style';
import Textarea from '../../../../components/atoms/Textarea';
import Selection from '../../../../components/molecules/Selection';
import { MessageIcon } from '@aimpact-korea/arrange-front-icons/build/icons';
import { LinkfarmProductType } from '@aimpact-korea/arrange-front-types';

const cx = classNames.bind(styles);

export class LocationQuery {
  @Type(() => String)
  keyword?: string;
}

const InspectionLinkfarmProducts: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationQuery = useLocationSearchQueryParam(LocationQuery);

  const currentRedirectFromParam = ({ keyword }: { keyword?: string }) => {
    const queryStringObject = {
      ...locationQuery,
      keyword: keyword || undefined
    };

    navigate(`${location.pathname}?${qs.stringify(queryStringObject)}`);
  };

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'검수중인 쇼핑몰 상품'}>
          <div className={cx('header-container')}>
            <InputSearch
              placeholder="상품검색"
              onClickSearch={(text) =>
                currentRedirectFromParam({ keyword: text })
              }
              defaultValue={locationQuery.keyword}
            />
          </div>
        </Header>

        <InspectionLinkfarmProductData keyword={locationQuery.keyword} />
      </Suspense>
    </DefaultTemplate>
  );
};

export default InspectionLinkfarmProducts;

function InspectionLinkfarmProductData({ keyword }: { keyword?: string }) {
  const query = useLinkfarmProducts({
    keyword,
    linkfarmProducInspectiontStatses: [
      LinkfarmProducInspectiontStats.FAILED,
      LinkfarmProducInspectiontStats.REQUEST
    ],
    take: 20,
    cursor: {
      standardColumn: 'inspectiontRequestAt',
      orderBy: 'DESC'
    },
    linkfarmProductType: LinkfarmProductType.PAYMENT_GATEWAY
  });

  return (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={query
        .data!.pages.map((page) => page.data.linkfarmProducts.length)
        .reduce((sum, current) => sum + current, 0)}
      next={query.fetchNextPage}
      hasMore={query.hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              No.
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체명 / 대표자
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              상품명 / 상품문구
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              상품옵션
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              신청일
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              상품보기
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              승인여부
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              승인거부 코멘트
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {query.data?.pages.map((group) =>
            group.data.linkfarmProducts.map((linkfarmProduct) => (
              <InspectionLinkfarmProductDataRow
                key={linkfarmProduct.id}
                linkfarmProduct={linkfarmProduct}
              />
            ))
          )}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
}

const LINKFARM_PRODUCT_INSPECTION_STATES: {
  text: string;
  state: LinkfarmProducInspectiontStats;
  color: ColorType;
}[] = [
  {
    text: '승인',
    state: LinkfarmProducInspectiontStats.SUCCESS,
    color: 'primary-color'
  },
  {
    text: '검수중',
    state: LinkfarmProducInspectiontStats.REQUEST,
    color: 'black'
  },
  {
    text: '승인불가',
    state: LinkfarmProducInspectiontStats.FAILED,
    color: 'secondary-color'
  }
];

function InspectionLinkfarmProductDataRow({
  linkfarmProduct
}: {
  linkfarmProduct: LinkfarmProductsRes['linkfarmProducts'][number];
}) {
  const [
    isActiveLinkfarmProductInspectionState,
    setActiveLinkfarmProductInspectionState
  ] = useState(false);
  const [inspectiontStatus, setInspectiontStatus] =
    useState<LinkfarmProducInspectiontStats>(linkfarmProduct.inspectiontStatus);

  const [isFailedCauseInputModal, setFailedCauseInputModal] = useState(false);
  const [failedCauseInput, setFailedCauseInput] = useState('');

  const [isFailedCauseModal, setFailedCauseModal] = useState(false);
  const [failedCause, setFailedCause] = useState('');

  const [isShowTaxSelectModal, setShowTaxSelectModal] = useState(false);
  const [taxType, setTaxType] = useState<LinkfarmProductTaxType>(
    LinkfarmProductTaxType.TAX_FREE
  );

  const { mutate } = useModifyLinkfarmProductMutate();

  const mutateArrangeAppForceSignin = MutateArrangeAppForceSignin.useMutate({
    onSuccess: (response) => {
      const token = response.data;

      window.open(
        (process.env.REACT_APP_ARRANGE_WEB_HOST as string) +
          `/me/products/store-connected?barofarmProductId=${
            linkfarmProduct.id
          }&access_token=${encodeURIComponent(token.accessToken)}`,
        '_blank'
      );
    }
  });

  function onClickLinkfarmProduct() {
    mutateArrangeAppForceSignin.mutate({ storeId: linkfarmProduct.store.id });
  }

  function onSelectLinkfarmProductInspection(value: string) {
    const state = value as LinkfarmProducInspectiontStats;

    switch (state) {
      case LinkfarmProducInspectiontStats.FAILED:
        setFailedCauseInput('');
        setFailedCauseInputModal(true);
        break;
      case LinkfarmProducInspectiontStats.SUCCESS:
        setShowTaxSelectModal(true);
        break;
    }
  }

  function onClickApproveLinkfarmProduct() {
    mutate({
      linkfarmProductId: linkfarmProduct.id,
      inspectiontStatus: LinkfarmProducInspectiontStats.SUCCESS,
      taxType: taxType
    });
    setInspectiontStatus(LinkfarmProducInspectiontStats.SUCCESS);

    setShowTaxSelectModal(false);
    setTaxType(LinkfarmProductTaxType.TAX);
  }

  function onClickLinkfarmProductInspectionFail() {
    if (!failedCauseInput) {
      alert('승인거절 사유를 적어주세요.');
      return;
    }

    mutate({
      linkfarmProductId: linkfarmProduct.id,
      inspectiontRequestFailReason: failedCauseInput,
      inspectiontStatus: LinkfarmProducInspectiontStats.FAILED
    });
    setInspectiontStatus(LinkfarmProducInspectiontStats.FAILED);

    setFailedCauseInputModal(false);
  }

  function getLinkfarmProductInspectionChangeState(
    state: LinkfarmProducInspectiontStats
  ) {
    switch (state) {
      case LinkfarmProducInspectiontStats.FAILED:
        return [LinkfarmProducInspectiontStats.FAILED];
      case LinkfarmProducInspectiontStats.REQUEST:
        return [
          LinkfarmProducInspectiontStats.REQUEST,
          LinkfarmProducInspectiontStats.SUCCESS,
          LinkfarmProducInspectiontStats.FAILED
        ];
      case LinkfarmProducInspectiontStats.SUCCESS:
        return [LinkfarmProducInspectiontStats.SUCCESS];
    }
  }

  return (
    <React.Fragment>
      {isFailedCauseInputModal && (
        <Modal
          sizeType="large"
          isOpen={isFailedCauseInputModal}
          handleClose={() => setFailedCauseInputModal(false)}
        >
          <Modal.Header>
            <Modal.Header.Title text="거절사유" />
          </Modal.Header>
          <Modal.Body>
            <Textarea
              style={{ width: '100%', height: '400px' }}
              placeholder="거절사유를 입력해주세요."
              value={failedCauseInput}
              onChange={(event) => setFailedCauseInput(event.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Flex direction="row" style={{ width: '100%' }} gap="xsmall">
              <Button
                fullwidth
                text="닫기"
                onClick={() => setFailedCauseInputModal(false)}
              />
              <Button
                fullwidth
                text="승인거절"
                onClick={() => onClickLinkfarmProductInspectionFail()}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
      {isShowTaxSelectModal && (
        <Modal
          sizeType="small"
          isOpen={isShowTaxSelectModal}
          handleClose={() => setShowTaxSelectModal(false)}
        >
          <Modal.Header>
            <Modal.Header.Title text="과세 구분" />
          </Modal.Header>
          <Modal.Body>
            <Flex
              direction="row"
              style={{ width: '100%' }}
              alignItems="center"
              justifyContent="center"
              gap="small"
            >
              <Selection>
                <Selection.Input
                  value={LinkfarmProductTaxType.TAX_FREE}
                  type="radio"
                  name="tax"
                  id="tax_id_free"
                  isChecked={taxType === LinkfarmProductTaxType.TAX_FREE}
                  onChangeChecked={() =>
                    setTaxType(LinkfarmProductTaxType.TAX_FREE)
                  }
                ></Selection.Input>
                <Selection.Label htmlFor="tax_id_free">
                  <Text>면세</Text>
                </Selection.Label>
              </Selection>
              <Selection>
                <Selection.Input
                  value={LinkfarmProductTaxType.TAX}
                  type="radio"
                  name="tax"
                  id="tax_id_tax"
                  isChecked={taxType === LinkfarmProductTaxType.TAX}
                  onChangeChecked={() => setTaxType(LinkfarmProductTaxType.TAX)}
                ></Selection.Input>
                <Selection.Label htmlFor="tax_id_tax">
                  <Text>과세</Text>
                </Selection.Label>
              </Selection>
            </Flex>
          </Modal.Body>
          <Modal.Footer>
            <Flex direction="row" style={{ width: '100%' }} gap="xsmall">
              <Button
                fullwidth
                text="닫기"
                onClick={() => setShowTaxSelectModal(false)}
              />
              <Button
                fullwidth
                text="승인"
                onClick={() => onClickApproveLinkfarmProduct()}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
      {isFailedCauseModal && (
        <Modal
          sizeType="large"
          isOpen={isFailedCauseModal}
          handleClose={() => setFailedCauseModal(false)}
        >
          <Modal.Header>
            <Modal.Header.Title text="거절사유" />
          </Modal.Header>
          <Modal.Body>
            <Textarea
              style={{ width: '100%', height: '400px' }}
              placeholder="거절 사유."
              value={failedCause}
              readOnly={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <Flex direction="row" style={{ width: '100%' }} gap="xsmall">
              <Button
                fullwidth
                text="확인"
                onClick={() => setFailedCauseModal(false)}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
      <Table.Body.Row>
        <Table.Body.Row.Cell alignType="center">
          <Text size="small" isBold={true}>
            No. {linkfarmProduct.id}
          </Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="left">
          <Flex gap="large" direction="column" alignItems="center">
            <Text isBold={true}>{linkfarmProduct.store.company}</Text>
            <Text>{linkfarmProduct.store.ceoName}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="large" direction="column" alignItems="center">
            <Text>{linkfarmProduct.product.name}</Text>
            <Text>{linkfarmProduct.storeTitle}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <ProductOption options={linkfarmProduct.product.options} />
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Text>{linkfarmProduct.inspectiontRequestAt}</Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Button
            text="보기"
            size="medium"
            onClick={() => onClickLinkfarmProduct()}
          />
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <DropdownMenu
            isActive={isActiveLinkfarmProductInspectionState}
            setActive={setActiveLinkfarmProductInspectionState}
            setValue={onSelectLinkfarmProductInspection}
            value={inspectiontStatus}
          >
            <DropdownMenu.Title>
              <DropdownMenu.Title.UpDownIcon />
              <DropdownMenu.Title.Text placeholder="" />
            </DropdownMenu.Title>
            <DropdownMenu.ItemWrapper alignType="center" size="small">
              <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                {LINKFARM_PRODUCT_INSPECTION_STATES.filter((state) =>
                  getLinkfarmProductInspectionChangeState(
                    linkfarmProduct.inspectiontStatus
                  ).some((s) => state.state === s)
                ).map((state) => (
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                    key={state.state}
                    value={state.state}
                    colorType={state.color}
                    className={cx('setting-dropdown-item')}
                  >
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                      text={state.text}
                    />
                  </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                ))}
              </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
            </DropdownMenu.ItemWrapper>
          </DropdownMenu>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          {linkfarmProduct.inspectiontRequestFailReason && (
            <Text
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (linkfarmProduct.inspectiontRequestFailReason) {
                  setFailedCause(linkfarmProduct.inspectiontRequestFailReason);
                  setFailedCauseModal(true);
                }
              }}
            >
              <MessageIcon />
            </Text>
          )}
        </Table.Body.Row.Cell>
      </Table.Body.Row>
    </React.Fragment>
  );
}

interface ProductOptionProps {
  options: LinkfarmProductsRes['linkfarmProducts'][number]['product']['options'];
  cutLine?: number;
}

const ProductOption: React.FC<ProductOptionProps> = ({
  options,
  cutLine = 5
}) => {
  const [readmoreTotalItem, setReadmoreTotalItem] = useState(0);
  const [readmore, setReadmore] = useState(false);

  return (
    <ReadMore setReadMore={setReadmore} readMore={readmore}>
      <List gap="small">
        <ReadMore.ItemWrapper
          cutline={cutLine}
          onChangeItem={(count) => setReadmoreTotalItem(count)}
        >
          {options.map((option) => (
            <div key={option.name} className="flex">
              <Text>{option.name}</Text>
            </div>
          ))}
        </ReadMore.ItemWrapper>
        {!readmore && readmoreTotalItem > cutLine && (
          <Text
            onClick={() => setReadmore(true)}
            colorType="gray-color"
            style={{ cursor: 'pointer' }}
          >
            + {readmoreTotalItem - cutLine}개의 상품 옵션
          </Text>
        )}
        {readmore && (
          <Text
            colorType="gray-color"
            style={{ cursor: 'pointer' }}
            onClick={() => setReadmore(false)}
          >
            숨기기
          </Text>
        )}
      </List>
    </ReadMore>
  );
};
