import classNames from 'classnames/bind';
import produce from 'immer';
import { useCallback, useState } from 'react';
import { AddStopWordName } from '../../../hooks/http/stop-word/add-stop-word-name';
import { FetchStopWordName } from '../../../hooks/http/stop-word/fetch-stop-word-name';
import { RemoveStopWordName } from '../../../hooks/http/stop-word/remove-stop-word-name';
import { StopWordType } from '../../../types/stop-word';
import Button from '../../atoms/Button';
import Flex from '../../molecules/Flex';
import TextInput from '../../molecules/TextInput';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

type Props = {
  type: StopWordType;
};
function StopWordComponent({ type }: Props) {
  const [selectStopWords, setSelectStopWords] = useState<string[]>([]);
  const [stopWordInput, setStopWordInput] = useState('');

  const fetchStopWordsQuery = FetchStopWordName.useFetchQuery({
    type: type
  });
  const removeStopWordMutation = RemoveStopWordName.useMutateQuery({
    onSuccess: () => {
      setSelectStopWords([]);
    }
  });
  const addStopWordMutation = AddStopWordName.useMutateQuery({
    onSettled: () => {
      setStopWordInput('');
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const stopWords = fetchStopWordsQuery.data!.data;

  const hasActiveStopWord = useCallback(
    (text: string) => {
      return selectStopWords.some((stopWord) => stopWord === text);
    },
    [selectStopWords]
  );

  function onClickStopWord(stopWord: string) {
    if (!hasActiveStopWord(stopWord)) {
      setSelectStopWords(
        produce(selectStopWords, (draft) => {
          draft.push(stopWord);
        })
      );
    } else {
      setSelectStopWords(
        produce(selectStopWords, (draft) => {
          let findIndex = -1;
          while (
            (findIndex = draft.findIndex((sw) => sw === stopWord)) !== -1
          ) {
            draft.splice(findIndex, 1);
          }
        })
      );
    }
  }

  function onClickRemoveStopWordBySelected() {
    if (!selectStopWords.length) {
      alert('삭제할 불용어 리스트를 클릭해주세요.');
      return;
    }

    if (confirm(`${selectStopWords.length}개의 불용어를 삭제하시겠습니까?`)) {
      selectStopWords.map((selectStopWord) => {
        removeStopWordMutation.mutate({
          word: selectStopWord,
          type
        });
      });
    }
  }

  function onClickAddStopWord() {
    if (!stopWordInput) {
      return;
    }

    addStopWordMutation.mutate({ word: stopWordInput, type });
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap="medium"
    >
      <Flex direction="row" gap="small">
        <TextInput isContour={true}>
          <TextInput.Head>
            <TextInput.Head.Text text="불용어" />
          </TextInput.Head>
          <TextInput.Body>
            <TextInput.Body.Input
              type="text"
              value={stopWordInput}
              onChange={(event) => setStopWordInput(event.target.value)}
            />
            <TextInput.Body.ClearIcon />
          </TextInput.Body>
        </TextInput>
        <Button
          size="medium"
          text="추가"
          isLoading={removeStopWordMutation.isLoading}
          onClick={() => onClickAddStopWord()}
        />
      </Flex>
      <Flex
        direction="column"
        gap="medium"
        style={{ height: '400px', width: '512px' }}
      >
        <Flex
          style={{
            overflow: 'scroll',
            border: '1px solid black',
            borderRadius: '4px'
          }}
          gap="xsmall"
          alignItems="center"
        >
          {stopWords.words
            .slice()
            .reverse()
            .map((stopWord, index) => (
              <div
                className={cx('stop-word', {
                  active: hasActiveStopWord(stopWord)
                })}
                style={{ width: '100%', textAlign: 'center' }}
                key={index}
                onClick={() => onClickStopWord(stopWord)}
              >
                {stopWord}
              </div>
            ))}
        </Flex>
        <Flex alignItems="end">
          <Button
            onClick={() => onClickRemoveStopWordBySelected()}
            text="삭제"
            size="small"
            type="secondary-color"
            isLoading={removeStopWordMutation.isLoading}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default StopWordComponent;
