import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import { useState } from 'react';
import Button from '../../atoms/Button';
import DropdownMenu from '../../molecules/DropdownMenu';
import Modal from '../../molecules/Modal';
import Table from '../../molecules/Table';
import TextInput from '../../molecules/TextInput';

function CreateAndroidAppVersionModal({
  isOpen,
  handleSave,
  handleClose,
  isLoading
}: {
  isOpen: boolean;
  handleSave: (arrangeManualGroup: {
    androidAppType: 'ARRANGE' | 'ARRANGE_PLUS';
    version: string;
  }) => void;
  handleClose: () => void;
  isLoading?: boolean;
}) {
  const [androidMessageApp, setAndroidMessageApp] = useState<{
    androidAppType: 'ARRANGE' | 'ARRANGE_PLUS';
    version: string;
  }>({
    androidAppType: 'ARRANGE',
    version: ''
  });

  return (
    <Modal sizeType="large" isOpen={isOpen} handleClose={() => handleClose()}>
      <Modal.Header>
        <Modal.Header.Title text="앱 버전 추가" />
      </Modal.Header>
      <Modal.Body>
        <Table type="simple-table">
          <Table.Body>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">앱 타입</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <DropdownMenu
                  setValue={(value) =>
                    setAndroidMessageApp((old) => ({
                      ...old,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      androidAppType: value as any
                    }))
                  }
                  value={androidMessageApp.androidAppType}
                >
                  <DropdownMenu.Title isBottomLine={true}>
                    <DropdownMenu.Title.Text placeholder="구독상품을 선택해주세요."></DropdownMenu.Title.Text>
                  </DropdownMenu.Title>
                  <DropdownMenu.ItemWrapper alignType="center" size="small">
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                        value={'ARRANGE'}
                      >
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                          text={'ARRANGE'}
                        />
                      </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                        value={'ARRANGE_PLUS'}
                      >
                        <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                          text={'ARRANGE_PLUS'}
                        />
                      </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                    </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                  </DropdownMenu.ItemWrapper>
                </DropdownMenu>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">앱 버전</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={androidMessageApp.version}
                      onChange={(e) =>
                        setAndroidMessageApp({
                          ...androidMessageApp,
                          version: e.target.value
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          flexDirection="flex-row"
          width="w-full"
          gapSize={StyleBuilderInstane.toGapSpaceType('gap-xxs')}
        >
          <Button
            text="취소"
            type="secondary-color"
            fullwidth={true}
            onClick={() => handleClose()}
            isLoading={isLoading}
          />
          <Button
            text="저장"
            fullwidth={true}
            onClick={() => handleSave(androidMessageApp)}
            isLoading={isLoading}
          />
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateAndroidAppVersionModal;
