import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { arrangeAppApi } from '../../../utils/axios';

export namespace FetchNoticeDetail {
  export type ReqeustType = {
    noticeId: number;
  };

  export type ResponseType = {
    id: number;
    title: string;
    content: string;
    contentHtml: string;
    createdAt: number;
    updatedAt: number;
  };

  export const KEY_STRING = 'fetch-notice-detail' as const;

  export type QueryKeyType = [typeof KEY_STRING, ReqeustType];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    queryKey
  }: QueryFunctionContext<QueryKeyType2>) {
    const _queryKey = queryKey as QueryKeyType;

    return await arrangeAppApi.get<ResponseType>(
      `/notices/${_queryKey[1].noticeId}`
    );
  }

  export const useRefetchData = () => {
    const queryClient = useQueryClient();

    const refetch = (noticeId: number) => {
      queryClient.refetchQueries([KEY_STRING, { noticeId }]);
    };

    return { refetch };
  };

  export const useFetch = (
    { noticeId }: ReqeustType,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING, { noticeId }], fetchData, {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
