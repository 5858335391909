import {
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { FetchCouponPresetList } from './fetch-coupon-preset';

export namespace PatchCouponPresetIsEnabled {
  export type RequestType = {
    isEnabled: boolean;
    couponPresetId: number;
  };

  export const useMutate = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ isEnabled, couponPresetId }) => {
        return axios.patch(`/coupont-preset/${couponPresetId}/is-enabled`, {
          isEnabled
        });
      },
      {
        onSuccess: (...args) => {
          queryClient.refetchQueries({
            queryKey: [FetchCouponPresetList.KEY_STRING],
            stale: false
          });

          onSuccess?.(...args);
        },
        onError: (...args) => {
          if (args[0].isAxiosError) {
            alert(args[0].response?.data);
          }
          onError?.(...args);
        },
        ...options
      }
    );

    return mutation;
  };
}
