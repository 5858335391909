import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFilter<T extends Record<string, any>>(initState?: T) {
  const [filter, setFilter] = useState<T | undefined>(initState);

  const reset = () => {
    setFilter(initState);
  };

  return { reset, filter, setFilter };
}
