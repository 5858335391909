import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { SizeType } from '../../../types/style';
import styles from './index.module.scss';
import Body from './subcomponents/Body';
import Header from './subcomponents/Header';
import Footer from './subcomponents/Footer';
import { useSetRecoilState } from 'recoil';
import { bgFadeoutState } from '../../../recoil/atoms/global-state';
import OutsideClickHandler from 'react-outside-click-handler';
import { useCallback } from 'react';

const cx = classNames.bind(styles);

export interface Props {
  sizeType: Extract<SizeType, 'small' | 'large'>;
  children: React.ReactNode;
  isOpen: boolean;
  handleClose?: () => void;
  isHandleEscClose?: boolean;
}

type ModalComponent = React.FC<Props> & {
  Body: typeof Body;
  Header: typeof Header;
  Footer: typeof Footer;
};

const Modal: ModalComponent = ({
  sizeType,
  children,
  isOpen,
  isHandleEscClose = true,
  handleClose
}) => {
  const setBgFadeout = useSetRecoilState(bgFadeoutState);

  const handleCloseCallback = () => {
    if (isOpen) {
      handleClose?.();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setBgFadeout(true);
    } else {
      setBgFadeout(false);
    }

    return () => {
      setBgFadeout(false);
    };
  }, [isOpen, setBgFadeout]);

  const escHandleClose = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        handleClose?.();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (isHandleEscClose === true) {
      document.addEventListener('keydown', escHandleClose);
    }
    return () => {
      document.removeEventListener('keydown', escHandleClose);
    };
  }, [escHandleClose, isHandleEscClose]);

  return (
    <div style={{ position: 'absolute' }}>
      <OutsideClickHandler onOutsideClick={() => handleCloseCallback()}>
        <div
          className={cx(
            'container',
            `size--${sizeType}`,
            `${isOpen && 'open'}`
          )}
        >
          {children}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
export default Modal;
