import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import axios from '../../../utils/axios';

export namespace ForceSignin {
  export type Param = {
    accountId: number;
  };

  export type ResData = {
    accessToken: string;
    refreshToken: string;
  };

  export const useMutate = ({
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse<ResData>, AxiosError, Param> = {}) => {
    const mutation = useMutation(
      ({ accountId }) => {
        return axios.post(`/accounts/force-signin/${accountId}`);
      },
      {
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('존재하지 않는 유저입니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
