import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { FetchOrderSettles } from './fetch-order-settles';
export namespace SuccessOrderSettle {
  export type RequestType = {
    orderSettleId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { modify } = FetchOrderSettles.useModify();

    const mutation = useMutation(
      ({ orderSettleId }) => {
        return axios.patch(`/order-settles/${orderSettleId}/success-settle`);
      },
      {
        onSuccess: (...rest) => {
          modify({
            id: rest[1].orderSettleId,
            isSettle: true
          });
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
