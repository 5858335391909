import classNames from 'classnames/bind';
import Skeleton from '../Skeleton';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const ListSkeleton: React.FC = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <Skeleton viewBox="0 0 1608 32">
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H123C127.418 0 131 3.58172 131 8V24C131 28.4183 127.418 32 123 32H8C3.58172 32 0 28.4183 0 24V8Z"
            fill="#C4C4C4"
          />
          <path
            d="M1531 8C1531 3.58172 1534.58 0 1539 0H1600C1604.42 0 1608 3.58172 1608 8V24C1608 28.4183 1604.42 32 1600 32H1539C1534.58 32 1531 28.4183 1531 24V8Z"
            fill="#C4C4C4"
          />
          <path
            d="M1236 8C1236 3.58172 1239.58 0 1244 0H1515C1519.42 0 1523 3.58172 1523 8V24C1523 28.4183 1519.42 32 1515 32H1244C1239.58 32 1236 28.4183 1236 24V8Z"
            fill="#C4C4C4"
          />
        </Skeleton>
      </div>

      <div className={cx('body')}>
        <div className={cx('title')}>
          <Skeleton viewBox="0 0 1555 24">
            <path
              d="M0 8C0 3.58172 3.58172 0 8 0H51C55.4183 0 59 3.58172 59 8V16C59 20.4183 55.4183 24 51 24H8C3.58172 24 0 20.4183 0 16V8Z"
              fill="#C4C4C4"
            />
            <path
              d="M174 8C174 3.58172 177.582 0 182 0H284C288.418 0 292 3.58172 292 8V16C292 20.4183 288.418 24 284 24H182C177.582 24 174 20.4183 174 16V8Z"
              fill="#C4C4C4"
            />
            <path
              d="M446 8C446 3.58172 449.582 0 454 0H556C560.418 0 564 3.58172 564 8V16C564 20.4183 560.418 24 556 24H454C449.582 24 446 20.4183 446 16V8Z"
              fill="#C4C4C4"
            />
            <path
              d="M718 8C718 3.58172 721.582 0 726 0H828C832.418 0 836 3.58172 836 8V16C836 20.4183 832.418 24 828 24H726C721.582 24 718 20.4183 718 16V8Z"
              fill="#C4C4C4"
            />
            <path
              d="M952 8C952 3.58172 955.582 0 960 0H1003C1007.42 0 1011 3.58172 1011 8V16C1011 20.4183 1007.42 24 1003 24H960C955.582 24 952 20.4183 952 16V8Z"
              fill="#C4C4C4"
            />
            <path
              d="M1088 8C1088 3.58172 1091.58 0 1096 0H1139C1143.42 0 1147 3.58172 1147 8V16C1147 20.4183 1143.42 24 1139 24H1096C1091.58 24 1088 20.4183 1088 16V8Z"
              fill="#C4C4C4"
            />
            <path
              d="M1262 8C1262 3.58172 1265.58 0 1270 0H1372C1376.42 0 1380 3.58172 1380 8V16C1380 20.4183 1376.42 24 1372 24H1270C1265.58 24 1262 20.4183 1262 16V8Z"
              fill="#C4C4C4"
            />
            <path
              d="M1496 8C1496 3.58172 1499.58 0 1504 0H1547C1551.42 0 1555 3.58172 1555 8V16C1555 20.4183 1551.42 24 1547 24H1504C1499.58 24 1496 20.4183 1496 16V8Z"
              fill="#C4C4C4"
            />
          </Skeleton>
        </div>
        {Array.from(Array(8).keys()).map((i) => (
          <Item key={i} />
        ))}
      </div>
    </div>
  );
};

export default ListSkeleton;

const Item = () => {
  return (
    <div className={cx('content')}>
      <Skeleton viewBox="0 0 1555 64">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H51C55.4183 0 59 3.58172 59 8V16C59 20.4183 55.4183 24 51 24H8C3.58172 24 0 20.4183 0 16V8Z"
          fill="#C4C4C4"
        />
        <path
          d="M0 48C0 43.5817 3.58172 40 8 40H51C55.4183 40 59 43.5817 59 48V56C59 60.4183 55.4183 64 51 64H8C3.58172 64 0 60.4183 0 56V48Z"
          fill="#C4C4C4"
        />
        <path
          d="M174 8C174 3.58172 177.582 0 182 0H284C288.418 0 292 3.58172 292 8V16C292 20.4183 288.418 24 284 24H182C177.582 24 174 20.4183 174 16V8Z"
          fill="#C4C4C4"
        />
        <path
          d="M174 48C174 43.5817 177.582 40 182 40H284C288.418 40 292 43.5817 292 48V56C292 60.4183 288.418 64 284 64H182C177.582 64 174 60.4183 174 56V48Z"
          fill="#C4C4C4"
        />
        <path
          d="M446 8C446 3.58172 449.582 0 454 0H556C560.418 0 564 3.58172 564 8V16C564 20.4183 560.418 24 556 24H454C449.582 24 446 20.4183 446 16V8Z"
          fill="#C4C4C4"
        />
        <path
          d="M446 48C446 43.5817 449.582 40 454 40H556C560.418 40 564 43.5817 564 48V56C564 60.4183 560.418 64 556 64H454C449.582 64 446 60.4183 446 56V48Z"
          fill="#C4C4C4"
        />
        <path
          d="M718 8C718 3.58172 721.582 0 726 0H828C832.418 0 836 3.58172 836 8V16C836 20.4183 832.418 24 828 24H726C721.582 24 718 20.4183 718 16V8Z"
          fill="#C4C4C4"
        />
        <path
          d="M718 48C718 43.5817 721.582 40 726 40H828C832.418 40 836 43.5817 836 48V56C836 60.4183 832.418 64 828 64H726C721.582 64 718 60.4183 718 56V48Z"
          fill="#C4C4C4"
        />
        <path
          d="M952 8C952 3.58172 955.582 0 960 0H1003C1007.42 0 1011 3.58172 1011 8V16C1011 20.4183 1007.42 24 1003 24H960C955.582 24 952 20.4183 952 16V8Z"
          fill="#C4C4C4"
        />
        <path
          d="M952 48C952 43.5817 955.582 40 960 40H1003C1007.42 40 1011 43.5817 1011 48V56C1011 60.4183 1007.42 64 1003 64H960C955.582 64 952 60.4183 952 56V48Z"
          fill="#C4C4C4"
        />
        <path
          d="M1088 8C1088 3.58172 1091.58 0 1096 0H1139C1143.42 0 1147 3.58172 1147 8V16C1147 20.4183 1143.42 24 1139 24H1096C1091.58 24 1088 20.4183 1088 16V8Z"
          fill="#C4C4C4"
        />
        <path
          d="M1088 48C1088 43.5817 1091.58 40 1096 40H1139C1143.42 40 1147 43.5817 1147 48V56C1147 60.4183 1143.42 64 1139 64H1096C1091.58 64 1088 60.4183 1088 56V48Z"
          fill="#C4C4C4"
        />
        <path
          d="M1262 8C1262 3.58172 1265.58 0 1270 0H1372C1376.42 0 1380 3.58172 1380 8V16C1380 20.4183 1376.42 24 1372 24H1270C1265.58 24 1262 20.4183 1262 16V8Z"
          fill="#C4C4C4"
        />
        <path
          d="M1262 48C1262 43.5817 1265.58 40 1270 40H1372C1376.42 40 1380 43.5817 1380 48V56C1380 60.4183 1376.42 64 1372 64H1270C1265.58 64 1262 60.4183 1262 56V48Z"
          fill="#C4C4C4"
        />
        <path
          d="M1496 8C1496 3.58172 1499.58 0 1504 0H1547C1551.42 0 1555 3.58172 1555 8V16C1555 20.4183 1551.42 24 1547 24H1504C1499.58 24 1496 20.4183 1496 16V8Z"
          fill="#C4C4C4"
        />
        <path
          d="M1496 48C1496 43.5817 1499.58 40 1504 40H1547C1551.42 40 1555 43.5817 1555 48V56C1555 60.4183 1551.42 64 1547 64H1504C1499.58 64 1496 60.4183 1496 56V48Z"
          fill="#C4C4C4"
        />
      </Skeleton>
    </div>
  );
};
