import React, { Dispatch } from 'react';
import { SizeType } from '../../../../types/style';
import { Actions } from './actions';

export interface State {
  size: Extract<SizeType, 'large' | 'medium' | 'xxlarge'>;
  disabled: boolean;
  active: boolean;
  type: 'search' | 'default';
  onChangeValue?: (value: string) => void;
  isFocus?: boolean;
  widthType: 'full' | 'auto' | 'default';
}

export const initState: State = {
  size: 'medium',
  disabled: false,
  active: false,
  type: 'default',
  onChangeValue: undefined,
  isFocus: false,
  widthType: 'default'
};

type ContextType = {
  state: State;
  dispatch: Dispatch<Actions>;
};

export const TextInputContext = React.createContext<ContextType | undefined>(
  undefined
);

export const useContextApi = (): ContextType => {
  const context = React.useContext(TextInputContext);

  if (!context) {
    throw new Error('useContextApi error');
  }

  return context;
};

export * from './actions';
export * from './reducer';
