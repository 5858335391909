import { IconProps } from '@aimpact-korea/arrange-front-icons/build/icons';
import classNames from 'classnames/bind';
import styles from './label.module.scss';

const cx = classNames.bind(styles);

export interface Props {
  label: string;
  iconComponent?: React.FC<IconProps>;
  isBold?: boolean;
}

const Label: React.FC<Props> = ({
  label,
  iconComponent: IconComponent,
  isBold = false
}) => {
  return (
    <label className={`${cx('label', { bold: isBold })} font-size--small`}>
      {IconComponent && (
        <IconComponent width="16" height="16" className={cx('icon')} />
      )}
      {label}
    </label>
  );
};

export default Label;
