import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { useErrorHandler } from 'react-error-boundary';
import {
  ArrangeProductKindOfKind,
  ArrangeProductPaymentPg,
  ArrangeProductPaymentStatus,
  SortState
} from '@aimpact-korea/arrange-front-types';
import { ArrangeProductPaymentListSortStandard } from '../../../types/sort';
import { isNextCursor, QueryInfiniteCallback } from '..';
import qs from 'qs';

export namespace FetchArrangeProductPayment {
  export type ArrangeProductPaymentListSortStandardCursor = {
    id?: number;
    value?: string;
  } & SortState<ArrangeProductPaymentListSortStandard>;

  export type ResponseType = {
    cursor: ArrangeProductPaymentListSortStandardCursor | null;
    items: {
      id: number;
      arrangeProductPaymentStatus: ArrangeProductPaymentStatus;
      arrangeProductPaymentPg: ArrangeProductPaymentPg;
      paymentPrice: number;
      refundAt: string | null;
      payedAt: string | null;
      createdAt: string;
      arrangeProductKindOfKind: ArrangeProductKindOfKind;
      store: {
        id: number;
        company: string;
      };
    }[];
  };

  export type RequestType = {
    cursor?: ArrangeProductPaymentListSortStandardCursor | null;
    limit?: number;
  };

  export const KEY_STRING = 'fetch-arrange-product-payment' as const;

  export type QueryKeyType = [typeof KEY_STRING, RequestType];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    pageParam,
    queryKey
  }: QueryFunctionContext<
    QueryKeyType2,
    ArrangeProductPaymentListSortStandardCursor
  >) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _queryKey = queryKey as QueryKeyType;

    let cursor: ArrangeProductPaymentListSortStandardCursor | undefined =
      undefined;
    if (pageParam) {
      cursor = pageParam;
    } else if (_queryKey[1].cursor) {
      cursor = {};
      if (_queryKey[1].cursor.id) {
        cursor.id = _queryKey[1].cursor.id;
      }
      if (_queryKey[1].cursor.orderBy) {
        cursor.orderBy = _queryKey[1].cursor.orderBy;
      }
      if (_queryKey[1].cursor.standardColumn) {
        cursor.standardColumn = _queryKey[1].cursor.standardColumn;
      }
      if (_queryKey[1].cursor.value) {
        cursor.value = _queryKey[1].cursor.value;
      }
    }

    return await axios.get<ResponseType>(
      '/arrange-product-payment?' + qs.stringify({ cursor }),
      {
        params: {
          limit: _queryKey[1].limit
        }
      }
    );
  }

  export const useRefetchData = () => {
    const queryClient = useQueryClient();

    const refetch = () => {
      queryClient.refetchQueries([KEY_STRING]);
    };

    return { refetch };
  };

  export const useFetch = (
    { cursor, limit }: RequestType = {},
    {
      ...props
    }: QueryInfiniteCallback<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useInfiniteQuery([KEY_STRING, { cursor, limit }], fetchData, {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      getNextPageParam: (data, allData) => {
        return isNextCursor(data.data.cursor, allData);
      },
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
