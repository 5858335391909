import { StatusCodes } from 'http-status-codes';
import { UseInfiniteQueryOptions, QueryKey } from '@tanstack/react-query';
import { isAxiosError } from '../../utils/axios';

// @TODO 정식으로 useQueries에 Type checking이 정상적으로 돌아갈 때 교체
// https://github.com/tannerlinsley/react-query/issues/1675 github 이슈
// export type QueryCallback<
//   TQueryFnData,
//   TError,
//   TData = unknown,
//   TQueryKey extends QueryKey = QueryKey
// > = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;

export type QueryInfiniteCallback<
  TQueryFnData,
  TError,
  TData = unknown,
  TQueryKey extends QueryKey = QueryKey
> = UseInfiniteQueryOptions<
  TQueryFnData,
  TError,
  TData,
  TQueryFnData,
  TQueryKey
>;

export function isNextCursor(
  cursor: unknown,
  allDatas: { data: { cursor: unknown } }[]
): unknown {
  if (allDatas.length > 2) {
    return cursor !== allDatas[allDatas.length - 2].data.cursor && cursor;
  }

  return cursor ?? false;
}

export function retryFunction(failureCount: number, error: Error) {
  if (failureCount >= 2) {
    return false;
  }

  if (isAxiosError(error)) {
    if (
      error.response?.status === StatusCodes.NOT_FOUND ||
      error.response?.status === StatusCodes.BAD_REQUEST ||
      error.response?.status === StatusCodes.UNAUTHORIZED ||
      error.response?.status === StatusCodes.FORBIDDEN
    ) {
      return false;
    }
  }

  return true;
}

export type AimpactAxiosError = {
  statusCode: number;
  error: string;
  message: string;
  exceptionCode: string;
};
