import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchArrangeManualGroup } from './fetch-arrange-manual-group';

export namespace SwapOrderArrangeManualGroup {
  export type RequestType = {
    arrangeManualGroupId: number;
    targetArrangeManualGroupId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { refetch } = FetchArrangeManualGroup.useRefetchData();

    const mutation = useMutation(
      ({ arrangeManualGroupId, targetArrangeManualGroupId }) => {
        return axios.patch(
          `/arrange-manual/group/${arrangeManualGroupId}/swap-order`,
          {
            targetArrangeManualGroupId: targetArrangeManualGroupId
          }
        );
      },
      {
        onSuccess: (...rest) => {
          refetch();
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
