import React, { Suspense, useEffect, useState } from 'react';
import Text from '../../components/atoms/Text';
import Textarea from '../../components/atoms/Textarea';
import Flex from '../../components/molecules/Flex';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Selection from '../../components/molecules/Selection';
import Table from '../../components/molecules/Table';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import {
  DeleteMessageLogMutation,
  FetchMessageAnalysis,
  ModifyMessageLogMutation
} from '../../hooks/http/message-analysis';
import Pagination from '@material-ui/lab/Pagination';
import { Type } from '../../utils/decorators';
import { useLocationSearchQueryParam } from '../../hooks/useLocationSearchQueryParam';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Modal from '../../components/molecules/Modal';
import Button from '../../components/atoms/Button';
import MultiDropdownMenu from '../../components/molecules/MultiDropdownMenu';
import Hr from '../../components/atoms/Hr';
import ClipboardCopy from '../../components/molecules/ClipboardCopy';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  FilterIcon,
  MessageIcon,
  TrashIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import { FetchStopWordName } from '../../hooks/http/stop-word/fetch-stop-word-name';
import { AddStopWordName } from '../../hooks/http/stop-word/add-stop-word-name';
import { RemoveStopWordName } from '../../hooks/http/stop-word/remove-stop-word-name';
import { StopWordType } from '../../types/stop-word';

const USED_CASE_ITEMDS = [
  {
    text: '전체' as const,
    value: 'all' as const,
    colorType: 'primary-color' as const,
    checked: true
  },
  {
    text: '성공' as const,
    value: 'isSuccess' as const,
    colorType: 'primary-color' as const,
    checked: false
  },
  {
    text: '실패' as const,
    value: 'isFail' as const,
    colorType: 'secondary-color' as const,
    checked: false
  }
];

export class LocationQuery {
  @Type(() => Number)
  pageNo?: number;
}

const MessageAnalysisLogsPage: React.FC = () => {
  const locationQuery = useLocationSearchQueryParam(LocationQuery);

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'메세지 분석 리스트'}></Header>
        <MessageAnalysisDataWrapper pageNo={locationQuery.pageNo} />
      </Suspense>
    </DefaultTemplate>
  );
};

export default MessageAnalysisLogsPage;

interface SearchFilter {
  pageNo?: number;
  take?: number;
  states: ('SUCCESS' | 'FAIL' | 'UN_SELECT')[];
}

function MessageAnalysisDataWrapper(
  { pageNo = 1 }: { pageNo?: number } = { pageNo: 1 }
) {
  const [usedCaseItems, setUsedCaseItems] = useState(USED_CASE_ITEMDS);
  const [filter, setFilter] = useState<SearchFilter>({
    pageNo,
    take: 20,
    states: ['SUCCESS', 'FAIL', 'UN_SELECT']
  });

  const fetchMessageAnalysisQuery = FetchMessageAnalysis.useFetchQuery(filter);

  useEffect(() => {
    if (pageNo) {
      setFilter((filter) => ({
        ...filter,
        pageNo
      }));
    }
  }, [pageNo]);

  const navigate = useNavigate();

  const items = fetchMessageAnalysisQuery.data?.data.items ?? [];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pageMetaData = fetchMessageAnalysisQuery.data!.data.meta;

  function onChangePage(pageNo: number) {
    navigate({
      search: createSearchParams({ pageNo: `${pageNo}` }).toString()
    });
  }

  function onChangeUsedCaseItem() {
    const states: SearchFilter['states'] = [];

    const checkItems = usedCaseItems.filter(
      (usedCaseItem) => usedCaseItem.checked === true
    );

    if (checkItems.find((checkItem) => checkItem.value === 'all')) {
      states.push('FAIL');
      states.push('SUCCESS');
      states.push('UN_SELECT');
    }

    if (checkItems.find((checkItem) => checkItem.value === 'isSuccess')) {
      states.push('SUCCESS');
    }

    if (checkItems.find((checkItem) => checkItem.value === 'isFail')) {
      states.push('FAIL');
    }

    if (!checkItems.length) {
      states.push('UN_SELECT');
    }

    setFilter({
      ...filter,
      states
    });
  }

  return (
    <Flex style={{ width: '2300px', minWidth: '100%' }} alignItems="center">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              No.
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체명
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              원본메시지
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-4">
              고객
              <br />
              이름 / 불용어 / 연락처 / 주소
            </Table.Cell>
            {/* <Table.Cell tag="th" alignType="center" className="col-1">
              이름 / 불용어
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              연락처
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              주소
            </Table.Cell> */}
            <Table.Cell tag="th" alignType="center" className="col-1">
              <MultiDropdownMenu
                placeholder="케이스"
                IconComponent={FilterIcon}
                items={usedCaseItems}
                setChangeCheckedIndex={(idx, state) => {
                  usedCaseItems[idx].checked = state;

                  if (state && usedCaseItems[idx].value === 'all') {
                    usedCaseItems.forEach((usedCaseItem, index) => {
                      if (index !== idx) {
                        usedCaseItem.checked = false;
                      }
                    });
                  } else if (state) {
                    usedCaseItems.forEach((usedCaseItem) => {
                      if (usedCaseItem.value === 'all') {
                        usedCaseItem.checked = false;
                      }
                    });
                  }

                  setUsedCaseItems([...usedCaseItems]);
                }}
                handleClose={onChangeUsedCaseItem}
              />
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              메모
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              생성날짜
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              삭제
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body style={{ position: 'relative' }}>
          {items.map((item) => (
            <MessageAnalysisProcessData item={item} key={item.id} />
          ))}
        </Table.Body>
      </Table>
      <div>
        <Pagination
          count={pageMetaData.totalPages}
          defaultPage={1}
          page={pageMetaData.currentPage}
          boundaryCount={5}
          onChange={(_, page) => onChangePage(page)}
          variant="outlined"
        />
      </div>
    </Flex>
  );
}

function MessageAnalysisProcessData({
  item
}: {
  item: FetchMessageAnalysis.ResponseType['items'][number];
}) {
  const [isShowAllLogs, setShowAllLogs] = useState(false);

  function onToggleShowAllLog() {
    setShowAllLogs(!isShowAllLogs);
  }

  return (
    <React.Fragment>
      {item.items.map((itemData, index) =>
        isShowAllLogs ? (
          <MessageAnalysisData
            store={item.store}
            item={itemData}
            key={itemData.id}
          />
        ) : (
          index === 0 && (
            <MessageAnalysisData
              store={item.store}
              item={itemData}
              key={itemData.id}
            />
          )
        )
      )}
      {item.items.length > 1 && (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -30px)'
          }}
        >
          <div
            style={{
              display: 'inline-block',
              borderRadius: '100%',
              backgroundColor: '#c4c4c4',
              width: '30px',
              height: '30px',
              cursor: 'pointer'
            }}
            onClick={() => onToggleShowAllLog()}
          >
            {isShowAllLogs ? (
              <ChevronUpIcon width={30} height={30} colorType="text-gray-800" />
            ) : (
              <ChevronDownIcon
                width={30}
                height={30}
                colorType="text-gray-800"
              />
            )}
          </div>
        </div>
      )}
      <div style={{ position: 'absolute', height: '1px', width: '100%' }}>
        <Hr />
      </div>
    </React.Fragment>
  );
}

const stopWordType = StopWordType['namefinder.blacklist'];
function MessageAnalysisData({
  item,
  store
}: {
  item: FetchMessageAnalysis.ResponseType['items'][number]['items'][number];
  store: FetchMessageAnalysis.ResponseType['items'][number]['store'];
}) {
  const [isMemoModal, setMemoModal] = useState(false);

  const fetchStopWordsQuery = FetchStopWordName.useFetchQuery({
    type: stopWordType
  });
  const addStopWordMutation = AddStopWordName.useMutateQuery();
  const removeStopWordMutation = RemoveStopWordName.useMutateQuery();
  const deleteMessageLogMutation = DeleteMessageLogMutation.useMutateQuery();
  const modifyMessageLogMutation = ModifyMessageLogMutation.useMutateQuery({
    onSettled: () => {
      setMemoModal(false);
    }
  });

  const [memo, setMemo] = useState(item.memo ?? '');

  useEffect(() => {
    if (!isMemoModal) {
      setMemo(item.memo ?? '');
    }
  }, [isMemoModal, item.memo]);

  function onClickStopWordCheckbox(stopWord: string, checked: boolean) {
    if (checked) {
      addStopWordMutation.mutate({
        word: stopWord,
        type: stopWordType
      });
    } else {
      removeStopWordMutation.mutate({
        word: stopWord,
        type: stopWordType
      });
    }
  }

  function onChangeInterestLog(value: boolean) {
    modifyMessageLogMutation.mutate({
      id: item.id,
      isInterestLog: value
    });
  }

  function onClickShowMemoModal() {
    setMemoModal(true);
  }

  function onClickChangeMemo() {
    if (!memo) {
      alert('메모를 입력해주세요.');
      return;
    }

    modifyMessageLogMutation.mutate({
      id: item.id,
      memo
    });
  }

  function onClickDeleteMemo() {
    if (confirm('메세지 로그의 메모를 삭제 하시겠습니까?')) {
      modifyMessageLogMutation.mutate({
        id: item.id,
        memo: null
      });
    }
  }

  function onClickRemoveMessageAnalysisLog() {
    if (confirm('메세지 로그를 삭제하시겠습니까?')) {
      deleteMessageLogMutation.mutate({
        id: item.id
      });
    }
  }

  return (
    <React.Fragment>
      {isMemoModal && (
        <Modal
          isOpen={isMemoModal}
          sizeType="large"
          handleClose={() => setMemoModal(false)}
        >
          <Modal.Header>
            <Modal.Header.Title text="메모를 입력합니다" />
          </Modal.Header>
          <Modal.Body>
            <Textarea
              style={{ width: '100%', height: '320px' }}
              cols={5}
              placeholder="메모를 입력합니다."
              defaultValue={memo}
              onBlur={(event) => setMemo(event.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Flex direction="row" style={{ width: '100%' }} gap="xsmall">
              {item.memo && (
                <Button
                  text="삭제"
                  fullwidth={true}
                  type="secondary-color"
                  onClick={() => onClickDeleteMemo()}
                />
              )}
              <Button
                text="수정"
                fullwidth={true}
                onClick={() => onClickChangeMemo()}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
      <Table.Body.Row>
        <Table.Body.Row.Cell alignType="center">
          <Text size="small" isBold={true}>
            No. {item.id}
          </Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          {store?.company}
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="xsmall">
            <Flex
              direction="row"
              gap="medium"
              alignItems="center"
              justifyContent="center"
            >
              <Text>{item.request.customerName.name}</Text>
              <Text>{item.request.customerPhone.phone}</Text>
            </Flex>
            <Textarea
              style={{ width: '100%', height: '200px' }}
              readOnly={true}
              colorType="black"
              value={item.message}
            />
            <div style={{ display: 'inline-block' }}>
              <ClipboardCopy text={item.message}>
                <Button text="복사" size="small" fit={true} />
              </ClipboardCopy>
            </div>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="xsmall">
            <Flex
              alignItems="center"
              justifyContent="center"
              direction="row"
              gap="medium"
            >
              <Text isBold={true}>{item.result.customer.name.name}</Text>
              <Text isBold={true}>{item.result.customer.phone.phone}</Text>
            </Flex>
            <Hr />
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="start"
              gap="small"
            >
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap="medium"
              >
                {item.result.names.map((name, index) => (
                  <Selection key={index}>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      direction="row"
                      gap="small"
                    >
                      <Selection.Label htmlFor={name.name + index}>
                        {name.name === null ? (
                          <Text
                            size="medium"
                            colorType="secondary-color"
                            isBold={true}
                          >
                            항목없음
                          </Text>
                        ) : (
                          <Text size="medium" isBold={true}>
                            {name.name}
                          </Text>
                        )}
                      </Selection.Label>
                      <Selection.Input
                        id={name.name + index}
                        type="checkbox"
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        isChecked={fetchStopWordsQuery.data!.data.words.some(
                          (stopWord) => stopWord === name.name
                        )}
                        disabled={name.name === null}
                        onChangeChecked={() =>
                          onClickStopWordCheckbox(
                            name.name,
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            !fetchStopWordsQuery.data!.data.words.some(
                              (stopWord) => stopWord === name.name
                            )
                          )
                        }
                      ></Selection.Input>
                    </Flex>
                  </Selection>
                ))}
              </Flex>
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap="medium"
              >
                {item.result.phones.map((phone, index) => {
                  return phone.phone === null ? (
                    <Text
                      size="medium"
                      key={index}
                      colorType="secondary-color"
                      isBold={true}
                    >
                      항목없음
                    </Text>
                  ) : (
                    <Text size="medium" key={index} isBold={true}>
                      {phone.phone}
                    </Text>
                  );
                })}
              </Flex>
              <Flex
                direction="column"
                justifyContent="start"
                alignItems="center"
                gap="medium"
              >
                {item.result.addresses.map((address, index) => (
                  <Text size="medium" key={index} isBold={true}>
                    {address.zipcode && `(${address.zipcode}) `}
                    {address.address1}
                    {address.address2 && ` ${address.address2}`}
                  </Text>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="small">
            <Selection>
              <Selection.Input
                type="radio"
                id={`interest-success-${item.id}`}
                isChecked={
                  item.isInterestLog === null ? false : !item.isInterestLog
                }
                onChangeChecked={() => onChangeInterestLog(false)}
              />
              <Selection.Label htmlFor={`interest-success-${item.id}`}>
                성공
              </Selection.Label>
            </Selection>
            <Selection>
              <Selection.Input
                type="radio"
                id={`interest-fail-${item.id}`}
                isChecked={
                  item.isInterestLog === null ? false : item.isInterestLog
                }
                onChangeChecked={() => onChangeInterestLog(true)}
              />
              <Selection.Label htmlFor={`interest-fail-${item.id}`}>
                실패
              </Selection.Label>
            </Selection>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <div
            style={{ cursor: 'pointer', display: 'inline-block' }}
            onClick={() => onClickShowMemoModal()}
          >
            <MessageIcon
              colorType={item.memo ? 'text-primary-500' : 'text-black'}
            />
          </div>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Text>{item.createdAt}</Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <div
            style={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={() => onClickRemoveMessageAnalysisLog()}
          >
            <TrashIcon colorType="text-gray-500" />
          </div>
        </Table.Body.Row.Cell>
      </Table.Body.Row>
    </React.Fragment>
  );
}
