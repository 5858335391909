import { Bank } from '@aimpact-korea/arrange-front-types';
import {
  InfiniteData,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';
import { Optional } from 'utility-types';
import axios from '../../../utils/axios';
import produce from 'immer';
import { useErrorHandler } from 'react-error-boundary';
import { isNextCursor, QueryInfiniteCallback } from '..';

export namespace FetchResellerListApi {
  export enum FindResellerColumns {
    ACCOUNT_NAME = 'ACCOUNT_NAME',
    ACCOUNT_PHONE = 'ACCOUNT_PHONE',
    CHANNEL_NAME = 'CHANNEL_NAME'
  }

  export type ResponseType = {
    items: {
      id: number;
      channelName: string;
      instagramId: string | null;
      youtubeId: string | null;
      bankInfo: {
        bank: Bank;
        bankAccount: string;
        bankHolder: string;
      };
      createdAt: string;
      address: string;
      detailAddress: string;
      postCode: string;
      account: {
        id: number;
        name: string;
        phone: string;
      };
    }[];
    cursor: CursorType;
  };

  export type CursorType = {
    id?: number;
    standardColumn?: 'createdAt' | undefined;
    orderBy?: 'DESC' | 'ASC' | null | undefined;
    value?: string | number;
  };

  export type RequestType = {
    limit: number;
    cursor?: CursorType;
    findColumns?: FindResellerColumns[];
    keyword?: string;
    resellerId?: number;
  };

  export const KEY_STRING = 'fetch-reseller-list' as const;

  export type QueryKeyType = [typeof KEY_STRING, RequestType];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    pageParam,
    queryKey
  }: QueryFunctionContext<QueryKeyType2, CursorType>) {
    const _queryKey = queryKey as QueryKeyType;

    let cursor: CursorType | undefined = undefined;
    if (pageParam) {
      cursor = pageParam;
    } else if (_queryKey[1].cursor) {
      cursor = {};
      if (_queryKey[1].cursor.id) {
        cursor.id = _queryKey[1].cursor.id;
      }
      if (_queryKey[1].cursor.orderBy) {
        cursor.orderBy = _queryKey[1].cursor.orderBy;
      }
      if (_queryKey[1].cursor.standardColumn) {
        cursor.standardColumn = _queryKey[1].cursor.standardColumn;
      }
      if (_queryKey[1].cursor.value) {
        cursor.value = _queryKey[1].cursor.value;
      }
    }

    return await axios.get<ResponseType>(
      '/reseller?' + qs.stringify({ cursor, states: _queryKey[1].findColumns }),
      {
        params: {
          limit: _queryKey[1].limit,
          keyword: _queryKey[1].keyword || null,
          resellerId: _queryKey[1].resellerId || null
        }
      }
    );
  }

  export const useModify = () => {
    const queryClient = useQueryClient();

    const modify = (
      resellerProduct: Optional<ResponseType['items'][number]> & { id: number }
    ) => {
      queryClient.setQueriesData(
        [KEY_STRING],
        (previous: InfiniteData<AxiosResponse<ResponseType>> | undefined) => {
          const _previous = previous as InfiniteData<
            AxiosResponse<ResponseType>
          >;
          return produce(_previous, (draft) => {
            draft.pages = draft.pages.map((page) => {
              page.data.items = page.data.items.map((item) => {
                if (item.id === resellerProduct.id) {
                  return {
                    ...item,
                    ...resellerProduct
                  };
                }
                return item;
              });

              return page;
            });
          });
        }
      );
    };

    const remove = (resellerRequestId: number) => {
      queryClient.setQueriesData(
        [KEY_STRING],
        (previous: InfiniteData<AxiosResponse<ResponseType>> | undefined) => {
          const _previous = previous as InfiniteData<
            AxiosResponse<ResponseType>
          >;

          return produce(_previous, (draft) => {
            draft.pages = draft.pages.map((page) => {
              page.data.items = page.data.items.filter(
                (item) => item.id !== resellerRequestId
              );
              return page;
            });
          });
        }
      );
    };

    return { remove, modify };
  };

  export const useFetch = (
    {
      cursor: { id, orderBy, standardColumn, value } = {},
      limit = 15,
      findColumns,
      keyword,
      resellerId
    }: RequestType = { limit: 15 },
    {
      ...props
    }: QueryInfiniteCallback<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useInfiniteQuery(
      [
        KEY_STRING,
        {
          cursor: {
            id,
            orderBy,
            standardColumn,
            value
          },
          resellerId,
          limit,
          findColumns,
          keyword
        }
      ],
      fetchData,
      {
        getNextPageParam: (data, datas) => {
          return isNextCursor(data.data.cursor, datas);
        },
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}
