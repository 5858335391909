import classNames from 'classnames/bind';
import React, { useReducer } from 'react';
import { initState, reducer, TableContext } from './context';
import styles from './index.module.scss';
import Body from './subcomponents/Body';
import Cell from './subcomponents/Cell';
import Head from './subcomponents/Head';
import Row from './subcomponents/Row';

const cx = classNames.bind(styles);

export type Props = React.ComponentPropsWithoutRef<'table'> & {
  type?: 'table' | 'simple-table';
};

export interface TableComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLTableElement>
  > {
  Head: typeof Head;
  Body: typeof Body;
  Row: typeof Row;
  Cell: typeof Cell;
}

// eslint-disable-next-line react/display-name
const Table = React.forwardRef<HTMLTableElement, Props>(
  ({ children, className, type = 'table', ...props }, ref) => {
    const [state, dispatch] = useReducer(reducer, initState);

    return (
      <TableContext.Provider value={{ state, dispatch }}>
        <table
          className={`${cx('container', type + '_type')} ${className ?? ''}`}
          ref={ref}
          {...props}
        >
          {children}
        </table>
      </TableContext.Provider>
    );
  }
) as TableComponent;

Table.displayName = 'Table';

Table.Head = Head;
Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;
export default Table;
