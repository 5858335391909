import {
  DropdownUpIcon,
  DropdownDownIcon,
  DropdownIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import { useEffect, useState } from 'react';
import { Sort } from '../../../types/sort';

export interface Props {
  orderBy?: Sort | null;
}

type DropdownIconComponentType =
  | typeof DropdownUpIcon
  | typeof DropdownDownIcon
  | typeof DropdownIcon;

const getIconComponent = (orderBy?: Sort | null): DropdownIconComponentType => {
  switch (orderBy) {
    case 'ASC':
      return DropdownUpIcon;
    case 'DESC':
      return DropdownDownIcon;
    default:
      return DropdownIcon;
  }
};

const SortingIcon: React.FC<Props> = ({ orderBy }) => {
  const [Component, setComponent] = useState<DropdownIconComponentType>(
    getIconComponent(orderBy)
  );

  useEffect(() => {
    setComponent(getIconComponent(orderBy));
  }, [orderBy]);

  return <Component colorType="text-gray-800" width="24px" height="24px" />;
};

export default SortingIcon;
