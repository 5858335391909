import Text from '../../../atoms/Text';
import Flex from '../../../molecules/Flex';

export interface BoxProps {
  title: string;
  children?: React.ReactNode;
}

const Box: React.FC<BoxProps> = ({ children, title }) => {
  return (
    <Flex gap="xlarge">
      <Text colorType="gray-dark-color" isBold={true}>
        {title}
      </Text>
      {children}
    </Flex>
  );
};

export default Box;
