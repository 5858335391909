import { Drawer } from '@material-ui/core';
import styles from './index.module.scss';
import ArrangeLogoContent from '../../../assets/images/arrange-logo-content.png';
import MenuButton from '../../molecules/MenuButton';
import ActiveIcon from '../../molecules/ActiveIcon';
import classNames from 'classnames/bind';
import ActiveButton from '../../atoms/ActiveButton';
import Text from '../../atoms/Text';
import { useRecoilValue } from 'recoil';
import useSignout from '../../../hooks/useSignout';
import React from 'react';
import { menuTypeInfo, menuList } from '../../../recoil/selector/menu-type';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { DoorLogoutIcon } from '@aimpact-korea/arrange-front-icons/build/icons';

const cx = classNames.bind(styles);

const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const signout = useSignout();
  const queryClient = useQueryClient();

  const gMenuTypeInfo = useRecoilValue(menuTypeInfo);

  const onClickMenuButton = (link: string) => {
    queryClient.removeQueries();

    navigate(link);
    window.scrollTo(0, 0);
  };

  const onClickSignoutBtn = () => {
    signout();
  };

  return (
    <div className={cx('navbar')}>
      <Drawer
        className={cx('drawer')}
        variant="permanent"
        classes={{
          paper: cx('drawerPaper')
        }}
        anchor="left"
      >
        <img src={ArrangeLogoContent} className={cx('logo-img')} />
        <div className={cx('dashboard-container')}>
          <MenuButton
            key={'dash-board'}
            startIcon={
              menuList[0].iconComponent ? (
                <ActiveIcon
                  active={menuList[0].type === gMenuTypeInfo?.type}
                  IconComponent={menuList[0].iconComponent}
                />
              ) : undefined
            }
            active={menuList[0].type === gMenuTypeInfo?.type}
            label={menuList[0].name}
            onClick={() => onClickMenuButton(menuList[0].link)}
          />
        </div>
        <div className={cx('cs-container')}>
          <p className={cx('title')}>CS</p>
          {menuList.slice(1).map((menu) => (
            <React.Fragment key={menu.type}>
              <MenuButton
                startIcon={
                  menu.iconComponent ? (
                    <ActiveIcon
                      active={menu.type === gMenuTypeInfo?.type}
                      IconComponent={menu.iconComponent}
                    />
                  ) : undefined
                }
                active={menu.type === gMenuTypeInfo?.type}
                label={menu.name}
                onClick={() => onClickMenuButton(menu.link)}
                className={`${cx('menu-list-btn')}`}
              />
              {(menu.type === gMenuTypeInfo?.type ||
                gMenuTypeInfo?.parentMenu === menu.type) &&
                menu.childrenMenus?.map((childrenMenu) => (
                  <MenuButton
                    key={childrenMenu.type}
                    sizeType="small"
                    startIcon={<div></div>}
                    active={childrenMenu.type === gMenuTypeInfo?.type}
                    label={childrenMenu.name}
                    onClick={() => onClickMenuButton(childrenMenu.link)}
                    className={`${cx('menu-list-btn')}`}
                  />
                ))}
            </React.Fragment>
          ))}
        </div>
        <div className={cx('footer-container')}>
          <ActiveButton
            className={cx('signout-btn')}
            startIcon={<DoorLogoutIcon />}
            label="로그아웃"
            active={false}
            onClick={() => onClickSignoutBtn()}
          />
          <div className={cx('copylight-container')}>
            <Text colorType="white" size="xxsmall" alignType="right" tag="p">
              made by aimpact inc.
            </Text>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Navigation;
