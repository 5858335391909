import classNames from 'classnames/bind';
import Text from '../../../atoms/Text';
import styles from './header.module.scss';

const cx = classNames.bind(styles);

export interface Props {
  children: React.ReactNode;
}

type HeaderComponent = React.FC<Props> & {
  Title: typeof Title;
};

const Header: HeaderComponent = ({ children }) => {
  return (
    <div className={`${cx('container')}`}>
      {children}
      <div className={cx('contour')}></div>
    </div>
  );
};

interface TitleProps {
  text: string;
}

const Title: React.FC<TitleProps> = ({ text }) => {
  return (
    <Text isBold={true} size={'medium'}>
      {text}
    </Text>
  );
};

Header.Title = Title;
export default Header;
