import './App.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-quill/dist/quill.snow.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import Routers from './Routers';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { retryFunction } from './hooks/http';
import './react-quill-init';

if (process.env.NODE_ENV === 'production') {
  console.log('sentry connect!!!!!');

  Sentry.init({
    dsn: 'https://d7481bd011f64d4ca65006bc05b8f781@o518315.ingest.sentry.io/5880857',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retry: retryFunction as any
    }
  }
});

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Routers />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </RecoilRoot>
    </HelmetProvider>
  );
};

export default App;
