import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchArrangeManualGroupDetail } from './fetch-arrange-manual';

export namespace SwapOrderArrangeManual {
  export type RequestType = {
    arrangeManualGroupId: number;
    arrangeManualId: number;
    targetArrangeManualId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { refetch } = FetchArrangeManualGroupDetail.useRefetchData();

    const mutation = useMutation(
      ({ arrangeManualId, targetArrangeManualId }) => {
        return axios.patch(`/arrange-manual/${arrangeManualId}/swap-order`, {
          targetArrangeManualId: targetArrangeManualId
        });
      },
      {
        onSuccess: (...rest) => {
          refetch({ arrangeManualGroupId: rest[1].arrangeManualGroupId });
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
