import dayjs from 'dayjs';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Text from '../../components/atoms/Text';
import DropdownMenu from '../../components/molecules/DropdownMenu';
import List from '../../components/molecules/List';
import Table from '../../components/molecules/Table';
import { FetchTransactions } from '../../hooks/http/order';
import { OrderState, OrderStateInfo, OrderType } from '../../types/order';
import ReadMore from '../../components/molecules/ReadMore';
import { useNavigate } from 'react-router';
import Flex from '../../components/molecules/Flex';
import { parsePhone } from '../../utils/format/phone';
import SortingWithText, {
  useSort
} from '../../components/organisms/SortingWithText';
import { TransactionListSortStandard } from '../../types/sort';
import MultiDropdownMenu from '../../components/molecules/MultiDropdownMenu';
import { ORDER_STATE, ORDER_TYPE } from '../../utils/dropdown-item';
import { useFilter } from '../../hooks/useFilter';
import { LocationQuery } from '.';
import Hr from '../../components/atoms/Hr';
import styles from './transaction-data.module.scss';
import classNames from 'classnames/bind';
import {
  CardCreditIcon,
  FilterIcon,
  MinusIcon,
  MailIcon,
  TrashIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import { ColorType } from '../../types/style';
import produce from 'immer';

const cx = classNames.bind(styles);

export interface Props {
  keyword?: string;
  locationQuery?: LocationQuery;
}

interface SearchFilter {
  orderType?: OrderType[];
  orderStates?: OrderState[];
  isReseller?: boolean;
}

const TransactionData: React.FC<Props> = ({ keyword, locationQuery }) => {
  const [orderTypeCheckeds, setOrderTypeCheckeds] = useState<
    {
      text: string;
      value: OrderType | 'IS_RESELLER';
      colorType: ColorType;
      checked: boolean;
    }[]
  >(
    Array.from([
      ...ORDER_TYPE,
      {
        text: '리셀러주문' as const,
        value: 'IS_RESELLER' as const,
        colorType: 'primary-color' as ColorType,
        checked: false
      }
    ])
  );
  const [orderStateCheckeds, setOrderStateCheckeds] = useState(ORDER_STATE);

  const { filter: searchFilter, setFilter: setSearchFilter } =
    useFilter<SearchFilter>({
      orderType: (
        orderTypeCheckeds.filter(
          (order) => order.checked === true && order.value !== 'IS_RESELLER'
        ) as typeof ORDER_TYPE
      ).map((order) => order.value),
      orderStates: orderStateCheckeds
        .filter((orderState) => orderState.checked === true)
        .map((orderState) => orderState.value)
    });

  const { props, sort } = useSort<TransactionListSortStandard>({
    initState: {
      orderBy: 'DESC',
      standardColumn: 'createdAt'
    }
  });

  const { data, fetchNextPage, hasNextPage } =
    FetchTransactions.useTransactions({
      keyword,
      cursor: {
        orderBy: sort?.orderBy,
        standardColumn: sort?.standardColumn
      },
      orderTypes: searchFilter?.orderType,
      orderStates: searchFilter?.orderStates,
      storeId: locationQuery?.storeId,
      isReseller: searchFilter?.isReseller || undefined,
      orderSettleAt: locationQuery?.orderSettleAt,
      resellerOrderSettleAt: locationQuery?.resellerOrderSettleAt,
      resellerId: locationQuery?.resellerId
    });

  const onFilterStateChange = () => {
    const isReseller = orderTypeCheckeds.some(
      (orderTypeChecked) =>
        orderTypeChecked.checked == true &&
        orderTypeChecked.value === 'IS_RESELLER'
    );

    setSearchFilter({
      isReseller: isReseller || undefined,
      orderType: (
        orderTypeCheckeds.filter(
          (order) => order.checked === true && order.value !== 'IS_RESELLER'
        ) as typeof ORDER_TYPE
      ).map((order) => order.value),
      orderStates: orderStateCheckeds
        .filter((orderState) => orderState.checked === true)
        .map((orderState) => orderState.value)
    });
  };

  return (
    <InfiniteScroll
      style={{ overflow: 'visible' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={data!.pages
        .map((page) =>
          page.data.orderGroups.reduce(
            (acc, orderGroup) => acc + orderGroup.orders.length,
            0
          )
        )
        .reduce((sum, current) => sum + current, 0)}
      next={fetchNextPage}
      hasMore={hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              주문번호
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체명
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              주문자
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-3">
              결제 상품
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              수령 * 가격
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              결제금액
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <MultiDropdownMenu
                placeholder="거래방식"
                IconComponent={FilterIcon}
                items={orderTypeCheckeds}
                setChangeCheckedIndex={(idx, state) => {
                  setOrderTypeCheckeds(
                    produce(orderTypeCheckeds, (draft) => {
                      draft[idx].checked = state;
                    })
                  );
                }}
                handleClose={onFilterStateChange}
              />
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <MultiDropdownMenu
                placeholder="주문상태"
                IconComponent={FilterIcon}
                items={orderStateCheckeds}
                setChangeCheckedIndex={(idx, state) => {
                  setOrderStateCheckeds(
                    produce(orderStateCheckeds, (draft) => {
                      draft[idx].checked = state;
                    })
                  );
                }}
                handleClose={onFilterStateChange}
              />
              {/* 주문상태 */}
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <SortingWithText {...props('createdAt')}>거래일</SortingWithText>
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              삭제 <br />
              (메시지 주문)
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data?.pages.map((page, i) => (
            <React.Fragment key={i}>
              {page.data.orderGroups.map((orderGroup) => (
                <React.Fragment key={orderGroup.id}>
                  <TransactionDataRow
                    orders={orderGroup.orders}
                    createdAt={orderGroup.createdAt}
                    customer={{
                      id: orderGroup.customerId,
                      name: orderGroup.customerName,
                      phone: orderGroup.customerPhone
                    }}
                    orderType={orderGroup.orderGroupBundle.orderType}
                    storeId={orderGroup.orderGroupBundle.storeId}
                    storeName={orderGroup.orderGroupBundle.store.company}
                  />
                  <Table.Row className={cx('order-group-div-tr')}>
                    <Table.Cell colSpan={10}>
                      <Hr className={cx('order-group-div-hr')} size="2px" />
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
};

export default TransactionData;

interface TransactionDataRowProps {
  orders: FetchTransactions.TransactionsRes['orderGroups'][number]['orders'];
  storeId: number;
  storeName: string;
  orderType: OrderType;
  customer: {
    id: number;
    name: string;
    phone: string;
  };
  createdAt: string;
}
const TransactionDataRow: React.FC<TransactionDataRowProps> = React.memo(
  ({ orders, storeName, storeId, customer, orderType, createdAt }) => {
    const navigate = useNavigate();

    const onClickStoreName = (storeId: number) => {
      navigate({
        pathname: `/store/${storeId}`
      });
    };

    const OrderTypeRender = (state: OrderType) => {
      switch (state) {
        case OrderType.MESSAGE:
          return <MailIcon width="24" height="24" fill="black" />;
        case OrderType.STORE:
          return <CardCreditIcon width="24" height="24" fill="black" />;
      }
    };

    const RemoveOrderIconRender = (state: OrderType) => {
      switch (state) {
        case OrderType.MESSAGE:
          return <TrashIcon width="24" height="24" fill="black" />;
        case OrderType.STORE:
          return <MinusIcon width="24" height="24" fill="black" />;
      }
    };

    return (
      <React.Fragment>
        {orders.map((order) => (
          <Table.Row key={order.id}>
            <Table.Cell alignType="center">
              <List gap="xsmall">
                <List.Item>
                  <Text isBold={true} size="small">
                    No.{order.id}
                  </Text>
                </List.Item>
                <List.Item>
                  <Text isBold={true}>{order.code}</Text>
                </List.Item>
              </List>
            </Table.Cell>
            <Table.Cell alignType="center">
              <Text
                textDecoration="underline"
                style={{ cursor: 'pointer' }}
                isBold={true}
                colorType="primary-color"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();

                  onClickStoreName(storeId);
                }}
              >
                {storeName}
              </Text>
            </Table.Cell>
            <Table.Cell alignType="center">
              <Flex gap="small">
                <Text
                  colorType="primary-color"
                  isBold={true}
                  textDecoration="underline"
                  style={{ cursor: 'pointer' }}
                >
                  {customer.name}
                </Text>
                <Text size="small">{parsePhone(customer.phone)}</Text>
              </Flex>
            </Table.Cell>
            <Table.Cell alignType="center">
              <PaymentProductCell orderProducts={order.orderProducts} />
            </Table.Cell>
            <Table.Cell alignType="center">
              <List gap="xsmall">
                {order.orderProducts.map((product) => (
                  <List.Item
                    key={`${product.id}_${product.name}_${product.optionCode}_${product.option}`}
                  >
                    <Text>
                      {product.price.toLocaleString()} * {product.count}개
                    </Text>
                  </List.Item>
                ))}
              </List>
            </Table.Cell>
            <Table.Cell alignType="center">
              <Text size="medium">
                {order.totalPaymentPrice.toLocaleString()}원
              </Text>
            </Table.Cell>
            <Table.Cell alignType="center">
              {OrderTypeRender(orderType)}
            </Table.Cell>
            <Table.Cell alignType="center">
              <OrderStateDropdown state={order.orderState} />
            </Table.Cell>
            <Table.Cell alignType="center">
              <Text>{dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}</Text>
            </Table.Cell>

            <Table.Cell alignType="center">
              {RemoveOrderIconRender(orderType)}
            </Table.Cell>
          </Table.Row>
        ))}
      </React.Fragment>
    );
  }
);

TransactionDataRow.displayName = 'TransactionDataRow';

interface OrderStateDropdownProps {
  state: OrderState;
}

const OrderStateDropdown: React.FC<OrderStateDropdownProps> = ({ state }) => {
  const [isActive, setActive] = useState(false);
  const [value, setValue] = useState<OrderState>(state);

  return (
    <DropdownMenu
      isActive={isActive}
      setActive={(state) => setActive(state)}
      setValue={(value) => setValue(value as OrderState)}
      value={value}
      size="medium"
    >
      <DropdownMenu.Title>
        <DropdownMenu.Title.Text colorType={OrderStateInfo[value].color} />
        <DropdownMenu.Title.UpDownIcon />
      </DropdownMenu.Title>
      <DropdownMenu.ItemWrapper alignType="center" size="small">
        <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
          {Object.keys(OrderState)
            // .filter((orderState) => orderState !== OrderState.EXCHANGED)
            .map((orderState) => (
              <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                key={orderState}
                value={orderState}
                className={(orderState === value && 'display-none') || ''}
              >
                <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                  text={OrderStateInfo[orderState as OrderState].text}
                  colorType={OrderStateInfo[orderState as OrderState].color}
                />
              </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
            ))}
        </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
      </DropdownMenu.ItemWrapper>
    </DropdownMenu>
  );
};

interface PaymentProductCellProps {
  orderProducts: FetchTransactions.TransactionsRes['orderGroups'][number]['orders'][number]['orderProducts'];
}

/**
 * @todo 디자인 컴포넌트화 시키기
 * {@link /src/components/pages/Product/ProductData.tsx} 랑 똑같이 사용 되고 있음
 */
const PaymentProductCell: React.FC<PaymentProductCellProps> = ({
  orderProducts
}) => {
  const navigate = useNavigate();
  const [readmoreTotalItem, setReadmoreTotalItem] = useState(0);
  const [readmore, setReadmore] = useState(false);

  function onClickResellerSearch(id: number) {
    navigate(`/reseller?id=${id}`);
  }

  return (
    <ReadMore setReadMore={setReadmore} readMore={readmore}>
      <List gap="small">
        <ReadMore.ItemWrapper
          cutline={5}
          onChangeItem={(count) => setReadmoreTotalItem(count)}
        >
          {orderProducts.map((product) => (
            <Text
              key={`${product.id}_${product.name}_${product.optionCode}_${product.option}`}
              tag="p"
            >
              {product.name} | {product.option}
              {product.reseller ? (
                <React.Fragment>
                  &nbsp;-&nbsp;
                  <Text
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onClick={() => onClickResellerSearch(product.reseller!.id)}
                    textDecoration="underline"
                    style={{ cursor: 'pointer' }}
                    isBold={true}
                    colorType="primary-color"
                  >
                    {product.reseller.channelName}
                  </Text>
                </React.Fragment>
              ) : (
                ''
              )}
            </Text>
          ))}
        </ReadMore.ItemWrapper>
        {!readmore && readmoreTotalItem > 5 && (
          <Text
            onClick={() => setReadmore(true)}
            colorType="gray-color"
            style={{ cursor: 'pointer' }}
          >
            + {readmoreTotalItem - 5}개의 상품 옵션
          </Text>
        )}
        {readmore && (
          <Text
            colorType="gray-color"
            style={{ cursor: 'pointer' }}
            onClick={() => setReadmore(false)}
          >
            숨기기
          </Text>
        )}
      </List>
    </ReadMore>
  );
};
