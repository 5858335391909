import { OrderState, OrderType } from '../types/order';
import { ColorType } from '../types/style';

export type ItemType = Pick<ItemCheckedType, 'colorType' | 'text' | 'value'>;

export type ItemCheckedType = {
  text?: string;
  value?: string;
  colorType?: ColorType;
  checked?: boolean;
};

export const USED_ITEMDS = [
  {
    text: '사용중' as const,
    value: 'used' as const,
    colorType: 'primary-color' as const,
    checked: false
  },
  {
    text: '미사용' as const,
    value: 'un-used' as const,
    colorType: 'primary-color' as const,
    checked: false
  }
];

export const ORDER_TYPE = Object.freeze([
  {
    text: '메시지주문' as const,
    value: OrderType.MESSAGE as const,
    colorType: 'primary-color' as const,
    checked: false
  },
  {
    text: '쇼핑몰주문',
    value: OrderType.STORE as const,
    colorType: 'primary-color' as const,
    checked: false
  }
]);

export const DELIVERY_FORM_SETTING_TYPE = [
  {
    text: '대리점요청' as const,
    value: 'request' as const,
    colorType: 'blue-color' as const,
    checked: false
  },
  {
    text: '직접등록' as const,
    value: 'self' as const,
    colorType: 'secondary-color' as const,
    checked: false
  }
];

export const ORDER_STATE = [
  {
    text: '결제완료' as const,
    value: OrderState.PAID as const,
    colorType: 'primary-color' as const,
    checked: false
  },
  {
    text: '교환요청' as const,
    value: OrderState.EXCHANGE_REQUESTED as const,
    colorType: 'blue-color' as const,
    checked: false
  },
  {
    text: '교환' as const,
    value: OrderState.EXCHANGED as const,
    colorType: 'black' as const,
    checked: false
  },
  {
    text: '주문취소' as const,
    value: OrderState.CANCELLED as const,
    colorType: 'secondary-color' as const,
    checked: false
  },
  {
    text: '결제대기' as const,
    value: OrderState.WAITING_FOR_PAYMENT as const,
    colorType: 'black' as const,
    checked: false
  },
  {
    text: '발송완료' as const,
    value: OrderState.SHIPPED as const,
    colorType: 'gray-color' as const,
    checked: false
  },
  {
    text: '수령완료' as const,
    value: OrderState.PICK_UP_ON_SITE_RECEIVED as const,
    colorType: 'gray-color' as const,
    checked: false
  },
  {
    text: '미수령' as const,
    value: OrderState.PICK_UP_ON_SITE_NOT_RECEIVED as const,
    colorType: 'gray-color' as const,
    checked: false
  }
];
