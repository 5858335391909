import React from 'react';
import DaumPostcode, { AddressData } from 'react-daum-postcode';

export interface Props {
  onSelectPostcode: (address: string, postcode: string) => void;
}

const Postcode: React.FC<Props> = ({ onSelectPostcode }) => {
  const handleComplete = (data: AddressData) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    onSelectPostcode(fullAddress, data.zonecode);
  };

  return <DaumPostcode onComplete={handleComplete} />;
};

export default Postcode;
