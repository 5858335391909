import { Suspense } from 'react';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import DeliveryFormsData from './DeliveryFormsData';

const DeliveryForm: React.FC = () => {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'택배양식'} />
        <DeliveryFormsData />
      </Suspense>
    </DefaultTemplate>
  );
};

export default DeliveryForm;
