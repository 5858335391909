import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { AlignType, ColorType } from '../../../../types/style';
import styles from './cell.module.scss';

const cx = classNames.bind(styles);

interface Props extends React.ComponentPropsWithoutRef<'td'> {
  tag?: 'td' | 'th';
  alignType?: AlignType;
  fontColor?: ColorType;
}

const Cell = React.forwardRef<HTMLTableCellElement, Props>(
  (
    { children, tag = 'td', alignType, fontColor, className, ...props },
    ref
  ) => {
    const [fontColorType, setFontColorType] = useState<ColorType>('black');

    useEffect(() => {
      if (!fontColor) {
        switch (tag) {
          case 'td':
            setFontColorType('black');
            break;
          case 'th':
            setFontColorType('gray-dark-color');
            break;
        }
      }
    }, [fontColor, tag]);

    const _props = {
      className: `${cx(
        'container'
      )} text-align--${alignType} font-color--${fontColorType} ${
        className ?? ''
      }`,
      ref,
      ...props
    };

    if (tag === 'th') {
      return <th {..._props}>{children}</th>;
    }
    return <td {..._props}>{children}</td>;
  }
);

Cell.displayName = 'Cell';

export default Cell;
