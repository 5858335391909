import React, { Dispatch } from 'react';
import { Actions } from './actions';

export interface State {
  readmore: boolean;
  setReadMore?: (state: boolean) => void;
  totalItem: number;
}

export const initState: State = {
  readmore: false,
  setReadMore: undefined,
  totalItem: 0
};

type ContextType = {
  state: State;
  dispatch: Dispatch<Actions>;
};

export const ReadMoreContext = React.createContext<ContextType | undefined>(
  undefined
);

export const useContextApi = (): ContextType => {
  const context = React.useContext(ReadMoreContext);

  if (!context) {
    throw new Error('useContextApi error');
  }

  return context;
};

export * from './actions';
export * from './reducer';
