import classNames from 'classnames/bind';
import React, { useCallback, useRef, useState } from 'react';
import { throttle } from 'throttle-debounce';
import { SizeType } from '../../../types/style';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

type Position = {
  left: number;
  top: number;
};

export interface Props {
  sizeType?: Extract<SizeType, 'xxsmal' | 'xsmall' | 'small' | 'medium'>;
  label: string | string[];
  children: React.ReactNode;
  fontSize?: SizeType;
}

const Tooltip: React.FC<Props> = ({
  children,
  label,
  sizeType = 'medium',
  fontSize = 'medium'
}: Props) => {
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState<Position>({ left: 0, top: 0 });
  const mouseMoveEventRef = useRef(
    throttle(100, (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target == tooltipRef.current) {
        return;
      }

      setPosition({
        left: e.clientX,
        top: e.clientY + (containerRef.current?.offsetHeight ?? 0) / 2
      });
    })
  );
  const tooltipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const showTooltip = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    moveTooltip(e);
    setShow(true);
  };

  const hiddenTooltip = () => {
    setShow(false);
  };

  const moveTooltip = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      mouseMoveEventRef.current(e);
    },
    []
  );

  return (
    <div
      ref={containerRef}
      className={cx('container')}
      onMouseEnter={(e) => showTooltip(e)}
      onMouseLeave={() => hiddenTooltip()}
      onMouseMove={(e) => moveTooltip(e)}
    >
      {children}
      {show && (
        <div
          ref={tooltipRef}
          className={`${cx(
            'tooltip',
            `size--${sizeType}`
          )} font-size--${fontSize} font-color--white`}
          style={{ left: position.left, top: position.top }}
        >
          {Array.isArray(label)
            ? label.map((label) => (
                <>
                  {label}
                  <br />
                </>
              ))
            : label}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
