import { ProductCategoryKind } from '@aimpact-korea/arrange-front-types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { arrangeAppApi } from '../../../utils/axios';

export namespace FetchRootCategory {
  export type ResponseType = {
    items: {
      id: number;
      parentId: number | null;
      level: number;
      isEtc: boolean;
      name: string;
      kind: ProductCategoryKind | null;
      order: number;
    }[];
  };

  export const KEY_STRING = 'fetch-root-category' as const;

  type TQueryKey = [typeof KEY_STRING];

  export async function fetchData() {
    return await arrangeAppApi.get<ResponseType>('/product-category/root');
  }

  export const useFetchQuery = ({
    ...props
  }: UseQueryOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    AxiosResponse<ResponseType>,
    TQueryKey
  > = {}) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING], fetchData, {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
