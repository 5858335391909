import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { Optional } from 'utility-types';
import produce from 'immer';
import { ContractTermsType } from '../../../types/contract-terms';

export namespace FetchContractTerms {
  export type ResponseType = {
    type: ContractTermsType;
    contentHtml: string;
  };

  export type RequestType = {
    type: ContractTermsType;
  };

  export const KEY_STRING = 'fetch-contract-terms' as const;

  export type QueryKeyType = [typeof KEY_STRING, RequestType];
  export type QueryKeyType2 = QueryKeyType & QueryKey;

  export async function fetchData({
    queryKey
  }: QueryFunctionContext<QueryKeyType2>) {
    const _queryKey = queryKey as QueryKeyType;

    return await axios.get<ResponseType>(
      `/contract-terms/${_queryKey[1].type}`
    );
  }

  export const useModify = () => {
    const queryClient = useQueryClient();

    const modify = (
      contractTerms: Optional<ResponseType> & { type: ContractTermsType }
    ) => {
      queryClient.setQueriesData(
        [KEY_STRING, { type: contractTerms.type }],
        (previous: AxiosResponse<ResponseType> | undefined) => {
          if (!previous) {
            return previous;
          }

          return produce(previous, (draft) => {
            if (draft.data.type === contractTerms.type) {
              draft.data = {
                ...draft.data,
                ...contractTerms
              };
            }
          });
        }
      );
    };

    return { modify };
  };

  export const useFetch = (
    { type }: RequestType,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery(
      [
        KEY_STRING,
        {
          type
        }
      ],
      fetchData,
      {
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}
