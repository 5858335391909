import { Optional } from 'utility-types';
import { State } from '.';

export enum ActionKind {
  SET_STATE = 'SET_STATE'
}

export interface SetState {
  type: ActionKind.SET_STATE;
  payload: Optional<State>;
}

export type Actions = SetState;
