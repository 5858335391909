import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import {
  QueryFunctionContext,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import axios from '../../../utils/axios';
import qs from 'qs';
import produce from 'immer';

export namespace FetchMessageAnalysis {
  export enum ServerType {
    LAMBDA1 = 'LAMBDA1',
    LAMBDA2 = 'LAMBDA2',
    PHP = 'PHP',
    PYTHON_SG = 'PYTHON_SG',
    PYTHON_SG_V2 = 'PYTHON_SG_V2',
    PYTHON_SG_TO_NAME = 'PYTHON_SG_TO_NAME'
  }

  export type ResponseType = {
    items: {
      id: number;
      store: {
        id: number;
        company: string;
      } | null;
      createdAt: string;
      items: {
        id: number;
        request: {
          customerName: {
            name: string;
            nameIndex: number;
          };
          customerPhone: {
            phone: string;
            phoneIndex: number;
          };
          message: string;
          forcedNames: {
            name: string;
            nameIndex: number;
          }[];
          forcedAddresses: {
            addrRocation: string;
            address1: string;
            address2: string;
            addressIndex: number;
            isJibun: boolean;
            zipcode: string;
          }[];
          canceledNames: {
            name: string;
            nameIndex: number;
          }[];
        };
        result: {
          addresses: {
            address1: string;
            address2: string;
            addrRocation: string;
            zipcode: string;
            isJibun: boolean;
            addressIndex: number;
          }[];

          customer: {
            name: {
              name: string;
              nameIndex: number;
            };
            phone: {
              phone: string;
              phoneIndex: number;
            };
          };
          names: {
            name: string;
            nameIndex: number;
          }[];
          receivers: {
            address: {
              address1: string;
              address2: string;
              addrRocation: string;
              zipcode: string;
              isJibun: boolean;
              addressIndex: number;
            };
            name: {
              name: string;
              nameIndex: number;
            };
            phone: {
              phone: string;
              phoneIndex: number;
            };
          }[];
          phones: {
            phone: string;
            phoneIndex: number;
          }[];
        };
        serverType: ServerType;
        createdAt: string;
        memo: string | null;
        isInterestLog: boolean | null;
        message: string;
      }[];
    }[];
    meta: {
      itemCount: number;
      totalItems: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
  };

  export type RequestParam = {
    take?: number;
    pageNo?: number;
    states: ('SUCCESS' | 'FAIL' | 'UN_SELECT')[];
  };

  export const KEY_STRING = 'fetch-message-analysis-process' as const;

  export type QueryKeyType = [typeof KEY_STRING, RequestParam];
  export type TQueryKey = QueryKeyType & QueryKey;

  export async function fetch({ queryKey }: QueryFunctionContext<TQueryKey>) {
    const _queryKey = queryKey as QueryKeyType;

    return await axios.get<ResponseType>(
      '/message-analysis/process?' +
        qs.stringify(_queryKey[1], { arrayFormat: 'brackets' })
    );
  }

  export const useFetchQuery = (
    { pageNo = 1, take = 20, states = [] }: RequestParam = {
      pageNo: 1,
      take: 20,
      states: []
    },
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery(
      ['fetch-message-analysis-process', { pageNo, take, states }],
      fetch,
      {
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}

export namespace ModifyMessageLogMutation {
  export type RequestParam = {
    id: number;
    memo?: string | null;
    isInterestLog?: boolean;
  };

  export const useMutateQuery = ({
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestParam> = {}) => {
    const errorHandler = useErrorHandler();
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ isInterestLog, memo, id }) => {
        return axios.put(`message-analysis/${id}`, {
          isInterestLog,
          memo
        });
      },
      {
        onError: errorHandler,
        onSuccess: (_, { id, isInterestLog, memo }) => {
          queryClient.setQueriesData<
            AxiosResponse<FetchMessageAnalysis.ResponseType> | undefined
          >([FetchMessageAnalysis.KEY_STRING], (data) => {
            return produce(data, (draft) => {
              if (draft?.data) {
                for (const item of draft.data.items) {
                  const finditem =
                    item.items.find((item) => item.id === id) ?? null;

                  if (finditem) {
                    if (isInterestLog !== undefined) {
                      finditem.isInterestLog = isInterestLog;
                    }
                    if (memo !== undefined) {
                      finditem.memo = memo;
                    }
                  }
                }
              }
            });
          });
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace DeleteMessageLogMutation {
  export type RequestParam = {
    id: number;
  };

  export const useMutateQuery = ({
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestParam> = {}) => {
    const errorHandler = useErrorHandler();
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ id }) => {
        return axios.delete(`message-analysis/${id}`);
      },
      {
        onError: errorHandler,
        onSuccess: () => {
          queryClient.refetchQueries([FetchMessageAnalysis.KEY_STRING]);
        },
        ...options
      }
    );

    return mutation;
  };
}
