import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Button from '../../atoms/Button';
import Modal from '../../molecules/Modal';
import Table from '../../molecules/Table';
import TextInput from '../../molecules/TextInput';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

type PickupOnSiteRoundType = {
  pickupOnSiteRoundId?: number;
  name: string;
  pickupStartAt: string;
  pickupEndAt: string;
  purchaseStartDate: string;
  purchaseEndDate: string;
};

function ModifyPickupOnSiteRoundModal({
  isOpen,
  pickupOnSiteRound: pickupOnSiteRoundProps,
  handleSave,
  handleClose,
  isLoading
}: {
  isOpen: boolean;
  pickupOnSiteRound: PickupOnSiteRoundType;
  handleSave: (arrangeManualGroup: PickupOnSiteRoundType) => void;
  handleClose: () => void;
  isLoading?: boolean;
}) {
  const [pickupOnSiteRound, setPickupOnSiteRound] = useState(
    pickupOnSiteRoundProps
  );

  useEffect(() => {
    if (pickupOnSiteRoundProps) {
      setPickupOnSiteRound(pickupOnSiteRoundProps);
    }
  }, [pickupOnSiteRoundProps]);

  function handleChangePickupDateRange([startAt, endAt]: [
    startAt: Date,
    endAt: Date
  ]) {
    setPickupOnSiteRound((pickupOnSite) => ({
      ...pickupOnSite,
      pickupStartAt: dayjs(startAt).format('YYYY-MM-DD HH:mm:ss'),
      pickupEndAt: dayjs(endAt).format('YYYY-MM-DD HH:mm:ss')
    }));
  }

  function handleChangePurchaseDateRange([startAt, endAt]: [
    startAt: Date,
    endAt: Date
  ]) {
    setPickupOnSiteRound((pickupOnSite) => ({
      ...pickupOnSite,
      purchaseStartDate: dayjs(startAt).format('YYYY-MM-DD 00:00:00'),
      purchaseEndDate: dayjs(endAt).format('YYYY-MM-DD 00:00:00')
    }));
  }

  return (
    <Modal sizeType="large" isOpen={isOpen} handleClose={() => handleClose()}>
      <Modal.Header>
        <Modal.Header.Title text="어레인지 매뉴얼 그룹 수정" />
      </Modal.Header>
      <Modal.Body>
        <Table type="simple-table">
          <Table.Body>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">회차명</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={pickupOnSiteRound.name}
                      onChange={(e) =>
                        setPickupOnSiteRound({
                          ...pickupOnSiteRound,
                          name: e.target.value
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">
                구매가능 시작/종료일
              </Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <DateRangePicker
                      value={[
                        dayjs(pickupOnSiteRound.purchaseStartDate).toDate(),
                        dayjs(pickupOnSiteRound.purchaseEndDate).toDate()
                      ]}
                      onChange={(date) => {
                        const typedDate: [Date, Date] | null = date as
                          | [Date, Date]
                          | null;

                        if (
                          typedDate &&
                          typedDate.length === 2 &&
                          typedDate[0] instanceof Date &&
                          typedDate[1] instanceof Date
                        ) {
                          handleChangePurchaseDateRange(
                            typedDate as [Date, Date]
                          );
                        }
                      }}
                      locale="ko"
                    />
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">
                픽업 시작/종료일
              </Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <DateTimeRangePicker
                      value={[
                        dayjs(pickupOnSiteRound.pickupStartAt).toDate(),
                        dayjs(pickupOnSiteRound.pickupEndAt).toDate()
                      ]}
                      isClockOpen={false}
                      onChange={(date) => {
                        console.log('date', date);
                        const typedDate: [Date, Date] | null = date as
                          | [Date, Date]
                          | null;

                        if (
                          typedDate &&
                          typedDate.length === 2 &&
                          typedDate[0] instanceof Date &&
                          typedDate[1] instanceof Date
                        ) {
                          handleChangePickupDateRange(
                            typedDate as [Date, Date]
                          );
                        }
                      }}
                      locale="ko"
                    />
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          flexDirection="flex-row"
          width="w-full"
          gapSize={StyleBuilderInstane.toGapSpaceType('gap-xxs')}
        >
          <Button
            text="취소"
            type="secondary-color"
            fullwidth={true}
            onClick={() => handleClose()}
            isLoading={isLoading}
          />
          <Button
            text={pickupOnSiteRound.pickupOnSiteRoundId ? `저장` : '생성'}
            fullwidth={true}
            onClick={() => handleSave(pickupOnSiteRound)}
            isLoading={isLoading}
          />
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export default ModifyPickupOnSiteRoundModal;
