import { isNextCursor, QueryInfiniteCallback } from '..';
import { AxiosResponse, AxiosError } from 'axios';
import {
  useInfiniteQuery,
  QueryFunctionContext,
  QueryKey
} from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { OrderState, OrderType } from '../../../types/order';
import { SortState, TransactionListSortStandard } from '../../../types/sort';
import { useErrorHandler } from 'react-error-boundary';
import qs from 'qs';
import { CourierType } from '@aimpact-korea/arrange-front-types';

export namespace FetchTransactions {
  export type TransactionsCurser = {
    id?: number;
    value?: string;
  } & SortState<TransactionListSortStandard>;

  export type TransactionsRes = {
    orderGroups: {
      id: number;
      code: string;
      receiverName: string;
      receiverPhone: string;
      receiverAddress: string;
      receiverDetailAddress: string | null;
      receiverPostCode: string | null;
      customerId: number;
      customerName: string;
      customerPhone: string;
      orderGroupBundle: {
        id: number;
        orderType: OrderType;
        storeId: number;
        store: {
          id: number;
          company: string;
        };
      };
      orders: {
        id: number;
        code: string;
        depositAt: string | null;
        isPaid: boolean;
        canceledAt: string | null;
        exchangedFromId: number | null;
        orderGroupId: number;
        deliveryTrackingNumber: string | null;
        deliveryCourier: CourierType | null;
        orderState: OrderState;
        totalPaymentPrice: number;
        shippingDate: string | null;
        orderProducts: {
          id: number;
          productId: number;
          name: string;
          optionCode: string | null;
          option: string;
          price: number;
          count: number;
          reseller: {
            id: number;
            channelName: string;
          } | null;
        }[];
      }[];
      createdAt: string;
      updatedAt: string;
    }[];
    cursor: TransactionsCurser;
  };

  export type TransactionsParam = {
    limit?: number;
    cursor?: TransactionsCurser;
    keyword?: string;
    orderTypes?: OrderType[];
    orderStates?: OrderState[];
    storeId?: number;
    orderSettleAt?: string;
    resellerOrderSettleAt?: string;
    isReseller?: boolean | null;
    resellerId?: number;
  };

  export const KEY_STRING = 'transactions' as const;

  type TransactionsQueryKeyType = [typeof KEY_STRING, TransactionsParam];
  type TransactionsQueryKey = TransactionsQueryKeyType & QueryKey;

  export async function FetchTransactions({
    pageParam,
    queryKey
  }: QueryFunctionContext<TransactionsQueryKey, TransactionsCurser>) {
    const _queryKey = queryKey as TransactionsQueryKeyType;

    let cursor: TransactionsCurser | undefined = undefined;
    if (pageParam) {
      cursor = pageParam;
    } else if (_queryKey[1].cursor) {
      cursor = {};
      if (_queryKey[1].cursor.id) {
        cursor.id = _queryKey[1].cursor.id;
      }
      if (_queryKey[1].cursor.orderBy) {
        cursor.orderBy = _queryKey[1].cursor.orderBy;
      }
      if (_queryKey[1].cursor.standardColumn) {
        cursor.standardColumn = _queryKey[1].cursor.standardColumn;
      }
      if (_queryKey[1].cursor.value) {
        cursor.value = _queryKey[1].cursor.value;
      }
    }

    return await axios.get<TransactionsRes>(
      '/order/order-group?' + qs.stringify({ cursor }),
      {
        params: {
          limit: _queryKey[1].limit,
          keyword: _queryKey[1].keyword,
          orderTypes: _queryKey[1].orderTypes,
          orderStates: _queryKey[1].orderStates,
          storeId: _queryKey[1].storeId,
          orderSettleAt: _queryKey[1].orderSettleAt,
          resellerOrderSettleAt: _queryKey[1].resellerOrderSettleAt,
          isReseller: _queryKey[1].isReseller,
          resellerId: _queryKey[1].resellerId
        }
      }
    );
  }

  export const useTransactions = (
    {
      cursor: { id, orderBy, standardColumn, value } = {},
      limit = 15,
      keyword,
      orderStates: orderState,
      orderTypes,
      storeId,
      isReseller,
      orderSettleAt,
      resellerOrderSettleAt,
      resellerId
    }: TransactionsParam = {},
    {
      ...props
    }: QueryInfiniteCallback<
      AxiosResponse<TransactionsRes>,
      AxiosError,
      AxiosResponse<TransactionsRes>,
      TransactionsQueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useInfiniteQuery(
      [
        'transactions',
        {
          keyword,
          cursor: { id, value, standardColumn, orderBy },
          limit,
          orderSettleAt,
          orderStates: orderState,
          orderTypes,
          isReseller,
          storeId,
          resellerOrderSettleAt,
          resellerId
        }
      ],
      FetchTransactions,
      {
        getNextPageParam: (data, allData) => {
          return isNextCursor(data.data.cursor, allData);
        },
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}
