import { useEffect, useState } from 'react';
import { ColorType } from '../../../../types/style';
import TextAtom from '../../../atoms/Text';
import { useContextApi } from '../context';

export interface Props {
  placeholder?: string;
  colorType?: ColorType;
}

const Text: React.FC<Props> = ({ placeholder = '', colorType }) => {
  const { state } = useContextApi();

  const [titleColor, setTitleColor] = useState<ColorType>('gray-dark-color');

  useEffect(() => {
    if (state.isActive || state.value) {
      if (state.value) {
        setTitleColor(colorType ?? state.value.colorType);
      } else {
        setTitleColor('gray-dark-color');
      }
    } else {
      setTitleColor('gray-dark-color');
    }

    if (state.disabled) {
      setTitleColor('gray-color');
    }
  }, [colorType, state.disabled, state.isActive, state.items, state.value]);

  return (
    <TextAtom size={state.size} isBold={true} colorType={titleColor}>
      {(state.value && state.value.text) ?? placeholder}
    </TextAtom>
  );
};

export default Text;
