import { Suspense, useEffect, useState } from 'react';
import DefaultTemplate from '../../templates/Default';
import Header from '../../organisms/Header';
import ListSkeleton from '../../molecules/ListSkeleton';
import InputSearch from '../../organisms/InputSearch';
import Button from '../../atoms/Button';
import { useLocation, useNavigate } from 'react-router';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import DropdownMenu from '../../molecules/DropdownMenu';
import StoresData from './StoresData';
import { useLocationSearchQueryParam } from '../../../hooks/useLocationSearchQueryParam';
import { Type } from '../../../utils/decorators';
import qs from 'qs';
import { FileIcon } from '@aimpact-korea/arrange-front-icons/build/icons';
import {
  FetchStoreSendMessage,
  MutationStoreDetail
} from '../../../hooks/http/store';
import Modal from '../../molecules/Modal';
import Textarea from '../../atoms/Textarea';
import Flex from '../../molecules/Flex';

const cx = classNames.bind(styles);

export class QueryStringParam {
  @Type(() => String)
  keyword?: string;

  @Type(() => Number)
  storeId?: number;

  @Type(() => String)
  modalType?: 'send-store-message-modal';
}

export interface Props {
  isBlockStoresView: boolean;
}

const Stores: React.FC<Props> = ({ isBlockStoresView }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const locationQuery = useLocationSearchQueryParam(QueryStringParam);

  const [orderDropdownValue, setOrderDropdownValue] = useState('주문수');
  const [orderDropdownActive, setOrderDropdownActive] = useState(false);

  const [sendStoreMessageInput, setSendStoreMessageInput] = useState('');
  const mutationStoreDetail = MutationStoreDetail.useMutate({
    onSuccess: () => {
      alert('메세지가 전송되었습니다.');
      navigate(-1);
    }
  });
  const fetchSendStoreMssgae = FetchStoreSendMessage.useFetchSendStoreMessage(
    {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      storeId: locationQuery.storeId!
    },
    { enabled: !!locationQuery.storeId, suspense: false }
  );

  const sendAdminMessage = fetchSendStoreMssgae.data?.data.sendAdminMessage;

  useEffect(() => {
    if (locationQuery.modalType !== 'send-store-message-modal') {
      setSendStoreMessageInput('');
    } else {
      if (sendAdminMessage) {
        setSendStoreMessageInput(sendAdminMessage);
      }
    }
  }, [sendAdminMessage, locationQuery.modalType]);

  const currentRedirectFromParam = ({ keyword }: { keyword?: string }) => {
    const changeQueryString = {
      ...locationQuery
    };

    if (keyword !== null && keyword !== undefined) {
      changeQueryString.keyword = keyword;
    }

    navigate(`${location.pathname}?${qs.stringify(changeQueryString)}`);
  };

  function onClickSubmitMessage() {
    if (!locationQuery.storeId) {
      alert('다시 시도해주세요.');
      navigate(-1);
      return;
    }

    if (!sendStoreMessageInput) {
      alert('전송하실 메세지를 입력해주세요.');
      return;
    }

    mutationStoreDetail.mutate({
      storeId: locationQuery.storeId,
      sendMessage: sendStoreMessageInput
    });
  }

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={isBlockStoresView ? '차단업체' : '업체목록'}>
          {locationQuery.modalType === 'send-store-message-modal' &&
          locationQuery.storeId &&
          fetchSendStoreMssgae.isSuccess ? (
            <Modal
              sizeType="large"
              isOpen={locationQuery.modalType === 'send-store-message-modal'}
              handleClose={() => navigate(-1)}
            >
              <Modal.Header>
                <Modal.Header.Title text="메세지 전송" />
              </Modal.Header>
              <Modal.Body>
                <Textarea
                  style={{ width: '100%', height: '400px' }}
                  placeholder="스토어에 보낼 메세지를 입력해주세요."
                  value={sendStoreMessageInput}
                  onChange={(event) =>
                    setSendStoreMessageInput(event.target.value)
                  }
                />
              </Modal.Body>
              <Modal.Footer>
                <Flex direction="row" style={{ width: '100%' }} gap="xsmall">
                  <Button fullwidth text="닫기" onClick={() => navigate(-1)} />
                  <Button
                    fullwidth
                    text="메세지 보내기"
                    onClick={() => onClickSubmitMessage()}
                    isLoading={mutationStoreDetail.isLoading}
                  />
                </Flex>
              </Modal.Footer>
            </Modal>
          ) : (
            ''
          )}
          <div className={cx('header-container')}>
            <div className={cx('flex')}>
              <DropdownMenu
                value={orderDropdownValue}
                size="large"
                isActive={orderDropdownActive}
                setActive={setOrderDropdownActive}
                setValue={setOrderDropdownValue}
              >
                <DropdownMenu.Title isBottomLine={true}>
                  <DropdownMenu.Title.UpDownIcon />
                  <DropdownMenu.Title.Text
                    colorType={'primary-color'}
                    placeholder=""
                  />
                </DropdownMenu.Title>
                <DropdownMenu.ItemWrapper size="medium">
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup
                    isContour={true}
                  >
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item value="주문수">
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                        text="주문수"
                        colorType={'primary-color'}
                      />
                    </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item value="날짜">
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                        text="날짜"
                        colorType={'primary-color'}
                      />
                    </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                  </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                </DropdownMenu.ItemWrapper>
              </DropdownMenu>

              <Button
                text="엑셀 내보내기"
                size="large"
                startIcon={<FileIcon colorType="text-white" />}
              />
            </div>
            <InputSearch
              placeholder="업체명 / 대표자 / 연락처 / 아이디"
              onClickSearch={(text) =>
                currentRedirectFromParam({ keyword: text })
              }
            />
          </div>
        </Header>
        <StoresData
          keyword={locationQuery.keyword}
          isBlockStoresView={isBlockStoresView}
        />
      </Suspense>
    </DefaultTemplate>
  );
};

export default Stores;
