import { Button as MButton } from '@material-ui/core';
import styles from './index.module.scss';
import { SizeType, ColorType } from '../../../types/style';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

const cx = classNames.bind(styles);

export interface Props {
  text: string;
  fullwidth?: boolean;
  fit?: boolean;
  size?: Extract<SizeType, 'medium' | 'large' | 'small'>;
  type?: ColorType;
  isBorder?: boolean;
  disabledBoxShadow?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  fontColor?: ColorType;
  borderColor?: ColorType;
  htmlType?: 'submit' | 'reset' | 'button';
}

const Button: React.FC<Props> = ({
  text,
  fullwidth = false,
  size = 'medium',
  type = 'primary-color',
  isBorder = false,
  disabledBoxShadow = false,
  onClick,
  isLoading = false,
  startIcon,
  disabled = false,
  endIcon,
  fit = false,
  fontColor,
  borderColor,
  htmlType = 'button'
}: Props) => {
  const [fontSize, setFontSize] = useState<SizeType>(size);

  useEffect(() => {
    switch (size) {
      // 의도된 non-break
      case 'medium':
      case 'large':
        setFontSize('medium');
        break;
      case 'small':
        setFontSize('small');
        break;
    }
  }, [size]);

  const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (!isLoading) {
      onClick?.(event);
    }
  };

  if (!startIcon && !fit) {
    startIcon = <div></div>;
  }
  if (!endIcon && !fit) {
    endIcon = <div></div>;
  }

  return (
    <MButton
      className={`${cx(
        'btn',
        `type--${type}`,
        `size--${size}`,
        `${fit && 'fit'}`,
        { fullwidth },
        { border: isBorder },
        { 'disabled-box-shadow': disabledBoxShadow },
        { disabled: disabled }
      )} ${borderColor ? `border-color--${borderColor}` : ''}`}
      disabled={disabled}
      onClick={(e) => onClickHandler(e)}
      fullWidth={fullwidth}
      startIcon={startIcon}
      endIcon={endIcon}
      type={htmlType}
    >
      {(isLoading && (
        <div className={cx('loading-circle')}>
          <div className={cx('circle')}></div>
          <div className={cx('circle')}></div>
          <div className={cx('circle')}></div>
        </div>
      )) || (
        <span
          className={`font-size--${fontSize} ${
            fontColor ? `font-color--${fontColor}` : ''
          }`}
        >
          {text}
        </span>
      )}
    </MButton>
  );
};

export default Button;
