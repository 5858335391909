import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import {
  QueryFunctionContext,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { useErrorHandler } from 'react-error-boundary';
import qs from 'qs';

export namespace FetchVisionOcrLog {
  export type ResponseType = {
    items: {
      id: number;
      isSuccessed: boolean | null;
      createdAt: string;
      memo: string | null;
      visionOcrLogType: 'NAVER' | 'GOOGLE';
      request: {
        density: number;
        width: number;
        oneUIVersion: number;
      };
      response: {
        name: string;
        phone: string;
      };
      store: {
        id: number;
        company: string;
      } | null;
      attachment: {
        id: number;
        fullImageUrl: string;
      };
    }[];
    meta: {
      itemCount: number;
      totalItems: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
  };

  export type ParameterType = {
    take?: number;
    pageNo?: number;
    states: ('SUCCESS' | 'FAIL' | 'UN_SELECT')[];
  };

  export const KEY_STRING = 'fetch-vision-ocr-log' as const;

  export type QueryKeyType = [typeof KEY_STRING, ParameterType];
  export type TQueryKey = QueryKeyType & QueryKey;

  export async function fetchFn({ queryKey }: QueryFunctionContext<TQueryKey>) {
    const _queryKey = queryKey as QueryKeyType;

    return await axios.get<ResponseType>(
      '/vision/ocr-log?' +
        qs.stringify(_queryKey[1], { arrayFormat: 'brackets' })
    );
  }

  export const useFetchQuery = (
    { pageNo = 1, take = 10, states = [] }: ParameterType = {
      pageNo: 1,
      take: 10,
      states: []
    },
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery(
      [
        'fetch-vision-ocr-log',
        {
          pageNo,
          take,
          states
        }
      ],
      fetchFn,
      {
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}

export namespace ModifyVisionOcrLogMutation {
  export type RequestParam = {
    id: number;
    memo?: string | null;
    isSuccessed?: boolean;
  };

  export const useMutateQuery = ({
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestParam> = {}) => {
    const errorHandler = useErrorHandler();
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ isSuccessed, memo, id }) => {
        return axios.put(`vision/ocr-log/${id}`, {
          isSuccessed,
          memo
        });
      },
      {
        onError: errorHandler,
        onSuccess: (_, { id, isSuccessed, memo }) => {
          queryClient.setQueriesData<
            AxiosResponse<FetchVisionOcrLog.ResponseType> | undefined
          >([FetchVisionOcrLog.KEY_STRING], (data) => {
            if (data?.data) {
              const item = data.data.items.find((item) => item.id === id);

              if (item) {
                if (isSuccessed !== undefined) {
                  item.isSuccessed = isSuccessed;
                }
                if (memo !== undefined) {
                  item.memo = memo;
                }
              }
            }

            return data;
          });
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace DeleteVisionOcrLogMutation {
  export type RequestParam = {
    id: number;
  };

  export const useMutateQuery = ({
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestParam> = {}) => {
    const errorHandler = useErrorHandler();
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ id }) => {
        return axios.delete(`vision/ocr-log/${id}`);
      },
      {
        onError: errorHandler,
        onSuccess: () => {
          queryClient.refetchQueries([FetchVisionOcrLog.KEY_STRING]);
        },
        ...options
      }
    );

    return mutation;
  };
}
