import React from 'react';

export type Props = React.ComponentPropsWithoutRef<'li'>;

export type ItemComponent = React.ForwardRefExoticComponent<
  Props & React.RefAttributes<HTMLLIElement>
>;

// eslint-disable-next-line react/display-name
const Item = React.forwardRef<HTMLLIElement, Props>(
  ({ children, className = '', ...props }, ref) => {
    return (
      <li ref={ref} {...props} className={`${className}`}>
        {children}
      </li>
    );
  }
) as ItemComponent;

Item.displayName = 'Item';

export default Item;
