import { AxiosError, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { useErrorHandler } from 'react-error-boundary';
import {
  InfiniteData,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { AimpactAxiosError, isNextCursor, QueryInfiniteCallback } from '..';
import { ProductListSortStandard, SortState } from '../../../types/sort';
import axios from '../../../utils/axios';
import produce from 'immer';
import { Optional } from 'utility-types';
import { ProductCategoryKind } from '@aimpact-korea/arrange-front-types';

export namespace FetchProducts {
  export type ProductsCurser = {
    id?: number;
    value?: string;
  } & SortState<ProductListSortStandard>;

  export type ProductsRes = {
    products: {
      id: number;
      code: string;
      storeName: string;
      ceoName: string;
      productName: string;
      name: string;
      orderCount: number;
      isInterestProduct: boolean;
      isLegacy: boolean;
      options: {
        name: string;
        price: number;
        normalPrice: number;
        stock?: number | null;
        isDisabledStore: true;
      }[];
      createdAt: string;
      updatedAt: string;
    }[];
    cursor: {
      date: string;
      id: number;
    };
  };

  export type ProductsParam = {
    keyword?: string;
    isInterest?: boolean;
    cursor?: ProductsCurser;
    limit?: number;
    storeId?: number;
  };

  export const KEY_STRING = 'products' as const;

  type ProductsQueryKeyType = [typeof KEY_STRING, ProductsParam];
  type ProductsQueryKey = ProductsQueryKeyType & QueryKey;

  export function useModifyData() {
    const queryClient = useQueryClient();

    function modify(
      product: Optional<ProductsRes['products'][number]> & { id: number },
      param?: ProductsParam
    ) {
      queryClient.setQueriesData(
        param ? [FetchProducts.KEY_STRING, param] : [FetchProducts.KEY_STRING],
        (
          previous:
            | InfiniteData<AxiosResponse<FetchProducts.ProductsRes>>
            | undefined
        ) => {
          const _previous = previous as InfiniteData<
            AxiosResponse<FetchProducts.ProductsRes>
          >;

          return produce(_previous, (draft) => {
            draft.pages = draft.pages.map((page) => {
              page.data.products = page.data.products.map((item) => {
                if (item.id === product.id) {
                  return {
                    ...item,
                    ...product
                  };
                }

                return item;
              });

              return page;
            });
          });
        }
      );
    }

    function remove(productId: number, param?: ProductsParam) {
      queryClient.setQueriesData(
        param ? [FetchProducts.KEY_STRING, param] : [FetchProducts.KEY_STRING],
        (
          previous:
            | InfiniteData<AxiosResponse<FetchProducts.ProductsRes>>
            | undefined
        ) => {
          const _previous = previous as InfiniteData<
            AxiosResponse<FetchProducts.ProductsRes>
          >;

          return produce(_previous, (draft) => {
            draft.pages = draft.pages.map((page) => {
              page.data.products = page.data.products.filter(
                (item) => item.id !== productId
              );

              return page;
            });
          });
        }
      );
    }

    return { modify, remove };
  }

  export async function fetchProducts({
    pageParam,
    queryKey
  }: QueryFunctionContext<ProductsQueryKey, ProductsCurser>) {
    const _queryKey = queryKey as ProductsQueryKeyType;

    const urlSearchParams = new URLSearchParams();

    if (pageParam) {
      if (pageParam.id) {
        urlSearchParams.append('cursor[id]', `${pageParam.id}`);
      }
      if (pageParam.orderBy) {
        urlSearchParams.append('cursor[orderBy]', `${pageParam.orderBy}`);
      }
      if (pageParam.standardColumn) {
        urlSearchParams.append(
          'cursor[standardColumn]',
          `${pageParam.standardColumn}`
        );
      }
      if (pageParam.value) {
        urlSearchParams.append('cursor[value]', `${pageParam.value}`);
      }
    } else if (_queryKey[1].cursor?.standardColumn) {
      if (_queryKey[1].cursor.id) {
        urlSearchParams.append('cursor[id]', `${_queryKey[1].cursor.id}`);
      }
      if (_queryKey[1].cursor.orderBy) {
        urlSearchParams.append(
          'cursor[orderBy]',
          `${_queryKey[1].cursor.orderBy}`
        );
      }
      if (_queryKey[1].cursor.standardColumn) {
        urlSearchParams.append(
          'cursor[standardColumn]',
          `${_queryKey[1].cursor.standardColumn}`
        );
      }
      if (_queryKey[1].cursor.value) {
        urlSearchParams.append('cursor[value]', `${_queryKey[1].cursor.value}`);
      }
    }

    const queryParam = `?${urlSearchParams.toString()}`;

    return await axios.get<ProductsRes>('/product' + queryParam, {
      params: {
        keyword: _queryKey[1].keyword || undefined,
        isInterest: _queryKey[1].isInterest,
        limit: _queryKey[1].limit,
        storeId: _queryKey[1].storeId
      }
    });
  }

  export const useProducts = (
    {
      cursor: { id, orderBy, standardColumn: standard, value } = {},
      keyword = '',
      isInterest = false,
      limit = 15,
      storeId
    }: ProductsParam = {},
    {
      ...props
    }: QueryInfiniteCallback<
      AxiosResponse<ProductsRes>,
      AxiosError,
      AxiosResponse<ProductsRes>,
      ProductsQueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useInfiniteQuery(
      [
        KEY_STRING,
        {
          cursor: { id, value, standardColumn: standard, orderBy },
          keyword,
          isInterest,
          limit,
          storeId
        }
      ],
      fetchProducts,
      {
        getNextPageParam: (data, datas) => {
          return isNextCursor(data.data.cursor, datas);
        },
        refetchOnWindowFocus: false,
        staleTime: 300000,
        onError: errorHandler,
        ...props
      }
    );

    return query;
  };
}

export namespace FetchProduct {
  export type ProductRes = {
    id: number;
    code: string;
    storeName: string;
    ceoName: string;
    productName: string;
    name: string;
    orderCount: number;
    isInterestProduct: boolean;
    options: {
      code: string;
      name: string;
      price: number;
      normalPrice: number;
      stock: number | null;
      isDisabledStore: boolean;
    }[];
    createdAt: string;
    updatedAt: string;
    satisfactionPercent: number;
    reviewCount: number;
    productCategory: {
      productCategoryLevels: {
        id: number;
        parentId: number | null;
        level: number;
        isEtc: boolean;
        name: string;
        kind: ProductCategoryKind | null;
        order: number;
      }[];
    } | null;
    productCategoryKind: {
      id: number;
      isEtc: boolean;
      name: string;
      order: number;
      productCategoryId: number;
    } | null;
  };

  export type ProductParam = {
    productId: number;
  };

  export const KEY_STRING = 'store' as const;

  type ProductQueryKeyType = [typeof KEY_STRING, ProductParam];
  type ProductQueryKey = ProductQueryKeyType & QueryKey;

  export async function fetchProduct({
    queryKey
  }: QueryFunctionContext<ProductQueryKey>) {
    const _queryKey = queryKey as ProductQueryKeyType;

    return await axios.get<ProductRes>(`/product/${_queryKey[1].productId}`);
  }

  export const useFetchProduct = (
    { productId }: ProductParam,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ProductRes>,
      AxiosError,
      AxiosResponse<ProductRes>,
      ProductQueryKeyType
    > = {}
  ) => {
    const query = useQuery(
      [
        KEY_STRING,
        {
          productId
        }
      ],
      fetchProduct,
      {
        staleTime: 300000,
        ...props
      }
    );

    return query;
  };
}

export namespace MutationProductAddInteresting {
  export type Param = {
    productId: number;
  };

  export const useMutate = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, Param> = {}) => {
    const queryClient = useQueryClient();
    const { modify } = FetchProducts.useModifyData();

    const mutation = useMutation(
      ({ productId }) => {
        return axios.post(`/product/interest/${productId}`);
      },
      {
        onSuccess: (...rest) => {
          modify({
            id: rest[1].productId,
            isInterestProduct: true
          });

          queryClient.invalidateQueries([
            FetchProducts.KEY_STRING,
            { isInterest: true }
          ]);

          onSuccess?.(...rest);
        },
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('존재하지 않는 상품입니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace MutationProductCancelInteresting {
  export type Param = {
    productId: number;
  };

  export const useMutate = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, Param> = {}) => {
    const { modify, remove } = FetchProducts.useModifyData();

    const mutation = useMutation(
      ({ productId }) => {
        return axios.delete(`/product/interest/${productId}`);
      },
      {
        onSuccess: (...rest) => {
          modify({
            id: rest[1].productId,
            isInterestProduct: false
          });

          remove(rest[1].productId, {
            isInterest: true
          });
          onSuccess?.(...rest);
        },
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('관심상품에 등록되지 않는 상품번호입니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace MutationProductDetail {
  export type Param = {
    productId: number;
    name?: string;
    options?: {
      code: string;
      name: string;
      price: number;
      normalPrice: number;
      stock: number | null;
      isDisabledStore: boolean;
    }[];
    productCategoryKindId?: number;
    lastProductCategoryId?: number;
  };

  export const useMutate = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<
    AxiosResponse,
    AxiosError<AimpactAxiosError>,
    Param
  > = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({
        productId,
        name,
        options,
        lastProductCategoryId,
        productCategoryKindId
      }) => {
        return axios.patch(`/product/${productId}`, {
          name,
          options,
          lastProductCategoryId,
          productCategoryKindId
        });
      },
      {
        onSuccess: (...rest) => {
          queryClient.invalidateQueries([
            FetchProduct.KEY_STRING,
            { productId: rest[1].productId }
          ]);
          onSuccess?.(...rest);
        },
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('상품 번호가 틀렸습니다.');
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}

export namespace DeleteProductApi {
  export type Param = {
    productId: number;
  };

  export const useMutate = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<
    AxiosResponse,
    AxiosError<AimpactAxiosError>,
    Param
  > = {}) => {
    const queryClient = useQueryClient();
    const { remove } = FetchProducts.useModifyData();

    const mutation = useMutation(
      ({ productId }) => {
        return axios.delete(`/product/${productId}`);
      },
      {
        onSuccess: (...rest) => {
          queryClient.removeQueries([
            FetchProduct,
            { productId: rest[1].productId }
          ]);

          remove(rest[1].productId);

          onSuccess?.(...rest);
        },
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('존재하지 않는 상품입니다.');
              break;
          }

          switch (error.response?.data.exceptionCode) {
            case 'P_1005':
              alert(
                '해당상품은 어레인지 쇼핑몰에 연동되어 있어 삭제가 불가능합니다.\n쇼핑몰에서 상품연동 해제후 삭제해 주세요!'
              );
              break;
          }

          onError?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
