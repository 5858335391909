import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import {
  InfiniteData,
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import axios from '../../../../../utils/axios';
import { AimpactAxiosError } from '../../..';
import { FetchLinkfarmProductResellsApi } from './fetch-linkfarm-product-resells';
import produce from 'immer';

export namespace DeleteLinkfarmProductProductResellApi {
  export type Parameter = {
    linkfarmProductResellId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<
    AxiosResponse,
    AxiosError<AimpactAxiosError>,
    Parameter
  > = {}) => {
    const errorHandler = useErrorHandler();
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ linkfarmProductResellId }) => {
        return axios.delete(
          `/linkfarm/product/resell/${linkfarmProductResellId}`
        );
      },
      {
        onSuccess: (...args) => {
          const variables = args[1];

          queryClient.setQueriesData(
            [FetchLinkfarmProductResellsApi.QUERY_KEY_STRING],
            (
              previous:
                | InfiniteData<
                    AxiosResponse<FetchLinkfarmProductResellsApi.ResponseType>
                  >
                | undefined
            ) => {
              const _previous = previous as InfiniteData<
                AxiosResponse<FetchLinkfarmProductResellsApi.ResponseType>
              >;
              return produce(_previous, (draft) => {
                draft.pages = draft.pages.map((page) => {
                  page.data.linkfarmProductResells =
                    page.data.linkfarmProductResells.filter(
                      (linkfarmProductResell) =>
                        linkfarmProductResell.id !==
                        variables.linkfarmProductResellId
                    );

                  return page;
                });
              });
            }
          );

          onSuccess?.(...args);
        },
        onError: errorHandler,
        ...options
      }
    );

    return mutation;
  };
}
