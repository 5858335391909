import classNames from 'classnames/bind';
import React, { useEffect, useReducer } from 'react';
import { ActionKind, DivTableContext, initState, reducer } from './context';
import styles from './index.module.scss';
import Body from './subcomponents/Body';
import Head from './subcomponents/Head';

const cx = classNames.bind(styles);

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
  size: number[];
}

export interface DivTableComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
  > {
  Head: typeof Head;
  Body: typeof Body;
}

/*
 * React Dnd Table 이슈 때문에 만든 DivTable..
 * 해결 방안은 있는데 너무 오래걸릴 것 같아 임시로 만들었다.
 * 시간 날 때 React Dnd Table 이슈 해결해야겠다.
 */

// eslint-disable-next-line react/display-name
const DivTable = React.forwardRef<HTMLDivElement, Props>(
  ({ children, size, ...props }, ref) => {
    const [state, dispatch] = useReducer(reducer, initState);

    useEffect(() => {
      dispatch({
        type: ActionKind.SET_SIZE,
        payload: size
      });
    }, [size]);

    return (
      <DivTableContext.Provider value={{ state, dispatch }}>
        <div ref={ref} className={cx('container')} {...props}>
          {children}
        </div>
      </DivTableContext.Provider>
    );
  }
) as DivTableComponent;

DivTable.displayName = 'DivTable';

DivTable.Head = Head;
DivTable.Body = Body;

export default DivTable;
