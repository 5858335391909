import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../../../utils/axios';

export namespace AttachmentFileUploadApi {
  export type ParameterType = {
    file: File;
  };

  export type ResponseType = {
    attachmentId: number;
    url: string;
  };

  export const useMutate = ({
    ...options
  }: UseMutationOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    ParameterType
  > = {}) => {
    const errorHandler = useErrorHandler();

    const mutation = useMutation(
      ({ file }) => {
        const formData = new FormData();
        formData.append('file', file, file.name);

        return axios.post(`attachments`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      },
      {
        onError: errorHandler,
        ...options
      }
    );

    return mutation;
  };
}
