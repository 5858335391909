import classNames from 'classnames/bind';
import { EventHandler, KeyboardEvent, useEffect, useState } from 'react';
import Button from '../../atoms/Button';
import TextInput from '../../molecules/TextInput';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props {
  text?: string;
  setText?: (text: string) => void;
  onClickSearch?: (text: string) => void;
  placeholder?: string;
  defaultValue?: string;
}

const InputSearch: React.FC<Props> = ({
  text: propText = '',
  setText: propSetText,
  placeholder = '',
  onClickSearch,
  defaultValue
}) => {
  const [text, setText] = useState(propText);

  const handleTextChange = (text: string) => {
    setText(text);
    propSetText?.(text);
  };

  const onKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = (e) => {
    if (e.key === 'Enter') {
      onClickSearch?.(text);
    }
  };

  useEffect(() => {
    setText(propText);
  }, [propText]);

  return (
    <div className={cx('container')}>
      <TextInput type="search">
        <TextInput.Body>
          <TextInput.Body.Input
            placeholder={placeholder}
            value={text || undefined}
            handleChangeValue={(text) => handleTextChange(text)}
            onKeyPress={onKeyPress}
            defaultValue={defaultValue}
          />
          <TextInput.Body.SearchIcon />
        </TextInput.Body>
      </TextInput>
      <Button text="검색" onClick={() => onClickSearch?.(text)} />
    </div>
  );
};

export default InputSearch;
