import classNames from 'classnames/bind';
import React, { useEffect, useReducer } from 'react';
import { SizeType } from '../../../types/style';
import { ActionKind, initState, reducer, TextInputContext } from './context';
import styles from './index.module.scss';
import Body from './subcomponents/Body';
import Head from './subcomponents/Head';

const cx = classNames.bind(styles);

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
  type?: 'search' | 'default';
  isContour?: boolean;
  disabled?: boolean;
  sizeType?: Extract<SizeType, 'large' | 'medium' | 'xxlarge'>;
  widthType?: 'full' | 'auto' | 'default';
}

interface TextInputComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
  > {
  Head: typeof Head;
  Body: typeof Body;
}

// eslint-disable-next-line react/display-name
const TextInput = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      type = 'default',
      isContour = false,
      children,
      sizeType = 'medium',
      disabled = false,
      widthType = 'default',
      className
    },
    ref
  ) => {
    const [state, dispatch] = useReducer(reducer, initState);

    useEffect(() => {
      dispatch({
        type: ActionKind.SET_STATE,
        payload: {
          disabled,
          size: sizeType,
          type,
          widthType
        }
      });
    }, [sizeType, disabled, type, widthType]);

    return (
      <TextInputContext.Provider value={{ state, dispatch }}>
        <div
          ref={ref}
          className={`${cx(
            'container',
            widthType === 'full' && 'full-width',
            `type--${type}`,
            {
              disabled
            }
          )} ${className ?? ''}`}
        >
          {children}
          {isContour && (
            <div
              className={cx('contour', state.active && 'active', { disabled })}
            ></div>
          )}
        </div>
      </TextInputContext.Provider>
    );
  }
) as TextInputComponent;

TextInput.Head = Head;
TextInput.Body = Body;

export default TextInput;
