import { Suspense } from 'react';
import ListSkeleton from '../../../../components/molecules/ListSkeleton';
import Header from '../../../../components/organisms/Header';
import DefaultTemplate from '../../../../components/templates/Default';
import StopWordComponent from '../../../../components/pages/StopWord';
import { StopWordType } from '../../../../types/stop-word';

export const StopWordAddressPage: React.FC = () => {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'주소분석 불용어'}></Header>
        <StopWordComponent type={StopWordType['namefinder.blacklist']} />
      </Suspense>
    </DefaultTemplate>
  );
};

export default StopWordAddressPage;
