import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import { Suspense, useState } from 'react';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import { FetchAndroidAppVersionList } from '../../hooks/http/android/fetch-android-app-version-list';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Button from '../../components/atoms/Button';
import { RemoveAndroidAppVersion } from '../../hooks/http/android/remove-android-app-version';
import Selection from '../../components/atoms/Selection';
import { ModifyAndroidAppVersion } from '../../hooks/http/android/modify-android-app-version';
import CreateAndroidAppVersionModal from '../../components/features/CreateAndroidAppVersionModal';
import { CreateAndroidAppVersion } from '../../hooks/http/android/create-android-app-version';
import semver from 'semver';

const cx = classNames.bind(styles);

export default function AndroidAppVersionPage() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'안드로이드 앱 버전'}></Header>
        <AndroidMessageAppList />
      </Suspense>
    </DefaultTemplate>
  );
}

function AndroidMessageAppList() {
  const arrangeAppVersion = FetchAndroidAppVersionList.useFetch();
  const removeAndroidAppVersion = RemoveAndroidAppVersion.useMutate();
  const modifyAndroidAppVersion = ModifyAndroidAppVersion.useMutate();
  const createAndroidAppVersion = CreateAndroidAppVersion.useMutate({
    onError: (error) => {
      switch (error.response?.data.exceptionCode) {
        case 'A_V_1001':
          alert('이미 등록된 안드로이드 버전 입니다');
          break;
      }
    },
    onSettled: () => {
      setShowCreateAppVersionModal(false);
    }
  });

  const [isShowCreateAppVersionModal, setShowCreateAppVersionModal] =
    useState(false);

  const isLoading =
    removeAndroidAppVersion.isLoading ||
    modifyAndroidAppVersion.isLoading ||
    arrangeAppVersion.isLoading ||
    createAndroidAppVersion.isLoading;

  function onClickAddAndroidAppVersionBtn() {
    setShowCreateAppVersionModal(true);
  }

  function onClickRemoveAndroidAppVersion(versionId: number) {
    if (confirm('앱 버전을 삭제하히겠습니까?')) {
      removeAndroidAppVersion.mutate({ versionId: versionId });
    }
  }

  function onChangeIsCompulsionVersion(
    versionId: number,
    isCompulsionVersion: boolean
  ) {
    modifyAndroidAppVersion.mutate({ versionId, isCompulsionVersion });
  }

  function onCreateAndroidAppVersion(
    androidAppVersion: CreateAndroidAppVersion.RequestType
  ) {
    if (!androidAppVersion.androidAppType) {
      return;
    }

    if (!semver.valid(androidAppVersion.version)) {
      alert('버전이 잘 못 됐습니다.');
      return;
    }

    createAndroidAppVersion.mutate({
      androidAppType: androidAppVersion.androidAppType,
      version: androidAppVersion.version
    });
  }

  return (
    <Flex
      flexDirection="flex-col"
      width="w-full"
      alignItems="items-start"
      gapSize={StyleBuilderInstane.toGapSpaceType('gap-s')}
    >
      {isShowCreateAppVersionModal && (
        <CreateAndroidAppVersionModal
          isOpen={isShowCreateAppVersionModal}
          isLoading={isLoading}
          handleClose={() => setShowCreateAppVersionModal(false)}
          handleSave={(androidAppVersion) =>
            onCreateAndroidAppVersion(androidAppVersion)
          }
        />
      )}
      <Button
        onClick={() => onClickAddAndroidAppVersionBtn()}
        text="추가"
        isLoading={isLoading}
      />
      <table className={`${cx('table')}`}>
        <thead>
          <tr>
            <th>No</th>
            <th>버전</th>
            <th>앱 타입</th>
            <th>강제업데이트 여부</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          {arrangeAppVersion.data?.data.map((version) => (
            <tr key={version.id}>
              <td>{version.id}</td>
              <td>{version.version}</td>
              <td>{version.androidAppType}</td>
              <td>
                <Selection
                  type="toggle"
                  isChecked={version.isCompulsionVersion}
                  onChangeChecked={() =>
                    onChangeIsCompulsionVersion(
                      version.id,
                      !version.isCompulsionVersion
                    )
                  }
                  disabled={isLoading}
                />
              </td>
              <td>
                <Button
                  type="secondary-color"
                  text="삭제"
                  disabled={isLoading}
                  onClick={() => onClickRemoveAndroidAppVersion(version.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
}
