import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import ClipboardCopy from '../../molecules/ClipboardCopy';
import Flex from '../../molecules/Flex';
import List from '../../molecules/List';
import ReadMore from '../../molecules/ReadMore';
import SettingDropdown from '../../molecules/SettingDropdown';
import Table from '../../molecules/Table';
import {
  FetchProducts,
  MutationProductAddInteresting,
  MutationProductCancelInteresting
} from '../../../hooks/http/product';
import {
  DeleteItem,
  MessageItem,
  ModifyItem,
  UnExposedItem
} from '../../../types/setting-menu';
import styles from './product-data.module.scss';
import SortingWithText, { useSort } from '../../organisms/SortingWithText';
import { ProductListSortStandard } from '../../../types/sort';
import { LocationQuery } from '.';
import { DeleteProductApi } from '../../../hooks/http/product';
import {
  ChevronRightIcon,
  StarBlankIcon,
  StarFillIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import { Span } from '@aimpact-korea/arrange-front-atomic';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';

const cx = classNames.bind(styles);

const settingDropdownMenu = [
  UnExposedItem,
  MessageItem,
  ModifyItem,
  DeleteItem
];

type SettingDropdownMenuValue = typeof settingDropdownMenu[number]['value'];

export interface Props {
  keyword?: string;
  isInterestProductView?: boolean;
  locationQuery?: LocationQuery;
}

const ProductsData: React.FC<Props> = ({
  keyword,
  isInterestProductView = false,
  locationQuery
}) => {
  const { props, sort } = useSort<ProductListSortStandard>({
    initState: {
      orderBy: 'DESC',
      standardColumn: 'createdAt'
    }
  });

  const query = FetchProducts.useProducts({
    keyword,
    isInterest: isInterestProductView,
    cursor: {
      standardColumn: sort?.standardColumn,
      orderBy: sort?.orderBy
    },
    storeId: locationQuery?.storeId
  });

  return (
    <InfiniteScroll
      style={{ overflow: 'hidden' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={query
        .data!.pages.map((page) => page.data.products.length)
        .reduce((sum, current) => sum + current, 0)}
      next={query.fetchNextPage}
      hasMore={query.hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              관심상품
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              No.
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              상품코드
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체명 / 대표자
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              상품명
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              상품옵션
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <SortingWithText {...props('orderCount')}>
                거래건수
              </SortingWithText>
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              <SortingWithText {...props('updatedAt')}>
                수정일 / 등록일
              </SortingWithText>
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              설정
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {query.data?.pages.map((group, i) => (
            <ProductDataRow key={i} products={group.data.products} />
          ))}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
};

export default ProductsData;

interface ProductDataRowProps {
  products: FetchProducts.ProductsRes['products'];
}

const ProductDataRow: React.FC<ProductDataRowProps> = React.memo(
  ({ products }) => {
    const navigate = useNavigate();

    const mutationProductAddInteresting =
      MutationProductAddInteresting.useMutate();
    const mutationProductCancelInteresting =
      MutationProductCancelInteresting.useMutate();

    const redirectDetail = (productId: number) => {
      navigate(`/product/${productId}`);
    };

    const deleteProductApi = DeleteProductApi.useMutate({
      onSuccess: () => {
        alert('상품이 삭제 되었습니다.');
      }
    });

    const onSelectOption = (
      productId: number,
      value: SettingDropdownMenuValue
    ) => {
      switch (value) {
        case 'modify':
          redirectDetail(productId);
          break;
        case 'delete':
          if (confirm('상품을 정말 삭제하시겠습니까?')) {
            deleteProductApi.mutate({ productId: productId });
          }
          break;
        default:
          alert(`${value} 기능 구현중`);
      }
    };

    const onClickInteresting = (productId: number, type: 'add' | 'cancel') => {
      switch (type) {
        case 'add':
          mutationProductAddInteresting.mutate({ productId });
          break;
        case 'cancel':
          mutationProductCancelInteresting.mutate({ productId });
          break;
      }
    };

    const onClickDetail = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      productId: number
    ) => {
      e.stopPropagation();

      redirectDetail(productId);
    };

    return (
      <React.Fragment>
        {products.map((product) => (
          <Table.Body.Row key={product.id}>
            <Table.Body.Row.Cell alignType="center">
              {product.isInterestProduct ? (
                <StarFillIcon
                  colorType="text-primary-500"
                  onClick={() => onClickInteresting(product.id, 'cancel')}
                />
              ) : (
                <StarBlankIcon
                  onClick={() => onClickInteresting(product.id, 'add')}
                  colorType="text-gray-500"
                />
              )}
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text size="small" isBold={true}>
                No. {product.id}
              </Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <List gap="xsmall">
                <List.Item>
                  <Text isBold={true}>{product.code}</Text>
                </List.Item>
                <List.Item>
                  <ClipboardCopy text={product.code}>
                    <Button text="복사" size="small" fit={true} />
                  </ClipboardCopy>
                </List.Item>
              </List>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <List gap="small">
                <List.Item>
                  <Flex gap="large" direction="row">
                    <Text isBold={true}>{product.storeName}</Text>
                    <Text>{product.ceoName}</Text>
                  </Flex>
                </List.Item>
                <List.Item>
                  <Flex
                    direction="row"
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => onClickDetail(e, product.id)}
                  >
                    <Text colorType="gray-color" isBold={true}>
                      상세보기
                    </Text>
                    <ChevronRightIcon colorType="text-gray-500" />
                  </Flex>
                </List.Item>
              </List>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text>
                <Span
                  fontColorType={StyleBuilderInstane.toFontColorType(
                    product.isLegacy ? 'text-error' : 'text-black'
                  )}
                >
                  {product.productName}
                </Span>
              </Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <ProductOption options={product.options} />
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text>{product.orderCount}건</Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <List gap="medium">
                <List.Item>
                  <div className={cx('date-flex')}>
                    <Text colorType="gray-color">수정</Text>
                    <Text tag="p">
                      {dayjs(product.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                    </Text>
                  </div>
                </List.Item>
                <List.Item>
                  <div className={cx('date-flex')}>
                    <Text colorType="gray-color">등록</Text>
                    <Text tag="p">
                      {dayjs(product.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </Text>
                  </div>
                </List.Item>
              </List>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <SettingDropdown
                settingMenus={settingDropdownMenu}
                onSelect={(v) =>
                  onSelectOption(product.id, v as SettingDropdownMenuValue)
                }
              />
            </Table.Body.Row.Cell>
          </Table.Body.Row>
        ))}
      </React.Fragment>
    );
  }
);
ProductDataRow.displayName = 'ProductDataRow';

interface ProductOptionProps {
  options: FetchProducts.ProductsRes['products'][number]['options'];
}

const cutLine = 5;

const ProductOption: React.FC<ProductOptionProps> = ({ options }) => {
  const [readmoreTotalItem, setReadmoreTotalItem] = useState(0);
  const [readmore, setReadmore] = useState(false);

  return (
    <ReadMore setReadMore={setReadmore} readMore={readmore}>
      <List gap="small">
        <ReadMore.ItemWrapper
          cutline={cutLine}
          onChangeItem={(count) => setReadmoreTotalItem(count)}
        >
          {options.map((option) => (
            <div key={option.name} className="flex">
              <Text>{option.name} / </Text>
              <Text>{option.price.toLocaleString()}원</Text>
            </div>
          ))}
        </ReadMore.ItemWrapper>
        {!readmore && readmoreTotalItem > cutLine && (
          <Text
            onClick={() => setReadmore(true)}
            colorType="gray-color"
            style={{ cursor: 'pointer' }}
          >
            + {readmoreTotalItem - cutLine}개의 상품 옵션
          </Text>
        )}
        {readmore && (
          <Text
            colorType="gray-color"
            style={{ cursor: 'pointer' }}
            onClick={() => setReadmore(false)}
          >
            숨기기
          </Text>
        )}
      </List>
    </ReadMore>
  );
};
