import Skeleton from '../../molecules/Skeleton';

const DetailSkeletion: React.FC = () => {
  return (
    <Skeleton viewBox="0 0 1642 969">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1610 288H2V287H1610V288Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1610 515H2V514H1610V515Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1610 742H2V741H1610V742Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1610 969H2V968H1610V969Z"
        fill="#C4C4C4"
      />
      <path
        d="M361 177C361 172.582 364.582 169 369 169H666C670.418 169 674 172.582 674 177V244C674 248.418 670.418 252 666 252H369C364.582 252 361 248.418 361 244V177Z"
        fill="#C4C4C4"
      />
      <path
        d="M361 401C361 396.582 364.582 393 369 393H666C670.418 393 674 396.582 674 401V468C674 472.418 670.418 476 666 476H369C364.582 476 361 472.418 361 468V401Z"
        fill="#C4C4C4"
      />
      <path
        d="M722 401C722 396.582 725.582 393 730 393H1027C1031.42 393 1035 396.582 1035 401V468C1035 472.418 1031.42 476 1027 476H730C725.582 476 722 472.418 722 468V401Z"
        fill="#C4C4C4"
      />
      <path
        d="M1083 401C1083 396.582 1086.58 393 1091 393H1388C1392.42 393 1396 396.582 1396 401V468C1396 472.418 1392.42 476 1388 476H1091C1086.58 476 1083 472.418 1083 468V401Z"
        fill="#C4C4C4"
      />
      <path
        d="M361 628C361 623.582 364.582 620 369 620H666C670.418 620 674 623.582 674 628V695C674 699.418 670.418 703 666 703H369C364.582 703 361 699.418 361 695V628Z"
        fill="#C4C4C4"
      />
      <path
        d="M722 628C722 623.582 725.582 620 730 620H1027C1031.42 620 1035 623.582 1035 628V695C1035 699.418 1031.42 703 1027 703H730C725.582 703 722 699.418 722 695V628Z"
        fill="#C4C4C4"
      />
      <path
        d="M1083 628C1083 623.582 1086.58 620 1091 620H1388C1392.42 620 1396 623.582 1396 628V695C1396 699.418 1392.42 703 1388 703H1091C1086.58 703 1083 699.418 1083 695V628Z"
        fill="#C4C4C4"
      />
      <path
        d="M361 855C361 850.582 364.582 847 369 847H666C670.418 847 674 850.582 674 855V922C674 926.418 670.418 930 666 930H369C364.582 930 361 926.418 361 922V855Z"
        fill="#C4C4C4"
      />
      <path
        d="M722 855C722 850.582 725.582 847 730 847H1027C1031.42 847 1035 850.582 1035 855V922C1035 926.418 1031.42 930 1027 930H730C725.582 930 722 926.418 722 922V855Z"
        fill="#C4C4C4"
      />
      <path
        d="M1083 855C1083 850.582 1086.58 847 1091 847H1388C1392.42 847 1396 850.582 1396 855V922C1396 926.418 1392.42 930 1388 930H1091C1086.58 930 1083 926.418 1083 922V855Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 108C0 103.582 3.58172 100 8 100H198C202.418 100 206 103.582 206 108V125C206 129.418 202.418 133 198 133H8C3.58172 133 0 129.418 0 125V108Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 332C0 327.582 3.58172 324 8 324H198C202.418 324 206 327.582 206 332V349C206 353.418 202.418 357 198 357H8C3.58172 357 0 353.418 0 349V332Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 559C0 554.582 3.58172 551 8 551H198C202.418 551 206 554.582 206 559V576C206 580.418 202.418 584 198 584H8C3.58172 584 0 580.418 0 576V559Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 786C0 781.582 3.58172 778 8 778H198C202.418 778 206 781.582 206 786V803C206 807.418 202.418 811 198 811H8C3.58172 811 0 807.418 0 803V786Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 177C0 172.582 3.58172 169 8 169H305C309.418 169 313 172.582 313 177V244C313 248.418 309.418 252 305 252H8.00001C3.58173 252 0 248.418 0 244V177Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 401C0 396.582 3.58172 393 8 393H305C309.418 393 313 396.582 313 401V468C313 472.418 309.418 476 305 476H8.00001C3.58173 476 0 472.418 0 468V401Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 628C0 623.582 3.58172 620 8 620H305C309.418 620 313 623.582 313 628V695C313 699.418 309.418 703 305 703H8.00001C3.58173 703 0 699.418 0 695V628Z"
        fill="#C4C4C4"
      />
      <path
        d="M0 855C0 850.582 3.58172 847 8 847H305C309.418 847 313 850.582 313 855V922C313 926.418 309.418 930 305 930H8.00001C3.58173 930 0 926.418 0 922V855Z"
        fill="#C4C4C4"
      />
      <path
        d="M1164 107C1164 102.582 1167.58 99 1172 99H1362C1366.42 99 1370 102.582 1370 107V124C1370 128.418 1366.42 132 1362 132H1172C1167.58 132 1164 128.418 1164 124V107Z"
        fill="#C4C4C4"
      />
      <path
        d="M1404 108C1404 103.582 1407.58 100 1412 100H1602C1606.42 100 1610 103.582 1610 108V125C1610 129.418 1606.42 133 1602 133H1412C1407.58 133 1404 129.418 1404 125V108Z"
        fill="#C4C4C4"
      />
      <line x1="2" y1="66.5002" x2="1642" y2="66.5001" stroke="#727272" />
      <path
        d="M18 8C18 3.58172 21.5817 0 26 0H141C145.418 0 149 3.58172 149 8V24C149 28.4183 145.418 32 141 32H26C21.5817 32 18 28.4183 18 24V8Z"
        fill="#C4C4C4"
      />
      <path
        d="M1549 8C1549 3.58172 1552.58 0 1557 0H1618C1622.42 0 1626 3.58172 1626 8V24C1626 28.4183 1622.42 32 1618 32H1557C1552.58 32 1549 28.4183 1549 24V8Z"
        fill="#C4C4C4"
      />
      <path
        d="M1254 8C1254 3.58172 1257.58 0 1262 0H1533C1537.42 0 1541 3.58172 1541 8V24C1541 28.4183 1537.42 32 1533 32H1262C1257.58 32 1254 28.4183 1254 24V8Z"
        fill="#C4C4C4"
      />
    </Skeleton>
  );
};

export default DetailSkeletion;
