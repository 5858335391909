import classNames from 'classnames/bind';
import React, { Suspense, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useLocationSearchQueryParam } from '../../../hooks/useLocationSearchQueryParam';
import { Type } from '../../../utils/decorators';
import Button from '../../atoms/Button';
import DropdownMenu from '../../molecules/DropdownMenu';
import ListSkeleton from '../../molecules/ListSkeleton';
import Header from '../../organisms/Header';
import InputSearch from '../../organisms/InputSearch';
import DefaultTemplate from '../../templates/Default';
import styles from './index.module.scss';
import ProductsData from './ProductsData';
import qs from 'qs';
import { FileIcon } from '@aimpact-korea/arrange-front-icons/build/icons';

const cx = classNames.bind(styles);

export interface Props {
  isInterestProductView: boolean;
}

export class LocationQuery {
  @Type(() => Number)
  storeId?: number;
  @Type(() => String)
  keyword?: string;
}

const Products: React.FC<Props> = ({ isInterestProductView }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // const currentUrlParams = new URLSearchParams(history.location.search);

  const locationQuery = useLocationSearchQueryParam(LocationQuery);

  const [orderDropdownActive, setOrderDropdownActive] = useState(false);

  // @TODO: 엑셀내보내기 기능 만들면 SortingExcelExport component화 시키기 (다른 페이지에서도 사용되고 있음)
  const [orderDropdownValue, setOrderDropdownValue] = useState('주문수');

  const currentRedirectFromParam = ({ keyword }: { keyword?: string }) => {
    const queryStringObject = {
      ...locationQuery,
      keyword: keyword
    };

    navigate(`${location.pathname}?${qs.stringify(queryStringObject)}`);
  };

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header
          currentPageName={isInterestProductView ? '관심상품' : '업체상품'}
        >
          <div className={cx('header-container')}>
            <div className={cx('flex')}>
              <DropdownMenu
                value={orderDropdownValue}
                size="large"
                isActive={orderDropdownActive}
                setActive={setOrderDropdownActive}
                setValue={setOrderDropdownValue}
              >
                <DropdownMenu.Title isBottomLine={true}>
                  <DropdownMenu.Title.UpDownIcon />
                  <DropdownMenu.Title.Text
                    colorType={'primary-color'}
                    placeholder=""
                  />
                </DropdownMenu.Title>
                <DropdownMenu.ItemWrapper size="medium">
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup
                    isContour={true}
                  >
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item value="주문수">
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                        text="주문수"
                        colorType={'primary-color'}
                      />
                    </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                    <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item value="날짜">
                      <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                        text="날짜"
                        colorType={'primary-color'}
                      />
                    </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
                  </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                </DropdownMenu.ItemWrapper>
              </DropdownMenu>

              <Button
                text="엑셀 내보내기"
                size="large"
                startIcon={<FileIcon colorType="text-white" />}
              />
            </div>
            <InputSearch
              placeholder="상품검색"
              onClickSearch={(text) =>
                currentRedirectFromParam({ keyword: text })
              }
            />
          </div>
        </Header>

        <ProductsData
          keyword={locationQuery.keyword}
          isInterestProductView={isInterestProductView}
          locationQuery={locationQuery}
        />
      </Suspense>
    </DefaultTemplate>
  );
};

export default Products;
