import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchArrangeManualGroupDetail } from './fetch-arrange-manual';
import { FetchArrangeManualGroup } from './fetch-arrange-manual-group';

export namespace ModifyOrderArrangeManualGroup {
  export type RequestType = {
    arrangeManualGroupId: number;
    title?: string;
    thumbnailImageAttachmentId?: number | null;
    code?: string;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { refetch } = FetchArrangeManualGroupDetail.useRefetchData();
    const { refetch: refetchGroup } = FetchArrangeManualGroup.useRefetchData();

    const mutation = useMutation(
      ({ arrangeManualGroupId, title, thumbnailImageAttachmentId, code }) => {
        return axios.put(`/arrange-manual/group/${arrangeManualGroupId}`, {
          title,
          thumbnailImageAttachmentId,
          code
        });
      },
      {
        onSuccess: (...rest) => {
          refetch({ arrangeManualGroupId: rest[1].arrangeManualGroupId });
          refetchGroup();
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
