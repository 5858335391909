import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import {
  ChevronDownIcon,
  ChevronUpIcon
} from '@aimpact-korea/arrange-front-icons';
import classNames from 'classnames/bind';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import Button from '../../../components/atoms/Button';
import ListSkeleton from '../../../components/molecules/ListSkeleton';
import Header from '../../../components/organisms/Header';
import DefaultTemplate from '../../../components/templates/Default';
import { FetchArrangeManualGroupDetail } from '../../../hooks/http/arrange-manual/fetch-arrange-manual';
import { ModifyOrderArrangeManual } from '../../../hooks/http/arrange-manual/modify-arrange-manual';
import { SwapOrderArrangeManual } from '../../../hooks/http/arrange-manual/swap-arrange-manual';
import { useLocationStateParam } from '../../../hooks/useLocationSearchQueryParam';
import { Type } from '../../../utils/decorators';
import styles from './index.module.scss';
import CreateArrangeManualModal from '../../../components/features/CreateArrangeManualModal';
import { RemoveArrangeManual } from '../../../hooks/http/arrange-manual/remove-arrange-manual';
import { CreateArrangeManual } from '../../../hooks/http/arrange-manual/create-arrange-manual';

const cx = classNames.bind(styles);

class PathParams {
  @Type(() => Number)
  arrangeManualGroupId!: number;
}

export default function ArrangeManualGroupDetailVideo() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <ArrangeManualGroupDetailVideoCompo />
      </Suspense>
    </DefaultTemplate>
  );
}

function ArrangeManualGroupDetailVideoCompo() {
  const locationQueryString = useLocationStateParam(PathParams);

  const arrangeManualGroupDetail = FetchArrangeManualGroupDetail.useFetch({
    arrangeManualGroupId: locationQueryString.arrangeManualGroupId
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const arrangeManualGroupDetailData = arrangeManualGroupDetail.data!.data!;

  return (
    <React.Fragment>
      <Header
        currentPageName={`어레인지 매뉴얼 - ${arrangeManualGroupDetailData.title}`}
      ></Header>
      <ArrangeManualDetailVideoList
        arrangeManualGroupId={locationQueryString.arrangeManualGroupId}
      />
    </React.Fragment>
  );
}

function ArrangeManualDetailVideoList({
  arrangeManualGroupId
}: {
  arrangeManualGroupId: number;
}) {
  const arrangeManualGroupDetail = FetchArrangeManualGroupDetail.useFetch({
    arrangeManualGroupId
  });
  const swapOrderArrangeManual = SwapOrderArrangeManual.useMutate();
  const [isShowArrangeManualModifyModal, setShowArrangeManualModifyModal] =
    useState(false);
  const [isShowArrangeManualCreateModal, setShowArrangeManualCreateModal] =
    useState(false);
  const [selectModifyMovie, setSelectModifyMovie] = useState<{
    movieId: string;
    id: number;
    title: string;
    isImportant: boolean;
    code: string;
    thumbnailImage: {
      attachmentId: number;
      imageUrl: string;
    } | null;
  } | null>(null);
  const initManualState = useRef({
    isImportant: false,
    movieId: '',
    title: '',
    code: '',
    thumbnailImage: null
  });
  const modifyOrderArrangeManual = ModifyOrderArrangeManual.useMutate({
    onSuccess: () => {
      setShowArrangeManualModifyModal(false);
      alert('어레인지 사용법 동영상이 수정 되었습니다.');
    }
  });
  const createOrderArrangeManual = CreateArrangeManual.useMutate({
    onSuccess: () => {
      setShowArrangeManualCreateModal(false);
      alert('어레인지 사용법 동영상이 추가 되었습니다.');
    }
  });
  const removeArrangeManual = RemoveArrangeManual.useMutate({
    onSuccess: () => {
      alert('어레인지 매뉴얼 동영상이 삭제 되었습니다.');
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const arrangeManualGroupDetailData = arrangeManualGroupDetail.data!.data!;

  useEffect(() => {
    if (!isShowArrangeManualModifyModal) {
      setSelectModifyMovie(null);
    }
  }, [isShowArrangeManualModifyModal]);

  function arrangeManualSwapHandle(id: number, targetId: number | null) {
    if (!targetId) {
      return;
    }

    arrangeManualGroupSwap(id, targetId);
  }

  function arrangeManualGroupSwap(id: number, targetId: number) {
    swapOrderArrangeManual.mutate({
      arrangeManualId: id,
      targetArrangeManualId: targetId,
      arrangeManualGroupId: arrangeManualGroupDetailData.id
    });
  }

  function onClickRedirectArrangeManual(manualId: number) {
    window.open(
      `${process.env.REACT_APP_ARRANGE_WEB_HOST}/manual/${arrangeManualGroupDetailData.id}/${manualId}`,
      '_blank'
    );
  }

  function onClickShowArrangeManualModifyModal(
    arrangeManual: typeof selectModifyMovie
  ) {
    setSelectModifyMovie(arrangeManual);
    setShowArrangeManualModifyModal(true);
  }

  function onClickArrangeManualModify(arrangeManual: {
    isImportant: boolean;
    title: string;
    movieId: string;
    code: string;
    thumbnailImage: {
      attachmentId: number;
      imageUrl: string;
    } | null;
  }) {
    if (selectModifyMovie) {
      modifyOrderArrangeManual.mutate({
        ...arrangeManual,
        thumbnailImageAttachmentId:
          arrangeManual.thumbnailImage?.attachmentId ?? null,
        arrangeManualGroupId: arrangeManualGroupDetailData.id,
        arrangeManualId: selectModifyMovie.id
      });
    }
  }

  function onClickArrangeManualCreate(arrangeManual: {
    isImportant: boolean;
    title: string;
    movieId: string;
    code: string;
    thumbnailImage: {
      attachmentId: number;
      imageUrl: string;
    } | null;
  }) {
    createOrderArrangeManual.mutate({
      ...arrangeManual,
      arrangeManualGroupId: arrangeManualGroupDetailData.id,
      thumbnailImageAttachmentId:
        arrangeManual.thumbnailImage?.attachmentId ?? null
    });
  }

  function onClickRemoveArrangeManual(arrangeManual: {
    id: number;
    title: string;
  }) {
    if (
      confirm(
        `어레인지 매뉴얼 동영상 - '${arrangeManual.title}'을 삭제하시겠습니까?`
      )
    ) {
      removeArrangeManual.mutate({
        arrangeManualGroupId: arrangeManualGroupDetailData.id,
        arrangeManualId: arrangeManual.id
      });
    }
  }

  return (
    <Flex
      flexDirection="flex-col"
      width="w-full"
      alignItems="items-start"
      gapSize={StyleBuilderInstane.toGapSpaceType('gap-s')}
    >
      <Flex>
        <Button
          text="매뉴얼 추가"
          fullwidth={true}
          onClick={() => {
            setShowArrangeManualCreateModal(true);
          }}
        />
      </Flex>
      {selectModifyMovie && isShowArrangeManualModifyModal && (
        <CreateArrangeManualModal
          isOpen={isShowArrangeManualModifyModal}
          arrangeManual={selectModifyMovie}
          handleClose={() => setShowArrangeManualModifyModal(false)}
          handleSave={(arrangeManual) =>
            onClickArrangeManualModify(arrangeManual)
          }
          isLoading={modifyOrderArrangeManual.isLoading}
        />
      )}
      {isShowArrangeManualCreateModal && (
        <CreateArrangeManualModal
          isOpen={isShowArrangeManualCreateModal}
          arrangeManual={initManualState.current}
          handleClose={() => setShowArrangeManualCreateModal(false)}
          handleSave={(arrangeManual) =>
            onClickArrangeManualCreate(arrangeManual)
          }
          isLoading={modifyOrderArrangeManual.isLoading}
        />
      )}
      <table className={`${cx('table')}`}>
        <thead>
          <tr>
            <th>순서변경</th>
            <th>제목</th>
            <th>유튜브 동영상 코드</th>
            <th>수정</th>
            <th>바로가기</th>
          </tr>
        </thead>
        <tbody>
          {arrangeManualGroupDetailData.itemList.map((arrangeManual, index) => (
            <tr key={arrangeManual.id}>
              <th>
                <Flex flexDirection="flex-col">
                  <ChevronUpIcon
                    className={`${cx('order-btn')}`}
                    onClick={() =>
                      arrangeManualSwapHandle(
                        arrangeManual.id,
                        arrangeManualGroupDetailData.itemList[index - 1]?.id ??
                          null
                      )
                    }
                  ></ChevronUpIcon>
                  <ChevronDownIcon
                    className={`${cx('order-btn')}`}
                    onClick={() =>
                      arrangeManualSwapHandle(
                        arrangeManual.id,
                        arrangeManualGroupDetailData.itemList[index + 1]?.id ??
                          null
                      )
                    }
                  ></ChevronDownIcon>
                </Flex>
              </th>
              <th>{arrangeManual.title}</th>
              <th>{arrangeManual.movieId}</th>
              <th>
                <Flex gapSize={StyleBuilderInstane.toGapSpaceType('gap-xs')}>
                  <Button
                    text="수정"
                    onClick={() =>
                      onClickShowArrangeManualModifyModal({
                        id: arrangeManual.id,
                        isImportant: arrangeManual.isImportant,
                        movieId: arrangeManual.movieId,
                        title: arrangeManual.title,
                        code: arrangeManual.code,
                        thumbnailImage: arrangeManual.thumbnailImage
                          ? {
                              attachmentId:
                                arrangeManual.thumbnailImage.attachmentId,
                              imageUrl: arrangeManual.thumbnailImage.url
                            }
                          : null
                      })
                    }
                  />
                  <Button
                    text="삭제"
                    type="secondary-color"
                    onClick={() => onClickRemoveArrangeManual(arrangeManual)}
                  />
                </Flex>
              </th>
              <th>
                <Button
                  text="보기"
                  onClick={() => onClickRedirectArrangeManual(arrangeManual.id)}
                />
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
}
