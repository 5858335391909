import dayjs from 'dayjs';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Text from '../../components/atoms/Text';
import Flex from '../../components/molecules/Flex';
import SettingDropdown from '../../components/molecules/SettingDropdown';
import Table from '../../components/molecules/Table';
import SortingWithText, {
  useSort
} from '../../components/organisms/SortingWithText';
import { FetchDeliveryForms } from '../../hooks/http/delivery-form';
import {
  DeliveryFormCreatedTypeColor,
  deliveryFormSelectedColor
} from '../../types/delivery-form';
import { DeleteItem, ModifyItem } from '../../types/setting-menu';
import { deliveryFormCreatedTypeToString } from '../../utils/format/delivery-form';
import { DeliveryFormListSortStandard } from '../../types/sort';
import { useNavigate } from 'react-router';
import { ChevronRightIcon } from '@aimpact-korea/arrange-front-icons/build/icons';

const settingDropdownMenu = [ModifyItem, DeleteItem];

type SettingDropdownMenuValue = typeof settingDropdownMenu[number]['value'];

const DeliveryFormsData: React.FC = () => {
  const { props, sort } = useSort<DeliveryFormListSortStandard>({
    initState: {
      orderBy: 'DESC',
      standardColumn: 'updatedAt'
    }
  });

  const query = FetchDeliveryForms.useDeliveryForms({
    limit: 15,
    cursor: {
      orderBy: sort?.orderBy,
      standardColumn: sort?.standardColumn
    }
  });

  return (
    <InfiniteScroll
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={query
        .data!.pages.map((page) => page.data.orderExcels.length)
        .reduce((sum, current) => sum + current, 0)}
      next={query.fetchNextPage}
      hasMore={query.hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              No.
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체명 / 대표자
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              설정타입
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              택배사
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              사용여부
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <SortingWithText {...props('updatedAt')}>
                수정일 / 등록일
              </SortingWithText>
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              설정
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {query.data?.pages.map((group, i) => (
            <DeliveryFormsDataRow
              key={i}
              deliveryForms={group.data.orderExcels}
            />
          ))}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
};

export default DeliveryFormsData;

interface DeliveryFormsDataRowProps {
  deliveryForms: FetchDeliveryForms.DeliveryFormsRes['orderExcels'];
}

const DeliveryFormsDataRow: React.FC<DeliveryFormsDataRowProps> = React.memo(
  ({ deliveryForms }) => {
    const navigate = useNavigate();

    const detailRedirect = (deliveryId: number) => {
      navigate(`/delivery-form/${deliveryId}`);
    };

    const onSelectOption = (id: number, value: SettingDropdownMenuValue) => {
      switch (value) {
        case 'modify':
          detailRedirect(id);
          break;
        default:
          alert(`${value} 구현중`);
      }
    };

    const onClickDetail = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      deliveryId: number
    ) => {
      e.stopPropagation();

      detailRedirect(deliveryId);
    };

    return (
      <React.Fragment>
        {deliveryForms.map((deliveryForm) => (
          <Table.Body.Row key={deliveryForm.id}>
            <Table.Body.Row.Cell alignType="center">
              <Text size="small" isBold={true}>
                No. {deliveryForm.id}
              </Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="left">
              <Flex gap="medium">
                <Text isBold={true}>{deliveryForm.store.storeName}</Text>
                <Text>{deliveryForm.store.ceoName}</Text>
              </Flex>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text
                isBold={true}
                colorType={
                  DeliveryFormCreatedTypeColor[deliveryForm.createdType]
                }
              >
                {deliveryFormCreatedTypeToString(deliveryForm.createdType)}
              </Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Flex gap="xsmall">
                <Text>{deliveryForm.courierTypeKo}</Text>
                <Flex
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => onClickDetail(e, deliveryForm.id)}
                >
                  <Text colorType="gray-color" isBold={true}>
                    상세보기
                  </Text>
                  <ChevronRightIcon colorType="text-gray-500" />
                </Flex>
              </Flex>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Text
                colorType={deliveryFormSelectedColor(deliveryForm.isSelected)}
                isBold={true}
              >
                {deliveryForm.isSelected ? '사용중' : '미사용'}
              </Text>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <Flex direction="column" gap="small" alignItems="center">
                <Flex gap="medium" direction="row">
                  <Text colorType="gray-color">수정</Text>
                  <Text colorType="black">
                    {dayjs(deliveryForm.updatedAt).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}
                  </Text>
                </Flex>
                <Flex gap="medium" direction="row">
                  <Text colorType="gray-color">등록</Text>
                  <Text colorType="black">
                    {dayjs(deliveryForm.createdAt).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}
                  </Text>
                </Flex>
              </Flex>
            </Table.Body.Row.Cell>
            <Table.Body.Row.Cell alignType="center">
              <SettingDropdown
                settingMenus={settingDropdownMenu}
                onSelect={(v) =>
                  onSelectOption(deliveryForm.id, v as SettingDropdownMenuValue)
                }
              />
            </Table.Body.Row.Cell>
          </Table.Body.Row>
        ))}
      </React.Fragment>
    );
  }
);

DeliveryFormsDataRow.displayName = 'DeliveryFormsDataRow';
