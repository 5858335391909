import axios from '../../../../utils/axios';
import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery
} from '@tanstack/react-query';
import {
  LinkfarmProducInspectiontStats,
  LinkfarmProductTaxType
} from '../../../../types/linkfarm/product';
import {
  LinkfarmProductsCursorStandard,
  SortState
} from '../../../../types/sort';
import { AxiosError, AxiosResponse } from 'axios';
import { isNextCursor, QueryInfiniteCallback } from '../..';
import { useErrorHandler } from 'react-error-boundary';
import { LinkfarmProductType } from '@aimpact-korea/arrange-front-types';

export type LinkfarmProductsCurser = {
  id?: number;
  value?: string;
} & SortState<LinkfarmProductsCursorStandard>;

export type LinkfarmProductsRes = {
  linkfarmProducts: {
    id: number;
    reactionScore: number;
    storeTitle: string;
    inspectiontRequestFailReason: string | null;
    storeId: number;
    enabledBarofarm: boolean;
    inspectiontStatus: LinkfarmProducInspectiontStats;
    linkfarmFullUrl: string;
    inspectiontRequestAt: string | null;
    taxType: LinkfarmProductTaxType;
    barofarmProductPickuponSiteEndAt: string | null;
    barofarmProductPickuponSiteStartAt: string | null;
    images: {
      attachmentId: number;
      url: string;
    }[];
    isPickupOnSite: boolean;
    isVisibleLinkfarmMain: boolean;
    linkfarmProductType: LinkfarmProductType;
    product: {
      id: number;
      name: string;
      options: {
        name: string;
        stock: number | null;
        code: string;
        price: number | null;
        normalPrice: number;
      }[];
    };
    store: {
      id: number;
      company: string;
      ceoName: string;
      ceoPhone: string;
    };
    createdAt: string;
    updatedAt: string;
    pickuponSiteRound: {
      id: number;
      pickupStartAt: string;
      pickupEndAt: string;
      purchaseStartDate: string;
      purchaseEndDate: string;
      pickuponSite: {
        id: number;
        name: string;
        address: string;
        detailAddress: string;
        postCode: string;
        displayOrder: number;
      };
    } | null;
    linkfarmProductResell: {
      id: number;
    } | null;
  }[];
  cursor: LinkfarmProductsCurser;
};

export type LinkfarmProductsParam = {
  keyword?: string;
  cursor?: LinkfarmProductsCurser;
  take?: number;
  linkfarmProducInspectiontStatses?: LinkfarmProducInspectiontStats[];
  linkfarmProductType?: LinkfarmProductType;
};

export const LINK_FARM_FETCH_PRODUCT_KEY_STRING =
  'fetch-linkfarm-products' as const;

type LinkfarmProductsQueryKeyType = [
  typeof LINK_FARM_FETCH_PRODUCT_KEY_STRING,
  LinkfarmProductsParam
];
type LinkfarmProductsQueryKey = LinkfarmProductsQueryKeyType & QueryKey;

export async function fetchLinkfarmProducts({
  pageParam,
  queryKey
}: QueryFunctionContext<LinkfarmProductsQueryKey, LinkfarmProductsCurser>) {
  const _queryKey = queryKey as LinkfarmProductsQueryKeyType;

  const urlSearchParams = new URLSearchParams();

  if (pageParam) {
    if (pageParam.id) {
      urlSearchParams.append('cursor[id]', `${pageParam.id}`);
    }
    if (pageParam.orderBy) {
      urlSearchParams.append('cursor[orderBy]', `${pageParam.orderBy}`);
    }
    if (pageParam.standardColumn) {
      urlSearchParams.append(
        'cursor[standardColumn]',
        `${pageParam.standardColumn}`
      );
    }
    if (pageParam.value) {
      urlSearchParams.append('cursor[value]', `${pageParam.value}`);
    }
  } else if (_queryKey[1].cursor?.standardColumn) {
    if (_queryKey[1].cursor.id) {
      urlSearchParams.append('cursor[id]', `${_queryKey[1].cursor.id}`);
    }
    if (_queryKey[1].cursor.orderBy) {
      urlSearchParams.append(
        'cursor[orderBy]',
        `${_queryKey[1].cursor.orderBy}`
      );
    }
    if (_queryKey[1].cursor.standardColumn) {
      urlSearchParams.append(
        'cursor[standardColumn]',
        `${_queryKey[1].cursor.standardColumn}`
      );
    }
    if (_queryKey[1].cursor.value) {
      urlSearchParams.append('cursor[value]', `${_queryKey[1].cursor.value}`);
    }
  }

  const queryParam = `?${urlSearchParams.toString()}`;

  return await axios.get<LinkfarmProductsRes>(
    '/store/linkfarm/product' + queryParam,
    {
      params: {
        keyword: _queryKey[1].keyword || undefined,
        take: _queryKey[1].take,
        linkfarmProducInspectiontStatses:
          _queryKey[1].linkfarmProducInspectiontStatses,
        linkfarmProductType: _queryKey[1].linkfarmProductType
      }
    }
  );
}

export const useLinkfarmProducts = (
  {
    cursor: { id, orderBy, standardColumn: standard, value } = {},
    keyword = '',
    take = 15,
    linkfarmProducInspectiontStatses,
    linkfarmProductType
  }: LinkfarmProductsParam = {},
  {
    ...props
  }: QueryInfiniteCallback<
    AxiosResponse<LinkfarmProductsRes>,
    AxiosError,
    AxiosResponse<LinkfarmProductsRes>,
    LinkfarmProductsQueryKeyType
  > = {}
) => {
  const errorHandler = useErrorHandler();

  const query = useInfiniteQuery(
    [
      LINK_FARM_FETCH_PRODUCT_KEY_STRING,
      {
        cursor: { id, value, standardColumn: standard, orderBy },
        keyword,
        take,
        linkfarmProducInspectiontStatses,
        linkfarmProductType
      }
    ],
    fetchLinkfarmProducts,
    {
      getNextPageParam: (data, datas) => {
        return isNextCursor(data.data.cursor, datas);
      },
      refetchOnWindowFocus: false,
      staleTime: 300000,
      onError: errorHandler,
      ...props
    }
  );

  return query;
};
