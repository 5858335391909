import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import axios from '../../../utils/axios';
import { FetchStores } from '../store';

export namespace RequestAndroidAppReviewApi {
  export type Param = {
    accountId: number;
  };

  export const useMutate = ({
    onError,
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, Param> = {}) => {
    const { requestAndroidAppReview } = FetchStores.useModify();

    const mutation = useMutation(
      ({ accountId }) => {
        return axios.patch(`/accounts/${accountId}/request-android-app-review`);
      },
      {
        onError: (...rest) => {
          const error = rest[0];

          switch (error.response?.status) {
            case StatusCodes.NOT_FOUND:
              alert('존재하지 않는 유저입니다.');
              break;
          }

          onError?.(...rest);
        },
        onSuccess: (...rest) => {
          requestAndroidAppReview(rest[1].accountId);

          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
