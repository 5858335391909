import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { FetchResellerRequest } from './fetch-reseller-request';

export namespace ApproveResellerRequest {
  export type RequestType = {
    resellerRequestId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { remove } = FetchResellerRequest.useModifyResellerRequest();

    const mutation = useMutation(
      ({ resellerRequestId }) => {
        return axios.patch(`/reseller/request/approve/${resellerRequestId}`);
      },
      {
        onSuccess: (...rest) => {
          remove(rest[1].resellerRequestId);
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
