import classNames from 'classnames/bind';
import React from 'react';
import { SizeType } from '../../../types/style';
import styles from './index.module.scss';
import Item from './subcomponents/Item';

const cx = classNames.bind(styles);

export type Props = (
  | React.ComponentPropsWithoutRef<'ol'>
  | React.ComponentPropsWithoutRef<'ul'>
) & {
  gap?: Extract<
    SizeType,
    'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
  >;
};

export interface TableComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLUListElement | HTMLOListElement>
  > {
  Item: typeof Item;
}

// eslint-disable-next-line react/display-name
const List = React.forwardRef<HTMLUListElement | HTMLOListElement, Props>(
  ({ children, className = '', gap, ...props }, ref) => {
    return (
      <ul
        className={`${cx('container', `${gap && `gap--${gap}`}`)} ${className}`}
        ref={ref}
        {...props}
      >
        {children}
      </ul>
    );
  }
) as TableComponent;

List.displayName = 'Table';

List.Item = Item;
export default List;
