import { SearchIcon as SearchIconSvg } from '@aimpact-korea/arrange-front-icons/build/icons';
import BodyIcon from './BodyIcon';

export interface Props {
  onClick?: () => void;
}

const SearchIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <BodyIcon
      IconComponent={SearchIconSvg}
      onClick={() => onClick?.()}
      fill="text-black"
    />
  );
};

export default SearchIcon;
