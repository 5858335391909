export enum ActionKind {
  SET_SIZE = 'SET_SIZE'
}

export interface SetSize {
  type: ActionKind.SET_SIZE;
  payload: number[];
}

export type Actions = SetSize;
