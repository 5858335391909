import {
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchPickuponSiteDetail } from './fetch-pickupon-site-detail';

export namespace CreatePickuponSiteRound {
  export type RequestType = {
    pickupOnSiteId: number;
    name: string;
    pickupStartAt: string;
    pickupEndAt: string;
    purchaseStartDate: string;
    purchaseEndDate: string;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({
        name,
        pickupEndAt,
        pickupOnSiteId,
        pickupStartAt,
        purchaseEndDate,
        purchaseStartDate
      }) => {
        return axios.post(`pickupon-site/${pickupOnSiteId}/round`, {
          pickupStartAt,
          purchaseEndDate,
          purchaseStartDate,
          name,
          pickupEndAt
        });
      },
      {
        onSuccess: (...rest) => {
          queryClient.refetchQueries([
            FetchPickuponSiteDetail.KEY_STRING,
            { pickupOnSiteId: rest[1].pickupOnSiteId }
          ]);

          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
