import classNames from 'classnames/bind';
import React, { useEffect, useReducer, useState } from 'react';
import styles from './index.module.scss';
import { ActionKind, initState, ReadMoreContext, reducer } from './context';
import ItemWrapper from './subcomponents/ItemWrapper';

const cx = classNames.bind(styles);
export interface Props {
  children: React.ReactNode;
  className?: string;
  readMore?: boolean;
  setReadMore?: (state: boolean) => void;
}

type ReadMoreComponent = React.FC<Props> & {
  ItemWrapper: typeof ItemWrapper;
};

const ReadMore: ReadMoreComponent = ({
  className = '',
  children,
  readMore,
  setReadMore
}) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const [readMoreState, setReadMoreState] = useState<boolean>(
    readMore ?? false
  );

  useEffect(() => {
    dispatch({
      type: ActionKind.SET_STATE,
      payload: {
        readmore: readMore ?? readMoreState,
        setReadMore: setReadMore ?? setReadMoreState
      }
    });
  }, [readMore, readMoreState, setReadMore]);

  return (
    <ReadMoreContext.Provider value={{ state, dispatch }}>
      <div className={`${cx('container')} ${className}`}>{children}</div>
    </ReadMoreContext.Provider>
  );
};

ReadMore.ItemWrapper = ItemWrapper;

export default ReadMore;
