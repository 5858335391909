import classNames from 'classnames/bind';
import React from 'react';
import { ColorType } from '../../../types/style';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
  color?: ColorType;
  size?: string;
}

const Vr = React.forwardRef<HTMLDivElement, Props>(
  ({ size = '1px', color = 'gray-color', ...props }, ref) => {
    return (
      <div
        ref={ref}
        style={{ width: size }}
        className={`${cx('vr')} bg-color--${color}`}
        {...props}
      ></div>
    );
  }
);

Vr.displayName = 'Vr';

export default Vr;
