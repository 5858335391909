import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import { useFileUploader } from '@aimpact-korea/arrange-front-hooks';
import { PlusIcon } from '@aimpact-korea/arrange-front-icons';
import { useEffect, useState } from 'react';
import { AttachmentFileUploadApi } from '../../../hooks/http/attachment/upload-attachments';
import Button from '../../atoms/Button';
import Modal from '../../molecules/Modal';
import Table from '../../molecules/Table';
import TextInput from '../../molecules/TextInput';

type ArrangeManualGroupType = {
  title: string;
  code: string;
  thumbnailImage: {
    attachmentId: number;
    imageUrl: string;
  } | null;
};

function CreateArrangeManualGroupModal({
  isOpen,
  arrangeManualGroup: arrangeManualGroupProps,
  handleSave,
  handleClose,
  isLoading
}: {
  isOpen: boolean;
  arrangeManualGroup: ArrangeManualGroupType;
  handleSave: (arrangeManualGroup: ArrangeManualGroupType) => void;
  handleClose: () => void;
  isLoading?: boolean;
}) {
  const [arrangeManualGroup, setArrangeManualGroup] = useState(
    arrangeManualGroupProps
  );

  const attachmentFileUploadApi = AttachmentFileUploadApi.useMutate({
    onSuccess: (dd) => {
      setArrangeManualGroup((state) => ({
        ...state,
        thumbnailImage: {
          attachmentId: dd.data.attachmentId,
          imageUrl: dd.data.url
        }
      }));
    }
  });
  const { fileUploader } = useFileUploader();

  useEffect(() => {
    if (arrangeManualGroupProps) {
      setArrangeManualGroup(arrangeManualGroupProps);
    }
  }, [arrangeManualGroupProps]);

  function onClickThumbnailImage() {
    fileUploader(
      {
        accept: 'image/*',
        multiple: false
      },
      {
        onUpload: (files) => {
          if (!files.length) {
            return;
          }

          attachmentFileUploadApi.mutate({
            file: files[0]
          });
        }
      }
    );
  }

  return (
    <Modal sizeType="large" isOpen={isOpen} handleClose={() => handleClose()}>
      <Modal.Header>
        <Modal.Header.Title text="어레인지 매뉴얼 그룹 수정" />
      </Modal.Header>
      <Modal.Body>
        <Table type="simple-table">
          <Table.Body>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">제목</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={arrangeManualGroup.title}
                      onChange={(e) =>
                        setArrangeManualGroup({
                          ...arrangeManualGroup,
                          title: e.target.value
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">코드</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <TextInput isContour={true} widthType="full">
                  <TextInput.Body>
                    <TextInput.Body.Input
                      value={arrangeManualGroup.code}
                      onChange={(e) =>
                        setArrangeManualGroup({
                          ...arrangeManualGroup,
                          code: e.target.value
                        })
                      }
                    ></TextInput.Body.Input>
                  </TextInput.Body>
                </TextInput>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
            <Table.Body.Row>
              <Table.Body.Row.Cell tag="th">썸네일 이미지</Table.Body.Row.Cell>
              <Table.Body.Row.Cell>
                <Flex onClick={() => onClickThumbnailImage()}>
                  {arrangeManualGroup.thumbnailImage ? (
                    <img
                      src={arrangeManualGroup.thumbnailImage.imageUrl}
                      className="w-[200px] h-[200px]"
                    />
                  ) : (
                    <Flex className="w-[200px] h-[200px]">
                      <PlusIcon />
                    </Flex>
                  )}
                </Flex>
              </Table.Body.Row.Cell>
            </Table.Body.Row>
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          flexDirection="flex-row"
          width="w-full"
          gapSize={StyleBuilderInstane.toGapSpaceType('gap-xxs')}
        >
          <Button
            text="취소"
            type="secondary-color"
            fullwidth={true}
            onClick={() => handleClose()}
            isLoading={isLoading || attachmentFileUploadApi.isLoading}
          />
          <Button
            text="저장"
            fullwidth={true}
            onClick={() => handleSave(arrangeManualGroup)}
            isLoading={isLoading || attachmentFileUploadApi.isLoading}
          />
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateArrangeManualGroupModal;
