import { Optional } from 'utility-types';

interface ObjectType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [field: string]: any;
}

/**
 * target Object의 필드 Key값을 기준으로 diffTarget과 비교
 * 비교하여 값이 다른 부분은 diffTarget 값으로 대체
 *
 * undefined는 제외
 *
 * @param target
 * @param diffTarget
 */
export function objectDiffOmit<T extends ObjectType>(
  target: Optional<T>,
  diffTarget: Optional<T>
): Optional<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(target).reduce((diff: any, key) => {
    if (target[key] === diffTarget[key] || diffTarget[key] === undefined) {
      return diff;
    }
    diff[key] = diffTarget[key];
    return diff;
  }, {});
}
