import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchArrangeManualGroupDetail } from './fetch-arrange-manual';

export namespace ModifyOrderArrangeManual {
  export type RequestType = {
    arrangeManualId: number;
    title: string;
    movieId: string;
    isImportant: boolean;
    arrangeManualGroupId: number;
    thumbnailImageAttachmentId: number | null;
    code?: string;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { refetch } = FetchArrangeManualGroupDetail.useRefetchData();

    const mutation = useMutation(
      ({
        arrangeManualId,
        isImportant,
        movieId,
        title,
        thumbnailImageAttachmentId,
        code
      }) => {
        return axios.put(`/arrange-manual/${arrangeManualId}`, {
          isImportant,
          movieId,
          title,
          thumbnailImageAttachmentId,
          code
        });
      },
      {
        onSuccess: (...rest) => {
          refetch({ arrangeManualGroupId: rest[1].arrangeManualGroupId });
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
