import 'reflect-metadata';

export const IsTypeKey = Symbol('IsNumber');

export function Type(
  // eslint-disable-next-line @typescript-eslint/ban-types
  typeFunction: () => Function
): PropertyDecorator {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (target: any, propertyKey: string | symbol) => {
    const _target = target.constructor;

    const metadata = Reflect.getMetadata(IsTypeKey, _target) || {};

    metadata[propertyKey] = typeFunction();

    Reflect.defineMetadata(IsTypeKey, metadata, _target);
  };
}
