import classNames from 'classnames/bind';
import React, { Suspense, useEffect, useState } from 'react';
import Button from '../../../components/atoms/Button';
import Hr from '../../../components/atoms/Hr';
import Text from '../../../components/atoms/Text';
import Flex from '../../../components/molecules/Flex';
import Selection from '../../../components/molecules/Selection';
import TextInput from '../../../components/molecules/TextInput';
import DetailSkeletion from '../../../components/organisms/DetailSkeleton';
import Header from '../../../components/organisms/Header';
import DefaultTemplate from '../../../components/templates/Default';
import {
  FetchStore,
  MutateArrangeAppForceSignin,
  MutationStoreAddBlock,
  MutationStoreCancelBlock,
  MutationStoreDetail
} from '../../../hooks/http/store';
import styles from './index.module.scss';
import ProfileInfo from '../../../components/organisms/ProfileInfo';
import { parsePhone } from '../../../utils/format/phone';
import DetailDataTemplate from '../../../components/templates/DetailDataTemplate';
import { useFormik, FormikContextType } from 'formik';
import Postcode from '../../../components/molecules/PostCode';
import Modal from '../../../components/molecules/Modal';
import { objectDiffOmit } from '../../../utils/object/object-diff';
import { useLocationStateParam } from '../../../hooks/useLocationSearchQueryParam';
import { Type } from '../../../utils/decorators';
import {
  ArrowReturnIcon,
  EditPencilIcon,
  CrownIcon,
  MessageIcon,
  TrashIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';

const cx = classNames.bind(styles);

class PathParams {
  @Type(() => Number)
  storeId!: number;
}

interface IDetailForm {
  storeName?: string;
  ceoName?: string;
  phone?: string;
  email?: string;
  address?: string;
  detailAddress?: string | null;
  postCode?: string | null;
  alimtalkEnabled?: boolean;
  isStoreBlock?: boolean;
  barofarmEnabled?: boolean;
}

const StoreDetail: React.FC = () => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const locationQueryString = useLocationStateParam(PathParams);

  const mutation = MutationStoreDetail.useMutate();
  const addBlockMutation = MutationStoreAddBlock.useMutationStoreAddBlock();
  const cancelBlockMutation = MutationStoreCancelBlock.useMutate();

  const form = useFormik<IDetailForm>({
    initialValues: {},
    onSubmit: (values) => {
      setLoading(true);
      onSubmit(values);
    }
  });

  const onSubmit = (values: IDetailForm) => {
    const diff = objectDiffOmit<IDetailForm>(form.initialValues, values);

    const apis = [
      mutation.mutateAsync({
        storeId: locationQueryString.storeId,
        company: diff.storeName,
        barofarmEnabled: diff.barofarmEnabled,
        address: diff.address,
        detailAddress: diff.detailAddress,
        postCode: diff.postCode ? diff.postCode : undefined
      })
    ];

    if (typeof diff.isStoreBlock === 'boolean') {
      if (diff.isStoreBlock === true) {
        apis.push(
          addBlockMutation.mutateAsync({ storeId: locationQueryString.storeId })
        );
      } else if (diff.isStoreBlock === false) {
        apis.push(
          cancelBlockMutation.mutateAsync({
            storeId: locationQueryString.storeId
          })
        );
      }
    }

    Promise.all(apis).then(() => {
      setEditMode(false);
      setLoading(false);
    });
  };

  const toogleStoreInfoView = () => {
    // @TODO
    // 쇼핑몰 정보 보여주기
  };

  const onResetForm = () => {
    setEditMode(false);

    form.resetForm();
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <DefaultTemplate>
        <Suspense fallback={<DetailSkeletion />}>
          <Header
            prevPages={[
              {
                name: '업체목록',
                path: '/store'
              }
            ]}
            currentPageName={'업체정보'}
            nextStep={{
              onClick: () => toogleStoreInfoView(),
              name: '쇼핑몰정보 보기'
            }}
          >
            <Flex gap="xlarge" direction="row">
              {editMode && (
                <React.Fragment>
                  <Button
                    startIcon={<TrashIcon colorType="text-red-800" />}
                    text="삭제"
                    type="transparent"
                    isBorder
                    disabledBoxShadow
                    borderColor="secondary-color"
                    size="large"
                    fontColor="secondary-color"
                  />
                  <Button
                    startIcon={<ArrowReturnIcon colorType="text-black" />}
                    onClick={(event: React.MouseEvent) => {
                      event.stopPropagation();

                      onResetForm();
                    }}
                    disabledBoxShadow
                    fontColor="black"
                    text="수정사항 되돌리기"
                    type="transparency"
                    borderColor="black"
                    isBorder
                    size="large"
                  />
                  <Button
                    text="수정완료"
                    size="large"
                    htmlType="submit"
                    isLoading={loading}
                  />
                </React.Fragment>
              )}
              {!editMode && (
                <Button
                  startIcon={<EditPencilIcon colorType="text-white" />}
                  onClick={(e) => {
                    e.stopPropagation();

                    setEditMode(true);
                  }}
                  text="업체정보 수정"
                  type="primary-color"
                  size="large"
                  disabled={editMode}
                />
              )}
            </Flex>
          </Header>
          <DetailForm
            storeId={locationQueryString.storeId}
            editMode={editMode}
            // setEditMode={setEditMode}
            form={form}
          />
        </Suspense>
      </DefaultTemplate>
    </form>
  );
};

export default StoreDetail;

interface Props {
  editMode: boolean;
  form: FormikContextType<IDetailForm>;
  storeId: number;
}

const DetailForm: React.FC<Props> = ({
  editMode,
  storeId,
  form: { resetForm, ...form }
}) => {
  const query = FetchStore.useStore(
    { storeId: storeId },
    {
      enabled: !editMode
    }
  );

  const mutateArrangeAppForceSignin = MutateArrangeAppForceSignin.useMutate({
    onSuccess: (response) => {
      const token = response.data;

      window.open(
        (process.env.REACT_APP_ARRANGE_WEB_HOST as string) +
          `?access_token=${encodeURIComponent(token.accessToken)}`,
        '_blank'
      );
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const storeData = query.data!.data;

  const [postCodeModalShow, setPostCodeModalShow] = useState(false);

  useEffect(() => {
    resetForm({
      values: {
        address: storeData.address,
        alimtalkEnabled: storeData.ceoAccount.alimtalkEnabled,
        ceoName: storeData.ceoAccount.name,
        detailAddress: storeData.detailedAddress,
        email: storeData.ceoAccount.email,
        isStoreBlock: storeData.isBlock,
        phone: storeData.ceoAccount.phone,
        postCode: storeData.postCode,
        storeName: storeData.company,
        barofarmEnabled: storeData.barofarmEnabled
      }
    });
  }, [
    resetForm,
    storeData.address,
    storeData.barofarmEnabled,
    storeData.ceoAccount.alimtalkEnabled,
    storeData.ceoAccount.email,
    storeData.ceoAccount.name,
    storeData.ceoAccount.phone,
    storeData.company,
    storeData.detailedAddress,
    storeData.isBlock,
    storeData.postCode
  ]);

  const onSelectPostcode = (address: string, postCode: string) => {
    form.setValues({
      ...form.values,
      address,
      postCode
    });

    setPostCodeModalShow(false);
  };

  const onClickSuperAdmin = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();

    mutateArrangeAppForceSignin.mutate({
      storeId: storeId
    });
  };

  return (
    <Flex gap="xxlarge">
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Text size="large" isBold={true} colorType="gray-color">
          업체코드:{' '}
          <Text colorType="gray-dark-color" size="large" isBold={true}>
            aimpact
          </Text>
        </Text>
        <Flex direction="row" gap="xlarge">
          <Selection>
            <Selection.Label>
              <Text colorType="gray-color" isBold={true} size="small">
                알림톡 사용
              </Text>
            </Selection.Label>
            <Selection.Input
              disabled={!editMode}
              type="toggle"
              isChecked={form.values.alimtalkEnabled}
              onChangeChecked={() =>
                form.setValues({
                  ...form.values,
                  alimtalkEnabled: !form.values.alimtalkEnabled
                })
              }
            />
          </Selection>
          <Selection>
            <Selection.Label>
              <Text colorType="gray-color" isBold={true} size="small">
                스토어 차단
              </Text>
            </Selection.Label>
            <Selection.Input
              disabled={!editMode}
              type="toggle"
              value=""
              isChecked={form.values.isStoreBlock}
              onChangeChecked={() =>
                form.setValues({
                  ...form.values,
                  isStoreBlock: !form.values.isStoreBlock
                })
              }
            />
          </Selection>
          <Selection>
            <Selection.Label>
              <Text colorType="gray-color" isBold={true} size="small">
                쇼핑몰 개설
              </Text>
            </Selection.Label>
            <Selection.Input
              disabled={!editMode}
              type="toggle"
              value=""
              isChecked={form.values.barofarmEnabled}
              onChangeChecked={() =>
                form.setValues({
                  ...form.values,
                  barofarmEnabled: !form.values.barofarmEnabled
                })
              }
            />
          </Selection>
          <Button
            startIcon={<MessageIcon colorType="text-white" />}
            text="1:1 메시지"
            type="primary-color"
            size="large"
            disabled={editMode}
          />
          <Button
            startIcon={<MessageIcon colorType="text-white" />}
            text="슈퍼어드민 접속"
            type="primary-color"
            size="large"
            onClick={onClickSuperAdmin}
            disabled={editMode}
          />
        </Flex>
      </Flex>
      <Flex direction="row" gap="xlarge">
        <Flex direction="row" gap="small">
          <TextInput
            sizeType="xxlarge"
            disabled={!editMode}
            isContour={true}
            widthType="auto"
          >
            <TextInput.Head>
              <TextInput.Head.Icon />
              <TextInput.Head.Text text="업체명" />
            </TextInput.Head>
            <TextInput.Body>
              {/* <TextInput.Body.Input value={storeData.storeName} /> */}
              <TextInput.Body.Input
                value={form.values.storeName}
                handleChangeValue={(value) =>
                  form.setValues({
                    ...form.values,
                    storeName: value
                  })
                }
              />
            </TextInput.Body>
          </TextInput>
          <Text size="xlarge" className={cx('suffix')}>
            의
          </Text>
        </Flex>
        <Flex direction="row" gap="small">
          <TextInput
            sizeType="xxlarge"
            disabled={!editMode}
            isContour={true}
            widthType="auto"
          >
            <TextInput.Head>
              <TextInput.Head.Icon />
              <TextInput.Head.Text text="대표자" />
            </TextInput.Head>
            <TextInput.Body>
              <TextInput.Body.Input
                value={form.values.ceoName}
                handleChangeValue={(value) =>
                  form.setValues({
                    ...form.values,
                    ceoName: value
                  })
                }
              />
            </TextInput.Body>
          </TextInput>
          <Text size="xlarge" className={cx('suffix')}>
            대표님
          </Text>
        </Flex>
      </Flex>
      <Flex gap="xlarge" direction="row">
        <TextInput sizeType="large" disabled={!editMode} isContour={true}>
          <TextInput.Head>
            <TextInput.Head.Icon />
            <TextInput.Head.Text text="모바일" />
          </TextInput.Head>
          <TextInput.Body>
            <TextInput.Body.Input
              value={parsePhone(form.values.phone || '')}
              handleChangeValue={(value) =>
                form.setValues({
                  ...form.values,
                  phone: value.split('-').join('')
                })
              }
            />
          </TextInput.Body>
        </TextInput>

        <TextInput
          sizeType="large"
          widthType="auto"
          disabled={!editMode}
          isContour={true}
        >
          <TextInput.Head>
            <TextInput.Head.Icon />
            <TextInput.Head.Text text="이메일" />
          </TextInput.Head>
          <TextInput.Body>
            <TextInput.Body.Input
              value={form.values.email}
              handleChangeValue={(value) =>
                form.setValues({
                  ...form.values,
                  email: value
                })
              }
            />
          </TextInput.Body>
        </TextInput>

        <TextInput
          sizeType="large"
          widthType="auto"
          disabled={!editMode}
          isContour={true}
        >
          <TextInput.Head>
            <TextInput.Head.Icon />
            <TextInput.Head.Text text="주소" />
          </TextInput.Head>
          <TextInput.Body>
            <TextInput.Body.Input
              value={form.values.address}
              onClick={(e) => {
                e.stopPropagation();

                setPostCodeModalShow(true);
              }}
            />
          </TextInput.Body>
        </TextInput>
        <TextInput
          sizeType="large"
          widthType="auto"
          disabled={!editMode}
          isContour={true}
        >
          <TextInput.Head>
            <TextInput.Head.Icon />
            <TextInput.Head.Text text="상세 주소" />
          </TextInput.Head>
          <TextInput.Body>
            <TextInput.Body.Input
              value={form.values.detailAddress ?? ''}
              handleChangeValue={(value) =>
                form.setValues({
                  ...form.values,
                  detailAddress: value
                })
              }
            />
          </TextInput.Body>
        </TextInput>
      </Flex>
      <Hr color="gray-dark-color" />
      <DetailDataTemplate>
        <DetailDataTemplate.Box title="업체 요약">
          <Flex
            className={cx('store-summary-wrapper')}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                총 상품 수
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {storeData.productsCount} 개
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                총 주문 건수
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {storeData.orderGroupCount}건
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                최근 접속 시간
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {storeData.ceoAccount.lastLoginedAt}
              </Text>
            </Flex>
            <Flex gap="xsmall" direction="column" className={cx('item')}>
              <Text size="small" colorType="gray-color" isBold={true}>
                회원 가입 시간
              </Text>
              <Text size="xlarge" colorType="black" isBold={true}>
                {storeData.createdAt}
              </Text>
            </Flex>
          </Flex>
        </DetailDataTemplate.Box>
      </DetailDataTemplate>
      <Hr color="gray-dark-color" />
      <DetailDataTemplate>
        <DetailDataTemplate.Box title="공유 계정 목록">
          <Flex direction="row" gap="xxlarge">
            <ProfileInfo
              name={storeData.ceoAccount.name}
              phone={parsePhone(storeData.ceoAccount.phone)}
              outline={true}
              // IconComponent={"basic/crown/default/24dp"}
              IconComponent={CrownIcon}
              src={storeData.ceoAccount.kakaoProfileImg ?? ''}
            />
            {storeData.sharedStore
              .filter(
                (sharedStore) => sharedStore.id !== storeData.ceoAccount.id
              )
              .map((user) => (
                <ProfileInfo
                  key={user.id}
                  name={user.name}
                  phone={parsePhone(user.phone)}
                  src={user.kakaoProfileImg ?? undefined}
                />
              ))}
          </Flex>
        </DetailDataTemplate.Box>
      </DetailDataTemplate>

      {postCodeModalShow && (
        <Modal
          sizeType="large"
          isOpen={postCodeModalShow}
          handleClose={() => setPostCodeModalShow(false)}
        >
          <Modal.Body>
            <Postcode onSelectPostcode={onSelectPostcode} />
          </Modal.Body>
        </Modal>
      )}
    </Flex>
  );
};
