import { Suspense, useState } from 'react';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { Type } from '../../utils/decorators';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { useLocationSearchQueryParam } from '../../hooks/useLocationSearchQueryParam';
import qs from 'qs';
import InputSearch from '../../components/organisms/InputSearch';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FetchResellerListApi } from '../../hooks/http/reseller/fetch-reseller-list';
import Table from '../../components/molecules/Table';
import Text from '../../components/atoms/Text';
import Flex from '../../components/molecules/Flex';
import AFlex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import { BANK_NAME } from '@aimpact-korea/arrange-front-types';
import Button from '../../components/atoms/Button';
import { ForceSignin } from '../../hooks/http/account/force-signin';
import { DisabledReseller } from '../../hooks/http/reseller/disabled-reseller';
import Modal from '../../components/molecules/Modal';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import TextInput from '../../components/molecules/TextInput';
import { ModifyReseller } from '../../hooks/http/reseller/modify-reseller';
import { ModifyResellerInstagramId } from '../../hooks/http/reseller/modify-reseller-instagram-id';
import { ModifyResellerYoutubeId } from '../../hooks/http/reseller/modify-reseller-youtube-id';

const cx = classNames.bind(styles);

export class LocationQuery {
  @Type(() => String)
  keyword?: string;

  @Type(() => Number)
  id?: number;
}

function ResellerListPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const locationQuery = useLocationSearchQueryParam(LocationQuery);

  const currentRedirectFromParam = ({
    keyword
  }: {
    keyword?: string;
    id?: number;
  }) => {
    const queryStringObject = {
      ...locationQuery,
      keyword: keyword
    };

    navigate(`${location.pathname}?${qs.stringify(queryStringObject)}`);
  };

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'리셀러 목록'}>
          <div className={cx('header-container')}>
            <InputSearch
              placeholder="리셀러 검색 (채널명/이름/번호)"
              onClickSearch={(text) =>
                currentRedirectFromParam({ keyword: text })
              }
              defaultValue={locationQuery.keyword}
            />
          </div>
        </Header>
        <ResellerListData
          keyword={locationQuery.keyword}
          resellerId={locationQuery.id}
        />
      </Suspense>
    </DefaultTemplate>
  );
}

export default ResellerListPage;

function ResellerListData({
  keyword,
  resellerId
}: {
  keyword?: string;
  resellerId?: number;
}) {
  const query = FetchResellerListApi.useFetch({
    limit: 20,
    keyword,
    resellerId
  });

  return (
    <Flex
      style={{ maxWidth: '1800px', minWidth: 'fit-content' }}
      justifyContent="start"
    >
      <InfiniteScroll
        style={{ overflow: 'hidden', width: '100%' }}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        dataLength={query
          .data!.pages.map((page) => page.data.items.length)
          .reduce((sum, current) => sum + current, 0)}
        next={query.fetchNextPage}
        hasMore={query.hasNextPage ?? false}
        loader={<h4>Loading...</h4>}
      >
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell tag="th" alignType="center" className="col-1">
                No.
              </Table.Cell>
              <Table.Cell tag="th" alignType="center" className="col-1">
                채널명
              </Table.Cell>
              <Table.Cell tag="th" alignType="center" className="col-1">
                성명
              </Table.Cell>
              <Table.Cell tag="th" alignType="center" className="col-1">
                연락처
              </Table.Cell>
              <Table.Cell tag="th" alignType="center" className="col-1">
                주소
              </Table.Cell>
              <Table.Cell tag="th" alignType="center" className="col-1">
                인스타 / 유튜브
              </Table.Cell>
              <Table.Cell tag="th" alignType="center" className="col-1">
                정산계좌
              </Table.Cell>
              <Table.Cell tag="th" alignType="center" className="col-1">
                리셀러 삭제
              </Table.Cell>
              <Table.Cell tag="th" alignType="center" className="col-1">
                강제 로그인
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {query.data?.pages.map((group) =>
              group.data.items.map((item) => (
                <ResellerRow key={item.id} reseller={item} />
              ))
            )}
          </Table.Body>
        </Table>
      </InfiniteScroll>
    </Flex>
  );
}

function ResellerRow({
  reseller
}: {
  reseller: FetchResellerListApi.ResponseType['items'][number];
}) {
  const [showSnsModify, setShowSnsModify] = useState(false);
  const [instagramId, setInstagramId] = useState(reseller.instagramId);
  const [youtubeId, setYoutubeId] = useState(reseller.youtubeId);

  const {
    mutate: modiftResllerMutate,
    isLoading: modiftResllerMutateIsLoading
  } = ModifyReseller.useMutate({
    onSuccess: () => {
      setShowSnsModify(false);
    }
  });
  const {
    mutate: modiftResllerInstagramMutate,
    isLoading: modiftResllerInstagramMutateIsLoading
  } = ModifyResellerInstagramId.useMutate({
    onSuccess: () => {
      setShowSnsModify(false);
    }
  });
  const {
    mutate: modiftResllerYoutubeIdMutate,
    isLoading: modiftResllerYoutubeIdMutateIsLoading
  } = ModifyResellerYoutubeId.useMutate({
    onSuccess: () => {
      setShowSnsModify(false);
    }
  });

  const forceSignin = ForceSignin.useMutate({
    onSuccess: (response) => {
      const token = response.data;

      window.open(
        `${
          process.env.REACT_APP_LINKFARM_WEB_HOST as string
        }/reseller?access_token=${encodeURIComponent(token.accessToken)}`,
        '_blank'
      );
    }
  });

  const {
    mutate: disabledResellerMutate,
    isLoading: disabledResellerIsLoading
  } = DisabledReseller.useMutate();

  function onClickSigninLinkfarm() {
    forceSignin.mutate({ accountId: reseller.account.id });
  }

  // function onClickShowSnsModifyModal() {
  //   setShowSnsModify(true);
  // }

  function handleCloseSnsModify() {
    setShowSnsModify(false);
  }

  function handleSnsSave() {
    if (instagramId || youtubeId) {
      modiftResllerMutate({
        resellerId: reseller.id,
        instagramId: instagramId || undefined,
        youtubeId: youtubeId || undefined
      });
    }

    if (!instagramId) {
      modiftResllerInstagramMutate({
        resellerId: reseller.id
      });
    }
    if (!youtubeId) {
      modiftResllerYoutubeIdMutate({
        resellerId: reseller.id
      });
    }
  }

  function onClickRemoveReseller() {
    if (confirm(`"${reseller.channelName}" 리셀러를 삭제 하시겠습니까?`)) {
      disabledResellerMutate({ resellerId: reseller.id });
    }
  }

  const isLoading =
    modiftResllerMutateIsLoading ||
    modiftResllerInstagramMutateIsLoading ||
    modiftResllerYoutubeIdMutateIsLoading ||
    disabledResellerIsLoading;

  return (
    <>
      {showSnsModify ? (
        <Modal
          sizeType="large"
          isOpen={showSnsModify}
          handleClose={() => handleCloseSnsModify()}
        >
          <Modal.Header>
            <Modal.Header.Title text="SNS 수정" />
          </Modal.Header>
          <Modal.Body>
            <Table type="simple-table">
              <Table.Body>
                <Table.Body.Row>
                  <Table.Body.Row.Cell tag="th">
                    인스타그램 주소
                  </Table.Body.Row.Cell>
                  <Table.Body.Row.Cell>
                    <TextInput isContour={true} widthType="full">
                      <TextInput.Body>
                        <TextInput.Body.Input
                          value={instagramId ?? ''}
                          onChange={(e) => setInstagramId(e.target.value)}
                        ></TextInput.Body.Input>
                      </TextInput.Body>
                    </TextInput>
                  </Table.Body.Row.Cell>
                </Table.Body.Row>
                <Table.Body.Row>
                  <Table.Body.Row.Cell tag="th">
                    유튜브 주소
                  </Table.Body.Row.Cell>
                  <Table.Body.Row.Cell>
                    <TextInput isContour={true} widthType="full">
                      <TextInput.Body>
                        <TextInput.Body.Input
                          value={youtubeId ?? ''}
                          onChange={(e) => setYoutubeId(e.target.value)}
                        ></TextInput.Body.Input>
                      </TextInput.Body>
                    </TextInput>
                  </Table.Body.Row.Cell>
                </Table.Body.Row>
              </Table.Body>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <AFlex
              flexDirection="flex-row"
              width="w-full"
              gapSize={StyleBuilderInstane.toGapSpaceType('gap-xxs')}
            >
              <Button
                text="취소"
                type="secondary-color"
                fullwidth={true}
                onClick={() => handleCloseSnsModify()}
                isLoading={isLoading}
              />
              <Button
                text="저장"
                fullwidth={true}
                onClick={() => handleSnsSave()}
                isLoading={isLoading}
              />
            </AFlex>
          </Modal.Footer>
        </Modal>
      ) : undefined}
      <Table.Body.Row>
        <Table.Body.Row.Cell alignType="center">
          <Text size="small" isBold={true}>
            No. {reseller.id}
          </Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="left">
          <Flex gap="large" direction="column" alignItems="center">
            <Text isBold={true}>{reseller.channelName}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="large" direction="column" alignItems="center">
            <Text>{reseller.account.name}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Text>{reseller.account.phone}</Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="medium" direction="column" alignItems="center">
            <Text>
              [{reseller.postCode}] {reseller.address}
            </Text>
            <Text>{reseller.detailAddress}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="medium" direction="column" alignItems="center">
            {reseller.instagramId && (
              <Text>인스타 : {reseller.instagramId}</Text>
            )}
            {reseller.youtubeId && <Text>유튜브 : {reseller.youtubeId}</Text>}
            {/* <Button text="수정" onClick={() => onClickShowSnsModifyModal()} /> */}
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex gap="medium" direction="column" alignItems="center">
            <Text>은행 : {BANK_NAME[reseller.bankInfo.bank]}</Text>
            <Text>계좌번호 : {reseller.bankInfo.bankAccount}</Text>
            <Text>예금주명 : {reseller.bankInfo.bankHolder}</Text>
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Button
            text="삭제"
            onClick={() => onClickRemoveReseller()}
            isLoading={isLoading}
          />
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Button text="로그인" onClick={() => onClickSigninLinkfarm()} />
        </Table.Body.Row.Cell>
      </Table.Body.Row>
    </>
  );
}
