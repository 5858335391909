import classNames from 'classnames/bind';
import { useContextApi } from '../context';
import styles from './body.module.scss';
import BodyInput from './BodyInput';
import SearchIcon from './SearchIcon';
import ClearIcon from './ClearIcon';
import BodyIcon from './BodyIcon';
import ComplateIcon from './ComplateIcon';

const cx = classNames.bind(styles);

export interface Props {
  children: React.ReactNode;
}

type BodyComponent = React.FC<Props> & {
  Input: typeof BodyInput;
  SearchIcon: typeof SearchIcon;
  ClearIcon: typeof ClearIcon;
  BodyIcon: typeof BodyIcon;
  ComplateIcon: typeof ComplateIcon;
};

const Body: BodyComponent = ({ children }) => {
  const { state } = useContextApi();

  return (
    <div
      className={cx(
        'container',
        `type--${state.type}`,
        `${state.widthType === 'default' && `size--${state.size}`}`,
        `${state.widthType === 'full' && `full-width`}`
      )}
    >
      {children}
    </div>
  );
};

Body.Input = BodyInput;
Body.SearchIcon = SearchIcon;
Body.ClearIcon = ClearIcon;
Body.BodyIcon = BodyIcon;
Body.ComplateIcon = ComplateIcon;

export default Body;
