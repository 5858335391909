import { Suspense } from 'react';
import DefaultTemplate from '../../templates/Default';
import Header from '../../organisms/Header';
import ListSkeleton from '../../molecules/ListSkeleton';
import InputSearch from '../../organisms/InputSearch';
import Button from '../../atoms/Button';
import { useLocation, useNavigate } from 'react-router';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import AccountsData from './AccountsData';
import { useLocationSearchQueryParam } from '../../../hooks/useLocationSearchQueryParam';
import { Type } from '../../../utils/decorators';
import qs from 'qs';
import { FileIcon } from '@aimpact-korea/arrange-front-icons/build/icons';

const cx = classNames.bind(styles);

export class QueryStringParam {
  @Type(() => String)
  keyword?: string;
}

const Accounts: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const locationQuery = useLocationSearchQueryParam(QueryStringParam);

  const currentRedirectFromParam = ({ keyword }: { keyword?: string }) => {
    const changeQueryString = {
      ...locationQuery
    };

    if (keyword !== null && keyword !== undefined) {
      changeQueryString.keyword = keyword;
    }

    navigate(`${location.pathname}?${qs.stringify(changeQueryString)}`);
  };

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'유지 리스트'}>
          <div className={cx('header-container')}>
            <div className={cx('flex')}>
              <Button
                text="엑셀 내보내기"
                size="large"
                startIcon={<FileIcon colorType="text-white" />}
              />
            </div>
            <InputSearch
              placeholder="이름 / 번호"
              onClickSearch={(text) =>
                currentRedirectFromParam({ keyword: text })
              }
            />
          </div>
        </Header>
        <AccountsData keyword={locationQuery.keyword} />
      </Suspense>
    </DefaultTemplate>
  );
};

export default Accounts;
