import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import axios from '../../../utils/axios';
import { adminAuthInfo } from '../../../recoil/atoms';
import { AxiosResponse, AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import cookie from 'react-cookies';

export namespace LoginMutation {
  export type UseLoginResponse = {
    accessToken: string;
    refreshToken: string;
  };

  export type UseLoginParam = {
    id: string;
    pwd: string;
  };

  export const useLogin = ({
    onError,
    onSuccess,
    ...args
  }: UseMutationOptions<
    AxiosResponse<UseLoginResponse>,
    AxiosError,
    UseLoginParam
  > = {}) => {
    const setAdminAuthInfo = useSetRecoilState(adminAuthInfo);

    const mutation = useMutation(
      (param: UseLoginParam) =>
        axios.post<{
          accessToken: string;
          refreshToken: string;
        }>('/auth/sign-in', {
          id: param.id,
          password: param.pwd
        }),
      {
        onSuccess: (...rest) => {
          setAdminAuthInfo(rest[0].data);

          window.localStorage.setItem('access_token', rest[0].data.accessToken);
          cookie.save('refresh_token', rest[0].data.refreshToken, {
            httpOnly: true
          });

          onSuccess?.(...rest);
        },
        onError: (...rest) => {
          switch (rest[0].response?.status) {
            // 의도된 non-break
            case StatusCodes.UNAUTHORIZED:
            case StatusCodes.NOT_FOUND:
              alert('아이디 혹은 비밀번호가 잘못됐습니다.');
              break;
          }

          onError?.(...rest);
        },
        ...args
      }
    );

    return mutation;
  };
}
