function arrayMoveMutate(array: unknown[], from: number, to: number) {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arrayMove(array: unknown[], from: number, to: number): any[] {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
}
