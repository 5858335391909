import React from 'react';
import Row from './Row';
import styles from './body.module.scss';
import classNames from 'classnames/bind';
import Cell from './Cell';

const cx = classNames.bind(styles);

export type Props = React.ComponentPropsWithoutRef<'tbody'>;

export interface BodyComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLTableSectionElement>
  > {
  Row: typeof Row;
  Cell: typeof Cell;
}

// eslint-disable-next-line react/display-name
const Body = React.forwardRef<HTMLTableSectionElement, Props>(
  ({ children, className, ...props }, ref) => {
    return (
      <tbody
        className={`${cx('container')} ${className ?? ''}`}
        ref={ref}
        {...props}
      >
        {children}
      </tbody>
    );
  }
) as BodyComponent;

Body.displayName = 'Body';
Body.Row = Row;
Body.Cell = Cell;

export default Body;
