import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import {
  ChevronDownIcon,
  ChevronUpIcon
} from '@aimpact-korea/arrange-front-icons';
import classNames from 'classnames/bind';
import { Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/atoms/Button';
import CreateArrangeManualGroupModal from '../../components/features/CreateArrangeManualGroupModal';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import { FetchArrangeManualGroup } from '../../hooks/http/arrange-manual/fetch-arrange-manual-group';
import { ModifyOrderArrangeManualGroup } from '../../hooks/http/arrange-manual/modify-arrange-manual-group';
import { SwapOrderArrangeManualGroup } from '../../hooks/http/arrange-manual/swap-order-arrange-manual-group';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export default function ArrangeManualVideo() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'어레인지 사용법 동영상'}></Header>
        <ArrangeManualVideoList />
      </Suspense>
    </DefaultTemplate>
  );
}

function ArrangeManualVideoList() {
  const fetchArrangeManualGroup = FetchArrangeManualGroup.useFetch();
  const swapOrderArrangeManualGroup = SwapOrderArrangeManualGroup.useMutate();
  const modifyOrderArrangeManualGroup = ModifyOrderArrangeManualGroup.useMutate(
    {
      onSuccess: () => {
        setShowArrangeManualGroupModifyModal(false);
        alert('어레인지 사용법 그룹이 변경되었습니다.');
      }
    }
  );

  const [
    isShowArrangeManualGroupModifyModal,
    setShowArrangeManualGroupModifyModal
  ] = useState(false);
  const [selectModifyManualGroup, setSelectModifyManualGroup] = useState<{
    id: number;
    title: string;
    code: string;
    thumbnailImage: {
      attachmentId: number;
      imageUrl: string;
    } | null;
  } | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const arrangeManualGroupData = fetchArrangeManualGroup.data!.data!;

  useEffect(() => {
    if (!isShowArrangeManualGroupModifyModal) {
      setSelectModifyManualGroup(null);
    }
  }, [isShowArrangeManualGroupModifyModal]);

  function arrangeManualGroupSwapHandle(id: number, targetId: number | null) {
    if (!targetId) {
      return;
    }

    arrangeManualGroupSwap(id, targetId);
  }

  function arrangeManualGroupSwap(id: number, targetId: number) {
    swapOrderArrangeManualGroup.mutate({
      arrangeManualGroupId: id,
      targetArrangeManualGroupId: targetId
    });
  }

  function onClickShowArrangeManualGroupModifyModal(arrangeManualGroup: {
    id: number;
    title: string;
    code: string;
    thumbnailImage: {
      attachmentId: number;
      imageUrl: string;
    } | null;
  }) {
    setSelectModifyManualGroup(arrangeManualGroup);
    setShowArrangeManualGroupModifyModal(true);
  }

  function onClickArrangeManualModify(arrangeManualGroup: {
    title: string;
    thumbnailImage: {
      attachmentId: number;
      imageUrl: string;
    } | null;
  }) {
    if (selectModifyManualGroup) {
      modifyOrderArrangeManualGroup.mutate({
        ...arrangeManualGroup,
        thumbnailImageAttachmentId:
          arrangeManualGroup.thumbnailImage?.attachmentId ?? null,
        arrangeManualGroupId: selectModifyManualGroup.id
      });
    }
  }

  return (
    <Flex
      flexDirection="flex-col"
      width="w-full"
      alignItems="items-start"
      gapSize={StyleBuilderInstane.toGapSpaceType('gap-s')}
    >
      {selectModifyManualGroup && isShowArrangeManualGroupModifyModal && (
        <CreateArrangeManualGroupModal
          isOpen={isShowArrangeManualGroupModifyModal}
          arrangeManualGroup={selectModifyManualGroup}
          handleClose={() => setShowArrangeManualGroupModifyModal(false)}
          handleSave={(arrangeManualGroup) =>
            onClickArrangeManualModify(arrangeManualGroup)
          }
          isLoading={modifyOrderArrangeManualGroup.isLoading}
        />
      )}
      <table className={`${cx('table')}`}>
        <thead>
          <tr>
            <th>순서변경</th>
            <th>제목</th>
            <th>수정</th>
            <th>바로가기</th>
          </tr>
        </thead>
        <tbody>
          {arrangeManualGroupData.groupList.map((group, index) => (
            <tr key={group.id}>
              <td>
                <Flex flexDirection="flex-col">
                  <ChevronUpIcon
                    className={`${cx('order-btn')}`}
                    onClick={() =>
                      arrangeManualGroupSwapHandle(
                        group.id,
                        arrangeManualGroupData.groupList[index - 1]?.id ?? null
                      )
                    }
                  ></ChevronUpIcon>
                  <ChevronDownIcon
                    className={`${cx('order-btn')}`}
                    onClick={() =>
                      arrangeManualGroupSwapHandle(
                        group.id,
                        arrangeManualGroupData.groupList[index + 1]?.id ?? null
                      )
                    }
                  ></ChevronDownIcon>
                </Flex>
              </td>
              <th>
                <Link to={`/arrange-manual-video/${group.id}`}>
                  {group.title}
                </Link>
              </th>
              <td>
                <Flex gapSize={StyleBuilderInstane.toGapSpaceType('gap-xs')}>
                  <Button
                    text="수정"
                    onClick={() =>
                      onClickShowArrangeManualGroupModifyModal({
                        id: group.id,
                        title: group.title,
                        code: group.code,
                        thumbnailImage: group.thumbnailImage
                          ? {
                              attachmentId: group.thumbnailImage.attachmentId,
                              imageUrl: group.thumbnailImage.url
                            }
                          : null
                      })
                    }
                  />
                </Flex>
              </td>
              <th>{group.title}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
}
