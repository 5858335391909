import classNames from 'classnames/bind';
import styles from './head.module.scss';
import LabelIcon from './LabelIcon';
import LabelText from './LabelText';

const cx = classNames.bind(styles);

export interface Props {
  children: React.ReactNode;
}

type HeadComponent = React.FC<Props> & {
  Icon: typeof LabelIcon;
  Text: typeof LabelText;
};

const Head: HeadComponent = ({ children }) => {
  return <div className={cx('container')}>{children}</div>;
};

Head.Icon = LabelIcon;
Head.Text = LabelText;
export default Head;
