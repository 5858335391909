import React from 'react';
import Row from './Row';
import styles from './head.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export type Props = React.ComponentPropsWithoutRef<'thead'>;

export interface HeadComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLTableSectionElement>
  > {
  Row: typeof Row;
}

// eslint-disable-next-line react/display-name
const Head = React.forwardRef<HTMLTableSectionElement, Props>(
  ({ children, className, ...props }, ref) => {
    return (
      <thead
        className={`${cx('container')} ${className ?? ''}`}
        ref={ref}
        {...props}
      >
        {children}
      </thead>
    );
  }
) as HeadComponent;

Head.displayName = 'Head';
Head.Row = Row;

export default Head;
