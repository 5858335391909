import React from 'react';
import { ColorType, SizeType } from '../../../types/style';

export interface Props extends React.ComponentPropsWithoutRef<'label'> {
  sizeType?: Extract<SizeType, 'small' | 'medium'>;
  colorType?: ColorType;
}

const Label = React.forwardRef<HTMLLabelElement, Props>(
  ({ sizeType = 'medium', children, colorType = 'black', ...props }, ref) => {
    return (
      <label
        ref={ref}
        className={`font-size--${sizeType} font-color--${colorType}`}
        {...props}
      >
        {children}
      </label>
    );
  }
);

Label.displayName = 'Label';
export default Label;
