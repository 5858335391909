import { useState } from 'react';
import ReactQuill from 'react-quill';
import {
  FetchContractTerms,
  UpdateContractTerms
} from '../../../hooks/http/contract-terms';
import { ContractTermsType } from '../../../types/contract-terms';
import Button from '../../atoms/Button';
import Flex from '../../molecules/Flex';

export function ContractTermsDetail({ type }: { type: ContractTermsType }) {
  const fetchContractTerms = FetchContractTerms.useFetch({ type });
  const updateContractTerms = UpdateContractTerms.useMutate({
    onSuccess: () => {
      alert('약관정보가 수정 되었습니다.');
    }
  });

  const [value, setValue] = useState(
    fetchContractTerms.data?.data.contentHtml ?? ''
  );

  return (
    <Flex direction="column" gap="xsmall" alignItems="center">
      <Flex style={{ width: '100%', height: '550px' }}>
        <ReactQuill
          style={{ width: '100%', height: '500px' }}
          modules={{
            toolbar: [
              [
                {
                  indent: '-1'
                },
                {
                  indent: '+1'
                }
              ],
              [
                'bold',
                'italic',
                {
                  color: []
                },
                {
                  background: []
                }
              ]
            ]
          }}
          theme="snow"
          value={value}
          onChange={setValue}
        ></ReactQuill>
      </Flex>
      <Button
        text="수정"
        onClick={() => updateContractTerms.mutate({ contentHtml: value, type })}
      />
    </Flex>
  );
}
