import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { FetchNoticeList } from './fetch-notice-list';
import axios from '../../../utils/axios';

export namespace CreateNoticeApi {
  export type ReqeustType = {
    title: string;
    contentHtml: string;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, ReqeustType> = {}) => {
    const { refetch } = FetchNoticeList.useRefetchData();

    const mutation = useMutation(
      ({ title, contentHtml }) => {
        return axios.post(`/notices`, {
          title,
          contentHtml
        });
      },
      {
        onSuccess: (...rest) => {
          refetch();
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
