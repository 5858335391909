import { State } from '.';
import { Optional } from 'utility-types';
import { ColorType } from '../../../../types/style';

export enum ActionKind {
  SET_STATE = 'SET_STATE',
  ADD_ITEM = 'ADD_ITEM'
}

export interface SetState {
  type: ActionKind.SET_STATE;
  payload: Optional<State>;
}

export interface AddItem {
  type: ActionKind.ADD_ITEM;
  payload: {
    text: string;
    value: string;
    colorType: ColorType;
  };
}

export type Actions = SetState | AddItem;
