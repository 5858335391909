import Text from './Text';
import styles from './item.module.scss';
import classNames from 'classnames/bind';
import { ColorType, SizeType } from '../../../../types/style';
import { useContextApi as useContextApiMenu } from '../../DropdownMenu/context';
import {
  ActionKind,
  DropdownItemItemContext,
  initState,
  reducer
} from './item-context';
import { useContextApi } from '../context';
import { useEffect, useReducer, useState } from 'react';

const cx = classNames.bind(styles);

type ItemCompoentType = React.FC<Props> & { Text: typeof Text };
type ComponentSizeType = Extract<SizeType, 'small' | 'medium' | 'large'>;

export interface Props {
  sizeType?: ComponentSizeType;
  children?: React.ReactNode;
  colorType?: ColorType;
  value: string;
  className?: string;
}

const Item: ItemCompoentType = ({
  sizeType: propSizeType,
  children,
  value,
  colorType,
  className
}: Props) => {
  const [itemItemState, ItemItemDispatch] = useReducer(reducer, initState);

  const { state } = useContextApi();
  const { state: menuState } = useContextApiMenu();

  const [isActive, setActive] = useState(false);
  const [sizeType, setSizeType] = useState<ComponentSizeType | undefined>(
    propSizeType
  );

  useEffect(() => {
    if (menuState?.value?.value === value) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [menuState.value, value]);

  useEffect(() => {
    ItemItemDispatch({
      type: ActionKind.SET_STATE,
      payload: {
        value: value,
        colorType: colorType
      }
    });
  }, [colorType, value]);

  useEffect(() => {
    if (!propSizeType) {
      setSizeType(state.size);
    }
  }, [propSizeType, state.size]);

  const handleClick = () => {
    menuState.setValue?.(value);
    menuState.setActive?.(false);
  };

  return (
    <DropdownItemItemContext.Provider
      value={{ state: itemItemState, dispatch: ItemItemDispatch }}
    >
      <div
        className={`${cx(
          'container',
          `size--${sizeType}`,
          isActive && 'active'
        )} ${className ?? ''}`}
        onClick={() => handleClick()}
      >
        {children}
      </div>
    </DropdownItemItemContext.Provider>
  );
};

Item.Text = Text;
export default Item;
