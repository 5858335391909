import classNames from 'classnames/bind';
import React from 'react';
import { ColorType } from '../../../types/style';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

type Props = React.ComponentPropsWithoutRef<'textarea'> & {
  colorType?: Extract<ColorType, 'primary-color' | 'black'>;
};

const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ value, colorType = 'primary-color', ...props }, ref) => {
    return (
      <textarea
        className={cx('container', value && 'active', {
          'color-primary': colorType === 'primary-color'
        })}
        ref={ref}
        value={value}
        {...props}
      ></textarea>
    );
  }
);

Textarea.displayName = 'Textarea';

export default Textarea;
