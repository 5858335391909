import {
  CouponFeautreType,
  CouponSaleType
} from '@aimpact-korea/arrange-front-types';
import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import axios from '../../../utils/axios';

export namespace FetchCouponPresetList {
  export type ResponseType = {
    couponPresetItems: {
      id: number;
      couponSaleType: CouponSaleType;
      amount: number;
      minAmountPrice: number;
      maxAmountPrice: number | null;
      couponFeautreType: CouponFeautreType;
      isDuplicationUse: boolean;
      isEnabled: boolean;
      updatedAt: string;
      createdAt: string;
    }[];
  };

  type TUseQueryOptions = UseQueryOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    AxiosResponse<ResponseType>,
    TQueryKey
  >;

  export const KEY_STRING = 'fetch-coupon-preset-list' as const;

  type TQueryKey = [typeof KEY_STRING];
  type TQueryKey2 = TQueryKey & QueryKey;

  export async function fetchData({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    queryKey
  }: QueryFunctionContext<TQueryKey2>) {
    return await axios.get<ResponseType>(`/coupont-preset`);
  }

  export const useFetchQuery = ({ ...props }: TUseQueryOptions = {}) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING], fetchData, {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
