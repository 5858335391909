import classNames from 'classnames/bind';
import React, { useEffect, useReducer } from 'react';
import { AlignType, SizeType } from '../../../types/style';
import styles from './index.module.scss';
import { reducer, initState, ActionKind, DropdownItemContext } from './context';
import { useContextApi } from '../DropdownMenu/context';
import ListVerticalItemGroup from './subcomponents/ListVerticalItemGroup';
import ListBoxItemGroup from './subcomponents/ListBoxItemGroup';

const cx = classNames.bind(styles);

export interface Props {
  size?: Extract<SizeType, 'small' | 'medium' | 'large'>;
  alignType?: AlignType;
  children: React.ReactNode;
}

type DropdownItemWrapperComponent = React.FC<Props> & {
  ListVerticalItemGroup: typeof ListVerticalItemGroup;
  ListBoxItemGroup: typeof ListBoxItemGroup;
};

const DropdownItemWrapper: DropdownItemWrapperComponent = ({
  size = 'medium',
  alignType = 'left',
  children
}: Props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { state: menuState } = useContextApi();

  useEffect(() => {
    dispatch({
      type: ActionKind.SET_STATE,
      payload: {
        size: size,
        alignType: alignType,
        isOpen: menuState.isActive
      }
    });
  }, [size, alignType, menuState.isActive]);

  return (
    <DropdownItemContext.Provider value={{ state, dispatch }}>
      <div
        className={`${cx(
          'container',
          `size--${size}`,
          `align--${alignType}`,
          menuState.isActive && 'visible'
        )} text-align--${alignType} font-size--${size}`}
        style={{
          maxHeight: '400px',
          overflowY: 'auto'
        }}
      >
        {children}
      </div>
    </DropdownItemContext.Provider>
  );
};

DropdownItemWrapper.ListVerticalItemGroup = ListVerticalItemGroup;
DropdownItemWrapper.ListBoxItemGroup = ListBoxItemGroup;
export default DropdownItemWrapper;
