import { IconProps } from '@aimpact-korea/arrange-front-icons/build/icons';
import { FontColorType } from '@aimpact-korea/arrange-front-types';
import { useEffect, useState } from 'react';
import { useContextApi } from '../context';

export interface Props {
  IconComponent: React.FC<IconProps>;
  className?: string;
  onClick?: () => void;
  fill?: FontColorType;
}

const BodyIcon: React.FC<Props> = ({
  IconComponent,
  className,
  onClick,
  fill
}) => {
  const { state } = useContextApi();

  const [size, setSize] = useState(18);

  useEffect(() => {
    switch (state.size) {
      // 의도된 non-break
      case 'large':
      case 'medium':
        setSize(24);
        break;
      case 'xxlarge':
        setSize(36);
        break;
    }
  }, [state.size]);

  return (
    <IconComponent
      colorType={fill}
      width={`${size}`}
      height={`${size}`}
      className={className}
      onClick={() => onClick?.()}
    />
  );
};

export default BodyIcon;
