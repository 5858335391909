import { DeliveryFormCreatedType } from '../../types/delivery-form';

export function deliveryFormCreatedTypeToString(
  type: DeliveryFormCreatedType
): string {
  switch (type) {
    case DeliveryFormCreatedType.SELF:
      return '직접등록';
    case DeliveryFormCreatedType.REQUEST:
      return '대리점 요청';
  }
}
