import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { ContractTermsType } from '../../../types/contract-terms';
import { FetchContractTerms } from './fetch-contract-terms';

export namespace UpdateContractTerms {
  export type RequestType = {
    contentHtml: string;
    type: ContractTermsType;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { modify } = FetchContractTerms.useModify();

    const mutation = useMutation(
      ({ contentHtml, type }) => {
        return axios.post(`/contract-terms/${type}`, {
          contentHtml
        });
      },
      {
        onSuccess: (...rest) => {
          modify({
            type: rest[1].type,
            contentHtml: rest[1].contentHtml
          });
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
