import classNames from 'classnames/bind';
import React from 'react';
import Cell from './Cell';
import styles from './row.module.scss';

const cx = classNames.bind(styles);

export type Props = React.ComponentPropsWithoutRef<'tr'>;

export interface RowComponent
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLTableRowElement>
  > {
  Cell: typeof Cell;
}

// eslint-disable-next-line react/display-name
const Row = React.forwardRef<HTMLTableRowElement, Props>(
  ({ children, className, ...props }, ref) => {
    return (
      <tr
        className={`${cx('container')} ${className ?? ''}`}
        ref={ref}
        {...props}
      >
        {children}
      </tr>
    );
  }
) as RowComponent;

Row.displayName = 'Row';
Row.Cell = Cell;

export default Row;
