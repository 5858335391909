import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { arrangeAppApi } from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';

export namespace FetchPickuponSite {
  export type ResponseType = {
    items: {
      id: number;
      name: string;
      address: string;
      detailAddress: string;
      postCode: string;
      displayOrder: number;
      pickupOnSiteRounds: {
        id: number;
        name: string;
        pickupStartAt: string;
        pickupEndAt: string;
        purchaseStartDate: string;
        purchaseEndDate: string;
      }[];
    }[];
  };

  export const KEY_STRING = 'fetch-pickupon-site-list' as const;

  export type QueryKeyType = [typeof KEY_STRING];
  export type TQueryKey = QueryKeyType & QueryKey;

  export async function fetch({ queryKey }: QueryFunctionContext<TQueryKey>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _queryKey = queryKey as QueryKeyType;

    return await arrangeAppApi.get<ResponseType>('/pickupon-site');
  }

  export const useFetchQuery = ({
    ...props
  }: UseQueryOptions<
    AxiosResponse<ResponseType>,
    AxiosError,
    AxiosResponse<ResponseType>,
    QueryKeyType
  > = {}) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING], fetch, {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
