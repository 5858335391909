import React, { Dispatch } from 'react';
import { ColorType } from '../../../../../types/style';
import { Actions } from './actions';

export interface State {
  value: string;
  text: string;
  colorType: ColorType;
}

export const initState: State = {
  value: '',
  text: '',
  colorType: 'primary-color'
};

type ContextType = {
  state: State;
  dispatch: Dispatch<Actions>;
};

export const DropdownItemItemContext = React.createContext<
  ContextType | undefined
>(undefined);

export const useContextApi = (): ContextType => {
  const context = React.useContext(DropdownItemItemContext);

  if (!context) {
    throw new Error('useContextApi error');
  }

  return context;
};

export * from './actions';
export * from './reducer';
