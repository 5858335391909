import { atom } from 'recoil';
import { MenuType } from '../../types/menu-type';

export const bgFadeoutState = atom({
  key: 'bgFadeoutState',
  default: false
});

export const adminAuthInfo = atom<{
  accessToken: string | null;
}>({
  key: 'adminAuthInfo',
  default: {
    /**
     * @TODO: 추후 AtomEffect 사용해서 localStorage 동기화
     */
    accessToken: window.localStorage.getItem('access_token')
  }
});

export const menuType = atom<MenuType | undefined>({
  key: 'menuType',
  default: 'dash-board'
});
