import {
  InfiniteData,
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import produce from 'immer';
import axios from '../../../utils/axios';
import {
  LinkfarmProductsRes,
  LINK_FARM_FETCH_PRODUCT_KEY_STRING
} from '../linkfarm/product/fetch-linkfarm-product';

export namespace UnlinkProductPickuponSiteRound {
  export type RequestType = {
    barofarmProductId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ barofarmProductId }) => {
        return axios.patch(
          `pickupon-site/round/unlink-product/${barofarmProductId}`
        );
      },
      {
        onSuccess: (...rest) => {
          const barofarmProductId = rest[1].barofarmProductId;

          queryClient.setQueriesData<
            InfiniteData<AxiosResponse<LinkfarmProductsRes>> | undefined
          >([LINK_FARM_FETCH_PRODUCT_KEY_STRING], (previous) => {
            if (previous) {
              return produce(previous, (draft) => {
                draft = {
                  ...draft,
                  pages: draft.pages.map((page) => ({
                    ...page,
                    data: {
                      ...page.data,
                      linkfarmProducts: page.data.linkfarmProducts.map((lp) => {
                        if (lp.id === barofarmProductId) {
                          return {
                            ...lp,
                            pickuponSiteRound: null
                          };
                        }

                        return lp;
                      })
                    }
                  }))
                };
                return draft;
              });
            }

            return previous;
          });

          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
