import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import {
  InfiniteData,
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import axios from '../../../../../utils/axios';
import { LinkfarmProductResellSalePeriodType } from '../../../../../types/linkfarm';
import { AimpactAxiosError } from '../../..';
import * as Yup from 'yup';
import { $enum } from 'ts-enum-util';
import dayjs from 'dayjs';
import { FetchLinkfarmProductResellsApi } from './fetch-linkfarm-product-resells';
import produce from 'immer';

export namespace ModifyLinkfarmProductProductResellApi {
  export type Parameter = {
    linkfarmProductResellId: number;
    salePeriodStartAt?: string | null;
    salePeriodEndAt?: string | null;
    saleCommission?: number;
    resellerCommission?: number;
    salePeriodType?: LinkfarmProductResellSalePeriodType;
    maxPickCount?: number;
  };

  export const linkfarmProductResellSchema: Yup.SchemaOf<Parameter> =
    Yup.object().shape({
      linkfarmProductResellId: Yup.number().required(),
      resellerCommission: Yup.number()
        .optional()
        .min(0.01, '리셀러 수수료는 최소 0.01 부터 가능합니다.')
        .max(50, '리셀러 수수료는 최대 50까지 가능합니다'),
      saleCommission: Yup.number()
        .optional()
        .min(0.01, '판매 수수료는 최소 0.01 부터 가능합니다.')
        .max(50, '리셀러 수수료는 최대 50까지 가능합니다'),
      salePeriodType: Yup.mixed()
        .optional()
        .oneOf($enum(LinkfarmProductResellSalePeriodType).getValues()),
      salePeriodStartAt: Yup.string().when('salePeriodType', {
        is: LinkfarmProductResellSalePeriodType.PERIOD,
        then: Yup.string()
          .required('판매기간을 설정해주세요.')
          .test('date', '판매기간을 설정해주세요.', (value) =>
            dayjs(value).isValid()
          ),
        otherwise: Yup.string().optional().nullable()
      }),
      salePeriodEndAt: Yup.string().when('salePeriodType', {
        is: LinkfarmProductResellSalePeriodType.PERIOD,
        then: Yup.string()
          .required('판매기간을 설정해주세요.')
          .test('date', '판매기간을 설정해주세요.', (value) =>
            dayjs(value).isValid()
          ),
        otherwise: Yup.string().optional().nullable()
      }),
      enabled: Yup.bool().optional(),
      maxPickCount: Yup.number()
        .optional()
        .min(1, '리셀러 픽 카운트는 최소 1명 부터 가능합니다.')
        .max(10000, '리셀러 픽 카운트는 최대 10000명 까지 가능합니다.')
    });

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<
    AxiosResponse,
    AxiosError<AimpactAxiosError>,
    Parameter
  > = {}) => {
    const errorHandler = useErrorHandler();
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({
        linkfarmProductResellId,
        resellerCommission,
        saleCommission,
        salePeriodType,
        salePeriodEndAt,
        salePeriodStartAt,
        maxPickCount
      }) => {
        // @TODO: form에서 해야 함
        linkfarmProductResellSchema.validateSync({
          linkfarmProductResellId,
          resellerCommission,
          saleCommission,
          salePeriodType,
          salePeriodEndAt,
          salePeriodStartAt,
          maxPickCount
        });

        return axios.put(
          `/linkfarm/product/resell/${linkfarmProductResellId}`,
          {
            resellerCommission,
            saleCommission,
            salePeriodType,
            salePeriodEndAt,
            salePeriodStartAt,
            maxPickCount
          }
        );
      },
      {
        onSuccess: (...args) => {
          const variables = args[1];

          queryClient.setQueriesData(
            [FetchLinkfarmProductResellsApi.QUERY_KEY_STRING],
            (
              previous:
                | InfiniteData<
                    AxiosResponse<FetchLinkfarmProductResellsApi.ResponseType>
                  >
                | undefined
            ) => {
              const _previous = previous as InfiniteData<
                AxiosResponse<FetchLinkfarmProductResellsApi.ResponseType>
              >;

              return produce(_previous, (draft) => {
                draft.pages = draft.pages.map((page) => {
                  page.data.linkfarmProductResells =
                    page.data.linkfarmProductResells.map(
                      (linkfarmProductResell) => {
                        if (
                          linkfarmProductResell.id ===
                          variables.linkfarmProductResellId
                        ) {
                          variables.salePeriodType;

                          if (variables.resellerCommission !== undefined) {
                            linkfarmProductResell.resellerCommission =
                              variables.resellerCommission;
                          }
                          if (variables.saleCommission !== undefined) {
                            linkfarmProductResell.saleCommission =
                              variables.saleCommission;
                          }
                          if (variables.salePeriodEndAt !== undefined) {
                            linkfarmProductResell.salePeriodEndAt =
                              variables.salePeriodEndAt;
                          }
                          if (variables.salePeriodStartAt !== undefined) {
                            linkfarmProductResell.salePeriodStartAt =
                              variables.salePeriodStartAt;
                          }
                          if (variables.salePeriodType !== undefined) {
                            linkfarmProductResell.salePeriodType =
                              variables.salePeriodType;
                          }
                          if (variables.maxPickCount !== undefined) {
                            linkfarmProductResell.maxPickCount =
                              variables.maxPickCount;
                          }
                        }

                        return linkfarmProductResell;
                      }
                    );

                  return page;
                });
              });
            }
          );

          onSuccess?.(...args);
        },
        onError: errorHandler,
        ...options
      }
    );

    return mutation;
  };
}
