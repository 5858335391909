import { IconProps } from '@aimpact-korea/arrange-front-icons/build/icons';
import styles from './index.module.scss';

export interface Props extends Omit<IconProps, 'className'> {
  active: boolean;
  IconComponent: React.FC<IconProps>;
}

const ActiveIcon: React.FC<Props> = ({ IconComponent, active, ...props }) => {
  return (
    <IconComponent
      {...props}
      className={`${active && styles.active} ${styles.icon}`}
    />
  );
};

export default ActiveIcon;
