import classNames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props {
  children: React.ReactNode;
}

const ContentCenter: React.FC<Props> = ({ children }) => {
  return <div className={cx('container')}>{children}</div>;
};

export default ContentCenter;
