import React, { Suspense } from 'react';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Table from '../../components/molecules/Table';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FetchArrangeProductPayment } from '../../hooks/http/arrange-product-payment/fetch-arrange-product-payment';
import List from '../../components/molecules/List';
import Text from '../../components/atoms/Text';
import { convertArrangeProductPaymentStatusKo } from '@aimpact-korea/arrange-front-utils/build';

const ArrangeProductPaymentPage: React.FC = () => {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName="어레인지 상품 구매 내역"></Header>
        <ArrangeProductPaymentDataWrapper />
      </Suspense>
    </DefaultTemplate>
  );
};

export default ArrangeProductPaymentPage;

const ArrangeProductPaymentDataWrapper: React.FC = () => {
  const { data, fetchNextPage, hasNextPage } =
    FetchArrangeProductPayment.useFetch({ limit: 20 });

  return (
    <InfiniteScroll
      style={{ overflow: 'visible' }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLength={data!.pages
        .map((page) => page.data.items.length)
        .reduce((sum, current) => sum + current, 0)}
      next={fetchNextPage}
      hasMore={hasNextPage ?? false}
      loader={<h4>Loading...</h4>}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              결제번호
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              구매상품
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              구매상태
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              상품금액
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              결제날짜
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              환불날짜
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data?.pages.map((page) =>
            page.data.items.map((item) => (
              <ArrangeProductPaymentDataRow key={item.id} data={item} />
            ))
          )}
        </Table.Body>
      </Table>
    </InfiniteScroll>
  );
};

const ArrangeProductPaymentDataRow: React.FC<{
  data: FetchArrangeProductPayment.ResponseType['items'][number];
}> = ({ data }) => {
  return (
    <Table.Row>
      <Table.Cell alignType="center">
        <Text isBold={true}>No.{data.id}</Text>
      </Table.Cell>
      <Table.Cell alignType="center">
        <List>
          <List.Item>
            <Text>{data.store.id}</Text>
          </List.Item>
          <List.Item>
            <Text>{data.store.company}</Text>
          </List.Item>
        </List>
      </Table.Cell>
      <Table.Cell alignType="center">
        <Text>{data.arrangeProductKindOfKind}</Text>
      </Table.Cell>
      <Table.Cell alignType="center">
        <Text>
          {convertArrangeProductPaymentStatusKo(
            data.arrangeProductPaymentStatus
          )}
        </Text>
      </Table.Cell>
      <Table.Cell alignType="center">
        <Text>{data.paymentPrice.toLocaleString()}</Text>원
      </Table.Cell>
      <Table.Cell alignType="center">
        <Text>{data.payedAt}</Text>
      </Table.Cell>
      <Table.Cell alignType="center">
        <Text>{data.refundAt}</Text>
      </Table.Cell>
    </Table.Row>
  );
};
