import classNames from 'classnames/bind';
import React from 'react';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props {
  type?: 'checkbox' | 'radio' | 'toggle';
  isChecked?: boolean;
  value?: string;
  disabled?: boolean;
  id?: string;
  onChangeChecked?: () => void;
  name?: string;
}

const Selection: React.FC<Props> = ({
  isChecked = false,
  type = 'checkbox',
  onChangeChecked,
  id = '',
  value,
  disabled = false,
  name
}) => {
  return (
    <input
      className={cx('selection', `type--${type}`)}
      type={type === 'toggle' ? 'checkbox' : 'radio'}
      value={value}
      checked={isChecked}
      id={id}
      name={name}
      disabled={disabled}
      onClick={() => {
        onChangeChecked?.();
      }}
      onChange={() => null}
    />
  );
};

export default Selection;
