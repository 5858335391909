import {
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { StopWordType } from '../../../types/stop-word';
import { FetchStopWordName } from './fetch-stop-word-name';
import axios from '../../../utils/axios';
import { AimpactAxiosError } from '..';

export namespace AddStopWordName {
  export type RequestParam = {
    type: StopWordType;
    word: string;
  };

  export const useMutateQuery = ({
    ...options
  }: UseMutationOptions<
    AxiosResponse,
    AxiosError<AimpactAxiosError>,
    RequestParam
  > = {}) => {
    const errorHandler = useErrorHandler();
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({ type, word }) => {
        return axios.post(`stop-word/name`, {
          type,
          word
        });
      },
      {
        onError: (error) => {
          console.log();
          if (error.response?.status === 409) {
            alert('이미 있는 불용어입니다.');
            return;
          }
          errorHandler(error);
        },
        onSuccess: (_, { type, word }) => {
          queryClient.setQueryData<
            AxiosResponse<FetchStopWordName.ResponseType> | undefined
          >([FetchStopWordName.KEY_STRING, { type }], (data) => {
            data?.data.words.push(word);

            return data;
          });
        },
        ...options
      }
    );

    return mutation;
  };
}
