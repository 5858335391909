import {
  useMutation,
  UseMutationOptions,
  useQueryClient
} from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { AxiosError, AxiosResponse } from 'axios';
import {
  CouponFeautreType,
  CouponSaleType
} from '@aimpact-korea/arrange-front-types';
import { FetchCouponPresetList } from './fetch-coupon-preset';

export namespace CreateCouponPreset {
  export type RequestType = {
    couponSaleType: CouponSaleType;
    amount: number;
    minAmountPrice: number;
    maxAmountPrice: number | null;
    couponFeautreType: CouponFeautreType;
    isDuplicationUse: boolean;
  };

  export const useMutate = ({
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const queryClient = useQueryClient();

    const mutation = useMutation(
      ({
        amount,
        couponFeautreType,
        couponSaleType,
        isDuplicationUse,
        maxAmountPrice,
        minAmountPrice
      }) => {
        return axios.post(`/coupont-preset`, {
          amount,
          couponFeautreType,
          couponSaleType,
          isDuplicationUse,
          maxAmountPrice,
          minAmountPrice
        });
      },
      {
        onSuccess: (...args) => {
          queryClient.refetchQueries({
            queryKey: [FetchCouponPresetList.KEY_STRING],
            stale: false
          });

          onSuccess?.(...args);
        },
        onError: (...args) => {
          if (args[0].isAxiosError) {
            alert(args[0].response?.data);
          }
          onError?.(...args);
        },
        ...options
      }
    );

    return mutation;
  };
}
