import {
  DotVerticalIcon,
  IconProps
} from '@aimpact-korea/arrange-front-icons/build/icons';
import classNames from 'classnames/bind';
import { useState } from 'react';
import DropdownMenu from '../DropdownMenu';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface SettingMenuType {
  IconComponent?: React.FC<IconProps>;
  value: string;
  text: string;
}

export interface Props {
  settingMenus: SettingMenuType[];
  onSelect: (value: string) => void;
}

const SettingDropdown: React.FC<Props> = ({ settingMenus, onSelect }) => {
  const [active, setActive] = useState(false);

  return (
    <DropdownMenu isActive={active} setActive={setActive} setValue={onSelect}>
      <DropdownMenu.Title>
        <DotVerticalIcon colorType="text-gray-800" />
      </DropdownMenu.Title>
      <DropdownMenu.ItemWrapper alignType="center" size="small">
        <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
          {settingMenus.map((sm) => (
            <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
              key={sm.value}
              value={sm.value}
              className={cx('setting-dropdown-item')}
            >
              {sm.IconComponent && (
                <sm.IconComponent colorType="text-primary-500" />
              )}
              <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text
                text={sm.text}
              />
            </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
          ))}
        </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
      </DropdownMenu.ItemWrapper>
    </DropdownMenu>
  );
};

export default SettingDropdown;
