import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';
import { FetchArrangeManualGroupDetail } from './fetch-arrange-manual';

export namespace RemoveArrangeManual {
  export type RequestType = {
    arrangeManualId: number;
    arrangeManualGroupId: number;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    const { refetch } = FetchArrangeManualGroupDetail.useRefetchData();

    const mutation = useMutation(
      ({ arrangeManualGroupId, arrangeManualId }) => {
        return axios.delete(
          `/arrange-manual/group/${arrangeManualGroupId}/${arrangeManualId}`
        );
      },
      {
        onSuccess: (...rest) => {
          refetch({ arrangeManualGroupId: rest[1].arrangeManualGroupId });
          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
