import React, { Dispatch } from 'react';
import { AlignType, SizeType } from '../../../../types/style';
import { Actions } from './actions';

export interface State {
  size: Extract<SizeType, 'small' | 'medium' | 'large'>;
  alignType: AlignType;
  isOpen: boolean;
}

export const initState: State = {
  size: 'medium',
  alignType: 'left',
  isOpen: false
};

type ContextType = {
  state: State;
  dispatch: Dispatch<Actions>;
};

export const DropdownItemContext = React.createContext<ContextType | undefined>(
  undefined
);

export const useContextApi = (): ContextType => {
  const context = React.useContext(DropdownItemContext);

  if (!context) {
    throw new Error('useContextApi error');
  }

  return context;
};

export * from './actions';
export * from './reducer';
