import { State } from '.';
import { ActionKind, Actions } from './actions';

export function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case ActionKind.SET_STATE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case ActionKind.ADD_ITEM: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.value]: action.payload
        }
      };
    }
    default:
      state;
  }
  return state;
}
