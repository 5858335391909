import React, { Dispatch } from 'react';
import { ColorType, SizeType } from '../../../../types/style';
import { Actions } from './actions';

export interface State {
  size: Extract<SizeType, 'medium' | 'large' | 'xxlarge'>;
  isActive: boolean;
  value: {
    text: string;
    value: string;
    colorType: ColorType;
  };
  items: {
    [key: string]: {
      text: string;
      value: string;
      colorType: ColorType;
    };
  };
  setActive?: (state: boolean) => void;
  setValue?: (state: string) => void;
  disabled: boolean;
}

export const initState: State = {
  size: 'medium',
  isActive: false,
  value: {
    text: '',
    value: '',
    colorType: 'primary-color'
  },
  items: {},
  setActive: undefined,
  setValue: undefined,
  disabled: false
};

type ContextType = {
  state: State;
  dispatch: Dispatch<Actions>;
};

export const DropdownMenuContext = React.createContext<ContextType | undefined>(
  undefined
);

export const useContextApi = (): ContextType => {
  const context = React.useContext(DropdownMenuContext);

  if (!context) {
    throw new Error('useContextApi error');
  }

  return context;
};

export * from './actions';
export * from './reducer';
