import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import classNames from 'classnames/bind';
import { Suspense, useState } from 'react';
import Button from '../../components/atoms/Button';
import CreateAndroidMessageAppModal from '../../components/features/CreateAndroidMessageAppModal';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import { CreateAndroidMessageApp } from '../../hooks/http/android/create-android-message-app';
import { FetchAndroidMessageApp } from '../../hooks/http/android/fetch-android-message-app';
import { RemoveAndroidMessageApp } from '../../hooks/http/android/remove-android-message-app';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export default function AndroidMessageAppPage() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'안드로이드 메시지 앱'}></Header>
        <AndroidMessageAppList />
      </Suspense>
    </DefaultTemplate>
  );
}

function AndroidMessageAppList() {
  const fetchAndroidMessage = FetchAndroidMessageApp.useFetch();
  const removeAndroidMessageApp = RemoveAndroidMessageApp.useMutate();
  const createAndroidMessageApp = CreateAndroidMessageApp.useMutate({
    onSuccess: () => {
      setShowArrangeManualGroupModifyModal(false);
      setSelectModifyManualGroup(null);
      alert('메시지 앱이 추가 되었습니다.');
    }
  });

  const [
    isShowArrangeManualGroupModifyModal,
    setShowArrangeManualGroupModifyModal
  ] = useState(false);

  const [selectModifyManualGroup, setSelectModifyManualGroup] = useState<{
    packageName: string;
    title: string;
    subtitle: string;
    sms: string;
    mms: string;
  } | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const fetchAndroidMessageData = fetchAndroidMessage.data!.data!;

  function onClickDeleteMessageApp(androidMessageAppId: number) {
    if (confirm('안드로이드 메지앱을 삭제 하시겠습니까?')) {
      removeAndroidMessageApp.mutate({
        androidMessageAppId
      });
    }
  }

  function onClickCreateAndroidMessageApp() {
    setShowArrangeManualGroupModifyModal(true);
    setSelectModifyManualGroup({
      mms: '',
      packageName: '',
      sms: '',
      subtitle: '',
      title: ''
    });
  }

  function onClickCreateAndroidMessageAppApply({
    mms,
    packageName,
    sms,
    subtitle,
    title
  }: {
    packageName: string;
    title: string;
    subtitle: string;
    sms: string;
    mms: string;
  }) {
    createAndroidMessageApp.mutate({
      mms,
      packageName,
      sms,
      subtitle,
      title
    });
  }

  return (
    <Flex
      flexDirection="flex-col"
      width="w-full"
      alignItems="items-start"
      gapSize={StyleBuilderInstane.toGapSpaceType('gap-s')}
    >
      {selectModifyManualGroup && isShowArrangeManualGroupModifyModal && (
        <CreateAndroidMessageAppModal
          isOpen={isShowArrangeManualGroupModifyModal}
          androidMessageApp={selectModifyManualGroup}
          handleClose={() => setShowArrangeManualGroupModifyModal(false)}
          handleSave={(androidMessageApp) =>
            onClickCreateAndroidMessageAppApply(androidMessageApp)
          }
          isLoading={createAndroidMessageApp.isLoading}
        />
      )}
      <Button onClick={() => onClickCreateAndroidMessageApp()} text="추가" />
      <table className={`${cx('table')}`}>
        <thead>
          <tr>
            <th>No</th>
            <th>title</th>
            <th>packageName</th>
            <th>subtitle</th>
            <th>sms</th>
            <th>mms</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          {fetchAndroidMessageData.map((messageApp) => (
            <tr key={messageApp.id}>
              <td>{messageApp.id}</td>
              <td>{messageApp.title}</td>
              <td>{messageApp.packageName}</td>
              <td>{messageApp.subtitle}</td>
              <td>{messageApp.sms}</td>
              <td>{messageApp.mms}</td>
              <td>
                <Button
                  type="secondary-color"
                  text="삭제"
                  onClick={() => onClickDeleteMessageApp(messageApp.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
}
