import {
  EditPencilIcon,
  IconProps
} from '@aimpact-korea/arrange-front-icons/build/icons';

export interface Props {
  IconComponent?: React.FC<IconProps>;
}

const LabelIcon: React.FC<Props> = ({ IconComponent = EditPencilIcon }) => {
  return <IconComponent colorType="text-gray-500" width="16" height="16" />;
};

export default LabelIcon;
