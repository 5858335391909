import { LazyLoadImage } from 'react-lazy-load-image-component';
import qs from 'qs';

type Props = Omit<
  React.ComponentProps<typeof LazyLoadImage>,
  'placeholderSrc'
> & {
  width: number;
  height?: number;
  q?: string;
  objectFit?: NonNullable<JSX.IntrinsicElements['img']['style']>['objectFit'];
};

export function Image({
  src: srcProps,
  width,
  height,
  q,
  objectFit,
  ...props
}: Props) {
  const src = `${srcProps}?${qs.stringify({
    w: width,
    h: height,
    q: q,
    t: objectFit
  })}`;
  const placeholderSrc = `${srcProps}?${qs.stringify({
    w: width,
    h: height,
    q: 5,
    t: objectFit
  })}`;

  return (
    <LazyLoadImage
      src={src}
      placeholderSrc={placeholderSrc}
      width={width}
      height={height}
      {...props}
      effect="blur"
    />
  );
}
