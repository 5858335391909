import Text from '../../atoms/Text';
import Flex from '../../molecules/Flex';
import ProfileImage, { Props as PIPRops } from '../../molecules/ProfileImage';

export interface Props extends PIPRops {
  name: string;
  phone: string;
}

const ProfileInfo: React.FC<Props> = ({ name, phone, ...props }) => {
  return (
    <Flex gap="large">
      <ProfileImage {...props} />
      <Flex gap="xsmall" alignItems="center">
        <Text colorType="gray-dark-color" isBold={true}>
          {name}
        </Text>
        <Text colorType="gray-color" isBold={true} size="small">
          {phone}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProfileInfo;
