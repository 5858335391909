import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import { Suspense, useState } from 'react';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Button from '../../components/atoms/Button';
import { FetchCouponPresetList } from '../../hooks/http/coupon-preset/fetch-coupon-preset';
import { CouponSaleType } from '@aimpact-korea/arrange-front-types';
import { CreateCouponPreset } from '../../hooks/http/coupon-preset/create-coupon-preset';
import CreateCouponPresetModal from '../../components/features/CreateCouponPresetModal';
import Selection from '../../components/molecules/Selection';
import { PatchCouponPresetIsEnabled } from '../../hooks/http/coupon-preset/patch-coupon-preset-is-enabled';

const cx = classNames.bind(styles);

export default function CouponPresetPage() {
  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'쿠폰 프리셋'}></Header>
        <CouponPresetListComponent />
      </Suspense>
    </DefaultTemplate>
  );
}

function CouponPresetListComponent() {
  const couponPresetListQuery = FetchCouponPresetList.useFetchQuery();
  const createCouponPresetMutate = CreateCouponPreset.useMutate({
    onSuccess: () => {
      handleCloseCouponPreset();
    }
  });
  const patchCouponPresetIsEnabledMutate =
    PatchCouponPresetIsEnabled.useMutate();
  const [isShowCouponPresetModal, setShowCouponPresetModal] = useState(false);

  const isLoading =
    couponPresetListQuery.isLoading ||
    createCouponPresetMutate.isLoading ||
    patchCouponPresetIsEnabledMutate.isLoading;

  function onClickAddCouponPresetModal() {
    setShowCouponPresetModal(true);
  }

  function createCouponPreset({
    amount,
    couponFeautreType,
    couponSaleType,
    isDuplicationUse,
    maxAmountPrice,
    minAmountPrice
  }: CreateCouponPreset.RequestType) {
    createCouponPresetMutate.mutate({
      amount,
      couponFeautreType,
      couponSaleType,
      isDuplicationUse,
      maxAmountPrice,
      minAmountPrice
    });
  }

  function handleCloseCouponPreset() {
    setShowCouponPresetModal(false);
  }

  return (
    <Flex
      flexDirection="flex-col"
      width="w-full"
      alignItems="items-start"
      gapSize={StyleBuilderInstane.toGapSpaceType('gap-s')}
    >
      {isShowCouponPresetModal && (
        <CreateCouponPresetModal
          isOpen={isShowCouponPresetModal}
          handleClose={handleCloseCouponPreset}
          handleSave={(couponPreset) => {
            createCouponPreset(couponPreset);
          }}
          isLoading={isLoading}
        />
      )}
      <Button
        onClick={() => onClickAddCouponPresetModal()}
        text="추가"
        isLoading={isLoading}
      />
      <table className={`${cx('table')}`}>
        <thead>
          <tr>
            <th>No</th>
            <th>쿠폰 타입</th>
            <th>쿠폰 기능</th>
            <th>할인가</th>
            <th>최대 할인가</th>
            <th>최소 주문금액</th>
            <th>쿠폰 중복사용 여부</th>
            <th>활성화 여부</th>
          </tr>
        </thead>
        <tbody>
          {couponPresetListQuery.data?.data.couponPresetItems.map(
            (couponPreset) => (
              <tr key={couponPreset.id}>
                <td>{couponPreset.id}</td>
                <td>{couponPreset.couponSaleType}</td>
                <td>{couponPreset.couponFeautreType}</td>
                <td>
                  {couponPreset.couponSaleType === CouponSaleType.PRICE
                    ? `${couponPreset.amount.toLocaleString()}원`
                    : `${couponPreset.amount}%`}
                </td>
                <td>{couponPreset.maxAmountPrice?.toLocaleString() ?? 0}원</td>
                <td>{couponPreset.minAmountPrice?.toLocaleString() ?? 0}원</td>
                <td>{couponPreset.isDuplicationUse ? '가능' : '불가능'}</td>
                <td>
                  <Selection>
                    <Selection.Input
                      disabled={isLoading}
                      type="toggle"
                      isChecked={couponPreset.isEnabled}
                      onChangeChecked={() =>
                        patchCouponPresetIsEnabledMutate.mutate({
                          couponPresetId: couponPreset.id,
                          isEnabled: !couponPreset.isEnabled
                        })
                      }
                    ></Selection.Input>
                  </Selection>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </Flex>
  );
}
