import { LinkfarmProductResellDisplayedState } from '../types/linkfarm';

export const linkfarmProductResellDisplayedStateKo: Record<
  LinkfarmProductResellDisplayedState,
  string
> = {
  END_OF_SALE: '판매종료',
  SALE: '판매중',
  TO_BE_SOLD: '판매예정',
  PAUSE: '일시중지'
};
