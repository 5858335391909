import React from 'react';
import Flex from '../../molecules/Flex';
import Box from './subcomponents/Box';

type DetailDataComponent = React.FC<{ children?: React.ReactNode }> & {
  Box: typeof Box;
};

const DetailDataTemplate: DetailDataComponent = ({ children }) => {
  return <Flex gap="xxlarge">{children}</Flex>;
};

DetailDataTemplate.displayName = 'DetailData';

DetailDataTemplate.Box = Box;

export default DetailDataTemplate;
