import classNames from 'classnames/bind';
import Label from '../../atoms/Label';
import AtomSelection from '../../atoms/Selection';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props {
  children: React.ReactNode;
  className?: string;
}

type SelectionCompoent = React.FC<Props> & {
  Label: typeof Label;
  Input: typeof AtomSelection;
};

/**
 * @TODO div forwardRef 로 변경
 */

const Selection: SelectionCompoent = ({ children, className = '' }) => {
  return <div className={`${cx('container')} ${className}`}>{children}</div>;
};

Selection.Label = Label;
Selection.Input = AtomSelection;
export default Selection;
