import {
  useMutation,
  UseMutationOptions
  // useQueryClient
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../../utils/axios';

export namespace ModifyPickupOnSiteRound {
  export type RequestType = {
    pickupOnSiteRoundId: number;
    name?: string;
    pickupStartAt?: string;
    pickupEndAt?: string;
    purchaseStartDate?: string;
    purchaseEndDate?: string;
  };

  export const useMutate = ({
    onSuccess,
    ...options
  }: UseMutationOptions<AxiosResponse, AxiosError, RequestType> = {}) => {
    // const queryClient = useQueryClient();

    const mutation = useMutation(
      ({
        pickupOnSiteRoundId,
        name,
        pickupEndAt,
        pickupStartAt,
        purchaseEndDate,
        purchaseStartDate
      }) => {
        return axios.put(`/pickupon-site/round/${pickupOnSiteRoundId}`, {
          name,
          pickupEndAt,
          pickupStartAt,
          purchaseEndDate,
          purchaseStartDate
        });
      },
      {
        onSuccess: (...rest) => {
          // const pickupOnSiteRoundId = rest[1].pickupOnSiteRoundId;

          onSuccess?.(...rest);
        },
        ...options
      }
    );

    return mutation;
  };
}
