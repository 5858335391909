import { useEffect, useState } from 'react';
import { useContextApi } from '../context';
import styles from './up-down-icon.module.scss';
import classNames from 'classnames/bind';
import {
  ChevronDownIcon,
  ChevronUpIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';

const cx = classNames.bind(styles);

const UpDownIcon: React.FC = () => {
  const { state } = useContextApi();

  const [iconSize, setIconSize] = useState<number>(24);
  const [IconComponent, setIconComponent] = useState<
    typeof ChevronUpIcon | typeof ChevronDownIcon | null
  >(null);

  useEffect(() => {
    switch (state.size) {
      // 의도 된 non-break
      case 'medium':
      case 'large':
        setIconSize(24);
        break;
      case 'xxlarge':
        setIconSize(54);
        break;
    }
  }, [state.size]);

  useEffect(() => {
    if (state.isActive) {
      setIconComponent(ChevronUpIcon);
    } else {
      setIconComponent(ChevronDownIcon);
    }
  }, [state.isActive]);

  if (!IconComponent) {
    return <div></div>;
  }

  return (
    <IconComponent
      className={cx('arrow-icon', { disabled: state.disabled })}
      width={`${iconSize}px`}
      height={`${iconSize}px`}
    />
  );
};

export default UpDownIcon;
