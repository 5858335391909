import classNames from 'classnames/bind';
import React from 'react';
import Button, { Props as ButtonProps } from '../../atoms/ActiveButton';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export interface Props extends ButtonProps {
  className?: string;
}

const MenuButton: React.FC<Props> = ({
  className,
  onClick,
  active,
  startIcon,
  label,
  ...props
}) => {
  return (
    <div className={`${cx('container')} ${className}`}>
      <Button
        startIcon={startIcon}
        label={label}
        active={active}
        onClick={onClick}
        {...props}
      ></Button>
      <div
        className={`${styles['active-box']}
        ${active && styles.active}`}
      ></div>
    </div>
  );
};

export default MenuButton;
