import { Span } from '@aimpact-korea/arrange-front-atomic';
import Flex from '@aimpact-korea/arrange-front-atomic/build/atoms/Flex';
import StyleBuilderInstane from '@aimpact-korea/arrange-front-atomic/build/utils/style-builder';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { Suspense, useState } from 'react';
import Button from '../../../components/atoms/Button';
import ModifyPickupOnSiteRoundModal from '../../../components/features/ModifyPickupOnSiteRoundModal';
import ListSkeleton from '../../../components/molecules/ListSkeleton';
import Header from '../../../components/organisms/Header';
import DefaultTemplate from '../../../components/templates/Default';
import { CreatePickuponSiteRound } from '../../../hooks/http/pickupon-site/create-pickupon-site-round';
import { FetchPickuponSiteDetail } from '../../../hooks/http/pickupon-site/fetch-pickupon-site-detail';
import { ModifyPickupOnSiteRound } from '../../../hooks/http/pickupon-site/modify-pickupon-site-round';
import { PickupRequestPickupOnSiteAlarm1 } from '../../../hooks/http/pickupon-site/pickup-request-pickupon-site-alarm-1';
import { PickupRequestPickupOnSiteAlarm2 } from '../../../hooks/http/pickupon-site/pickup-request-pickupon-site-alarm-2';
import { PickupRequestPickupOnSiteAlarm3 } from '../../../hooks/http/pickupon-site/pickup-request-pickupon-site-alarm-3';
import { useLocationStateParam } from '../../../hooks/useLocationSearchQueryParam';
import { Type } from '../../../utils/decorators';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

class QueryStringParams {
  @Type(() => Number)
  pickupOnSiteId!: number;
}

export default function PickonSiteDetailPage() {
  const locationQueryString = useLocationStateParam(QueryStringParams);

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'현장수령 지점 상세 회차 리스트'}></Header>
        <PickonSiteData pickupOnSiteId={locationQueryString.pickupOnSiteId} />
      </Suspense>
    </DefaultTemplate>
  );
}

function PickonSiteData({ pickupOnSiteId }: { pickupOnSiteId: number }) {
  const pickuponSite = FetchPickuponSiteDetail.useFetchQuery({
    pickupOnSiteId
  });

  const [isShowModifyPickupOnSiteModal, setShowModifyPickupOnSiteModal] =
    useState(false);

  const pickupRequestPickupOnSiteAlarm1 =
    PickupRequestPickupOnSiteAlarm1.useMutate({
      onSuccess: (response) => {
        alert(`${response.data.sendedCount}건의 알림톡이 발송 됐습니다.`);
      },
      onError: (response) => {
        alert(`${response.message}`);
      }
    });
  const pickupRequestPickupOnSiteAlarm2 =
    PickupRequestPickupOnSiteAlarm2.useMutate({
      onSuccess: (data) => {
        alert(`${data.data.sendedCount}건의 알림톡이 발송 됐습니다.`);
      },
      onError: (response) => {
        alert(`${response.message}`);
      }
    });
  const pickupRequestPickupOnSiteAlarm3 =
    PickupRequestPickupOnSiteAlarm3.useMutate({
      onSuccess: (data) => {
        alert(`${data.data.sendedCount}건의 알림톡이 발송 됐습니다.`);
      },
      onError: (response) => {
        alert(`${response.message}`);
      }
    });

  const isLoading =
    pickupRequestPickupOnSiteAlarm1.isLoading ||
    pickupRequestPickupOnSiteAlarm2.isLoading ||
    pickupRequestPickupOnSiteAlarm3.isLoading;

  const modifyPickupOnSiteRound = ModifyPickupOnSiteRound.useMutate({
    onSuccess: () => {
      alert('수정이 완료 되었습니다.');

      setShowModifyPickupOnSiteModal(false);
      setSelectPickupOnSite(null);
    }
  });

  const createPickupOnSiteRound = CreatePickuponSiteRound.useMutate({
    onSuccess: () => {
      alert('회차 추가가 완료 되었습니다.');

      setShowModifyPickupOnSiteModal(false);
      setSelectPickupOnSite(null);
    }
  });

  const [selectPickupOnSite, setSelectPickupOnSite] = useState<
    | React.ComponentProps<
        typeof ModifyPickupOnSiteRoundModal
      >['pickupOnSiteRound']
    | null
  >(null);

  function onModifyPickupOnSiteRound(
    pickupOnSiteRound: React.ComponentProps<
      typeof ModifyPickupOnSiteRoundModal
    >['pickupOnSiteRound']
  ) {
    if (pickupOnSiteRound.pickupOnSiteRoundId !== undefined) {
      modifyPickupOnSiteRound.mutate({
        ...pickupOnSiteRound,
        pickupOnSiteRoundId: pickupOnSiteRound.pickupOnSiteRoundId
      });
    } else {
      createPickupOnSiteRound.mutate({
        ...pickupOnSiteRound,
        pickupOnSiteId: pickupOnSiteId
      });
    }
  }

  function onClickModifyPickupOnSiteRound(
    pickupOnSiteRound: FetchPickuponSiteDetail.ResponseType['pickupOnSiteRounds'][number]
  ) {
    setSelectPickupOnSite({
      pickupOnSiteRoundId: pickupOnSiteRound.id,
      ...pickupOnSiteRound
    });
    setShowModifyPickupOnSiteModal(true);
  }

  function onClickCreatePickupOnSiteRound() {
    setSelectPickupOnSite({
      pickupOnSiteRoundId: undefined,
      name: '',
      pickupEndAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      pickupStartAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      purchaseEndDate: dayjs().format('YYYY-MM-DD'),
      purchaseStartDate: dayjs().format('YYYY-MM-DD')
    });
    setShowModifyPickupOnSiteModal(true);
  }

  function onClickPickupReqeustAlarmTalk(
    pickupOnSiteRoundId: number,
    type: 1 | 2 | 3
  ) {
    if (type === 1) {
      pickupRequestPickupOnSiteAlarm1.mutate({
        pickupOnSiteRoundId: pickupOnSiteRoundId
      });
    }
    if (type === 2) {
      pickupRequestPickupOnSiteAlarm2.mutate({
        pickupOnSiteRoundId: pickupOnSiteRoundId
      });
    }
    if (type === 3) {
      pickupRequestPickupOnSiteAlarm3.mutate({
        pickupOnSiteRoundId: pickupOnSiteRoundId
      });
    }
  }

  return (
    <Flex
      flexDirection="flex-col"
      width="w-full"
      alignItems="items-start"
      gapSize={StyleBuilderInstane.toGapSpaceType('gap-s')}
    >
      {isShowModifyPickupOnSiteModal && selectPickupOnSite && (
        <ModifyPickupOnSiteRoundModal
          isOpen={isShowModifyPickupOnSiteModal}
          pickupOnSiteRound={selectPickupOnSite}
          handleClose={() => setShowModifyPickupOnSiteModal(false)}
          handleSave={(androidMessageApp) =>
            onModifyPickupOnSiteRound(androidMessageApp)
          }
          isLoading={modifyPickupOnSiteRound.isLoading}
        />
      )}
      <Button text="추가" onClick={() => onClickCreatePickupOnSiteRound()} />
      <table className={`${cx('table')}`}>
        <thead>
          <tr>
            <th>회차명</th>
            <th>지점명</th>
            <th>구매가능 기한</th>
            <th>픽업가능 기한</th>
            <th>알림톡 전송</th>
            <th>수정</th>
            <th>구매내역</th>
            <th>미수령 내역</th>
          </tr>
        </thead>
        <tbody>
          {pickuponSite.data?.data.pickupOnSiteRounds.map(
            (pickupOnSiteRound) => (
              <tr key={pickupOnSiteRound.id}>
                <td>{pickupOnSiteRound.name}</td>
                <td>{pickuponSite.data?.data.name}</td>
                <td>
                  {pickupOnSiteRound.purchaseStartDate} ~{' '}
                  {pickupOnSiteRound.purchaseEndDate}
                </td>
                <td>
                  {pickupOnSiteRound.pickupStartAt} ~{' '}
                  {pickupOnSiteRound.pickupEndAt}
                </td>
                <td>
                  <Flex gapSize={StyleBuilderInstane.toGapSpaceType('gap-xxs')}>
                    <Button
                      text="1차 알림톡 전송"
                      isLoading={isLoading}
                      onClick={() =>
                        onClickPickupReqeustAlarmTalk(pickupOnSiteRound.id, 1)
                      }
                    ></Button>
                    <Button
                      isLoading={isLoading}
                      text="2차 알림톡 전송"
                      onClick={() =>
                        onClickPickupReqeustAlarmTalk(pickupOnSiteRound.id, 2)
                      }
                    ></Button>
                    <Button
                      isLoading={isLoading}
                      text="3차 알림톡 전송"
                      onClick={() =>
                        onClickPickupReqeustAlarmTalk(pickupOnSiteRound.id, 3)
                      }
                    ></Button>
                  </Flex>
                </td>
                <td>
                  <Button
                    isLoading={isLoading}
                    text="수정"
                    onClick={() =>
                      onClickModifyPickupOnSiteRound(pickupOnSiteRound)
                    }
                  ></Button>
                </td>
                <td>
                  <div>
                    {pickupOnSiteRound.countByProducts.map((countByProduct) => (
                      <div key={countByProduct.productId}>
                        <Span
                          fontColorType={StyleBuilderInstane.toFontColorType(
                            'text-primary-500'
                          )}
                          fontWeightType="font-bold"
                        >
                          {countByProduct.productName}
                        </Span>
                        {countByProduct.options.map((option) => (
                          <div key={option.optionCode}>
                            <span>
                              {option.optionName} - {option.count}개
                            </span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div>
                    {pickupOnSiteRound.paidAndNotReceivedCountByProducts.map(
                      (countByProduct) => (
                        <div key={countByProduct.productId}>
                          <Span
                            fontColorType={StyleBuilderInstane.toFontColorType(
                              'text-primary-500'
                            )}
                            fontWeightType="font-bold"
                          >
                            {countByProduct.productName}
                          </Span>
                          {countByProduct.options.map((option) => (
                            <div key={option.optionCode}>
                              <span>
                                {option.optionName} - {option.count}개
                              </span>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </Flex>
  );
}
