import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { adminAuthInfo } from '../recoil/atoms';

const useSignout = () => {
  const setAdminAuthInfo = useSetRecoilState(adminAuthInfo);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signout = ({ url = '/' }: { url: string } = { url: '/' }) => {
    queryClient.clear();
    setAdminAuthInfo({ accessToken: null });
    localStorage.removeItem('access_token');
    navigate(url);
  };

  return signout;
};

export default useSignout;
