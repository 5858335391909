import React, { Suspense, useEffect, useState } from 'react';
import Text from '../../components/atoms/Text';
import Textarea from '../../components/atoms/Textarea';
import Flex from '../../components/molecules/Flex';
import ListSkeleton from '../../components/molecules/ListSkeleton';
import Table from '../../components/molecules/Table';
import Header from '../../components/organisms/Header';
import DefaultTemplate from '../../components/templates/Default';
import Pagination from '@material-ui/lab/Pagination';
import { Type } from '../../utils/decorators';
import { useLocationSearchQueryParam } from '../../hooks/useLocationSearchQueryParam';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Modal from '../../components/molecules/Modal';
import Button from '../../components/atoms/Button';
import MultiDropdownMenu from '../../components/molecules/MultiDropdownMenu';
import {
  DeleteVisionOcrLogMutation,
  FetchVisionOcrLog,
  ModifyVisionOcrLogMutation
} from '../../hooks/http/vision';
import { ColorType } from '../../types/style';
import { useQueryClient } from '@tanstack/react-query';
import Selection from '../../components/molecules/Selection';
import {
  FilterIcon,
  MessageIcon,
  TrashIcon
} from '@aimpact-korea/arrange-front-icons/build/icons';
import { Image } from '../../components/molecules/Image';

const USED_CASE_ITEMDS = [
  {
    text: '전체' as const,
    value: 'all' as const,
    colorType: 'primary-color' as ColorType,
    checked: true
  },
  {
    text: '성공' as const,
    value: 'right' as const,
    colorType: 'primary-color' as ColorType,
    checked: false
  },
  {
    text: '실패' as const,
    value: 'fail' as const,
    colorType: 'secondary-color' as ColorType,
    checked: false
  }
];

export class LocationQuery {
  @Type(() => Number)
  pageNo?: number;
}

const VisionOcrLogsPage: React.FC = () => {
  const locationQuery = useLocationSearchQueryParam(LocationQuery);

  return (
    <DefaultTemplate>
      <Suspense fallback={<ListSkeleton />}>
        <Header currentPageName={'비전 OCR 로그'}></Header>
        <VisionOcrLogDataWrapper pageNo={locationQuery.pageNo} />
      </Suspense>
    </DefaultTemplate>
  );
};

export default VisionOcrLogsPage;

interface SearchFilter {
  pageNo?: number;
  take?: number;
  states: ('SUCCESS' | 'FAIL' | 'UN_SELECT')[];
}

function VisionOcrLogDataWrapper(
  { pageNo = 1 }: { pageNo?: number } = { pageNo: 1 }
) {
  const [usedCaseItems, setUsedCaseItems] = useState(USED_CASE_ITEMDS);
  const [filter, setFilter] = useState<SearchFilter>({
    pageNo,
    take: 10,
    states: ['SUCCESS', 'FAIL', 'UN_SELECT']
  });

  const fetchVisionOcrQuery = FetchVisionOcrLog.useFetchQuery(filter);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (pageNo) {
      setFilter((filter) => ({
        ...filter,
        pageNo
      }));
    }
  }, [pageNo]);

  const navigate = useNavigate();

  const items = fetchVisionOcrQuery.data?.data.items ?? [];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pageMetaData = fetchVisionOcrQuery.data!.data.meta;

  function onChangePage(pageNo: number) {
    navigate({
      search: createSearchParams({ pageNo: `${pageNo}` }).toString()
    });
  }

  function onChangeUsedCaseItem() {
    const states: SearchFilter['states'] = [];

    const checkItems = usedCaseItems.filter(
      (usedCaseItem) => usedCaseItem.checked === true
    );

    if (checkItems.find((checkItem) => checkItem.value === 'all')) {
      states.push('FAIL');
      states.push('SUCCESS');
      states.push('UN_SELECT');
    }

    if (checkItems.find((checkItem) => checkItem.value === 'right')) {
      states.push('SUCCESS');
    }

    if (checkItems.find((checkItem) => checkItem.value === 'fail')) {
      states.push('FAIL');
    }

    if (!checkItems.length) {
      states.push('UN_SELECT');
    }

    queryClient.invalidateQueries([FetchVisionOcrLog.KEY_STRING]);

    setFilter((filter) => ({
      ...filter,
      states: states
    }));
  }

  return (
    <Flex style={{ width: '1800px', minWidth: '100%' }} alignItems="center">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell tag="th" alignType="center" className="col-1">
              No.
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              업체명
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              OCR 데이터
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-2">
              분석 (이름/핸드폰)
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              <MultiDropdownMenu
                placeholder="케이스"
                IconComponent={FilterIcon}
                items={usedCaseItems}
                setChangeCheckedIndex={(idx, state) => {
                  usedCaseItems[idx].checked = state;

                  if (state && usedCaseItems[idx].value === 'all') {
                    usedCaseItems.forEach((usedCaseItem, index) => {
                      if (index !== idx) {
                        usedCaseItem.checked = false;
                      }
                    });
                  } else if (state) {
                    usedCaseItems.forEach((usedCaseItem) => {
                      if (usedCaseItem.value === 'all') {
                        usedCaseItem.checked = false;
                      }
                    });
                  }

                  setUsedCaseItems([...usedCaseItems]);
                }}
                handleClose={onChangeUsedCaseItem}
              />
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              메모
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              생성날짜
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              API 타입
            </Table.Cell>
            <Table.Cell tag="th" alignType="center" className="col-1">
              삭제
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {items.map((item) => (
            <VisionOcrLogData item={item} key={item.id} />
          ))}
        </Table.Body>
      </Table>
      <div>
        <Pagination
          count={pageMetaData.totalPages}
          defaultPage={1}
          page={pageMetaData.currentPage}
          boundaryCount={5}
          onChange={(_, page) => onChangePage(page)}
          variant="outlined"
        />
      </div>
    </Flex>
  );
}

function VisionOcrLogData({
  item
}: {
  item: FetchVisionOcrLog.ResponseType['items'][number];
}) {
  const [isMemoModal, setMemoModal] = useState(false);
  // const [isActiveCaseDropdown, setActiveCaseDropdown] = useState(false);

  const deleteVisionOcrLogMutation =
    DeleteVisionOcrLogMutation.useMutateQuery();
  const modifyVisionOcrLogMutation = ModifyVisionOcrLogMutation.useMutateQuery({
    onSettled: () => {
      setMemoModal(false);
    }
  });

  const [memo, setMemo] = useState(item.memo ?? '');

  useEffect(() => {
    if (!isMemoModal) {
      setMemo(item.memo ?? '');
    }
  }, [isMemoModal, item.memo]);

  function onChangeSuccessFilter(value: string) {
    modifyVisionOcrLogMutation.mutate({
      id: item.id,
      isSuccessed: value === 'success'
    });
  }

  function onClickShowMemoModal() {
    setMemoModal(true);
  }

  function onClickChangeMemo() {
    if (!memo) {
      alert('메모를 입력해주세요.');
      return;
    }

    modifyVisionOcrLogMutation.mutate({
      id: item.id,
      memo
    });
  }

  function onClickDeleteMemo() {
    if (confirm('비전 OCR 로그의 메모를 삭제 하시겠습니까?')) {
      modifyVisionOcrLogMutation.mutate({
        id: item.id,
        memo: null
      });
    }
  }

  function onClickRemoveMessageAnalysisLog() {
    if (confirm('비전 OCR 로그를 삭제하시겠습니까?')) {
      deleteVisionOcrLogMutation.mutate({
        id: item.id
      });
    }
  }

  return (
    <React.Fragment>
      {isMemoModal && (
        <Modal
          isOpen={isMemoModal}
          sizeType="large"
          handleClose={() => setMemoModal(false)}
        >
          <Modal.Header>
            <Modal.Header.Title text="메모를 입력합니다" />
          </Modal.Header>
          <Modal.Body>
            <Textarea
              style={{ width: '100%', height: '320px' }}
              cols={5}
              placeholder="메모를 입력합니다."
              defaultValue={memo}
              onBlur={(event) => setMemo(event.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Flex direction="row" style={{ width: '100%' }} gap="xsmall">
              {item.memo && (
                <Button
                  text="삭제"
                  fullwidth={true}
                  type="secondary-color"
                  onClick={() => onClickDeleteMemo()}
                />
              )}
              <Button
                text="수정"
                fullwidth={true}
                onClick={() => onClickChangeMemo()}
              />
            </Flex>
          </Modal.Footer>
        </Modal>
      )}
      <Table.Body.Row>
        <Table.Body.Row.Cell alignType="center">
          <Text size="small" isBold={true}>
            No. {item.id}
          </Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          {item.store?.company}
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Image
            alt="ocr 이미지"
            width={340}
            src={`${item.attachment.fullImageUrl}`}
            style={{ width: '340px' }}
          />
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap="medium"
          >
            {item.response.name && <Text>{item.response.name}</Text>}
            {item.response.phone && <Text>{item.response.phone}</Text>}
          </Flex>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Flex direction="column" gap="small">
            <Selection>
              <Selection.Input
                type="radio"
                value="success"
                id={`${item.id}_successed_state_success`}
                isChecked={item.isSuccessed === true}
                onChangeChecked={() => onChangeSuccessFilter('success')}
              />
              <Selection.Label
                htmlFor={`${item.id}_successed_state_success`}
                colorType="primary-color"
                style={{
                  fontWeight: item.isSuccessed === true ? 'bold' : 'normal'
                }}
              >
                성공
              </Selection.Label>
            </Selection>
            <Selection>
              <Selection.Input
                type="radio"
                value="fail"
                id={`${item.id}_successed_state_fail`}
                isChecked={item.isSuccessed === false}
                onChangeChecked={() => onChangeSuccessFilter('fail')}
              />
              <Selection.Label
                htmlFor={`${item.id}_successed_state_fail`}
                colorType="secondary-color"
                style={{
                  fontWeight: item.isSuccessed === false ? 'bold' : 'normal'
                }}
              >
                실패
              </Selection.Label>
            </Selection>
          </Flex>
          {/* <DropdownMenu
            isActive={isActiveCaseDropdown}
            setActive={setActiveCaseDropdown}
            setValue={onChangeSuccessFilter}
            value={
              item.isSuccessed === null
                ? undefined
                : item.isSuccessed
                ? 'success'
                : 'fail'
            }
          >
            <DropdownMenu.Title>
              <DropdownMenu.Title.UpDownIcon />
              <DropdownMenu.Title.Text placeholder="성공여부" />
            </DropdownMenu.Title>

            <DropdownMenu.ItemWrapper alignType="center" size="small">
              <DropdownMenu.ItemWrapper.ListVerticalItemGroup>
                <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                  value={'success'}
                  colorType="primary-color"
                >
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text text="성공" />
                </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>

                <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item
                  value={'fail'}
                  colorType="secondary-color"
                >
                  <DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item.Text text="실패" />
                </DropdownMenu.ItemWrapper.ListVerticalItemGroup.Item>
              </DropdownMenu.ItemWrapper.ListVerticalItemGroup>
            </DropdownMenu.ItemWrapper>
          </DropdownMenu> */}
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <div
            style={{ cursor: 'pointer', display: 'inline-block' }}
            onClick={() => onClickShowMemoModal()}
          >
            <MessageIcon
              colorType={item.memo ? 'text-primary-500' : 'text-black'}
            />
          </div>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Text>{item.createdAt}</Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <Text>{item.visionOcrLogType === 'NAVER' ? '네이버' : '구글'}</Text>
        </Table.Body.Row.Cell>
        <Table.Body.Row.Cell alignType="center">
          <div
            style={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={() => onClickRemoveMessageAnalysisLog()}
          >
            <TrashIcon colorType="text-gray-800" />
          </div>
        </Table.Body.Row.Cell>
      </Table.Body.Row>
    </React.Fragment>
  );
}
