import classNames from 'classnames/bind';
import React from 'react';
import { AlignType } from '../../../../types/style';
import styles from './cell.module.scss';

const cx = classNames.bind(styles);

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
  textAlign?: AlignType;
}

const Cell = React.forwardRef<HTMLDivElement, Props>(
  ({ children, textAlign = 'center', ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cx('container', `text-align--${textAlign}`)}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Cell.displayName = 'Cell';

export default Cell;
