import { ExRoundIcon } from '@aimpact-korea/arrange-front-icons/build/icons';
import classNames from 'classnames/bind';
import { useContextApi } from '../context';
import BodyIcon from './BodyIcon';
import styles from './clear-icon.module.scss';

const cx = classNames.bind(styles);

const ClearIcon: React.FC = () => {
  const { state } = useContextApi();

  return (
    <BodyIcon
      IconComponent={ExRoundIcon}
      onClick={() => {
        state.onChangeValue?.('');
      }}
      fill="text-gray-500"
      className={cx('container', state.isFocus && 'visible')}
    />
  );
};

export default ClearIcon;
