import { useContextApi } from '../context';
import Text from './Text';
import UpDownIcon from './UpDownIcon';
import styles from './title.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export interface Props {
  isBottomLine?: boolean;
  children: React.ReactNode;
}

type TitleComponent = React.FC<Props> & {
  Text: typeof Text;
  UpDownIcon: typeof UpDownIcon;
};

const Title: TitleComponent = ({ children, isBottomLine = false }: Props) => {
  const { state } = useContextApi();

  const handleClickEvent = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!state.disabled) {
      e.stopPropagation();

      state.setActive?.(!state.isActive);
    }
  };

  return (
    <>
      <div
        className={`${cx(
          'title-container',
          `size--${state.size}`
        )} font-size--${state.size})`}
        onClick={(e) => handleClickEvent(e)}
      >
        {children}
      </div>
      <div
        className={cx(isBottomLine && 'bottom-line', state.value && 'active', {
          disabled: state.disabled
        })}
      ></div>
    </>
  );
};

Title.Text = Text;
Title.UpDownIcon = UpDownIcon;
export default Title;
