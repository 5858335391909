import {
  QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { StopWordType } from '../../../types/stop-word';
import axios from '../../../utils/axios';

export namespace FetchStopWordName {
  export type RequestType = {
    type: StopWordType;
  };
  export type ResponseType = { words: string[] };

  export const KEY_STRING = 'fetch-stop-word-name' as const;

  export type QueryKeyType = [typeof KEY_STRING, RequestType];
  export type TQueryKey = QueryKeyType | QueryKey;

  export async function fetch({
    queryKey
  }: QueryFunctionContext<QueryKeyType>) {
    return await axios.get<ResponseType>('/stop-word/name', {
      params: {
        type: queryKey[1].type
      }
    });
  }

  export const useFetchQuery = (
    { type }: RequestType,
    {
      ...props
    }: UseQueryOptions<
      AxiosResponse<ResponseType>,
      AxiosError,
      AxiosResponse<ResponseType>,
      QueryKeyType
    > = {}
  ) => {
    const errorHandler = useErrorHandler();

    const query = useQuery([KEY_STRING, { type: type }], fetch, {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      staleTime: Infinity,
      onError: errorHandler,
      ...props
    });

    return query;
  };
}
