import React from 'react';
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
  DroppableProvided,
  DroppableStateSnapshot,
  Droppable,
  Draggable,
  DraggableChildrenFn,
  DragStart
} from 'react-beautiful-dnd';

// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/tables.md

export interface SortableContainerProps {
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  onBeforeDragStart?: (initial: DragStart) => void;
  children: React.ReactNode;
}

export const SortableContainer: React.FC<SortableContainerProps> = ({
  children,
  onDragEnd,
  onBeforeDragStart
}) => {
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onBeforeDragStart={onBeforeDragStart}
    >
      {children}
    </DragDropContext>
  );
};

export interface SortableItemsProps {
  droppableId: string;
  children(
    provided: DroppableProvided,
    snapshot: DroppableStateSnapshot
  ): React.ReactElement<HTMLElement>;
  isDropDisabled?: boolean;
}

export const SortableItems: React.FC<SortableItemsProps> = ({
  children,
  droppableId,
  isDropDisabled = false
}) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled}>
      {children}
    </Droppable>
  );
};

export interface SortableItemProps {
  id: string;
  index: number;
  children: DraggableChildrenFn;
}

export const SortableItem: React.FC<SortableItemProps> = ({
  id,
  index,
  children
}) => {
  return (
    <Draggable draggableId={id} index={index}>
      {children}
    </Draggable>
  );
};
