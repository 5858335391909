import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import {
  FontWeightType,
  SizeType,
  ColorType,
  AlignType
} from '../../../types/style';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

export type Props = (
  | React.ComponentPropsWithoutRef<'p'>
  | React.ComponentPropsWithoutRef<'span'>
) & {
  isBold?: boolean;
  size?: SizeType;
  colorType?: ColorType;
  className?: string;
  tag?: 'p' | 'span';
  alignType?: AlignType;
  whiteSpace?: 'nowrap' | 'wrap';
  textDecoration?: 'underline' | '';
};

const Text: React.FC<Props> = ({
  children,
  isBold = false,
  size = 'medium',
  colorType: color,
  className = '',
  tag = 'span',
  alignType = false,
  whiteSpace = 'nowrap',
  textDecoration = '',
  style,
  ...props
}) => {
  const [fontWeight, setFontWeight] = useState<FontWeightType>('normal');
  const [customProps, setCustomProps] =
    useState<React.HTMLProps<HTMLSpanElement | HTMLParagraphElement>>();

  useEffect(() => {
    if (isBold) {
      setFontWeight('bold');
    } else {
      setFontWeight('normal');
    }
  }, [isBold]);

  useEffect(() => {
    setCustomProps({
      className: `${cx(
        'text',
        `${whiteSpace ? `white-space--${whiteSpace}` : ''}`
      )} font-weight--${fontWeight}
      ${color && `font-color--${color}`}
      ${size && `font-size--${size}`} ${className}
      ${(alignType && `text-align--${alignType}`) || ''}`,
      style: { textDecoration: textDecoration, ...style }
    });
  }, [
    alignType,
    className,
    color,
    fontWeight,
    size,
    style,
    textDecoration,
    whiteSpace
  ]);

  if (tag === 'p') {
    return (
      <p {...(customProps as unknown)} {...props}>
        {children}
      </p>
    );
  }

  return (
    <span {...customProps} {...props}>
      {children}
    </span>
  );
};

export default Text;
