import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export default function ContractTerms() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/contract-terms/arrange-agreement');
  }, [navigate]);
  return <div></div>;
}
