import { AxiosError, AxiosResponse } from 'axios';
import { useErrorHandler } from 'react-error-boundary';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../../../../../utils/axios';
import { LinkfarmProductResellSalePeriodType } from '../../../../../types/linkfarm';
import { AimpactAxiosError } from '../../..';
import * as Yup from 'yup';
import { $enum } from 'ts-enum-util';
import dayjs from 'dayjs';

export namespace CreateLinkfarmProductProductResellApi {
  export type Parameter = {
    linkfarmProductId: number;
    salePeriodStartAt?: string | null;
    salePeriodEndAt?: string | null;
    saleCommission: number;
    resellerCommission: number;
    salePeriodType: LinkfarmProductResellSalePeriodType;
    maxPickCount: number;
  };

  export const linkfarmProductResellSchema: Yup.SchemaOf<Parameter> =
    Yup.object().shape({
      linkfarmProductId: Yup.number().required(),
      resellerCommission: Yup.number()
        .required('리셀러 수수료는 필수입니다.')
        .min(0.01, '리셀러 수수료는 최소 0.01 부터 가능합니다.')
        .max(50, '리셀러 수수료는 최대 50까지 가능합니다'),
      saleCommission: Yup.number()
        .required()
        .min(0.01, '판매 수수료는 최소 0.01 부터 가능합니다.')
        .max(50, '리셀러 수수료는 최대 50까지 가능합니다'),
      salePeriodType: Yup.mixed()
        .oneOf($enum(LinkfarmProductResellSalePeriodType).getValues())
        .required(),
      salePeriodStartAt: Yup.string().when('salePeriodType', {
        is: LinkfarmProductResellSalePeriodType.PERIOD,
        then: Yup.string()
          .required('판매기간을 설정해주세요.')
          .test('date', '판매기간을 설정해주세요.', (value) =>
            dayjs(value).isValid()
          ),
        otherwise: Yup.string().optional().nullable()
      }),
      salePeriodEndAt: Yup.string().when('salePeriodType', {
        is: LinkfarmProductResellSalePeriodType.PERIOD,
        then: Yup.string()
          .required('판매기간을 설정해주세요.')
          .test('date', '판매기간을 설정해주세요.', (value) =>
            dayjs(value).isValid()
          ),
        otherwise: Yup.string().optional().nullable()
      }),
      maxPickCount: Yup.number()
        .required('리셀러 픽 카운트를 입력해주세요.')
        .min(1, '리셀러 픽 카운트는 최소 1명 부터 가능합니다.')
        .max(10000, '리셀러 픽 카운트는 최대 10000명 까지 가능합니다.')
    });

  export const useMutate = ({
    ...options
  }: UseMutationOptions<
    AxiosResponse,
    AxiosError<AimpactAxiosError>,
    Parameter
  > = {}) => {
    const errorHandler = useErrorHandler();

    const mutation = useMutation(
      ({
        linkfarmProductId,
        resellerCommission,
        saleCommission,
        salePeriodType,
        salePeriodEndAt,
        salePeriodStartAt,
        maxPickCount
      }) => {
        // @TODO: form에서 해야 함
        linkfarmProductResellSchema.validateSync({
          linkfarmProductId,
          resellerCommission,
          saleCommission,
          salePeriodType,
          salePeriodEndAt,
          salePeriodStartAt,
          maxPickCount
        });

        return axios.post(`/linkfarm/product/resell`, {
          linkfarmProductId,
          resellerCommission,
          saleCommission,
          salePeriodType,
          salePeriodEndAt,
          salePeriodStartAt,
          maxPickCount
        });
      },
      {
        onError: errorHandler,
        ...options
      }
    );

    return mutation;
  };
}
